import { useContext, useState } from "react";
import { fabric } from "fabric";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrush } from "@fortawesome/free-solid-svg-icons";

const FabricGradient = () => {
  const { canvas } = useContext(CanvasContext);
  const [options, setOptions] = useState({
    left: 100,
    top: 100,
    stroke: "black",
    hoverCursor: "default",
    strokeWidth: 6,
  });

  const addGradiant = () => {
    var circle = new fabric.Circle({
      left: 100,
      top: 100,
      radius: 50,
    });

    /*     var gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "pixels", // or 'percentage'
      coords: { x1: 0, y1: 0, x2: 0, y2: circle.height },
      colorStops: [
        { offset: 0, color: "#000" },
        { offset: 1, color: "#fff" },
      ],
    }); */
    var gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "pixels", // or 'percentage'
      coords: { x1: 0, y1: 0, x2: circle.width, y2: 0 },
      colorStops: [
        { offset: 0, color: "red" },
        { offset: 1, color: "blue" },
      ],
    });
    // or in percentage
    var gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "percentage",
      coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
      colorStops: [
        { offset: 0, color: "red" },
        { offset: 1, color: "blue" },
      ],
    });

    var gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "pixels", // or 'percentage'
      coords: { x1: 0, y1: 0, x2: circle.width, y2: 0 },
      colorStops: [
        { offset: 0, color: "red" },
        { offset: 0.2, color: "orange" },
        { offset: 0.4, color: "yellow" },
        { offset: 0.6, color: "green" },
        { offset: 0.8, color: "blue" },
        { offset: 1, color: "purple" },
      ],
    });

    circle.set("fill", gradient);
    canvas.add(circle);
  };

  return (
    <>
      <button onClick={addGradiant} className="text-[48px]">
        <FontAwesomeIcon icon={faBrush}></FontAwesomeIcon>
      </button>
    </>
  );
};

export default FabricGradient;

import { useContext, useState } from "react";
import { fabric } from "fabric";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCircle,
  faTriangleCircleSquare,
} from "@fortawesome/free-solid-svg-icons";

const FabricShape = () => {
  const { canvas } = useContext(CanvasContext);

  const addCircle = () => {
    const circle = new fabric.Circle({
      radius: 20,
      fill: "green",
      left: 100,
      top: 100,
    });
    canvas.add(circle);
  };
  const addTriangle = () => {
    const triangle = new fabric.Triangle({
      width: 20,
      height: 30,
      fill: "blue",
      left: 50,
      top: 50,
    });

    canvas.add(triangle);
  };
  const addPolygon = () => {
    const triangle = new fabric.Polygon(
      [
        { x: 185, y: 0 },
        { x: 250, y: 100 },
        { x: 385, y: 170 },
        { x: 0, y: 245 },
      ],
      {
        left: 100,
        top: 50,
        fill: "red",
      }
    );

    canvas.add(triangle);
  };
  const addImage = () => {
    // Load Image from <img> Tag
    /* var imgElement = document.getElementById("my-image");
    var imgInstance = new fabric.Image(imgElement, {
      left: 100,
      top: 100,
      angle: 30,
      opacity: 0.85,
    });
    canvas.add(imgInstance); */
    //Load from assets Folder
    /* fabric.Image.fromURL("my_image.png", function (oImg) {
      canvas.add(oImg);
    }); */
    //Load from URL
    /* fabric.Image.fromURL("my_image.png", function (oImg) {
      oImg.scale(0.5).set("flipX", true);
      canvas.add(oImg);
    }); */
  };

  const addPath = () => {
    var path = new fabric.Path(
      "M121.32,0L44.58,0C36.67,0,29.5,3.22,24.31,8.41\
    c-5.19,5.19-8.41,12.37-8.41,20.28c0,15.82,12.87,28.69,28.69,28.69c0,0,4.4,\
    0,7.48,0C36.66,72.78,8.4,101.04,8.4,101.04C2.98,106.45,0,113.66,0,121.32\
    c0,7.66,2.98,14.87,8.4,20.29l0,0c5.42,5.42,12.62,8.4,20.28,8.4c7.66,0,14.87\
    -2.98,20.29-8.4c0,0,28.26-28.25,43.66-43.66c0,3.08,0,7.48,0,7.48c0,15.82,\
    12.87,28.69,28.69,28.69c7.66,0,14.87-2.99,20.29-8.4c5.42-5.42,8.4-12.62,8.4\
    -20.28l0-76.74c0-7.66-2.98-14.87-8.4-20.29C136.19,2.98,128.98,0,121.32,0z"
    );
    path.set({ left: 120, top: 120 });
    path.set({ fill: "red", stroke: "green", opacity: 0.5 });

    canvas.add(path);
  };

  //Ellipse,Polygon,Polyline needs to ADD  TODO

  return (
    <>
      <button onClick={addCircle} className="text-[48px]">
        <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
      </button>
      <button onClick={addTriangle} className="text-[48px]">
        <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
      </button>
      {/* <button onClick={addImage}>Add Image</button> */}
      {/* <button
        onClick={addPath}
        className="inline-flex  items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 w-1/3"
      >
        <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
      </button> */}
    </>
  );
};

export default FabricShape;

export const tabItems = [
  {
    icon: "uploads",
    name: "Uploads",
  },
  {
    icon: "photos",
    name: "photos",
  },
  {
    icon: "background",
    name: "backgrounds",
  },
  // {
  //   icon: "texts",
  //   name: "text",
  // },

  {
    icon: "elements",
    name: "elements",
  },
];

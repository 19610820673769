import {
  faArrowRight,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router";

function Welcome(props) {
  let navigate = useNavigate();
  const postGrid = () => {
    navigate("/dashboard/postGrid");
  };

  const [monthslist, setMonthsList] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [generateDesigns, setgenerateDesigns] = useState(0);

  // const [designs_count] = 10; // Or something else

  const designs = [...Array(generateDesigns)].map((e, i) => (
    <option value={i + 1}>{i + 1}</option>
  ));

  const [currentMonth, setcurrentMonth] = useState("February");
  const [totaldays, settotaldays] = useState([
    { January: 31 },
    { February: 29 },
    { March: 31 },
    { April: 30 },
    { May: 31 },
    { June: 30 },
    { July: 31 },
    { August: 31 },
    { September: 30 },
    { October: 31 },
    { November: 30 },
    { December: 31 },
  ]);
  const [isCurrent, setIscurrent] = useState(false);

  let newDate = new Date();
  let today_date = newDate.getDate();

  const selectedMonth = (e) => {
    // console.log(e.target.value);
    if (e.target.value === currentMonth) {
      setIscurrent(true);
      let monthIndex = monthslist.findIndex((month) => month === currentMonth);
      let remaining_days = totaldays[monthIndex][currentMonth] - today_date;
      setgenerateDesigns(remaining_days);
    } else {
      setIscurrent(false);
      let monthIndex = monthslist.findIndex(
        (month) => month === e.target.value
      );
      setgenerateDesigns(totaldays[monthIndex][e.target.value]);
      console.log(totaldays[monthIndex][e.target.value]);
    }
  };

  return (
    <React.Fragment>
      <div className=" bg-white  flex justify-center items-center ">
        <div className="align-middle md:align-top pt-32">
          <h2 className=" font-semibold	text-center mb-6 text-2xl 	">
            Welcome Karyahub
          </h2>
          <form>
            <div className="">
              <div className="grid">
                <div className="flex ">
                  <label
                    className="block text-[15px] text-gray-800 font-normal md:text-right pr-2 pt-2"
                    htmlFor="inline-full-name"
                  >
                    Generate Designs for
                  </label>

                  {/* <div className="md:w-2/3">
                    <select className="w-16 border border-gray-400 ">
                      <option value="">0</option>
                      {designs}
                    </select>
                  </div> */}
                  {/*  <div className="md:w-1/3">
                    <label
                      className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-2 w-32"
                      htmlFor="inline-full-name"
                    >
                      Designs for
                    </label>
                  </div> */}
                  <div className="float-right">
                    <select
                      className="w-36 h-10 border border-gray-400 "
                      onChange={selectedMonth}
                    >
                      <option value="">Select</option>
                      {monthslist.map((monthname) => (
                        <option value={monthname}>{monthname}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex mt-5 w-72">
                  <p className="text-red-600 text-[12px]">
                    {isCurrent &&
                      `Only ${generateDesigns} days remaining in ${currentMonth} month. So you can generate a maximum of ${generateDesigns} creatives only`}
                  </p>
                </div>
                <div className="flex mt-5">
                  <label
                    className="block text-[15px] text-gray-800 font-normal md:text-right pr-2 pt-2"
                    htmlFor="inline-full-name"
                  >
                    No.of Designs Required
                  </label>

                  <div className="">
                    <select className="w-36 h-10 border border-gray-400">
                      <option value="">0</option>
                      {designs}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                onClick={postGrid}
                className=" rounded-3xl block py-2 m-auto w-48 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Generate Designs
                {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-4" /> */}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Welcome;

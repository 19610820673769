import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function PostAlternative(props) {
  return (
    <>
      <div className="inline-grid ">
        <div className="group relative ">
          <img
            alt="example"
            className=" h-64"
            // src="https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg"
            src={props.image}
          />
          <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-grey-900 opacity-0 group-hover:h-full group-hover:opacity-80  group-hover:bg-black">
            <div className=" rounded-full shadow-sm " role="group">
              <button
                type="button"
                className=" items-center    p-1 w-7 text-sm font-medium  text-white border border-gray-200 hover:bg-[#6659E3] 
                
                   dark:focus:text-white  rounded-full"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ height: "12px" }}
                ></FontAwesomeIcon>
              </button>
              <p className=" text-xs text-white py-2 text-center">Add</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostAlternative;

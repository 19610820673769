import React, { useState } from "react";
import PostAlternative from "./PostAlternative";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowLeftLong,
  faBox,
  faDownload,
  faExpand,
  faGreaterThan,
  faLessThan,
  faPencil,
  faPlus,
  faShareNodes,
  faSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import BackDropModal from "../Utils/Modal";
import DownloadFilter from "./DownloadFilter";
import ShareFilter from "./ShareFilter";

function PostFullView(props) {
  const [deletePopup, setdeletePopup] = useState(false);
  const deletePopupDisplay = () => {
    setdeletePopup((prevValue) => !prevValue);
  };
  let navigate = useNavigate();
  const Editor = () => {
    navigate("/editor");
  };
  const [customDesignshow, setcustomDesignshow] = useState(false);
  const [image, setimage] = useState("");
  const customDesignDisplay = (event, image) => {
    setcustomDesignshow((prevValue) => !prevValue);
    setimage(image);
  };
  let alternativepostItems;
  const instade_for_list = useStore((state) => state.alternative_designs);
  alternativepostItems = instade_for_list.map((item, index) => (
    <PostAlternative {...item} key={index}></PostAlternative>
  ));
  let bigpostItems;
  const big_post_list = useStore((state) => state.big_items);
  bigpostItems = big_post_list.map((item, index) => (
    <div className="items-center content-center " key={index}>
      <div className="px-2  ">
        <div className="font-normal text-[10px] w-64 mb-3">
          <span className=" ">
            <FontAwesomeIcon
              icon={faLessThan}
              className="mr-4 text-gray-500"
              style={{ height: "10px" }}
            />{" "}
            1 / 3
            <FontAwesomeIcon
              icon={faGreaterThan}
              className="ml-4 text-gray-500"
              style={{ height: "10px" }}
            />
          </span>
        </div>
        <img alt="example" className="w-full  h-80" src={item.image} />
        {customDesignshow && (
          <BackDropModal
            onConfirmed={customDesignDisplay}
            className="items-center justify-center "
          >
            <div className="z-50 border-0   rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
              {/*body*/}
              <div className="relative p-6  flex-auto ">
                <button
                  className="absolute right-2 top-2 items-center text-center w-10 px-2 py-1 rounded-full hover:text-white   hover:bg-[#6659E3]   text-black
                 bg-[#E6E9EF]  float-right  text-2xl"
                  onClick={() => customDesignDisplay(false)}
                >
                  <span className="">×</span>
                </button>
                <img src={image} className="w-fit h-fit md:h-[550px] " />
              </div>
              {/*footer*/}
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={customDesignDisplay}
            ></div>
          </BackDropModal>
        )}
      </div>

      <div className="text-center my-2" role="group">
        <button
          type="button"
          onClick={Editor}
          className="mr-1 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-900 bg-[#E6E9EF] border border-gray-200 hover:bg-blue-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
          title="Edit Image"
        >
          <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
        </button>
        <button
          type="button"
          className="mr-1 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-900 bg-[#E6E9EF] border-t border-b border-gray-200 hover:bg-blue-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
          title="Full screen"
          // onClick={customDesignDisplay}
          onClick={(event) => customDesignDisplay(event, item.image)}
        >
          <FontAwesomeIcon icon={faExpand}></FontAwesomeIcon>
        </button>

        <button
          type="button"
          onClick={deletePopupDisplay}
          className="mr-1 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-900 bg-[#E6E9EF] border border-gray-200  hover:bg-blue-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
          title="Delete image"
        >
          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
        </button>
      </div>
    </div>
  ));

  return (
    <>
      <div className="mx-3 my-6">
        <div className="text-left">
          <Link
            to={"/dashboard/PostGrid"}
            type="submit"
            className="text-center rounded-3xl block py-1  w-24 text-[13px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} className="mr-3" /> Back
          </Link>
        </div>
        <div className="grid grid-cols-2">
          <div className="font-semibold text-[19px] my-6">
            <p className="ml-2">1. Happy May Day</p>
          </div>
          <div>
            <div className=" text-right pr-7 pt-8">
              <DownloadFilter></DownloadFilter>
              <ShareFilter></ShareFilter>
              {/*  <button
              id="dropdownDeleteButton"
              type="button"
              className=" rounded-full  p-2 ml-1 border  w-10 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
              title="Download image"
            >
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </button> */}
              {/* <button
              id="dropdownShareButton"
              data-dropdown-toggle="dropdownShare"
              type="button"
              className=" rounded-full  p-2 ml-1 border  w-10 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
              title="Share"
            >
              <FontAwesomeIcon icon={faShareNodes}></FontAwesomeIcon>
            </button> */}
            </div>
          </div>
        </div>
        <div className="mx-[133px] my-4">
          <div className="grid grid-cols-2 mx-15">{bigpostItems}</div>
        </div>
        <div className="my-5">
          <h1 className="font-bold text-xl py-2">Alternative Designs</h1>
          <h2 className="text-xs ">15 templates</h2>
        </div>
        <div className="grid grid-cols-5 gap-3 mb-20 mr-8">
          {alternativepostItems}
          <div className="     h-64 items-center flex justify-center bg-[#6659E3]">
            <div className="mb-1 ">
              <div className="relative ">
                <div className="text-center">
                  <button className="  text-white  m-auto w-6 font-bold rounded-full border hover:bg-[#6556f0] ">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-semibold text-[12px]"
                    ></FontAwesomeIcon>
                  </button>
                  <p className=" text-xs text-white m-2">Generate More</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deletePopup && (
        <BackDropModal
          onConfirmed={deletePopupDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0  rounded-2xl shadow-lg relative flex flex-col w-96 bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between pt-5 pl-5  rounded-t">
              <h3 className="text-xl font-bold">Delete Project</h3>
              {/* <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => deletePopupDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button> */}
            </div>
            {/*body*/}
            <div className="relative  flex-auto">
              <form className="">
                <h2 className="text-[12px] ml-5 pt-2 ">
                  Do you want to remove Post?
                </h2>
              </form>
            </div>
            {/*footer*/}
            <div className="  p-4   rounded-b  w-full">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => deletePopupDisplay(false)}
                className=" rounded-3xl block py-2 w-32 float-right text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Yes Remove
              </button>
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => deletePopupDisplay(false)}
                className=" rounded-3xl block py-2 float-right mr-5  text-[12px]     "
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={deletePopupDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default PostFullView;

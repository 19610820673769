import React, { useState } from "react";
import BackDropModal from "../Utils/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

function Logout(props) {
  let navigate = useNavigate();
  const [logout, setlogout] = useState(false);
  const logoutDisplay = () => {
    setlogout((prevValue) => !prevValue);
  };

  const logoutUser = (msg) => {
    if (msg === "NO") {
      setlogout(false);
    } else {
      setlogout(false);
      return navigate("/login");
    }
  };
  return (
    <>
      <button onClick={logoutDisplay} className="w-full">
        <div className="flex items-center space-x-4">
          <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white">
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </div>
          <div className="">
            <p className="text-sm font-medium  truncate dark:text-white">
              Logout
            </p>
          </div>
        </div>
      </button>

      {logout && (
        <BackDropModal
          onConfirmed={logoutDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}

            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="">
                <h2 className="text-xs font-bold text-center mx-10">
                  Are you sure to logout?
                </h2>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                // onClick={dashboardScreen}

                onClick={() => logoutUser("NO")}
                className=" font-medium  dark:text-blue-500 underline "
              >
                No
              </button>

              <button
                // onClick={dashboardScreen}

                onClick={() => logoutUser("YES")}
                className=" rounded-3xl mx-auto mr-0  py-2 w-20 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Yes
                <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={logoutDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default Logout;

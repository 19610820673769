import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CanvasArea from "./CanvasArea/CanvasArea";
import "./Editor.scss";
import FooterMenu from "./FooterMenu/FooterMenu";
import Navbar from "./Navbar/Navbar";
import Panels from "./Panels/Panels";
import Toolbox from "./Toolbox/Toolbox";
import {
  faGreaterThan,
  faLessThan,
  faSlash,
} from "@fortawesome/free-solid-svg-icons";

function Editor() {
  return (
    <div className="editor">
      <Navbar />
      <div className="section-two">
        <Panels />
        <div className="section-three ">
          <div className="grid">
            <div className="mx-5 mt-5 mb-0 flex px-5">
              <div className="flex-1 font-semibold text-[19px]">
                May Day Post
              </div>
              <div className="flex-1 pt-2">
                <span className="inline-block text-right float-right text-[12px]">
                  <span className="font-semibold mr-5">Version</span>
                  <FontAwesomeIcon icon={faLessThan} className="mr-2" /> 1
                  <span className="text-[9px] mx-2">/</span> 3
                  <FontAwesomeIcon icon={faGreaterThan} className="ml-2" />
                </span>
              </div>
            </div>
          </div>
          <div className="items-center grid justify-center  content-center">
            <CanvasArea />
          </div>
          {/* <FooterMenu /> */}
        </div>
        {/* <div className="section-three"><Toolbox /> 22-12-2023</div> */}
      </div>
    </div>
  );
}

export default Editor;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  faArrowRightFromBracket,
  faEllipsisH,
  faEllipsisVertical,
  faFileLines,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout from "./Logout";

function ProfileMenu(props) {
  const [menuDisplay, useMenuDisplay] = useState(false);
  let navigate = useNavigate();

  const MenuDisplayAction = () => {
    useMenuDisplay((prevValue) => !prevValue);
  };
  return (
    <>
      <div className="m-3 ">
        <div className="fixed bottom-0 text-xs w-48 mb-4 hover:bg-[#6659E3] hover:text-white   rounded-md  py-1">
          {menuDisplay && (
            <>
              <div
                id="dropdownRight"
                className=" absolute z-50 bottom-8 w-full  bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm  dark:text-gray-200   divide-y divide-gray-200 dark:divide-gray-700  bg-black text-white cursor-pointer"
                  aria-labelledby="dropdownRightButton"
                >
                  <li className="p-2">
                    <Link to={"/dashboard/profile"}>
                      <div className="flex items-center space-x-4">
                        <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white">
                          <FontAwesomeIcon icon={faGear} />
                        </div>
                        <div className="">
                          <p className="text-sm font-medium  truncate dark:text-white">
                            Settings
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to={"/dashboard/PrivacyPolicy"}>
                      <div className="flex items-center space-x-4">
                        <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white">
                          <FontAwesomeIcon icon={faFileLines} />
                        </div>
                        <div className="">
                          <p className="text-sm font-medium  truncate dark:text-white">
                            Policies
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="p-2">
                    <Logout></Logout>
                  </li>
                </ul>
              </div>
              <div
                className="opacity-25 fixed inset-0 z-40 "
                onClick={MenuDisplayAction}
              ></div>
            </>
          )}
          <div
            className="flex items-center space-x-2 hover:text-white dark:text-white "
            onClick={MenuDisplayAction}
          >
            <div className="flex-shrink-0">
              <img
                className="w-5 rounded-full"
                src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                alt="Neil "
              />
            </div>
            <div className="flex-1 w-full">
              <p className="text-[12px] font-medium  truncate dark:text-white ">
                Praveen Kumar
              </p>
            </div>
            <div className="px-2 inline-flex items-center font-semibold hover:text-white ">
              <FontAwesomeIcon icon={faEllipsisH} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileMenu;

import {
  faCheckCircle,
  faCircle,
  faCircleDot,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Checkbox({ name, value = false, updateValue = () => {}, children }) {
  // handle checkbox change
  const handleChange = () => {
    updateValue(!value, name);
  };
  return (
    <>
      {/* <input
        type="checkbox"
        id={`${name}-checkbox`}
        name={name}
        checked={value}
        onChange={handleChange}
      />
      <label for={`${name}-checkbox`} className="ml-1 capitalize">
        {children}
      </label> */}

      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={handleChange}
        className="hidden peer "
        required=""
        id={`${name}-checkbox`}
      />
      <label
        htmlFor={`${name}-checkbox`}
        className="inline-block items-center  justify-between w-full text-gray-500 bg-white border-[1px] border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700  hover:text-gray-600 dark:peer-checked:text-gray-300  hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-[10px] peer-checked:bg-[#6659E3] peer-checked:text-white peer-checked:border-[#6659E3] p-1 "
      >
        <div className="inline-block w-full ">
          {/* <FontAwesomeIcon
                    icon={faCircle}
                    className="mx-2 peer-checked:hidden"
                  ></FontAwesomeIcon> */}
          {value && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#f3f2fd"
              size="lg"
              className="mr-2 peer-checked:visible"
            ></FontAwesomeIcon>
          )}
          {!value && (
            <FontAwesomeIcon
              icon={faCircle}
              color="#F3F2FD"
              size="lg"
              // fill="red"
              className="mr-2 peer-checked:visible "
            ></FontAwesomeIcon>
          )}
          {children}
        </div>
      </label>
    </>
  );
}

export default Checkbox;

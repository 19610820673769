import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import banner from "../../assets/images/banner.png";
import home_banner1 from "../../assets/images/home_banner1.png";
import home_banner2 from "../../assets/images/home_banner2.png";
import home_banner3 from "../../assets/images/home_banner3.png";
import home_banner4 from "../../assets/images/home_banner4.png";
import { Link } from "react-router-dom";
import InstadeSlider from "./InstadeSlider";
function Home(props) {
  return (
    <>
      <div
        style={{
          background:
            "rgb(201,191,229) linear-gradient(90deg, rgba(201,191,229,1) 26%, rgba(255,227,227,1) 89%)",
        }}
      >
        <section
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
          }}
          className="h-screen"
        >
          {/* banner screen */}
          <div className="text-center text-black">
            <p className=" text-4xl  pb-4  pt-10	 font-extrabold">
              AI Powered Instant Design Generator
            </p>
            <p className="font-semibold pb-4">
              You Can Generate Stunning Designs Instantly like a Magic
            </p>
            <Link
              // onClick={dashboardScreen}
              to="/login"
              type="submit"
              className=" rounded-3xl block py-3 w-32 text-sm text-white bg-blue-500 block mt-4 lg:inline-block lg:mt-0  mr-4 outline-none"
              style={{ backgroundColor: "#6659e3" }}
            >
              Try instaDe
              <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
            </Link>
          </div>
        </section>
      </div>
      <section>
        {/* capabilites section */}

        <div className="grid place-items-center w-1/3 items-center content-center mx-auto text-xs my-16">
          <p className="font-extrabold text-4xl my-5">
            Capabilities of instaDe
          </p>
          <p className="font-normal text-black leading-5 text-center">
            Instantly generate eye-catching social media creatives in all sizes.
            We're constantly expanding our capabilities to instantly generate
            even more types of designs like logo, flyer, pamphlet, brochure, or
            any other type of designs.
          </p>
        </div>
        <div>
          {/* slider */}
          <InstadeSlider></InstadeSlider>
        </div>
      </section>
      <section
        style={{
          background:
            "rgb(123,112,231) linear-gradient(180deg, rgba(123,112,231,1) 26%, rgba(255,255,255,1) 89%)",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${home_banner1})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right",
          }}
          className="h-screen flex  "
        >
          <div className="m-40 w-1/2   ">
            <p className="font-extrabold text-2xl my-5">
              Don’t have design skills or time?
            </p>
            <p className="font-normal text-black text-md leading-8 space-y-10 mb-5">
              Don’t worry, say goodbye to spending hours creating designs with
              sample templates and complex design tools. With instaDe you can
              generate stunning designs in seconds, without needing any design
              skills. Just input your requirement and watch the AI generate a
              custom design that perfectly fits your needs, all in a fraction of
              the time it would take using templates or complex design tools.
            </p>
            <Link
              // onClick={dashboardScreen}
              to="/login"
              type="submit"
              className=" rounded-3xl block py-3  w-32 text-sm text-center text-white bg-blue-500  mt-4 lg:inline-block lg:mt-0  mr-4 outline-none"
              style={{ backgroundColor: "#6659e3" }}
            >
              Try instaDe
              <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
            </Link>
          </div>
        </div>
      </section>
      <section className=" flex">
        <div className=" m-auto flex items-center ">
          <div className="w-1/2  ">
            <img src={home_banner2} className="w-2/3 pr-10 mr-0 mx-auto" />
          </div>
          <div className=" w-1/2  pr-40">
            <p className="font-extrabold text-2xl my-5">
              Focus on Bigger Goals
            </p>
            <p className="font-normal text-black text-md leading-6 space-y-10 mb-5">
              Whether you're a business, designer, or marketing agency, you can
              focus on bigger goals by reducing design time drastically with
              instaDe. Our platform is designed to be a magical tool that
              supercharges your design process, saving you time and effort while
              delivering stunning results.
            </p>
            <Link
              // onClick={dashboardScreen}
              to="/login"
              type="submit"
              className=" rounded-3xl block py-3  w-32 text-sm text-center text-white bg-blue-500  mt-4 lg:inline-block lg:mt-0  mr-4 outline-none"
              style={{ backgroundColor: "#6659e3" }}
            >
              Try instaDe
              <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
            </Link>
          </div>
        </div>
      </section>
      <section className=" flex">
        <div className=" m-auto flex items-center ">
          <div className=" w-1/2  pl-40">
            <p className="font-extrabold text-2xl my-5">
              We Understand Your Brand, Messaging and Target Audience
            </p>
            <p className="font-normal text-black text-md leading-6 space-y-10 mb-5">
              instaDe uses advanced AI algorithms to analyse your brand,
              messaging, and target audience to create customized designs that
              are perfectly tailored to your social media marketing needs. Our
              revolutionary technology delivers beautiful, high-quality,
              multiple variations of designs optimized for your message and
              target audience every time.
            </p>
          </div>
          <div className="w-1/2  ">
            <img src={home_banner4} className="w-2/3 pr-10 ml-0 mx-auto" />
          </div>
        </div>
      </section>
      <section>
        <div className="text-center my-10">
          <p className="font-extrabold text-2xl my-5">
            Start Creating Beautiful Designs instantly
          </p>
          <Link
            // onClick={dashboardScreen}
            to="/register"
            type="submit"
            className=" rounded-3xl block py-3  w-36 text-sm text-center text-white bg-blue-500  mt-4 lg:inline-block lg:mt-0  mr-4 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            sign up Now
            <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
          </Link>
        </div>
      </section>
      <section
        style={{
          backgroundImage: `url(${home_banner3})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
        className="h-96 mb-10"
      ></section>
    </>
  );
}

export default Home;

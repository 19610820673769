import {
  faCircle,
  faCloudUpload,
  faCloudUploadAlt,
  faFileUpload,
  faPencil,
  faUpRightFromSquare,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import BackDropModal from "../Utils/Modal";
import Checkbox from "../Utils/Checkbox";

function AccountEdit(props) {
  const setProfileDetails = useStore((state) => state.setProfileDetails);
  const [lselected, lsetSelected] = React.useState([]);
  const [langselected, langsetSelected] = React.useState([]);
  function handlelSelect(value, name) {
    if (value) {
      // if true
      // lsetSelected([...lselected, name]); // add to selected
      lsetSelected([name]); // add to selected
    } else {
      // if false
      // console.log(value);
      lsetSelected(lselected.filter((item) => item !== name)); // remove from selected
    }
  }
  function handlelangSelect(value, name) {
    if (value) {
      // langsetSelected([]); // unselect all
      // if true
      langsetSelected([name]); // add to selected
    } else {
      // if false
      // console.log(value);
      langsetSelected(langselected.filter((item) => item !== name)); // remove from selected
    }
  }

  const [customDesignshow, setcustomDesignshow] = useState(false);

  const customDesignDisplay = () => {
    setcustomDesignshow(true);
  };
  const onConfirm = () => {
    setcustomDesignshow(false);
  };

  let instade_for_list_items;
  const instade_for_list = useStore((state) => state.using_instade_for_profile);
  instade_for_list_items = instade_for_list.map((item, index) => (
    <li className="">
      <Checkbox
        name={item.name}
        value={lselected.includes(item.name)}
        updateValue={handlelSelect}
      >
        {item.name}
      </Checkbox>
      {/* <input
        type="checkbox"
        id={item.id}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={item.id}
        className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="block w-full ">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label> */}
    </li>
  ));

  let language_items;
  const language_list = useStore((state) => state.languages);
  language_items = language_list.map((item, index) => (
    <li className="">
      <Checkbox
        name={item.name}
        value={langselected.includes(item.name)}
        updateValue={handlelangSelect}
      >
        {item.name}
      </Checkbox>
      {/* <input
        type="checkbox"
        id={`language${item.id}`}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={`language${item.id}`}
        className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="block w-full ">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label> */}
    </li>
  ));

  let navigate = useNavigate();
  /*  const ProfileView = () => {
    navigate("/dashboard/Profile");
  }; */

  const profile_details = useStore((state) => state.profile_details);
  const [inputFields, setInputFields] = useState({
    name: profile_details.name,
    email: profile_details.email,
    phone: profile_details.phone,
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues) => {
    const re = /^[0-9\b]+$/;
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    let errors = {};

    if (!reg_email.test(inputValues.email)) {
      errors.email = "Email is invalid";
    }
    if (!inputValues.email) {
      errors.email = "Please Enter Email address";
    }

    if (!inputValues.name) {
      errors.name = "Please enter  Name";
    }
    if (re.test(inputValues.name)) {
      errors.name = "Only allow alphabets only";
    }

    if (!inputValues.phone) {
      errors.phone = "Please Enter Phone ";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // setErrors(validateValues(inputFields));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));

    setSubmitting(true);
    //
  };
  const finishSubmit = () => {
    const basic_details = {
      name: inputFields.name,
      email: inputFields.email,
      phone: inputFields.phone,
    };
    // console.log(basic_details);
    setProfileDetails(basic_details);

    navigate("/dashboard/Profile");
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <div className="mx-1 mt-6 mr-12 grid  gap-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="font-semibold text-[20px]">Your Account</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="pb-8 border-b-[1px] border-gray-300">
          <div className="flex gap-4 mb-10 content-center items-center ">
            <div className="mr-6">
              <h2 className="text-sm mt-8">Profile Photo</h2>

              <img
                src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                alt="..."
                className="shadow rounded-full max-w-full h-auto align-middle border-none w-16  my-2"
              />
              <button
                type="button"
                className=" rounded-3xl block  w-18 py-1 px-2 text-[10px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
                onClick={customDesignDisplay}
              >
                <FontAwesomeIcon icon={faPencil} className="mr-2" /> Edit
              </button>
              {customDesignshow && (
                <>
                  <BackDropModal onConfirmed={onConfirm} className="   ">
                    <div className="relative w-full  z-50 ">
                      {/*content*/}
                      <div className="px-8 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between py-5  rounded-t">
                          <h3 className="text-md font-semibold">
                            Update Profile Picture
                          </h3>
                          <button
                            className="  text-lg  border-0 text-black  float-right  leading-none  outline-none focus:outline-none"
                            onClick={() => onConfirm(false)}
                          >
                            <span className=" text-black   ">×</span>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto">
                          <div>
                            <img
                              className="w-32 object-center justify-center m-auto"
                              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                              alt="Neil "
                            />
                            <p className="text-[10px] font-semibold text-center pt-2  ">
                              Dimensions should be width:176px, height:176px
                            </p>
                          </div>
                          <div className="">
                            <label
                              className="grid justify-center lg:w-[500px] md:w-96 h-44  transition bg-white border-2 border-gray-300 border-dashed 
                            appearance-none cursor-pointer hover:border-gray-400 focus:outline-none text-center  my-4 p-8 "
                            >
                              <p className="   text-center w-96">
                                <FontAwesomeIcon
                                  className="w-48 h-10 font-light text-gray-500"
                                  icon={faUpload}
                                ></FontAwesomeIcon>
                              </p>
                              <p className=" font-light text-[10px]">
                                Drag and Drop here
                              </p>
                              <p className="text-[10px]">Or</p>
                              <p className="text-[#6659E3] font-semibold text-[10px] ">
                                Browse Files
                              </p>
                              <input
                                type="file"
                                name="file_upload"
                                className="hidden"
                              />
                            </label>
                          </div>
                          <div className="text-[10px] font-light text-center ">
                            Accepted File Types:Jpeg, Png
                          </div>
                        </div>

                        {/*footer*/}

                        <div className="flex items-center justify-center p-3  rounded-b">
                          <button
                            // onClick={dashboardScreen}
                            type="submit"
                            onClick={() => onConfirm(false)}
                            className=" rounded-3xl block py-2 mb-6 w-24 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                            style={{ backgroundColor: "#6659e3" }}
                          >
                            Upload
                            {/* <FontAwesomeIcon
                              icon={faUpRightFromSquare}
                              className="ml-2"
                            /> */}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="opacity-25 fixed inset-0 z-40 bg-black"
                      onClick={onConfirm}
                    ></div>
                  </BackDropModal>
                </>
              )}
            </div>
            <div>
              <h5 className=" text-[10px] mb-2">
                User Name <span className="text-red-600">*</span>
              </h5>
              <p className="text-[10px]">
                <input
                  // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                  type="text"
                  id="name"
                  name="name"
                  value={inputFields.name}
                  onChange={handleChange}
                  className={
                    errors.name
                      ? "shadow appearance-none  p-3  border rounded w-72 text-[10px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-red-500"
                      : "shadow appearance-none  p-3  border rounded w-72 text-[10px] border-gray-400  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  }
                  title={errors.name ? errors.name : ""}
                  placeholder=" "
                />
              </p>
            </div>
            <div>
              <h5 className=" text-[10px] mb-2">
                Email Address<span className="text-red-600">*</span>
              </h5>
              <p className="text-[10px]">
                <input
                  // className="shadow appearance-none p-3  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                  type="text"
                  id="email"
                  name="email"
                  value={inputFields.email}
                  onChange={handleChange}
                  className={
                    errors.email
                      ? "shadow appearance-none  p-3  border rounded w-72 text-[10px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-red-500"
                      : "shadow appearance-none p-3  border rounded w-72 text-[10px] border-gray-400    text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  }
                  title={errors.email ? errors.email : ""}
                  placeholder=" "
                />
              </p>
            </div>
            <div>
              <h5 className=" text-[10px] mb-2">
                Phone Number<span className="text-red-600">*</span>
              </h5>
              <p className="text-[10px]">
                <input
                  // className="shadow appearance-none p-3  border rounded w-72 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                  type="text"
                  id="phone"
                  name="phone"
                  value={inputFields.phone}
                  onChange={handleChange}
                  className={
                    errors.phone
                      ? "shadow appearance-none  p-3  border rounded w-72 text-[10px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-red-500"
                      : "shadow appearance-none p-3  border rounded w-72 text-[10px] border-gray-400   text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  }
                  title={errors.phone ? errors.phone : ""}
                  placeholder=" "
                />
              </p>
            </div>
          </div>
        </div>
        <div className="py-8  border-b-[1px] border-gray-300 ">
          <h2 className="font-bold my-4 text-[14px]">
            What will you be using InstaDe for?
          </h2>
          <ul className=" flex gap-1 ">{instade_for_list_items}</ul>
        </div>

        <div className="pb-8 border-b-[1px] border-gray-300">
          <h2 className="font-bold my-6">Language</h2>
          <ul className=" flex gap-1 ">{language_items}</ul>
        </div>

        <div className="text-right mt-2">
          <button
            type="submit"
            // onClick={ProfileView}
            className=" rounded-3xl  py-2 m-auto w-24 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default AccountEdit;

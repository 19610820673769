import { fabric } from "fabric";
import { useCallback, useContext, useEffect } from "react";
import { CanvasContext } from "../store/CanvasContext";

function useZoomHandler() {
  const { canvas, zoomRatio } = useContext(CanvasContext);

  const updateZoom = useCallback(
    (zoomRatio) => {
      if (canvas) {
        canvas.zoomToPoint(
          new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2),
          zoomRatio
        );
      }
    },
    [canvas]
  );

  useEffect(() => {
    updateZoom(zoomRatio);
  }, [zoomRatio]);
}

export default useZoomHandler;

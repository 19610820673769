import {
  faTrashCan,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import BackDropModal from "../Utils/Modal";

function DeleteFilter(props) {
  const [dropdownDeleteshow, setDropdownDeleteshow] = useState(false);
  const dropdownDeleteDisplay = () => {
    setDropdownDeleteshow((prevValue) => !prevValue);
  };
  const [deletepopup, setdeletepopup] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(3).fill(false)); //TODO
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };
  const DeletepopupDisplay = () => {
    const isFound = checkedState.some((element) => {
      if (element === true) {
        return true;
      }

      return false;
    });

    if (isFound) {
      // navigate("/dashboard/projectCreate");
      setdeletepopup((prevValue) => !prevValue);
      setDropdownDeleteshow(false);
    } else {
      alert("Please select any one design");
    }
  };

  return (
    <>
      <div className="relative inline-block text-left">
        <button
          id="dropdownDeleteButton"
          // data-dropdown-toggle="dropdownDelete"
          type="button"
          onClick={dropdownDeleteDisplay}
          className=" rounded-full  p-1 ml-1 border  w-8 text-[15px] bg-[#E6E9EF]    active:bg-blue-500 outline-none hover:bg-blue-100"
        >
          <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
        </button>

        {deletepopup && (
          <div
            className={` absolute right-0   z-50 mt-2 w-80 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
           ${deletepopup ? "block" : "hidden"} `}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div className="z-50 border-0 p-5 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between pb-2 border-b-[1px] border-solid border-slate-200 rounded-t">
                <h3 className="text-[15px] font-semibold">Delete Success</h3>
                <button
                  className=" ml-auto  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                  onClick={() => DeletepopupDisplay(false)}
                >
                  <span className="     text-[25px] block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-2 flex-auto">
                <form className="">
                  <h2 className="text-[12px]  text-center ">
                    You have Delete successfully?
                  </h2>
                </form>
              </div>
              {/*footer*/}
              <div className="flex   rounded-b">
                <button
                  // onClick={dashboardScreen}
                  type="submit"
                  onClick={() => DeletepopupDisplay(false)}
                  className=" font-medium text-[12px] dark:text-blue-500 underline "
                >
                  undo
                </button>

                <button
                  // onClick={dashboardScreen}
                  type="submit"
                  onClick={() => DeletepopupDisplay(false)}
                  className=" rounded-3xl  mx-auto mr-0  py-1 w-16 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                  style={{ backgroundColor: "#6659e3" }}
                >
                  OK
                  {/* <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="ml-2"
                  /> */}
                </button>
              </div>
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={DeletepopupDisplay}
            ></div>
          </div>
        )}
        {dropdownDeleteshow && (
          <>
            <div
              className={` absolute right-0 p-4 z-50 mt-2 w-80 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
               ${dropdownDeleteshow ? "block" : "hidden"} `}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div className="flex items-start justify-between p-1  border-b-[1px] border-slate-200 rounded-t">
                <h3 className="text-md font-semibold">Delete</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => dropdownDeleteDisplay(false)}
                >
                  <span className=" text-[25px]   font-normal block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="divide-y divide-gray-100 dark:divide-gray-700 pt-6">
                <ul className=" " style={{ overflowY: "scroll" }}>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            onChange={() => handleOnChange(0)}
                            className="mr-2  ml-2 bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          1. General designs for Mayday
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            onChange={() => handleOnChange(1)}
                            className="mr-2  ml-2 bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          2. Generate designs for 20% ....
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            onChange={() => handleOnChange(2)}
                            className="mr-2  ml-2 bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          3. A warm welcome to Mon...
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>

                  {/*  <li className="pb-3 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            className="mr-4  ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                          />
                          2. Generate designs for 20% ....
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right mx-4"
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image2"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-3 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            className="mr-4 ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                          />
                          3. A warm welcome to Mon...
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right mx-4"
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image3"
                          />
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>

              <div className=" block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg   dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                <button
                  className=" rounded-3xl block py-1 m-auto w-28 text-[12px]  text-white bg-blue-500   active:bg-blue-500 outline-none"
                  style={{ backgroundColor: "#6659e3" }}
                  onClick={DeletepopupDisplay}
                >
                  Delete
                  {/* <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="ml-4"
                  /> */}
                </button>
              </div>
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={dropdownDeleteDisplay}
            ></div>
          </>
        )}
      </div>
    </>
  );
}

export default DeleteFilter;

import {
  faArrowRight,
  faCircle,
  faHeart,
  faPlus,
  faTrash,
  faTrashCan,
  faUpRightFromSquare,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import useStore from "../../store/store";
import { useNavigate, useParams } from "react-router";
import TeamPopup from "./TeamPopup";

function ProjectUpdate(props) {
  // const { project_id } = useParams();
  const project_id = 1;
  const [EditMenu, setEditMenu] = useState(false);

  const [teampopup, setteampopup] = useState(false);

  const TeamepopupDisplay = () => {
    setteampopup((prevValue) => !prevValue);
  };

  const selected_project = useStore((state) => state.selected_project);
  console.log(selected_project);
  // getProject
  // useEffect(
  //   setSelectedProject(useStore().getProject(project_id)),
  //   alert(selected_project),
  //   []
  // );
  // useEffect(alert("hi"), []);

  let project_golas_items;
  const project_goals_list = useStore((state) => state.projectGoals);
  project_golas_items = project_goals_list.map((item, index) => (
    <li className="w-full inline-block">
      <input
        type="checkbox"
        id={item.id}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={item.id}
        className="inline-block items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="inline-block w-full ">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label>
    </li>
  ));

  let navigate = useNavigate();
  const [inputFields, setInputFields] = useState({
    project_name: selected_project.basic_details.project_name,
    logo: selected_project.basic_details.logo,
    logo_primary_color: selected_project.basic_details.logo_primary_color,
    much_info: selected_project.about_details.much_info,
    keywords: selected_project.about_details.keywords,
    project_feature: selected_project.about_details.project_feature,
    phone_number: selected_project.contact_details.phone_number,
    email: selected_project.contact_details.email,
    address: selected_project.contact_details.address,
    project_goals: selected_project.about_details.project_goals,
    services: selected_project.service_details,
  });
  const [errors, setErrors] = useState({
    logo: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues) => {
    const re = /^[0-9\b]+$/;
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    let errors = {};

    if (!inputValues.project_name) {
      errors.project_name = "Please Enter Project Name";
    }

    if (!inputValues.business_category) {
      errors.business_category = "Please enter Business category";
    }

    if (!inputValues.business_subcategory) {
      errors.business_subcategory = "Please enter business subcategory";
    }
    if (!inputValues.logo) {
      errors.logo = "Please Select logo";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // setErrors(validateValues(inputFields));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));

    setSubmitting(true);
    //
  };
  const finishSubmit = () => {
    const basic_details = {
      project_name: inputFields.project_name,
      business_category: inputFields.business_category,
      business_subcategory: inputFields.business_subcategory,
      logo: inputFields.logo,
    };
    props.basicDetailssubmit(basic_details);
    props.handleNext();
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <>
      <div className="m-10">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-0">
            <div className="mr-10  items-center ">
              <h3 className="text-xl  mb-4 font-extrabold">
                <span className=" border-l-4 border-[#6659E3] px-4  w-16">
                  Basic Details
                </span>
              </h3>

              <div className="relative mb-4">
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  value={inputFields.project_name}
                  onChange={handleChange}
                  className={
                    errors.project_name
                      ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                      : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  }
                  title={errors.project_name ? errors.project_name : ""}
                  placeholder=" "
                />
                <label
                  htmlFor="project_name"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  style={{ backgroundColor: "#FBFBFD" }}
                >
                  Project Name *
                </label>
              </div>

              <div className="relative h-10 mb-4 ">
                <div className="absolute top-2/4 right-3 grid h-5 w-5 -translate-y-2/4 place-items-center text-blue-gray-500">
                  <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                </div>
                <input
                  className="peer h-full w-full rounded-[7px] border border-gray-300 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3] focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=" "
                  value="karyahublogo.svg"
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-300 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-300 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#6659E3] peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-[#6659E3] peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-[#6659E3] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Upload Logo
                </label>
              </div>
              <div className=" ">
                <img src={logo} className="w-48 h-16" />
              </div>

              <div className="relative mb-4 ">
                <div className="absolute top-2/4 right-3 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#6659E3" }}
                    size="xl"
                  />
                </div>
                <input
                  className="peer h-full w-full rounded-[7px] border border-gray-300 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3] focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=" "
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-gray-800 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-300 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-300 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#6659E3] peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-[#6659E3] peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-[#6659E3] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Logo Primary Color
                </label>
              </div>

              <div className="mt-24 w-96 items-center m-auto">
                <h3 className="text-xl  mb-4 font-extrabold text-center mt-24">
                  <span className=" border-l-4 border-[#6659E3] px-4 text-center w-16">
                    Contact Details
                  </span>
                </h3>
                <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
                  <div className="w-full h-12 ">
                    <div className="relative mb-4">
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={inputFields.phone_number}
                        onChange={handleChange}
                        className={
                          errors.phone_number
                            ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                            : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                        }
                        title={errors.phone_number ? errors.phone_number : ""}
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        style={{ backgroundColor: "#FBFBFD" }}
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                  <button className="bg-[#FBFBFD] py-2 px-3 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg ">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-bold"
                    ></FontAwesomeIcon>
                  </button>
                </div>
                <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
                  <div className="w-full h-12 ">
                    <div className="relative mb-4">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={inputFields.email}
                        onChange={handleChange}
                        className={
                          errors.email
                            ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                            : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                        }
                        title={errors.email ? errors.email : ""}
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        style={{ backgroundColor: "#FBFBFD" }}
                      >
                        Email Address
                      </label>
                    </div>
                  </div>
                  <button className="bg-[#FBFBFD] py-2 px-2 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg ">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-bold"
                    ></FontAwesomeIcon>
                  </button>
                </div>

                <div className="mb-4">
                  <div className="relative mb-4">
                    <textarea
                      type="text"
                      id="address"
                      name="address"
                      value={inputFields.address}
                      onChange={handleChange}
                      className={
                        errors.address
                          ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                          : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                      }
                      title={errors.address ? errors.address : ""}
                      cols={3}
                      style={{ resize: "none" }}
                      placeholder=" "
                      // value="Progressive Tower, Jaihind Enclave, 19/B Feet Rd,Ayyappa Society, Chana Naik Nagar, Madhapur Hyderabad, Telangana - 500081"
                    ></textarea>
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      style={{ backgroundColor: "#FBFBFD" }}
                    >
                      Address
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mr-10  ">
                <h3 className="text-xl  mb-4 font-extrabold ">
                  <span className=" border-l-4 border-[#6659E3] px-4 w-16">
                    About Project
                  </span>
                </h3>
                <div className="mb-4">
                  <div className="relative mb-4">
                    <textarea
                      type="text"
                      cols={3}
                      style={{ resize: "none" }}
                      placeholder=" "
                      id="much_info"
                      name="much_info"
                      value={inputFields.much_info}
                      onChange={handleChange}
                      className={
                        errors.much_info
                          ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                          : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                      }
                      title={errors.much_info ? errors.much_info : ""}
                    ></textarea>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="relative mb-4">
                    <input
                      type="text"
                      id="keywords"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                      placeholder=" "
                      name="keywords"
                      value={inputFields.keywords}
                      onChange={handleChange}
                      title={errors.keywords ? errors.keywords : ""}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="relative mb-4">
                    <textarea
                      type="text"
                      id="project_feature"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                      cols={3}
                      style={{ resize: "none" }}
                      placeholder=" "
                      name="project_feature"
                      value={inputFields.project_feature}
                      onChange={handleChange}
                      title={
                        errors.project_feature ? errors.project_feature : ""
                      }
                    ></textarea>
                    <label
                      htmlFor="floating_outlined"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      style={{ backgroundColor: "#FBFBFD" }}
                    ></label>
                  </div>
                </div>
                <h3 className="text-sm font-bold mb-4"> Project Goal</h3>
                <ul className=" flex  gap-2 ">{project_golas_items}</ul>
              </div>
            </div>

            <div>
              <div className="  items-center ">
                {/* <h3 className="text-xl  mb-4 font-extrabold">
                  <span className=" border-l-4 border-[#6659E3] px-4  w-16">
                    Add Products / Services
                    <div className=" text-right">
                      <button
                        id="dropdownDeleteButton"
                        type="button"
                        className=" rounded-full  p-2  border  w-24 text-xs font-bold   hover:bg-blue-700 active:bg-blue-500 outline-none"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="mr-2"
                        ></FontAwesomeIcon>
                        Add
                      </button>
                    </div>
                  </span>
                </h3> */}
                <div className="grid grid-cols-4">
                  <div className="font-extrabold text-xl  w-full col-span-3">
                    <p className="  ">
                      <span className=" border-l-4 border-[#6659E3] px-4  ">
                        Add Products / Services
                      </span>
                    </p>
                  </div>
                  <div>
                    <div className=" text-right col-span-1">
                      <button
                        type="button"
                        className=" rounded-full  p-2 ml-1 border  w-24 text-sm    hover:bg-blue-700 active:bg-blue-500 outline-none"
                      >
                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add
                      </button>
                    </div>
                  </div>
                </div>
                <div className="h-screen overflow-scroll">
                  {inputFields.services.map((service, index) => (
                    <div className="flex items-center justify-center gap-4 my-4">
                      <div
                        className="px-8 py-4 rounded-lg  h-60 "
                        style={{ backgroundColor: "#F0F0F0" }}
                      >
                        <div className="mb-4">
                          <div className="relative mb-4">
                            <input
                              type="text"
                              id={`product_name${index}`}
                              name={`product_name${index}`}
                              // value={service.product_name}
                              defaultValue={service.product_name}
                              className={
                                "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900  rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                              }
                              title={
                                errors.product_name ? errors.product_name : ""
                              }
                              placeholder=" "
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="relative mb-4">
                            <textarea
                              type="text"
                              id={`description${index}`}
                              name={`description${index}`}
                              // value={service.product_name}

                              className={
                                "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900  rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                              }
                              title={
                                errors.description ? errors.description : ""
                              }
                              cols={3}
                              defaultValue={service.description}
                              style={{
                                resize: "none",
                                backgroundColor: "#FBFBFD",
                              }}
                              placeholder="Description"
                              // value="including information about color, sizing, material, pricing, shipping options, and more"
                            ></textarea>
                          </div>
                        </div>
                        <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
                          <div className="w-full h-12 mr-4">
                            <div className="relative mb-4">
                              <input
                                type="text"
                                id={`price${index}`}
                                name={`price${index}`}
                                className={
                                  "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900  rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                                }
                                title={errors.price ? errors.price : ""}
                                defaultValue={service.price}
                              />
                            </div>
                          </div>
                          <div className="w-full h-12 ">
                            <div className="relative mb-4">
                              <input
                                type="text"
                                id={`offer_price${index}`}
                                name={`offer_price${index}`}
                                className={
                                  "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900  rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                                }
                                title={
                                  errors.offer_price ? errors.offer_price : ""
                                }
                                defaultValue={service.offer_price}
                                style={{ backgroundColor: "#FBFBFD" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="mr-1 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-900 bg-[#E6E9EF] border border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                      >
                        <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                      </button>
                    </div>
                  ))}
                </div>

                {/* <div className="flex items-center justify-center gap-4 mb-4 ">
                  <div
                    className="px-8 py-4 rounded-lg  h-60 "
                    style={{ backgroundColor: "#F0F0F0" }}
                  >
                    <div className="mb-4">
                      <div className="relative mb-4">
                        <input
                          type="text"
                          id="floating_outlined"
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                          placeholder="Name of Product / Service "
                          style={{ backgroundColor: "#FBFBFD" }}
                          value={`Product Name`}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="relative mb-4">
                        <textarea
                          type="text"
                          id="floating_outlined"
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                          cols={3}
                          style={{ resize: "none", backgroundColor: "#FBFBFD" }}
                          placeholder="Description"
                          value="including information about color, sizing, material, pricing, shipping options, and more"
                        ></textarea>
                      </div>
                    </div>
                    <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
                      <div className="w-full h-12 mr-4">
                        <div className="relative mb-4">
                          <input
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                            placeholder="Price"
                            style={{ backgroundColor: "#FBFBFD" }}
                            value="Rs:25,000"
                          />
                        </div>
                      </div>
                      <div className="w-full h-12 ">
                        <div className="relative mb-4">
                          <input
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                            placeholder=" Offer Price"
                            value="Rs: 22,000"
                            style={{ backgroundColor: "#FBFBFD" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="mr-1 inline-flex items-center px-2 py-2 text-sm font-medium text-black bg-[#E6E9EF] border border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                  >
                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className=" bg-white flex justify-center items-center my-6">
            <button
              // onClick={dashboardScreen}
              type="submit"
              className=" rounded-3xl block py-2 w-36 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
              style={{ backgroundColor: "#6659e3" }}
            >
              Save Project
              <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
            </button>
          </div>
        </form>
        <section className=" dark:bg-gray-900">
          <div className="py-4    content-center items-center">
            <div className="bg-blue-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg  py-6 mb-8">
              <h1 className="text-gray-900 dark:text-white  text-xl font-bold mb-2 text-center">
                Great ideas are meant to be shared
              </h1>
              <p className="text-xs font-normal text-gray-500 dark:text-gray-400 mb-6 text-center">
                Get collaboration and creativity flowing so jobs get done,fast.
              </p>
            </div>
          </div>
        </section>
        <div className="grid grid-cols-2 mb-6">
          <div className="font-bold text-sm my-3">
            <p className="ml-2">Team Members(2)</p>
          </div>
          <div>
            <div className=" text-right">
              <button
                type="button"
                className=" rounded-full  p-2 ml-1 border  w-48 text-xs font-bold   hover:bg-blue-700 active:bg-blue-500 outline-none"
                onClick={TeamepopupDisplay}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-2"
                ></FontAwesomeIcon>
                Add Team Members
              </button>
              <TeamPopup
                setEditMenu={setEditMenu}
                teampopup={teampopup}
                TeamepopupDisplay={TeamepopupDisplay}
              ></TeamPopup>
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-collapse">
            <thead className="text-xs text-gray-700  border-b-2">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Team role
                </th>

                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                        alt="..."
                        className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-xs text-gray-600 truncate dark:text-white">
                        Radha Krishna
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-xs">radhakrishnav@gmail.com</td>
                <td className="px-6 ">
                  <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                    <option value="">View</option>
                  </select>
                </td>

                <td className="px-6 py-4">
                  <button
                    type="button"
                    className=" rounded-full  p-3 ml-1 border  w-12 text-sm bg-[#E6E9EF] text-black   hover:bg-blue-700 active:bg-blue-500 outline-none"
                  >
                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                  </button>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                        alt="..."
                        className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-xs text-gray-600 truncate dark:text-white">
                        Radha Krishna
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-xs">radhakrishnav@gmail.com</td>
                <td className="px-6 ">
                  <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                    <option value="">View</option>
                  </select>
                </td>

                <td className="px-6 py-4">
                  <button
                    type="button"
                    className=" rounded-full  p-3 ml-1 border text-black  w-12 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
                  >
                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                  </button>
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                        alt="..."
                        className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-xs text-gray-600 truncate dark:text-white">
                        Radha Krishna
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-xs">radhakrishnav@gmail.com</td>
                <td className="px-6 ">
                  <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                    <option value="">View</option>
                  </select>
                </td>

                <td className="px-6 py-4">
                  <button
                    type="button"
                    className=" rounded-full  p-3 ml-1 border text-black  w-12 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
                  >
                    <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ProjectUpdate;

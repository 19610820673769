import {
  faCircle,
  faGreaterThan,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";
import Select from "../Utils/Select";
import InputFile from "../Utils/InputFile";

function BasicDetails(props) {
  let navigate = useNavigate();

  const re = /^[0-9\b]+$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const isNotEmpty = (value) => value.trim() !== "";
  const isTest = (value) => value;
  const isAlphabet = (value) => {
    if (re.test(value)) {
      return false;
    }
    return true;
  };
  const isAlphabetMandate = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (re.test(value)) {
      return false;
    }
    return true;
  };

  const {
    value: projectNameValue,
    isValid: projectNameIsValid,
    hasError: projectNameHasError,
    valueChangeHandler: projectNameChangeHandler,
    inputBlurHandler: projectNameBlurHandler,
    reset: resetprojectName,
  } = useInput(isNotEmpty);

  const {
    value: targetLocationValue,
    isValid: targetLocationIsValid,
    hasError: targetLocationHasError,
    valueChangeHandler: targetLocationChangeHandler,
    inputBlurHandler: targetLocationBlurHandler,
    reset: resettargetLocation,
  } = useInput(isNotEmpty);

  const {
    value: WebsiteValue,
    isValid: WebsiteIsValid,
    hasError: WebsiteHasError,
    valueChangeHandler: WebsiteChangeHandler,
    inputBlurHandler: WebsiteBlurHandler,
    reset: resetWebsite,
  } = useInput(isTest);

  let formIsValid = false;
  /*  projectNameIsValid &&
    businessCategoryIsValid &&
    businessSubCategoryIsValid &&
    LogoIsValid */
  if (targetLocationIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      /* projectNameBlurHandler();
      businessCategoryBlurHandler();
      businessSubCategoryBlurHandler();
      LogoBlurHandler();
      LogoColorBlurHandler(); */
      targetLocationBlurHandler();
      return;
    }

    console.log("Submitted!");
    console.log(projectNameValue);
    const basic_details = {
      project_name: projectNameValue,

      website: WebsiteValue,
    };
    props.basicDetailssubmit(basic_details);
    props.handleNext();
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-28 w-80  items-center m-auto">
        <h3 className="text-xl mb-4 font-semibold text-center">
          <span className=" border-l-[3px] border-[#6659E3] px-4 text-center w-16">
            Basic Details
          </span>
        </h3>

        <div className="mb-4">
          <Input
            type="text"
            id="target_location"
            name="target_location"
            value={targetLocationValue}
            onChange={targetLocationChangeHandler}
            onBlur={targetLocationBlurHandler}
            isValid={targetLocationIsValid}
            error={targetLocationHasError}
            title="Target Location"
            isMandate={true}
          ></Input>
        </div>
        <div className="mb-4">
          <Input
            type="text"
            id="website"
            name="website"
            value={WebsiteValue}
            onChange={WebsiteChangeHandler}
            onBlur={WebsiteBlurHandler}
            // isValid={WebsiteIsValid}
            error={WebsiteHasError}
            title="Website URL"
            isMandate={true}
          ></Input>
        </div>

        <div className="mb-4">
          <Input
            type="text"
            id="project_name"
            name="project_name"
            value={projectNameValue}
            onChange={projectNameChangeHandler}
            onBlur={projectNameBlurHandler}
            isValid={projectNameIsValid}
            error={projectNameHasError}
            title="Project Name"
            isMandate={true}
          ></Input>
        </div>
      </div>
      <div className=" text-center content-center mt-4 ">
        <button
          className="bg-[#FBFBFD] py-2 px-2  w-10 font-extrabold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
          // onClick={handleNext}
        >
          <FontAwesomeIcon
            icon={faGreaterThan}
            className="font-bold"
          ></FontAwesomeIcon>
        </button>
      </div>
    </form>
  );
}

export default BasicDetails;

import React, { useState } from "react";
import Select from "react-select";

import BackDropModal from "../Utils/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faTrashCan,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

function TeamPopup(props) {
  const [sendRequestpopup, setsendRequestpopup] = useState(false);
  const sendRequestDisplay = () => {
    setsendRequestpopup((prevValue) => !prevValue);
    props.setEditMenu(false);
  };

  const team_members = [
    { value: "1", label: "Raj Kumar" },
    { value: "2", label: "Radha Krishna" },
    { value: "3", label: "Naveen" },
    { value: "4", label: "Praveen Kumar" },
  ];
  return (
    <>
      {props.teampopup && (
        <BackDropModal
          onConfirmed={props.TeamepopupDisplay}
          className="items-center justify-center    "
        >
          <div className="z-50 border-0   rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
              <h3 className="text-md font-semibold">Share to People</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.TeamepopupDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto ">
              <div className="grid grid-cols-2 mb-6">
                <div className="font-bold text-sm my-3">
                  <p className="ml-2">Team Members(2)</p>
                </div>
                <div>
                  <div className=" text-right">
                    <button
                      id="dropdownDeleteButton"
                      type="button"
                      onClick={() => {
                        props.TeamepopupDisplay(false);
                        sendRequestDisplay(false);
                      }}
                      className=" rounded-full  p-2 ml-1 border  w-48 text-xs font-bold   hover:bg-blue-700 active:bg-blue-500 outline-none"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="mr-2"
                      ></FontAwesomeIcon>
                      Add Team Members
                    </button>
                  </div>
                </div>
              </div>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-collapse">
                  <thead className="text-xs text-gray-700  border-b-2">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Team role
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                              alt="..."
                              className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-xs text-gray-600 truncate dark:text-white">
                              Radha Krishna
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-xs">
                        radhakrishnav@gmail.com
                      </td>
                      <td className="px-6 ">
                        <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                          <option value="">View</option>
                        </select>
                      </td>

                      <td className="px-6 py-4">
                        <button
                          id="dropdownDeleteButton"
                          data-dropdown-toggle="dropdownDelete"
                          type="button"
                          className=" rounded-full  p-3 ml-1 border  w-12 text-sm bg-[#E6E9EF] text-black   hover:bg-blue-700 active:bg-blue-500 outline-none"
                        >
                          <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                              alt="..."
                              className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-xs text-gray-600 truncate dark:text-white">
                              Radha Krishna
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-xs">
                        radhakrishnav@gmail.com
                      </td>
                      <td className="px-6 ">
                        <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                          <option value="">View</option>
                        </select>
                      </td>

                      <td className="px-6 py-4">
                        <button
                          id="dropdownDeleteButton"
                          data-dropdown-toggle="dropdownDelete"
                          type="button"
                          className=" rounded-full  p-3 ml-1 border text-black  w-12 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
                        >
                          <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                              alt="..."
                              className="shadow rounded-full max-w-full h-auto align-middle border-none w-12"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-xs text-gray-600 truncate dark:text-white">
                              Radha Krishna
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-xs">
                        radhakrishnav@gmail.com
                      </td>
                      <td className="px-6 ">
                        <select className=" text-xs    w-48 border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                          <option value="">View</option>
                        </select>
                      </td>

                      <td className="px-6 py-4">
                        <button
                          id="dropdownDeleteButton"
                          data-dropdown-toggle="dropdownDelete"
                          type="button"
                          className=" rounded-full  p-3 ml-1 border text-black  w-12 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none"
                        >
                          <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/*footer*/}
            <div className="flex  p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => {
                  props.TeamepopupDisplay(false);
                }}
                className=" rounded-3xl mx-auto   py-2 w-36 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Done
                <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={props.TeamepopupDisplay}
          ></div>
        </BackDropModal>
      )}

      {sendRequestpopup && (
        <BackDropModal
          onConfirmed={sendRequestDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-md font-semibold">
                <button
                  className="p-1 ml-auto  border-0 text-black  float-left  leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => {
                    props.TeamepopupDisplay(false);
                    sendRequestDisplay(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="mr-2"
                  ></FontAwesomeIcon>
                </button>
                Send Request
              </h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => sendRequestDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="">
                <div className="text-sm  w-96 my-5 grid ">
                  <div className="w-full pb-3">
                    <Select
                      closeMenuOnSelect={false}
                      // defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={team_members}
                      // className=" text-xs w-full   border	border-slate-300  focus:outline-none placeholder:text-slate-600 "
                      // multiple
                    >
                      {/* <option value="1">Raj Kumar</option>
                      <option value="2">Radha Krishna</option>
                      <option value="3">Naveen</option> */}
                    </Select>
                  </div>
                  <div className="w-full">
                    <select className=" text-xs w-full   border	border-slate-300  focus:outline-none placeholder:text-slate-600 ">
                      <option value="">Editor</option>
                    </select>
                  </div>
                </div>

                <div className="text-sm  w-96 my-5 ">
                  <input
                    type="checkbox"
                    id="notify"
                    value=""
                    className=" peer mr-3"
                    required=""
                  />
                  Notify People via email
                </div>
                <div className="text-xs font-bold w-96 ">
                  <textarea
                    type="text"
                    id="floating_outlined"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                    cols={3}
                    style={{ resize: "none" }}
                    placeholder="Message"
                  ></textarea>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex  p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => sendRequestDisplay(false)}
                className=" font-medium  dark:text-blue-500 underline "
              >
                Cancel
              </button>

              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => sendRequestDisplay(false)}
                className=" rounded-3xl mx-auto mr-0  py-2 w-32 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Invite
                <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={sendRequestDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default TeamPopup;

import { faCheck, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function InputFile(props) {
  let showtickMarks = props.showtickMarks;

  if (typeof showtickMarks === "undefined") {
    showtickMarks = true;
  }

  let classnames =
    "grid rounded   peer h-full w-full  border border-gray-300  bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-gray-900 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-600 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3]  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50";
  if (!props.error && props.isValid) {
    classnames =
      "grid rounded   peer h-full w-full    bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-gray-900 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-600 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3]  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 border border-green-500";
  }
  if (props.error && !props.isValid) {
    classnames =
      "grid rounded   peer h-full w-full    bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-gray-900 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-600 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3]  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 border border-red-500";
  }

  return (
    <div className="relative">
      {!props.error && props.isValid && showtickMarks && (
        <div className="absolute top-2/4 right-3 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: "green" }}
            size="sm"
          />
        </div>
      )}
      {props.error && showtickMarks && (
        <div className="absolute top-2/4 right-3 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
          <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} size="sm" />
        </div>
      )}

      <input
        type={props.type}
        id={props.id}
        name={props.name}
        // value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className="hidden"
        title={props.title ? props.title : ""}
        placeholder=" "
        maxLength={props.maxLength}
      />
      <label htmlFor={props.id} className={classnames}>
        {props.children}
      </label>
    </div>
  );
}

export default InputFile;

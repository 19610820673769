import axios from "axios";

const pixabayClient = axios.create({
  baseURL: "https://pixabay.com/api?key=27174649-fdf5a86d33e5edef17af66ff9",
});

export function getLoveImages(query) {
  /* return new Promise((resolve, reject) => {
    pixabayClient
      .get("", {
        params: {
          // key: process.env.REACT_APP_PIXABAY_CLIENT_ID,
          q: query,
          image_type: "photo",
        },
      })
      .then((response) => {
        // console.log(response.data.hits);
        const items = response.data.hits;
        resolve(items);
      })
      .catch((err) => {
        reject(err);
      });
    //  fetch(
    //   "https://pixabay.com/api?key=27174649-fdf5a86d33e5edef17af66ff9&q=love&image_type=photo"
    // )
    //   .then((res) => res.json())
    //   .then((json) => {
    //     const items = json.data.hits;
    //     resolve(items);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  }); */

  return new Promise((resolve, reject) => {
    const items = [
      {
        id: 700141,
        pageURL:
          "https://pixabay.com/photos/heart-love-romance-valentine-700141/",
        type: "photo",
        tags: "heart, love, romance",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/03/30/20/33/heart-700141_150.jpg",
        previewWidth: 150,
        previewHeight: 100,
        webformatURL:
          "https://pixabay.com/get/gd78221b85d09735a1c170aa6c43c0b6955fa61677ef87a3c6dd95678193a0cc7301203cfaec9553825351d41ca7343ef_640.jpg",
        webformatWidth: 640,
        webformatHeight: 427,
        largeImageURL:
          "https://pixabay.com/get/geb5e33d6b49856eb8a2c1b2e3d55afedae917ccf17475a438370900a5093f4541925775115715b5ce4e4a112b93c8a0dbbda731e34162f97df261ddf6362d39b_1280.jpg",
        imageWidth: 5013,
        imageHeight: 3347,
        imageSize: 3118414,
        views: 2070770,
        downloads: 1084823,
        collections: 2406,
        likes: 2777,
        comments: 585,
        user_id: 69781,
        user: "Ben_Kerckx",
        userImageURL:
          "https://cdn.pixabay.com/user/2017/05/13/20-08-47-204_250x250.jpg",
      },
      {
        id: 729509,
        pageURL:
          "https://pixabay.com/photos/rose-flower-love-romance-beautiful-729509/",
        type: "photo",
        tags: "rose, hd wallpaper, flower",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/04/19/08/32/rose-729509_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g82b532b6f471b4b218e6f5e549cb8649ad58bc41f7e2d7870080dbc99e0867346f893e0481b047e6c026d192ee44f028_640.jpg",
        webformatWidth: 640,
        webformatHeight: 425,
        largeImageURL:
          "https://pixabay.com/get/g3cd2a602eadf5c98ae1202d4c7c1c22523ef51392599b6826d5e46e63ec7d9ce83c0b3f754758979380d85c62e16a8ffcd6a6a3518d952ba90feca3715d5a117_1280.jpg",
        imageWidth: 1980,
        imageHeight: 1316,
        imageSize: 500190,
        views: 2957369,
        downloads: 1622178,
        collections: 3108,
        likes: 3437,
        comments: 651,
        user_id: 909086,
        user: "Bessi",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/04/11/22-45-05-994_250x250.jpg",
      },
      {
        id: 1192662,
        pageURL:
          "https://pixabay.com/photos/heart-leaves-foliage-garden-bush-1192662/",
        type: "photo",
        tags: "heart, leaves, foliage",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/02/10/21/57/heart-1192662_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g0c7f57a295561a06b56c058c030ec7bfd57cd8dc01111d45db283e99196abe4762f8cb5c6db62aa7648913f3b0d9f02da2aa5af94480b4789a3f0480599cbc48_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/ge2eb157e6143f10c0bff0deb92f4397660d12bce0d6125456a237a10d91fa8dc56f10fad40e6f78b3d2181cc735e4c7f263e2d6c6672a6735a74d3b0d9f69420_1280.jpg",
        imageWidth: 6000,
        imageHeight: 4000,
        imageSize: 6730913,
        views: 2308954,
        downloads: 1378477,
        collections: 2754,
        likes: 3231,
        comments: 656,
        user_id: 2023852,
        user: "2023852",
        userImageURL: "",
      },
      {
        id: 1046658,
        pageURL:
          "https://pixabay.com/photos/balloons-heart-sky-clouds-love-1046658/",
        type: "photo",
        tags: "balloons, heart, sky",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/11/16/22/39/balloons-1046658_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/gd74e40195a6a964c8e13edce67c6401c69b6cd383d2efefabd3ab69775dd3834a6b859d2db265b6926d4405d7b398cf6fdfcd6bb102a00d4c27fd3758d02d3df_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/gf6c5b7185c35e4313e3c290b19c0910ada5a4a57be590b93b7ed9a1fb842ab263b255987e66af8bf783a647186999436d87c74e3b0c416e919f640b98db3f3db_1280.jpg",
        imageWidth: 5184,
        imageHeight: 3456,
        imageSize: 2964496,
        views: 1495698,
        downloads: 727975,
        collections: 1839,
        likes: 2231,
        comments: 412,
        user_id: 1553824,
        user: "Peggy_Marco",
        userImageURL:
          "https://cdn.pixabay.com/user/2020/01/07/15-43-03-893_250x250.jpg",
      },
      {
        id: 1718244,
        pageURL:
          "https://pixabay.com/photos/couple-romance-bike-bicycle-meadow-1718244/",
        type: "photo",
        tags: "couple, romance, bike",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/10/06/05/19/couple-1718244_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g7605ea90ac99b53736051415d90638e0366df212a942286dcc5cbf040fc0ec9eb188fee498f1187ffdf0b5537504cbe0bef48267810a5169fae56f65d85050f2_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g0f893de001de50d08ce458c07d4b4e811e3100a21a17d9d001f7c43059f2ba91e1b515722ae9442f458192bd1cb0dee6712e73f647ef0e5b03840deaa9b7d046_1280.jpg",
        imageWidth: 5760,
        imageHeight: 3840,
        imageSize: 4669768,
        views: 1012280,
        downloads: 528270,
        collections: 1860,
        likes: 2020,
        comments: 332,
        user_id: 3194556,
        user: "3194556",
        userImageURL: "",
      },
      {
        id: 817365,
        pageURL:
          "https://pixabay.com/photos/children-siblings-brother-sister-817365/",
        type: "photo",
        tags: "children, siblings, brother",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/06/22/08/37/children-817365_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/gffde34d655c2e51241071a35fa844678848f49d2035578a3e6b1efc6b288e175d1f3c4f661cc62ce0b600b9ddb4006c0_640.jpg",
        webformatWidth: 640,
        webformatHeight: 425,
        largeImageURL:
          "https://pixabay.com/get/g07676137d0423ee04727c561f378f639a12badf3c8028a6e71085af04b9051c0de6473e62cee11fe87b9f8b2acdf4b866bb38ebfe4936696c7471f926ca0ec6a_1280.jpg",
        imageWidth: 4288,
        imageHeight: 2848,
        imageSize: 1867166,
        views: 1370584,
        downloads: 699184,
        collections: 1663,
        likes: 2234,
        comments: 460,
        user_id: 909086,
        user: "Bessi",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/04/11/22-45-05-994_250x250.jpg",
      },
      {
        id: 2258681,
        pageURL:
          "https://pixabay.com/photos/father-and-son-walking-railway-2258681/",
        type: "photo",
        tags: "father and son, walking, railway",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/04/25/06/15/father-and-son-2258681_150.jpg",
        previewWidth: 150,
        previewHeight: 88,
        webformatURL:
          "https://pixabay.com/get/gbff9118c272d5d1f40ecb82026b0ae71e2144ca10c6b5418f05d2fc0e37afdde8375b4219c9a8f98bfbf8ffd7a964e22ddc1b5cfff17287acb405cca801b24dc_640.jpg",
        webformatWidth: 640,
        webformatHeight: 377,
        largeImageURL:
          "https://pixabay.com/get/gda884bf0e8a7f821220844e7fb6258881fc94ca0feff308e60aaacedebd9044685d7577c0f514e41a88b798e2a1b311e6e8ddb42191fd6daa3753b5c236f93c0_1280.jpg",
        imageWidth: 2440,
        imageHeight: 1440,
        imageSize: 1065730,
        views: 1155622,
        downloads: 724292,
        collections: 1763,
        likes: 2152,
        comments: 387,
        user_id: 4144132,
        user: "4144132",
        userImageURL: "",
      },
      {
        id: 3147976,
        pageURL:
          "https://pixabay.com/photos/heart-love-sunset-shape-sign-3147976/",
        type: "photo",
        tags: "heart, love, sunset",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/02/12/10/45/heart-3147976_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g9a952fac5232cdb61bba7c49f02b9ea9a2fbf07f607746cc70ce3bf295a5e72bd9840f7ae4d1f759a5710d953c6f0b9f841091182432999373e99ab9b6a93726_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/gc56b42ff0e27e85016c1f545491a1411f36f1debdd145b54de4fa77d96a1539959b8a47fae30c38332ce2272ee36d07c94a4c96f888588e207be6f3e93296886_1280.jpg",
        imageWidth: 5875,
        imageHeight: 3917,
        imageSize: 2432222,
        views: 1188089,
        downloads: 770119,
        collections: 1653,
        likes: 1664,
        comments: 278,
        user_id: 1546875,
        user: "PhotoMIX-Company",
        userImageURL:
          "https://cdn.pixabay.com/user/2018/02/20/12-45-58-742_250x250.jpg",
      },
      {
        id: 1100254,
        pageURL:
          "https://pixabay.com/photos/paper-heart-symbol-romance-1100254/",
        type: "photo",
        tags: "paper, heart, symbol",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/12/19/20/32/paper-1100254_150.jpg",
        previewWidth: 150,
        previewHeight: 112,
        webformatURL:
          "https://pixabay.com/get/g5dd676fd319f0521ee2f26799ec382ba1c21ecd5bb6a24b73dd0ea86c56e49d4bc2ac021c664ce4dfd20bc254edd40ee66f84becc2f02d9436f7f690234816fb_640.jpg",
        webformatWidth: 640,
        webformatHeight: 479,
        largeImageURL:
          "https://pixabay.com/get/g73c0d16260167db8c9c88ce40bb0c4a6b3d5d6a3a4cca367e9c8ad97703a641b71d6cab8aedaa05740bd193310228db60bc7cf2c15878c421d5e284b07f95797_1280.jpg",
        imageWidth: 4431,
        imageHeight: 3323,
        imageSize: 2360983,
        views: 990169,
        downloads: 663172,
        collections: 1742,
        likes: 1619,
        comments: 236,
        user_id: 1441456,
        user: "DariuszSankowski",
        userImageURL:
          "https://cdn.pixabay.com/user/2015/12/21/10-54-58-771_250x250.jpg",
      },
      {
        id: 817369,
        pageURL:
          "https://pixabay.com/photos/siblings-brother-sister-children-817369/",
        type: "photo",
        tags: "siblings, brother, sister",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/06/22/08/38/siblings-817369_150.jpg",
        previewWidth: 150,
        previewHeight: 104,
        webformatURL:
          "https://pixabay.com/get/g78599e4b7e9e2f51d21aea7b8bdb434002befaa751909819bbd368f92e3bc492809a47cbe8dbfba9f717e412d79886f0_640.jpg",
        webformatWidth: 640,
        webformatHeight: 446,
        largeImageURL:
          "https://pixabay.com/get/g31850a54d1738580f6b720cc8a3138c6087ea7dfae29103afdc1589d3cc83de864c58d7a3da194d7d107dd8f4dc3396520e38e82ce56f2101554c7c9cdded1a6_1280.jpg",
        imageWidth: 4643,
        imageHeight: 3240,
        imageSize: 3224240,
        views: 943675,
        downloads: 458754,
        collections: 1454,
        likes: 1747,
        comments: 299,
        user_id: 909086,
        user: "Bessi",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/04/11/22-45-05-994_250x250.jpg",
      },
      {
        id: 712892,
        pageURL:
          "https://pixabay.com/photos/daisies-heart-flowers-white-flowers-712892/",
        type: "photo",
        tags: "daisies, heart, flowers",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/04/08/15/09/daisies-712892_150.jpg",
        previewWidth: 150,
        previewHeight: 110,
        webformatURL:
          "https://pixabay.com/get/g1b3abeaf269e6b2465895c6c290c9694a5880dc4847306c514faf282e64850ddedc35f22a659384ead998ce2e1d6cccd_640.jpg",
        webformatWidth: 640,
        webformatHeight: 471,
        largeImageURL:
          "https://pixabay.com/get/gfdf5e62a4fe4a80ea72282b1dd095eac7c223b768df875a4e639e7a5fd60432100404f874048fca5636af3c46d222ea2e3ea65cad8aa8111d116eb9bc0404ee6_1280.jpg",
        imageWidth: 3950,
        imageHeight: 2913,
        imageSize: 3106739,
        views: 1310577,
        downloads: 636111,
        collections: 1574,
        likes: 1909,
        comments: 337,
        user_id: 509903,
        user: "congerdesign",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/11/06/12-07-49-892_250x250.jpg",
      },
      {
        id: 22194,
        pageURL:
          "https://pixabay.com/photos/father-baby-portrait-infant-22194/",
        type: "photo",
        tags: "father, baby, portrait",
        previewURL:
          "https://cdn.pixabay.com/photo/2012/03/04/01/01/father-22194_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g4905c489eee68bb85df21035361b6d93c0e917c8c171158b33936d1622f55dc1216ce7cc0a370a5ceab7a86c6fec99c9_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g52593341bd7a3dac1eca80015490e12e27f1f916f560a53e443731c51e23ec4df20da3e2fc7adb1b6875eba573863002_1280.jpg",
        imageWidth: 5000,
        imageHeight: 3333,
        imageSize: 2542369,
        views: 1369013,
        downloads: 716893,
        collections: 2143,
        likes: 2178,
        comments: 490,
        user_id: 14,
        user: "PublicDomainPictures",
        userImageURL:
          "https://cdn.pixabay.com/user/2012/03/08/00-13-48-597_250x250.jpg",
      },
      {
        id: 2933984,
        pageURL:
          "https://pixabay.com/photos/man-woman-composing-dispute-2933984/",
        type: "photo",
        tags: "man, woman, composing",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/11/09/16/16/man-2933984_150.jpg",
        previewWidth: 150,
        previewHeight: 77,
        webformatURL:
          "https://pixabay.com/get/g17e116356c32a607a78ebe571817606e2a0bfa8c77660bd60fc0295bb817a96a5e7b351cf41f2f6f088567c9c1d5855aeb125c2ec9c508d3dde2f008485fd35e_640.jpg",
        webformatWidth: 640,
        webformatHeight: 330,
        largeImageURL:
          "https://pixabay.com/get/g7d4534ae48385db0df76098c8f5b70b67563deb3ebabcf60290567286de12f5d209ac128537efa6a4248254b21cd577c21d13cc653853fa43e92e3c4bd4e97ba_1280.jpg",
        imageWidth: 5000,
        imageHeight: 2585,
        imageSize: 710354,
        views: 678388,
        downloads: 520592,
        collections: 1617,
        likes: 1535,
        comments: 212,
        user_id: 2364555,
        user: "NoName_13",
        userImageURL:
          "https://cdn.pixabay.com/user/2022/12/12/07-40-59-226_250x250.jpg",
      },
      {
        id: 762564,
        pageURL: "https://pixabay.com/photos/heart-card-pastels-figure-762564/",
        type: "photo",
        tags: "heart, card, pastels",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/05/11/14/51/heart-762564_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/gb7e97c19da9f8aea2e865b821a90aa1509f6b6a6398063bb5824c47437c1c2d1853c7871c023aac14d3f361f6b2e9ab8_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g496f50911a01ba2823b82d6c29f2dc4c5f3aeecb40269d6474bf80edf6d93a3609049e1811d508c35bf7d583b0f4b99d6585737880bfe6ce4a56ff4cc1f2fcdb_1280.jpg",
        imageWidth: 5472,
        imageHeight: 3648,
        imageSize: 3837375,
        views: 788669,
        downloads: 477018,
        collections: 1294,
        likes: 1306,
        comments: 206,
        user_id: 1013994,
        user: "kaboompics",
        userImageURL:
          "https://cdn.pixabay.com/user/2018/04/09/00-31-22-504_250x250.jpg",
      },
      {
        id: 3798371,
        pageURL:
          "https://pixabay.com/photos/couple-love-outdoors-pair-park-3798371/",
        type: "photo",
        tags: "couple, love, outdoors",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/11/06/14/01/couple-3798371_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/gaf71918431d210bd7b0ea514df41aa254ffe35fc2ada40d679cc16cf5c789499947a73cc78ea010bcbb8c2fba7494e135fd15aea16a41009c7f0bc83fcf5d88a_640.jpg",
        webformatWidth: 640,
        webformatHeight: 359,
        largeImageURL:
          "https://pixabay.com/get/g74529d82241d4b4a1dbd5a55ab0f704035fbc0aafcc05eaf0f8bc5061be516df594731b1a48ffb6fd9bc994233f58b9caa1bcbd74d9f3e5024bf5fa2be606424_1280.jpg",
        imageWidth: 5184,
        imageHeight: 2911,
        imageSize: 1930913,
        views: 870022,
        downloads: 599443,
        collections: 1389,
        likes: 1589,
        comments: 222,
        user_id: 2364555,
        user: "NoName_13",
        userImageURL:
          "https://cdn.pixabay.com/user/2022/12/12/07-40-59-226_250x250.jpg",
      },
      {
        id: 3064048,
        pageURL:
          "https://pixabay.com/photos/couple-romance-love-kiss-lovers-3064048/",
        type: "photo",
        tags: "couple, romance, love",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/01/05/22/48/couple-3064048_150.jpg",
        previewWidth: 150,
        previewHeight: 72,
        webformatURL:
          "https://pixabay.com/get/gb2667945c910068d22038bba427481f7f3ef7bfb056309c477d4c3b0d600470cd3ce5dcf33623163549ae872061a586974f789f1dad04f2d91167b9ae8428acb_640.jpg",
        webformatWidth: 640,
        webformatHeight: 310,
        largeImageURL:
          "https://pixabay.com/get/gee811a5f7e17a3caccc019c5ab8971c23a4301436e97680153cb6ed01e92d872f1ae2fc06ddf431bc69c2017eb7379bf456eff43300548f58348c4c399eb4107_1280.jpg",
        imageWidth: 5911,
        imageHeight: 2869,
        imageSize: 2141311,
        views: 787260,
        downloads: 503243,
        collections: 1198,
        likes: 1541,
        comments: 292,
        user_id: 1857980,
        user: "dimitrisvetsikas1969",
        userImageURL:
          "https://cdn.pixabay.com/user/2022/07/17/16-35-41-826_250x250.jpg",
      },
      {
        id: 3061483,
        pageURL:
          "https://pixabay.com/photos/love-valentine-heart-in-love-3061483/",
        type: "photo",
        tags: "love, valentine, heart",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/01/04/19/43/love-3061483_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g64f13862246fefcff2b4c263763b7200d688662ff38bf12f423b45bf3e4082700ee76a23584180317a9be85aad4bf92ec4da741c866a731ced08ec54e376955e_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g06dbbaa62747d27f6f5d3c83a15e4d801ae4f5f72e31a4e7a82a90ffad533056bd2c2b7d31c75703e99310bea3024ae39fc7b921d9bfe706fbaf9f00454d0593_1280.jpg",
        imageWidth: 5364,
        imageHeight: 3571,
        imageSize: 3010450,
        views: 1851219,
        downloads: 1106035,
        collections: 1502,
        likes: 1660,
        comments: 232,
        user_id: 2218222,
        user: "Ylanite",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/07/28/10-13-57-501_250x250.jpeg",
      },
      {
        id: 3113651,
        pageURL:
          "https://pixabay.com/photos/portrait-people-darkness-art-3113651/",
        type: "photo",
        tags: "portrait, people, darkness",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/01/28/13/24/portrait-3113651_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g841b5c8d0c282471e31fa9199b71b16b826365e45f8e2443b8e90801d6fbbd5115bc2452121a78664528afb70677898e3e5eeed600d31c0b4c1a468bb5069cd1_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g44adce07a3ae60a7365d2a4c8f5fe5e6bcbea576ac7954643aa909c003a524c09d2c127346672e08e74324fdbce0d0e1c0d30e93cc342f03ec293d1001c7e15e_1280.jpg",
        imageWidth: 5309,
        imageHeight: 3539,
        imageSize: 2516536,
        views: 851174,
        downloads: 601645,
        collections: 1231,
        likes: 1368,
        comments: 188,
        user_id: 7853109,
        user: "Deflyne",
        userImageURL:
          "https://cdn.pixabay.com/user/2018/01/28/13-37-18-644_250x250.jpg",
      },
      {
        id: 731890,
        pageURL:
          "https://pixabay.com/photos/couple-making-out-young-people-731890/",
        type: "photo",
        tags: "couple, making out, young",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/04/20/17/38/couple-731890_150.jpg",
        previewWidth: 150,
        previewHeight: 100,
        webformatURL:
          "https://pixabay.com/get/g313b2e91bce34908667aa85bfc27a410260dea175c02d808478b2babd80fe018f8e7f3d7b3c6d6f9b355530d07d5c02e_640.jpg",
        webformatWidth: 640,
        webformatHeight: 427,
        largeImageURL:
          "https://pixabay.com/get/g15b526540a5f5946c02673e5f81db95261f617086790e8e8d43d7698570796385b46a91a48c6c7a6851b240414d799bad5b3b83bc6752533deabe4ac88477d46_1280.jpg",
        imageWidth: 7360,
        imageHeight: 4912,
        imageSize: 9056210,
        views: 1111791,
        downloads: 298646,
        collections: 1575,
        likes: 1461,
        comments: 161,
        user_id: 692575,
        user: "stokpic",
        userImageURL:
          "https://cdn.pixabay.com/user/2015/01/09/17-53-17-220_250x250.jpg",
      },
      {
        id: 2425121,
        pageURL:
          "https://pixabay.com/photos/man-woman-dog-pet-pug-owners-2425121/",
        type: "photo",
        tags: "man, woman, dog",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/06/20/22/14/man-2425121_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g77166605e64b305661523fc53932bd2c693c4cd6dd19c81139be0c8a5b3c4c18158151fb58a96bac056c8d7515d1046dfccea2877b53b83c095563179c162edf_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g1538e9b029f9ca1ccbe704e3115987f2fd2eedced40f03b5cb0bea0a22af65d5bacef1d85f77bdad0463a3ead43cee3de7fe5bfccb65c942e9f9d397626616d6_1280.jpg",
        imageWidth: 4460,
        imageHeight: 2973,
        imageSize: 2293248,
        views: 1793090,
        downloads: 1232941,
        collections: 2616,
        likes: 2493,
        comments: 389,
        user_id: 5688709,
        user: "5688709",
        userImageURL: "",
      },
    ];
    resolve(items);
  });
}

export function getBackgroundImages(query) {
  /* return new Promise((resolve, reject) => {
    pixabayClient
      .get("", {
        params: {
          // key: process.env.REACT_APP_PIXABAY_CLIENT_ID,
          q: query,
          image_type: "photo",
        },
      })
      .then((response) => {
        // console.log(response.data.hits);
        const items = response.data.hits;
        resolve(items);
      })
      .catch((err) => {
        reject(err);
      });
    //  fetch(
    //   "https://pixabay.com/api?key=27174649-fdf5a86d33e5edef17af66ff9&q=love&image_type=photo"
    // )
    //   .then((res) => res.json())
    //   .then((json) => {
    //     const items = json.data.hits;
    //     resolve(items);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  }); */

  return new Promise((resolve, reject) => {
    const items = [
      {
        id: 2695569,
        pageURL:
          "https://pixabay.com/photos/milky-way-stars-night-sky-2695569/",
        type: "photo",
        tags: "milky way, hd wallpaper, stars",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/g3a835f64ef634b7a2f01e937fa8778d70e3dae8f764d8dd45d41b91b3aa45fd84a344f4ade260c73249cf93edcb84776841da2c8f1e6c19ee4e0b57a831cbd28_640.jpg",
        webformatWidth: 640,
        webformatHeight: 359,
        largeImageURL:
          "https://pixabay.com/get/gdc312fabfd2337074b1575b67f10b193860a6c4ee62c5e4027a88a6db829249e5d600f5fda33bc9067361776c5d0ac6016ceb559a6b3127adb64b303d3b6a4a5_1280.jpg",
        imageWidth: 6070,
        imageHeight: 3414,
        imageSize: 14622947,
        views: 8118366,
        downloads: 5197941,
        collections: 6267,
        likes: 6660,
        comments: 1216,
        user_id: 4397258,
        user: "FelixMittermeier",
        userImageURL:
          "https://cdn.pixabay.com/user/2022/10/15/21-17-48-138_250x250.jpg",
      },
      {
        id: 3605547,
        pageURL:
          "https://pixabay.com/photos/ocean-milky-way-boat-sailing-3605547/",
        type: "photo",
        tags: "ocean, nature wallpaper, milky way",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_150.jpg",
        previewWidth: 150,
        previewHeight: 97,
        webformatURL:
          "https://pixabay.com/get/gaba0178ed403bb973bac5560ad050c5035e65d3792636950490c9637d4b0be752e9fe8d0fdf9e91b6db4282f8b0efc054fed41e48e855aa4e5c207451e3caf82_640.jpg",
        webformatWidth: 640,
        webformatHeight: 415,
        largeImageURL:
          "https://pixabay.com/get/g08d1858b974da24675f367a063732e65bdec74db9040f3657433a126191588fc870ca40a2ad74557c7ad5e63f2fc3729b948776d89ca767ced6e39db9dc1e2fe_1280.jpg",
        imageWidth: 6000,
        imageHeight: 3894,
        imageSize: 3590092,
        views: 5768970,
        downloads: 3765814,
        collections: 5538,
        likes: 5936,
        comments: 956,
        user_id: 7645255,
        user: "jplenio",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/10/26/14-38-35-241_250x250.png",
      },
      {
        id: 736885,
        pageURL:
          "https://pixabay.com/photos/tree-sunset-clouds-sky-silhouette-736885/",
        type: "photo",
        tags: "tree, sunset, clouds",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_150.jpg",
        previewWidth: 150,
        previewHeight: 93,
        webformatURL:
          "https://pixabay.com/get/g78ebbc93fcd5ac036c175b9a2407e12ce3868ebf91ab87aed008ed581dd0bf6ea12eefffa9eaf22a1f816e20fbebc20f_640.jpg",
        webformatWidth: 640,
        webformatHeight: 398,
        largeImageURL:
          "https://pixabay.com/get/g30dccd0c492a847879402e7de492321e786b0e2c096e060ab7ab314a70e767c9f86457c7d893f615b67d0583dc04aff94a2807312556aaf1c6c539de2be6ef6c_1280.jpg",
        imageWidth: 1920,
        imageHeight: 1195,
        imageSize: 186303,
        views: 8744058,
        downloads: 4712067,
        collections: 7814,
        likes: 9447,
        comments: 1920,
        user_id: 909086,
        user: "Bessi",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/04/11/22-45-05-994_250x250.jpg",
      },
      {
        id: 1867616,
        pageURL:
          "https://pixabay.com/photos/astronomy-bright-constellation-dark-1867616/",
        type: "photo",
        tags: "astronomy, bright, constellation",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/11/29/05/45/astronomy-1867616_150.jpg",
        previewWidth: 150,
        previewHeight: 102,
        webformatURL:
          "https://pixabay.com/get/ge079a7e6848dd5b3849fa9f5ce3721efe31541332e378743eb5a8699c8186f01887c2b56c3502907eac35bf53a50770194f60d04d9fc873303075505852abc3c_640.jpg",
        webformatWidth: 640,
        webformatHeight: 436,
        largeImageURL:
          "https://pixabay.com/get/g229aa0c066b09f594b71583ccda2289273d966edda010b0aa503f9a229be9ccab98c0479e88de780ce161d3903f8d5fddd1283f7b18c5c602b18aaffb6d6cce5_1280.jpg",
        imageWidth: 5005,
        imageHeight: 3417,
        imageSize: 5071902,
        views: 4592055,
        downloads: 3301278,
        collections: 4974,
        likes: 4532,
        comments: 735,
        user_id: 2286921,
        user: "Pexels",
        userImageURL:
          "https://cdn.pixabay.com/user/2016/03/26/22-06-36-459_250x250.jpg",
      },
      {
        id: 1074131,
        pageURL:
          "https://pixabay.com/photos/paper-old-texture-parchment-1074131/",
        type: "photo",
        tags: "paper, desktop backgrounds, old",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/12/03/08/50/paper-1074131_150.jpg",
        previewWidth: 150,
        previewHeight: 106,
        webformatURL:
          "https://pixabay.com/get/gaeac00305376de50cb8b647edb8f72aab7ff9d428498aa4605df4723a13a57b984984ccc52108e5aaac35c21dbe60883ad681c05b7c97a8cbd21e2ea7f4f19c4_640.jpg",
        webformatWidth: 640,
        webformatHeight: 454,
        largeImageURL:
          "https://pixabay.com/get/g7ca2d1809bced1f909e9e4f4faf3ef30d4d1803f84ffed73b142dbe10c6fa84911796a4734b21cf180e6eed2ab8bfb61bce107d1438194cc59ccbbd60868e9bd_1280.jpg",
        imageWidth: 6882,
        imageHeight: 4884,
        imageSize: 4479428,
        views: 3699663,
        downloads: 2807801,
        collections: 3806,
        likes: 3892,
        comments: 897,
        user_id: 935884,
        user: "ChrisFiedler",
        userImageURL:
          "https://cdn.pixabay.com/user/2015/07/28/07-59-10-969_250x250.jpg",
      },
      {
        id: 3082832,
        pageURL:
          "https://pixabay.com/photos/nature-waters-lake-island-3082832/",
        type: "photo",
        tags: "nature, waters, cool backgrounds",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/g75a1e716f4b0c3f3fc0cedbde96c7a9057d08049fdad74580c363ed01116a3aff4eb7955be9630dff725d910a347a4427476539407b78f01dcb938d25ee53882_640.jpg",
        webformatWidth: 640,
        webformatHeight: 359,
        largeImageURL:
          "https://pixabay.com/get/g3534b0e449ea65e25c8bd773ebcb45006918712ac5541c4eb822e06d5e98988e52777e3ef3b7bef43eb4eb70c0acafdff4d7e8a2702f23d56daba77dc41e8ed0_1280.jpg",
        imageWidth: 5757,
        imageHeight: 3238,
        imageSize: 4638828,
        views: 6858507,
        downloads: 4161460,
        collections: 4223,
        likes: 4838,
        comments: 730,
        user_id: 7645255,
        user: "jplenio",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/10/26/14-38-35-241_250x250.png",
      },
      {
        id: 276014,
        pageURL:
          "https://pixabay.com/photos/flowers-meadow-sunlight-summer-276014/",
        type: "photo",
        tags: "flowers, meadow, beautiful flowers",
        previewURL:
          "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_150.jpg",
        previewWidth: 150,
        previewHeight: 95,
        webformatURL:
          "https://pixabay.com/get/g038256d3cbd8881aaf2936bfcd5b7435a1529321542679ea3af9abdf8a24bfc0afa754f050c27713eb51c37865c418fe_640.jpg",
        webformatWidth: 640,
        webformatHeight: 407,
        largeImageURL:
          "https://pixabay.com/get/g56963d5d6454593dec652687ddc52a8bf7412ddc8a23a0fdf76201678e9b7db49d2c96b18f55b0f081f5c137963205855d8bfc2880b3546ccaf3c3c42745d636_1280.jpg",
        imageWidth: 4090,
        imageHeight: 2602,
        imageSize: 2134495,
        views: 4398605,
        downloads: 2090842,
        collections: 5776,
        likes: 7028,
        comments: 2012,
        user_id: 1107275,
        user: "Larisa-K",
        userImageURL:
          "https://cdn.pixabay.com/user/2015/06/13/06-38-56-116_250x250.jpg",
      },
      {
        id: 174276,
        pageURL:
          "https://pixabay.com/photos/poppies-field-sunset-dusk-sunlight-174276/",
        type: "photo",
        tags: "poppies, mac wallpaper, flower background",
        previewURL:
          "https://cdn.pixabay.com/photo/2013/08/20/15/47/poppies-174276_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g3eb79d0c9138e7e8a5b847e99cf532566c724b1280b79ea53bd8b775d079a9621d81229fc23dfb56baeec9d017d50a29_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g5f1d2b84a5c0d38b07f0dff1d3452e86673ec3b1013ae273dcd3b140f4e12afa23ec37cae70a17507aedb99aa29b5381e5bd92518cac656a200795b65a838bdb_1280.jpg",
        imageWidth: 6000,
        imageHeight: 4000,
        imageSize: 2624110,
        views: 2785251,
        downloads: 1605045,
        collections: 3496,
        likes: 3609,
        comments: 803,
        user_id: 49249,
        user: "danigeza",
        userImageURL: "",
      },
      {
        id: 445228,
        pageURL:
          "https://pixabay.com/photos/dandelion-seeds-dew-dewdrops-445228/",
        type: "photo",
        tags: "dandelion, seeds, dew",
        previewURL:
          "https://cdn.pixabay.com/photo/2014/09/14/18/04/dandelion-445228_150.jpg",
        previewWidth: 150,
        previewHeight: 93,
        webformatURL:
          "https://pixabay.com/get/gb3b8bfec59eb1867673c2bab43101583c1f7c071424e7d9668b1f65028fb0ab22d54673dcee575bfaebc1a6c3c3fab8a_640.jpg",
        webformatWidth: 640,
        webformatHeight: 401,
        largeImageURL:
          "https://pixabay.com/get/g0e37258d92847fbbc6b04de304b1575c3e2dd58fcbe6949e7186de33fa62e705bb6a0591c851152e734cbb2e5dbe3588fd70f66a703efcd96d3be8ec25222856_1280.jpg",
        imageWidth: 4770,
        imageHeight: 2995,
        imageSize: 3835874,
        views: 2994460,
        downloads: 1670559,
        collections: 3726,
        likes: 3949,
        comments: 827,
        user_id: 57296,
        user: "InspiredImages",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/11/28/02-22-20-529_250x250.jpg",
      },
      {
        id: 1911637,
        pageURL:
          "https://pixabay.com/photos/christmas-background-flat-lay-1911637/",
        type: "photo",
        tags: "christmas, hd wallpaper, background",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/12/16/15/25/christmas-1911637_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g338110544a8a480ec6f137924f9c7ec77712a23aff9850ec4409eaa39c2570e528c0fe82d31e92739bc1bf9b615e4eb7e36389953b65c17132e98af739257bae_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/ga21825dadfb672976923403916c122935113a883b102bca737e380fcb751ba817c039b51379068f6f06e847dfb4403988618521c5098c2cf3782ed59340b7328_1280.jpg",
        imageWidth: 5760,
        imageHeight: 3840,
        imageSize: 9768893,
        views: 3451802,
        downloads: 2423558,
        collections: 3494,
        likes: 3488,
        comments: 588,
        user_id: 3938704,
        user: "Daria-Yakovleva",
        userImageURL:
          "https://cdn.pixabay.com/user/2016/12/06/15-05-11-524_250x250.jpg",
      },
      {
        id: 1072366,
        pageURL:
          "https://pixabay.com/photos/black-board-traces-of-chalk-school-1072366/",
        type: "photo",
        tags: "black, mac wallpaper, board",
        previewURL:
          "https://cdn.pixabay.com/photo/2015/12/01/15/43/black-1072366_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/gbc065d5c96545b1818bc7b13c0d37e22c44976ff528a68782190330147fa37f77216d4cb81b01d16a16e00472c5a96e5b02e3b44526766a3154bb90c58a114f1_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g57251efefa48e928679805bf0a0b19c56cbf397917c85e184d83549808caacec7f543a8ca5a08f46f30b297dfed00c220d4c15affb02c93ffdca2bfe01640523_1280.jpg",
        imageWidth: 4752,
        imageHeight: 3168,
        imageSize: 4120115,
        views: 2835372,
        downloads: 2034580,
        collections: 3151,
        likes: 3280,
        comments: 227,
        user_id: 12364,
        user: "stux",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/07/02/13-55-27-199_250x250.jpeg",
      },
      {
        id: 2468874,
        pageURL:
          "https://pixabay.com/photos/colorful-painting-background-2468874/",
        type: "photo",
        tags: "colorful, beautiful wallpaper, painting",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/07/03/20/17/colorful-2468874_150.jpg",
        previewWidth: 150,
        previewHeight: 108,
        webformatURL:
          "https://pixabay.com/get/gf34dc8d62d7bcc1b1686bea29a4843c41a17350a3471897cfed9f68fd974a29e783d4801b479b1c53b8472af2872e3ea795a2fcd4952d50dc5b5633b4d322ccf_640.jpg",
        webformatWidth: 640,
        webformatHeight: 465,
        largeImageURL:
          "https://pixabay.com/get/gebfd7f69989001946f70974e7ea6783796e1d7b3939dfb70e0f624f5ba52e8141b5adaf415f1becf7e3b469e2a80aedc16409945ca499dafbcb425ff1ab8c160_1280.jpg",
        imageWidth: 3300,
        imageHeight: 2400,
        imageSize: 2465041,
        views: 2588750,
        downloads: 2011824,
        collections: 3123,
        likes: 2769,
        comments: 460,
        user_id: 4200899,
        user: "garageband",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/03/25/19-40-05-95_250x250.jpg",
      },
      {
        id: 3622519,
        pageURL:
          "https://pixabay.com/photos/forest-trees-fog-conifers-pine-3622519/",
        type: "photo",
        tags: "forest, trees, fog",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/08/21/23/29/forest-3622519_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g01ce09f22cdc55cecdebec87917dcbb8d1a5cfd94e8a3666ac0bda5bcbe481a0ccc247ad152a4f0a74e71bfe4797ed65fa82dc7b3cb6e2ffbc2c82918ecfa222_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g899a66efed5b95f850614688f27d02d79f4b30177c9e5cf4e64ad53cf27e9cd7462bcb43453f1285edbb1f0aed141349c3a410ec21fface828037d1c7e03be2f_1280.jpg",
        imageWidth: 6000,
        imageHeight: 4000,
        imageSize: 7139759,
        views: 3649881,
        downloads: 2857839,
        collections: 4102,
        likes: 3231,
        comments: 442,
        user_id: 7645255,
        user: "jplenio",
        userImageURL:
          "https://cdn.pixabay.com/user/2023/10/26/14-38-35-241_250x250.png",
      },
      {
        id: 1751455,
        pageURL:
          "https://pixabay.com/photos/beach-sea-sunset-sun-sunlight-1751455/",
        type: "photo",
        tags: "beach, sea, cool backgrounds",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/10/18/21/22/beach-1751455_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g170d3615130a91499dcd7ed006eb3ac9984307f21328fcf15bc619bba769ec3e3a247e0c0c9ec89de59b7815e853952460064cfc6959605f7ad1b1b49f4635a3_640.jpg",
        webformatWidth: 640,
        webformatHeight: 425,
        largeImageURL:
          "https://pixabay.com/get/g65b091a66363c0599de93a8c47496eed0446821517f1690fd805072092be6c192b1f850e8e3adf2d34418501b12b70377b8546a548d5cc7b683a68d81f5da08f_1280.jpg",
        imageWidth: 2200,
        imageHeight: 1464,
        imageSize: 1231856,
        views: 2690602,
        downloads: 1676762,
        collections: 4068,
        likes: 4357,
        comments: 807,
        user_id: 12019,
        user: "12019",
        userImageURL: "",
      },
      {
        id: 1192662,
        pageURL:
          "https://pixabay.com/photos/heart-leaves-foliage-garden-bush-1192662/",
        type: "photo",
        tags: "heart, leaves, foliage",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/02/10/21/57/heart-1192662_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g7e215eae71c25e196cbd28705fb16b05003b2ec969b121a2cf6bd5887b21aa42f885533c1b4d2f999ae564b2053055f5695559d77fd2b75f9e0c455b4425d7dd_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/ga8189e9a3403881df25594f8d6db9972ce7a6af9fa916aeb22d0d5ad5d2d3d3f382c8849a4b55dbd795d01f9545e27220e00485221fdebae3d93560e60e191c8_1280.jpg",
        imageWidth: 6000,
        imageHeight: 4000,
        imageSize: 6730913,
        views: 2308954,
        downloads: 1378477,
        collections: 2754,
        likes: 3231,
        comments: 656,
        user_id: 2023852,
        user: "2023852",
        userImageURL: "",
      },
      {
        id: 3077928,
        pageURL:
          "https://pixabay.com/photos/fantasy-beautiful-dawn-sunset-sky-3077928/",
        type: "photo",
        tags: "fantasy, wallpaper hd, 4k wallpaper",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/01/12/10/19/fantasy-3077928_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/g662f4cd8a1094517b7ef56fd2c2170fab5e52e2fcd8b897ba4eca36ffc1c45a2247c132e7c236f3964125da1e413d0ed9288948a672b627a097b5b22f0537631_640.jpg",
        webformatWidth: 640,
        webformatHeight: 360,
        largeImageURL:
          "https://pixabay.com/get/gc416ee44f0683f931afd06989094132ff0d39b75121b4baeb68725be3e4b2ece0e45c3fc31f2f36a492429c28632fc2b338f91b0df115bde2d83aae465fda948_1280.jpg",
        imageWidth: 3840,
        imageHeight: 2160,
        imageSize: 1925809,
        views: 4439129,
        downloads: 2847679,
        collections: 3947,
        likes: 4334,
        comments: 644,
        user_id: 2946451,
        user: "peter_pyw",
        userImageURL:
          "https://cdn.pixabay.com/user/2018/01/12/08-06-25-409_250x250.jpg",
      },
      {
        id: 1337565,
        pageURL:
          "https://pixabay.com/photos/seashells-sand-beach-sea-coast-1337565/",
        type: "photo",
        tags: "seashells, sand, cool backgrounds",
        previewURL:
          "https://cdn.pixabay.com/photo/2016/04/18/22/05/seashells-1337565_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/g8018435ac028d371199ed7719083aaf45c69d5a69123c7135be6f1561cb57e29d17e2efcfeba6238c9e6d0c7b030d00541d55de3a1d3388d706bf3148f871732_640.jpg",
        webformatWidth: 640,
        webformatHeight: 423,
        largeImageURL:
          "https://pixabay.com/get/gdffec345dda1224c2724adf9ee2589daab10e2348cbfb02e2d5fb89aaeaba7dc41a3b07755b3a85204d76131bdec95e1879558fde7ea3c1b05e629f1be813b10_1280.jpg",
        imageWidth: 3872,
        imageHeight: 2562,
        imageSize: 3810728,
        views: 2502003,
        downloads: 1790222,
        collections: 3134,
        likes: 3174,
        comments: 645,
        user_id: 1670164,
        user: "croisy",
        userImageURL:
          "https://cdn.pixabay.com/user/2015/11/20/14-04-35-712_250x250.jpg",
      },
      {
        id: 2049567,
        pageURL:
          "https://pixabay.com/photos/fantasy-butterflies-mushrooms-2049567/",
        type: "photo",
        tags: "fantasy, butterflies, mushrooms",
        previewURL:
          "https://cdn.pixabay.com/photo/2017/02/08/17/24/fantasy-2049567_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/g35331e07067ffd7096c8ef03c419b8a6e40273dbe56a744780df801ca5bd8f9cb38b0711d8120e7cc8e0c2c7f25e63565c8a2c83a4a7061b7cf858f4a36a68e0_640.jpg",
        webformatWidth: 640,
        webformatHeight: 360,
        largeImageURL:
          "https://pixabay.com/get/g30b019f4004c91eee78159c48f72840ce7895239d2c34b377c3188f3bbb2b237bb6e288d6dc33af53ed6bba049d20129894260bd265bf37a08c22ac10daf6ddd_1280.jpg",
        imageWidth: 3840,
        imageHeight: 2160,
        imageSize: 1244451,
        views: 3183024,
        downloads: 2168270,
        collections: 5068,
        likes: 4992,
        comments: 788,
        user_id: 4163614,
        user: "Stergo",
        userImageURL:
          "https://cdn.pixabay.com/user/2017/02/15/17-11-09-80_250x250.jpg",
      },
      {
        id: 3625405,
        pageURL:
          "https://pixabay.com/photos/thunderstorm-wallpaper-clouds-storm-3625405/",
        type: "photo",
        tags: "thunderstorm, wallpaper, nature",
        previewURL:
          "https://cdn.pixabay.com/photo/2018/08/23/07/35/thunderstorm-3625405_150.jpg",
        previewWidth: 150,
        previewHeight: 84,
        webformatURL:
          "https://pixabay.com/get/g7ab1c8af7dcc9cfcbaef80c2fe67813e02162312637bc86869ae22ac1b7b25d1707e6192749827f15fa856bf1ae16a3f6b6e285ce838602bd28765bfccc5e4d6_640.jpg",
        webformatWidth: 640,
        webformatHeight: 360,
        largeImageURL:
          "https://pixabay.com/get/g77e7ee812c4723b8846bb0774002fe63285e4de3ca95663121296d858cb55ed5d18b958ec7f2a0785a9a4c69e3cf0c73f858db4519b6a75d22a5eb43c35bdeb3_1280.jpg",
        imageWidth: 6000,
        imageHeight: 3375,
        imageSize: 4102858,
        views: 2666530,
        downloads: 2038111,
        collections: 3324,
        likes: 3407,
        comments: 520,
        user_id: 4397258,
        user: "FelixMittermeier",
        userImageURL:
          "https://cdn.pixabay.com/user/2022/10/15/21-17-48-138_250x250.jpg",
      },
      {
        id: 324175,
        pageURL:
          "https://pixabay.com/photos/pink-cherry-blossoms-flowers-branch-324175/",
        type: "photo",
        tags: "pink, cherry blossoms, flowers",
        previewURL:
          "https://cdn.pixabay.com/photo/2014/04/14/20/11/pink-324175_150.jpg",
        previewWidth: 150,
        previewHeight: 99,
        webformatURL:
          "https://pixabay.com/get/gfa1097885000dbebde92f9623b0af5213fb4b378ac26335c0e2ca0fc41781ad360d4dede438755d3c437485b0ddd7cb3_640.jpg",
        webformatWidth: 640,
        webformatHeight: 426,
        largeImageURL:
          "https://pixabay.com/get/g76eaeb04ca701daf9e47aa73201e0b6c4bf8c0a8b97494d904d8d0e2f88717ff786f00db49f177598dc3400dd5dc21a6c232cc7c4541444a8e0a000e694e84f2_1280.jpg",
        imageWidth: 6000,
        imageHeight: 4000,
        imageSize: 2613829,
        views: 4872814,
        downloads: 2601569,
        collections: 5187,
        likes: 6317,
        comments: 1532,
        user_id: 2,
        user: "Hans",
        userImageURL:
          "https://cdn.pixabay.com/user/2019/01/29/15-01-52-802_250x250.jpg",
      },
    ];

    resolve(items);
  });
}

export function getImage(uuid) {
  return new Promise((resolve, reject) => {
    pixabayClient
      .post(`items/${uuid}/api-download`, {
        format: "svg",
      })
      .then((response) => {
        const imageURL = response.data.response.download.url;
        resolve(imageURL);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

import { useCallback, useContext, useEffect, useState } from "react";
import { isArrow, isCtrlShiftZ, isCtrlZ } from "../utils/keyboard";
import { CanvasContext } from "../store/CanvasContext";

function useEventHandlers() {
  const { canvas, setActiveObject, activeObject, setZoomRatio } =
    useContext(CanvasContext);
  const [pause_saving, setPauseSaving] = useState(false);
  const [undo_stack, setUndoStack] = useState([]);
  const [redo_stack, setRedoStack] = useState([]);

  //variables for undo/redo

  /**
   * Canvas Mouse wheel handler
   */

  const UndoRedo = useCallback(() => {
    if (!pause_saving) {
      undo_stack.push(JSON.stringify(canvas));
      // console.log("Object added, state saved", undo_stack);
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      canvas.on("object:added", UndoRedo);
      canvas.on("object:modified", UndoRedo);
      canvas.on("object:removed", UndoRedo);
    }
    return () => {
      if (canvas) {
        canvas.off("object:added", UndoRedo);
        canvas.off("object:modified", UndoRedo);
        canvas.off("object:removed", UndoRedo);
      }
    };
  }, [canvas]);

  /**
   * Canvas Mouse wheel handler
   */

  const onMouseWheel = useCallback(
    (event) => {
      if (canvas && event.e.ctrlKey) {
        const delta = event.e.deltaY;
        let zoomRatio = canvas.getZoom();
        if (delta > 0) {
          zoomRatio -= 0.04;
        } else {
          zoomRatio += 0.04;
        }
        setZoomRatio(zoomRatio);
      }
      event.e.preventDefault();
      event.e.stopPropagation();
    },
    [canvas]
  );

  useEffect(() => {
    if (canvas) {
      canvas.on("mouse:wheel", onMouseWheel);
    }
    return () => {
      if (canvas) {
        canvas.off("mouse:wheel", onMouseWheel);
      }
    };
  }, [canvas]);

  /**
   * Canvas selection handlers
   */

  const onSelect = useCallback(
    ({ target }) => {
      if (target) {
        if (canvas) {
          setActiveObject(canvas.getActiveObject());
        }
      } else {
        setActiveObject(null);
      }
    },
    [canvas]
  );

  useEffect(() => {
    if (canvas) {
      canvas.on("selection:created", onSelect);
      canvas.on("selection:cleared", onSelect);
      canvas.on("selection:updated", onSelect);
    }
    return () => {
      if (canvas) {
        canvas.off("selection:cleared", onSelect);
        canvas.off("selection:created", onSelect);
        canvas.off("selection:updated", onSelect);
      }
    };
  }, [canvas]);

  /**
   * Keyboard Events Handler
   */

  const undo = useCallback(() => {
    // @ts-ignore
    // canvas?.undo();
    setPauseSaving(true);
    redo_stack.push(undo_stack.pop());
    let previous_state = undo_stack[undo_stack.length - 1];
    if (previous_state == null) {
      previous_state = "{}";
    }
    canvas.loadFromJSON(previous_state, function () {
      canvas.renderAll();
    });
    setPauseSaving(false);
  }, [canvas]);

  const redo = useCallback(() => {
    setPauseSaving(true);
    let redo_state = redo_stack.pop();
    if (redo_state != null) {
      undo_stack.push(redo_state);
      canvas.loadFromJSON(redo_state, function () {
        canvas.renderAll();
      });
      setPauseSaving(false);
    }
  }, [canvas]);

  const moveUp = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.top = activeObject.top - 2;
      activeObject.setCoords();
      canvas.requestRenderAll();
    }
  }, [activeObject, canvas]);

  const moveDown = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.top = activeObject.top + 2;
      activeObject.setCoords();
      canvas.requestRenderAll();
    }
  }, [activeObject, canvas]);

  const moveRight = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.left = activeObject.left + 2;
      activeObject.setCoords();
      canvas.requestRenderAll();
    }
  }, [activeObject, canvas]);

  const moveLeft = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.left = activeObject.left - 2;
      activeObject.setCoords();
      canvas.requestRenderAll();
    }
  }, [activeObject, canvas]);

  const onKeyDown = useCallback(
    (e) => {
      isCtrlZ(e) && undo();
      isCtrlShiftZ(e) && redo();
      if (isArrow(e)) {
        e.code === "ArrowLeft" && moveLeft();
        e.code === "ArrowRight" && moveRight();
        e.code === "ArrowDown" && moveDown();
        e.code === "ArrowUp" && moveUp();
      }
    },
    [canvas, activeObject]
  );
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [canvas, activeObject]);
}

export default useEventHandlers;

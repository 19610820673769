import { useContext, useState } from "react";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { fabric } from "fabric";
import useCoreHandler from "../../../Canvas/handlers/useCoreHandler";

function TextPanel() {
  const { canvas, activeObject } = useContext(CanvasContext);
  const { addObject } = useCoreHandler();
  const [options, setOptions] = useState({
    type: "text",
    width: 200,
    top: 10,
    left: 10,
    fontSize: 24,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    fontFamily: "arial",
    textDecoration: "normal",
    fill: "#000000",
    text: "Add a heading",
  });

  const addTextBox = () => {
    // const textBox = new fabric.Textbox("Add your text here", options);
    addObject(options);
    // canvas.add(textBox);

    var iText = new fabric.IText("lorem ipsum\ndolor\nsit Amet\nconsectetur", {
      left: 100,
      top: 150,
      fontFamily: "Helvetica",
      fill: "#333",
      styles: {
        0: {
          0: { fill: "red", fontSize: 20 },
          1: { fill: "red", fontSize: 30 },
          2: { fill: "red", fontSize: 40 },
          3: { fill: "red", fontSize: 50 },
          4: { fill: "red", fontSize: 60 },

          6: { textBackgroundColor: "yellow" },
          7: { textBackgroundColor: "yellow" },
          8: { textBackgroundColor: "yellow" },
          9: { textBackgroundColor: "yellow" },
        },
        1: {
          0: { textDecoration: "underline" },
          1: { textDecoration: "underline" },
          2: {
            fill: "green",
            fontStyle: "italic",
            textDecoration: "underline",
          },
          3: {
            fill: "green",
            fontStyle: "italic",
            textDecoration: "underline",
          },
          4: {
            fill: "green",
            fontStyle: "italic",
            textDecoration: "underline",
          },
        },
        2: {
          0: { fill: "blue", fontWeight: "bold" },
          1: { fill: "blue", fontWeight: "bold" },
          2: { fill: "blue", fontWeight: "bold" },

          4: { fontFamily: "Courier", textDecoration: "line-through" },
          5: { fontFamily: "Courier", textDecoration: "line-through" },
          6: { fontFamily: "Courier", textDecoration: "line-through" },
          7: { fontFamily: "Courier", textDecoration: "line-through" },
        },
        3: {
          0: {
            fontFamily: "Impact",
            fill: "#666",
            textDecoration: "line-through",
          },
          1: {
            fontFamily: "Impact",
            fill: "#666",
            textDecoration: "line-through",
          },
          2: {
            fontFamily: "Impact",
            fill: "#666",
            textDecoration: "line-through",
          },
          3: {
            fontFamily: "Impact",
            fill: "#666",
            textDecoration: "line-through",
          },
          4: {
            fontFamily: "Impact",
            fill: "#666",
            textDecoration: "line-through",
          },
        },
      },
    });

    var iText2 = new fabric.IText("foo bar\nbaz\nquux", {
      left: 400,
      top: 150,
      fontFamily: "Helvetica",
      fill: "#333",
      styles: {
        0: {
          0: { fill: "red" },
          1: { fill: "red" },
          2: { fill: "red" },
        },
        2: {
          0: { fill: "blue" },
          1: { fill: "blue" },
          2: { fill: "blue" },
          3: { fill: "blue" },
        },
      },
    });

    canvas.add(iText, iText2);
  };

  return (
    <div>
      <button onClick={addTextBox}>Add Text Box</button>
    </div>
  );
}
export default TextPanel;

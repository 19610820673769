import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";

function VerifyMobile() {
  let navigate = useNavigate();
  /*   const [submitting, setSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState({
    mobileNumber: "",
  });
  const [errors, setErrors] = useState({});

  // console.log(inputFields);
  const validateValues = (inputValues) => {
    let errors = {};
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_phone = /^[6-9]\d{9}$/;
    const re = /^[0-9\b]+$/;

    if (!inputValues.mobileNumber) {
      errors.mobileNumber = "Please Enter Mobile Number";
    }
    if (inputValues.mobileNumber.length !== 10) {
      errors.mobileNumber = "Please Enter Valid Mobile Number";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputFields);
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = () => {
    useStore.setState({ RegmobileNumber: inputFields.mobileNumber });
    setSubmitting(true);
    return navigate("/verifyMobileOtp");
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]); */

  const onlyNumbers = /^[0-9]*$/;
  const isNotEmpty = (value) => value.trim() !== "";

  const isValidMobile = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length !== 10) {
      return false;
    }
    if (!onlyNumbers.test(value)) {
      return false;
    }
    return true;
  };
  const {
    value: MobileValue,
    isValid: MobileIsValid,
    hasError: MobileHasError,
    valueChangeHandler: MobileChangeHandler,
    inputBlurHandler: MobileBlurHandler,
    reset: resetMobile,
  } = useInput(isValidMobile);

  let formIsValid = false;

  if (MobileIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      MobileBlurHandler();
      return;
    }
    useStore.setState({ RegmobileNumber: MobileValue });
    console.log(MobileValue);
    return navigate("/verifyMobileOtp");
  };

  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="">
          <img src={logo} className=" center h-24 w-70" />

          <div className="align-middle md:align-top pt-24">
            <p
              className=" font-medium	 text-center  text-xs	"
              style={{ color: "#007038" }}
            >
              You've successfully verified your email address!
            </p>
            <h2 className=" font-extrabold	 text-center mb-6 text-lg	">
              Link your mobile number
            </h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {/* <input
                type="text"
                placeholder="Enter Mobile number"
                name="mobileNumber"
                value={inputFields.mobileNumber}
                onChange={handleChange}
                className={
                  errors.mobileNumber
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.mobileNumber}
              /> */}
              <Input
                type="text"
                id="Mobile"
                name="Mobile"
                value={MobileValue}
                onChange={MobileChangeHandler}
                onBlur={MobileBlurHandler}
                isValid={MobileIsValid}
                error={MobileHasError}
                title="Enter Mobile number"
                isMandate={true}
              ></Input>
            </div>
            <div className="text-center mt-6">
              <button
                type="submit"
                className=" block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Send OTP
              </button>
              {/* <p className="text-xs	 text-center  mt-4">
                Remember Your Password?
                <Link
                  to="/login"
                  className="cursor-pointer px-2 font-bold"
                  style={{ color: "#6659e3" }}
                >
                  Log in
                </Link>
              </p> */}
            </div>
          </form>
        </div>

        <p className="text-center absolute inset-x-0 bottom-0 text-xs">
          <Link
            to="/terms-of-use"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Terms of use
          </Link>
          |
          <Link
            to="/privacy-policy"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Privacy policy
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

export default VerifyMobile;

import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";

function Forgot() {
  let navigate = useNavigate();
  const onlyNumbers = /^[0-9]*$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_phone = /^[6-9]\d{9}$/;
  const re = /^[0-9\b]+$/;
  const isNotEmpty = (value) => value.trim() !== "";

  const isValidMobile = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }

    if (re.test(value.substring(0, 1))) {
      if (!reg_phone.test(value)) {
        return false;
      }
    } else {
      if (!reg_email.test(value)) {
        return false;
      }
    }

    return true;
  };
  const isValidPassword = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length < 8) {
      return false;
    }
    return true;
  };
  const {
    value: MobileValue,
    isValid: MobileIsValid,
    hasError: MobileHasError,
    valueChangeHandler: MobileChangeHandler,
    inputBlurHandler: MobileBlurHandler,
    reset: resetMobile,
  } = useInput(isValidMobile);

  let formIsValid = false;

  if (MobileIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      MobileBlurHandler();

      return;
    }
    console.log(MobileValue);
    return navigate("/UpdatePassword");
  };
  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="">
          <Link to="/">
            <img src={logo} className="m-auto center h-24 w-70" />
          </Link>

          <div className="align-middle md:align-top pt-24">
            <h2 className=" font-extrabold	 text-center mb-6 text-lg	">
              we will send reset link to your email id and mobile number
            </h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {/* <input
                type="text"
                placeholder="Enter Email ID/Mobile Number *"
                name="email"
                value={inputFields.email}
                onChange={handleChange}
                className="block text-sm py-3 px-4  w-full border outline-none  focus:outline-none  placeholder:text-slate-600	"
              />
              {errors.email ? (
                <p className="error">
                  Email should be at least 15 characters long
                </p>
              ) : null} */}
              <Input
                type="text"
                id="Mobile"
                name="Mobile"
                value={MobileValue}
                onChange={MobileChangeHandler}
                onBlur={MobileBlurHandler}
                isValid={MobileIsValid}
                error={MobileHasError}
                title="Please Enter Email address/Phone number"
                isMandate={true}
              ></Input>
            </div>
            <div className="text-center mt-6">
              <button
                type="submit"
                className=" block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Send
              </button>
              <p className="text-xs	 text-center  mt-4">
                Remeber your password?
                <Link
                  to="/login"
                  className="cursor-pointer px-2 font-bold"
                  style={{ color: "#6659e3" }}
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
          <div className="mt-48 bottom-0">
            <p className="text-center  inset-x-0  text-xs">
              <Link
                to="/terms-of-use"
                className="cursor-pointer px-2 font-bold text-gray-600"
              >
                Terms of use
              </Link>
              |
              <Link
                to="/privacy-policy"
                className="cursor-pointer px-2 font-bold text-gray-600"
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Forgot;

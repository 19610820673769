import {
  faCircleCheck,
  faCircleXmark,
  faPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Dashboard(props) {
  let navigate = useNavigate();
  const ProjectCreate = () => {
    navigate("/dashboard/projectCreate");
  };
  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="align-middle md:align-top pt-20">
          <h2 className=" font-semibold	 text-center mb-6 text-2xl 	">
            By using instaDe
          </h2>
          <h3 className=" font-semibold	 text-center my-6 text-lg	">
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "#258750" }}
              className="mx-4"
              size="2xl"
            />
            You Can
          </h3>
          <ul className="list-none my-6 text-center leading-10 text-[12px]">
            <li>
              <FontAwesomeIcon
                icon={faStar}
                style={{ color: "#564ad0" }}
                className="mr-4"
              />
              Prepare Social Media Calendar
            </li>
            <li>
              <FontAwesomeIcon
                icon={faStar}
                style={{ color: "#564ad0" }}
                className="mr-4"
              />
              Generate images against Social media
            </li>
          </ul>

          <h3 className=" font-semibold	 text-center my-6 text-lg	">
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ color: "#d83a52" }}
              size="2xl"
              className="mx-4"
            />
            You Can't
          </h3>
          <ul className="list-none text-center my-2 leading-10  text-[12px]">
            <li>
              <FontAwesomeIcon
                icon={faStar}
                style={{ color: "#564ad0" }}
                className="mr-4"
              />
              Design any other marketing Material like
            </li>
            <li>Logo</li>
            <li>Brochure</li>
            <li>Flyer</li>

            <li>Pamphlet ... etc</li>
          </ul>
          <button
            type="button"
            onClick={ProjectCreate}
            className="m-auto rounded-3xl block py-2 w-48 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            <FontAwesomeIcon icon={faPlus} /> Create a Project
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;

import { useContext, useState } from "react";
import { fabric } from "fabric";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const FabricRect = () => {
  const { canvas } = useContext(CanvasContext);

  const [options, setOptions] = useState({
    left: 100,
    top: 100,
    fill: "red",
    width: 100,
    height: 100,
    // angle: 45
  });

  const addRect = () => {
    const rect = new fabric.Rect(options);
    canvas.add(rect);

    //To change position of Rectangle
    /*    rect.set({ left: 20, top: 50 });
    canvas.renderAll(); */
  };

  return (
    <>
      <button onClick={addRect} className=" text-[48px]">
        <FontAwesomeIcon icon={faSquare}></FontAwesomeIcon>
      </button>
    </>
  );
};

export default FabricRect;

import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import useStore from "../../store/store";
import "./verifyMobileotp.scss";
import Input from "../Utils/Input";
import useInput from "../../Hooks/use-input";

function VerifyMobileOtp() {
  let navigate = useNavigate();
  const mobileNumber = useStore((state) => state.RegmobileNumber);

  /* const [submitting, setSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [errors, setErrors] = useState({});

  // console.log(inputFields);
  const validateValues = (inputValues) => {
    let errors = {};
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_phone = /^[6-9]\d{9}$/;
    const re = /^[0-9\b]+$/;

    if (!inputValues.otp1) {
      errors.otp1 = "Please Enter otp";
    }
    if (!inputValues.otp2) {
      errors.otp2 = "Please Enter otp";
    }
    if (!inputValues.otp3) {
      errors.otp3 = "Please Enter otp";
    }
    if (!inputValues.otp4) {
      errors.otp4 = "Please Enter otp";
    }
    if (!re.test(inputValues.otp1)) {
      errors.otp1 = "Only allow Numerics only";
    }
    if (!re.test(inputValues.otp2)) {
      errors.otp2 = "Only allow Numerics only";
    }
    if (!re.test(inputValues.otp3)) {
      errors.otp3 = "Only allow Numerics only";
    }
    if (!re.test(inputValues.otp4)) {
      errors.otp4 = "Only allow Numerics only";
    }

    if (inputValues.otp1.length !== 1) {
      errors.otp1 = "Enter one digit only";
    }
    if (inputValues.otp2.length !== 1) {
      errors.otp2 = "Enter one digit only";
    }
    if (inputValues.otp3.length !== 1) {
      errors.otp3 = "Enter one digit only";
    }
    if (inputValues.otp4.length !== 1) {
      errors.otp4 = "Enter one digit only";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputFields);
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = () => {
    return navigate("/welcome");
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]); */

  const onlyNumbers = /^[0-9]*$/;
  const isNotEmpty = (value) => value.trim() !== "";

  const isValidOtp = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length !== 1) {
      return false;
    }
    if (!onlyNumbers.test(value)) {
      return false;
    }
    return true;
  };
  const {
    value: Otp1Value,
    isValid: Otp1IsValid,
    hasError: Otp1HasError,
    valueChangeHandler: Otp1ChangeHandler,
    inputBlurHandler: Otp1BlurHandler,
    reset: resetOtp1,
  } = useInput(isValidOtp);
  const {
    value: Otp2Value,
    isValid: Otp2IsValid,
    hasError: Otp2HasError,
    valueChangeHandler: Otp2ChangeHandler,
    inputBlurHandler: Otp2BlurHandler,
    reset: resetOtp2,
  } = useInput(isValidOtp);
  const {
    value: Otp3Value,
    isValid: Otp3IsValid,
    hasError: Otp3HasError,
    valueChangeHandler: Otp3ChangeHandler,
    inputBlurHandler: Otp3BlurHandler,
    reset: resetOtp3,
  } = useInput(isValidOtp);
  const {
    value: Otp4Value,
    isValid: Otp4IsValid,
    hasError: Otp4HasError,
    valueChangeHandler: Otp4ChangeHandler,
    inputBlurHandler: Otp4BlurHandler,
    reset: resetOtp4,
  } = useInput(isValidOtp);

  let formIsValid = false;

  if (Otp1IsValid && Otp2IsValid && Otp3IsValid && Otp4IsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      Otp1BlurHandler();
      Otp2BlurHandler();
      Otp3BlurHandler();
      Otp4BlurHandler();
      return;
    }

    return navigate("/welcome");
  };
  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="">
          <img src={logo} className=" center h-24 w-70" />

          <div className="align-middle md:align-top pt-24">
            <h2 className=" font-extrabold	 text-center mb-4 text-lg	">
              OTP Verification
            </h2>
            <p className="text-xs	 text-center  mb-4">
              Enter 4 Digit OTP sent to
            </p>
            <h3 className=" font-bold	 text-center mb-4 text-xs	">
              +91 {mobileNumber}
            </h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className=" flex flex-row items-center  w-full max-w-xs justify-between ">
              <div className="w-12 h-12 ">
                {/* <input
                  maxLength="1"
                  name="otp1"
                  type="number"
                  value={inputFields.otp1}
                  onChange={handleChange}
                  className={
                    errors.otp1
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.otp1}
                /> */}
                <Input
                  type="text"
                  id="Otp1"
                  name="Otp1"
                  value={Otp1Value}
                  onChange={Otp1ChangeHandler}
                  onBlur={Otp1BlurHandler}
                  isValid={Otp1IsValid}
                  error={Otp1HasError}
                  showtickMarks={false}
                ></Input>
              </div>
              <div className="w-12 h-12 ">
                {/* <input
                  maxLength="1"
                  name="otp2"
                  type="number"
                  value={inputFields.otp2}
                  onChange={handleChange}
                  className={
                    errors.otp2
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.otp2}
                /> */}
                <Input
                  type="text"
                  id="Otp2"
                  name="Otp2"
                  value={Otp2Value}
                  onChange={Otp2ChangeHandler}
                  onBlur={Otp2BlurHandler}
                  isValid={Otp2IsValid}
                  error={Otp2HasError}
                  showtickMarks={false}
                ></Input>
              </div>
              <div className="w-12 h-12 ">
                {/* <input
                  maxLength="1"
                  name="otp3"
                  type="number"
                  value={inputFields.otp3}
                  onChange={handleChange}
                  className={
                    errors.otp3
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.otp3}
                /> */}
                <Input
                  type="text"
                  id="Otp3"
                  name="Otp3"
                  value={Otp3Value}
                  onChange={Otp3ChangeHandler}
                  onBlur={Otp3BlurHandler}
                  isValid={Otp3IsValid}
                  error={Otp3HasError}
                  showtickMarks={false}
                ></Input>
              </div>
              <div className="w-12 h-12 ">
                {/*  <input
                  maxLength="1"
                  name="otp4"
                  type="number"
                  value={inputFields.otp4}
                  onChange={handleChange}
                  className={
                    errors.otp4
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.otp4}
                /> */}
                <Input
                  type="text"
                  id="Otp4"
                  name="Otp4"
                  value={Otp4Value}
                  onChange={Otp4ChangeHandler}
                  onBlur={Otp4BlurHandler}
                  isValid={Otp4IsValid}
                  error={Otp4HasError}
                  showtickMarks={false}
                ></Input>
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                type="submit"
                className=" block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                SUBMIT
              </button>
              {/* <p className="text-xs	 text-center  mt-4">
                Remember Your Password?
                <Link
                  to="/login"
                  className="cursor-pointer px-2 font-bold"
                  style={{ color: "#6659e3" }}
                >
                  Log in
                </Link>
              </p> */}
            </div>
          </form>
        </div>

        <p className="text-center absolute inset-x-0 bottom-0 text-xs">
          <Link
            to="/terms-of-use"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Terms of use
          </Link>
          |
          <Link
            to="/privacy-policy"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Privacy policy
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

export default VerifyMobileOtp;

import { useCallback, useContext, useEffect, useRef } from "react";
import { CanvasContext } from "../store/CanvasContext";

function useContainerHandler() {
  const containerRef = useRef();
  const { canvas } = useContext(CanvasContext);
  const updateCanvasSize = useCallback(
    (x, y) => {
      if (canvas) {
        canvas.setHeight(y).setWidth(x);
        canvas.renderAll();
        // @ts-ignore
        const workarea = canvas
          .getObjects()
          .find((obj) => obj.id === "fabric-canvas");
        if (workarea) {
          workarea.center();
        }
      }
    },
    [canvas]
  );
  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;
    updateCanvasSize(containerWidth, containerHeight);
  }, [canvas]);

  return containerRef;
}

export default useContainerHandler;

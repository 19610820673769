import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Select(props) {
  let showtickMarks = props.showtickMarks;

  if (typeof showtickMarks === "undefined") {
    showtickMarks = true;
  }

  let classnames = `block rounded px-2.5 pb-2.5 pt-2 w-full text-[12px] text-gray-900 bg-transparent   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] border border-gray-300 peer bg-white ${props.className}`;
  if (!props.error && props.isValid) {
    classnames = `block rounded px-2.5 pb-2.5 pt-2 w-full text-[12px] text-gray-900 bg-transparent   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer  bg-white border border-green-500 ${props.className}`;
  }
  if (props.error && !props.isValid) {
    classnames = `block rounded px-2.5 pb-2.5 pt-2 w-full text-[12px] text-gray-900 bg-transparent   appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer  bg-white border border-red-500 ${props.className}`;
  }

  return (
    <div className="relative">
      {!props.error && props.isValid && showtickMarks && (
        <div className="absolute top-2/4 right-6 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: "green" }}
            size="sm"
          />
        </div>
      )}
      {props.error && showtickMarks && (
        <div className="absolute top-2/4 right-6 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
          <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} size="sm" />
        </div>
      )}

      <select
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={classnames}
        title={props.title ? props.title : ""}
        placeholder=" "
      >
        {props.children}
      </select>
      <label
        htmlFor={props.id}
        className="absolute text-[12px] text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {props.title}{" "}
        {props.isMandate && <span style={{ color: "red" }}>*</span>}
      </label>
    </div>
  );
}

export default Select;

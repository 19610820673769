import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";
import Select from "../Utils/Select";
import TextArea from "../Utils/TextArea";

function ContactForm(props) {
  let contact_job_title_items;
  const contact_job_title = useStore((state) => state.contactform_Jobs);
  contact_job_title_items = contact_job_title.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));
  let company_size_items;
  const company_size = useStore((state) => state.contactform_company_size);
  company_size_items = company_size.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  let job_title_items;
  const job_title = useStore((state) => state.contactform_job_title);
  job_title_items = job_title.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  let countries_items;
  const countries = useStore((state) => state.contactform_countries);
  countries_items = countries.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));
  let cfenquiry_items;
  const cfenquiry = useStore((state) => state.contactform_enquiry);
  cfenquiry_items = cfenquiry.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  let navigate = useNavigate();
  /* const [submitting, setSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState({
    fname: "",
    lname: "",
    company_email: "",
    job_title: "",
    company_name: "",
    company_size: "",
    country: "",
    phone: "",
    enquiry_about: "",
    other_info: "",
  });
  const [errors, setErrors] = useState({});

  const validateValues = (inputValues) => {
    let errors = {};
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_phone = /^[6-9]\d{9}$/;
    const re = /^[0-9\b]+$/;

    if (!inputValues.fname) {
      errors.fname = "Please Enter FirstName";
    }
    if (!inputValues.lname) {
      errors.lname = "Please enter Lastname";
    }
    if (!inputValues.company_email) {
      errors.company_email = "Please enter Company Email";
    }
    if (!inputValues.job_title) {
      errors.job_title = "Please enter Job Title";
    }
    if (!inputValues.company_name) {
      errors.company_name = "Please enter Company Name";
    }
    if (!inputValues.company_size) {
      errors.company_size = "Please enter Company Size";
    }
    if (!inputValues.country) {
      errors.country = "Please enter Country";
    }
    if (!inputValues.phone) {
      errors.phone = "Please enter phone";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = () => {
    console.log(inputFields);
    return navigate("/welcome");
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]); */

  const isNotEmpty = (value) => value.trim() !== "";

  const {
    value: FirstNameValue,
    isValid: FirstNameIsValid,
    hasError: FirstNameHasError,
    valueChangeHandler: FirstNameChangeHandler,
    inputBlurHandler: FirstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isNotEmpty);
  const {
    value: LastNameValue,
    isValid: LastNameIsValid,
    hasError: LastNameHasError,
    valueChangeHandler: LastNameChangeHandler,
    inputBlurHandler: LastNameBlurHandler,
    reset: resetLastName,
  } = useInput(isNotEmpty);
  const {
    value: CompanyEmailValue,
    isValid: CompanyEmailIsValid,
    hasError: CompanyEmailHasError,
    valueChangeHandler: CompanyEmailChangeHandler,
    inputBlurHandler: CompanyEmailBlurHandler,
    reset: resetCompanyEmail,
  } = useInput(isNotEmpty);
  const {
    value: JobTitleValue,
    isValid: JobTitleIsValid,
    hasError: JobTitleHasError,
    valueChangeHandler: JobTitleChangeHandler,
    inputBlurHandler: JobTitleBlurHandler,
    reset: resetJobTitle,
  } = useInput(isNotEmpty);
  const {
    value: CompanyValue,
    isValid: CompanyIsValid,
    hasError: CompanyHasError,
    valueChangeHandler: CompanyChangeHandler,
    inputBlurHandler: CompanyBlurHandler,
    reset: resetCompany,
  } = useInput(isNotEmpty);

  const {
    value: CompanySizeValue,
    isValid: CompanySizeIsValid,
    hasError: CompanySizeHasError,
    valueChangeHandler: CompanySizeChangeHandler,
    inputBlurHandler: CompanySizeBlurHandler,
    reset: resetCompanySize,
  } = useInput(isNotEmpty);
  const {
    value: CountryValue,
    isValid: CountryIsValid,
    hasError: CountryHasError,
    valueChangeHandler: CountryChangeHandler,
    inputBlurHandler: CountryBlurHandler,
    reset: resetCountry,
  } = useInput(isNotEmpty);
  const {
    value: PhoneValue,
    isValid: PhoneIsValid,
    hasError: PhoneHasError,
    valueChangeHandler: PhoneChangeHandler,
    inputBlurHandler: PhoneBlurHandler,
    reset: resetPhone,
  } = useInput(isNotEmpty);
  const {
    value: EnquiryValue,
    isValid: EnquiryIsValid,
    hasError: EnquiryHasError,
    valueChangeHandler: EnquiryChangeHandler,
    inputBlurHandler: EnquiryBlurHandler,
    reset: resetEnquiry,
  } = useInput(isNotEmpty);
  const {
    value: AboutInfoValue,
    isValid: AboutInfoIsValid,
    hasError: AboutInfoHasError,
    valueChangeHandler: AboutInfoChangeHandler,
    inputBlurHandler: AboutInfoBlurHandler,
    reset: resetAboutInfo,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (
    FirstNameValue &&
    LastNameValue &&
    CompanyEmailValue &&
    JobTitleValue &&
    CompanyValue &&
    CompanySizeValue &&
    CountryValue &&
    PhoneValue
  ) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      FirstNameBlurHandler();
      LastNameBlurHandler();
      CompanyEmailBlurHandler();
      JobTitleBlurHandler();
      CompanyBlurHandler();
      CompanySizeBlurHandler();
      CountryBlurHandler();
      PhoneBlurHandler();
      return;
    }

    console.log(FirstNameValue);
    //TODO Send data to contact form submit api
    return navigate("/login");
  };

  return (
    <section className="container mx-auto   ">
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-lg border border-gray-200   shadow-2xl mb-16">
          <form className="w-full px-12 " onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>

                <input
                  type="text"
                  id="floating_outlined"
                  // className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent  border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  value={inputFields.fname}
                  onChange={handleChange}
                  className={
                    errors.fname
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.fname}
                />
              </div> */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <Input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={FirstNameValue}
                  onChange={FirstNameChangeHandler}
                  onBlur={FirstNameBlurHandler}
                  isValid={FirstNameIsValid}
                  error={FirstNameHasError}
                  title="First Name"
                  isMandate={true}
                ></Input>
              </div>

              {/* <div className="w-full md:w-1/2 px-3">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="floating_outlined"
                  // className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent  border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  value={inputFields.lname}
                  onChange={handleChange}
                  className={
                    errors.lname
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.lname}
                />
              </div> */}
              <div className="w-full md:w-1/2 px-3">
                <Input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={LastNameValue}
                  onChange={LastNameChangeHandler}
                  onBlur={LastNameBlurHandler}
                  isValid={LastNameIsValid}
                  error={LastNameHasError}
                  title="Last Name"
                  isMandate={true}
                ></Input>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  Company Email
                </label>

                <input
                  type="text"
                  id="floating_outlined"
                  // className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent  border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  value={inputFields.company_email}
                  onChange={handleChange}
                  className={
                    errors.company_email
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.company_email}
                />
              </div> */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <Input
                  type="text"
                  id="company_email"
                  name="company_email"
                  value={CompanyEmailValue}
                  onChange={CompanyEmailChangeHandler}
                  onBlur={CompanyEmailBlurHandler}
                  isValid={CompanyEmailIsValid}
                  error={CompanyEmailHasError}
                  title="Company Email"
                  isMandate={true}
                ></Input>
              </div>
              {/* <div className="w-full md:w-1/2 px-3">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-last-name"
                >
                  Job Title
                </label>
                <select
                  // className="block appearance-none w-full  border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                  id="grid-state"
                  value={inputFields.job_title}
                  onChange={handleChange}
                  className={
                    errors.job_title
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.job_title}
                >
                  <option>Please Select Option</option>
                  {contact_job_title_items}
                </select>
              </div> */}
              <div className="w-full md:w-1/2 px-3">
                {/* <Input
                  type="text"
                  id="job_title"
                  name="job_title"
                  value={JobTitleValue}
                  onChange={JobTitleChangeHandler}
                  onBlur={JobTitleBlurHandler}
                  isValid={JobTitleIsValid}
                  error={JobTitleHasError}
                  title="Job Title"
                  isMandate={true}
                ></Input> */}
                <Select
                  id="job_title"
                  name="job_title"
                  value={JobTitleValue}
                  onChange={JobTitleChangeHandler}
                  onBlur={JobTitleBlurHandler}
                  isValid={JobTitleIsValid}
                  error={JobTitleHasError}
                  title="Job Title"
                  isMandate={true}
                >
                  <option>Please Select Option</option>
                  {job_title_items}
                </Select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  Company
                </label>

                <input
                  type="text"
                  id="floating_outlined"
                  // className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent  border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  value={inputFields.company_name}
                  onChange={handleChange}
                  className={
                    errors.company_name
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.company_name}
                />
              </div> */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <Input
                  type="text"
                  id="company"
                  name="company"
                  value={CompanyValue}
                  onChange={CompanyChangeHandler}
                  onBlur={CompanyBlurHandler}
                  isValid={CompanyIsValid}
                  error={CompanyHasError}
                  title="Company"
                  isMandate={true}
                ></Input>
              </div>
              {/* <div className="w-full md:w-1/2 px-3">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-last-name"
                >
                  Company Size
                </label>
                <select
                  // className="block appearance-none w-full  border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                  value={inputFields.company_size}
                  onChange={handleChange}
                  className={
                    errors.company_size
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.company_size}
                  id="grid-state"
                >
                  <option>Please Select Option</option>
                  {company_size_items}
                </select>
              </div> */}
              <div className="w-full md:w-1/2 px-3">
                <Select
                  id="company_size"
                  name="company_size"
                  value={CompanySizeValue}
                  onChange={CompanySizeChangeHandler}
                  onBlur={CompanySizeBlurHandler}
                  isValid={CompanySizeIsValid}
                  error={CompanySizeHasError}
                  title="Compnay Size"
                  isMandate={true}
                >
                  <option>Please Select Option</option>
                  {company_size_items}
                </Select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              {/*  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  Country
                </label>

                <select
                  value={inputFields.country}
                  onChange={handleChange}
                  className={
                    errors.country
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.country}
                  id="grid-state"
                >
                  <option>Please Select Option</option>
                  {countries_items}
                </select>
              </div> */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <Select
                  id="country"
                  name="country"
                  value={CountryValue}
                  onChange={CountryChangeHandler}
                  onBlur={CountryBlurHandler}
                  isValid={CountryIsValid}
                  error={CountryHasError}
                  title="Country "
                  isMandate={true}
                >
                  <option>Please Select Option</option>
                  {countries_items}
                </Select>
              </div>
              {/* <div className="w-full md:w-1/2 px-3">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-last-name"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="floating_outlined"
                  value={inputFields.phone}
                  onChange={handleChange}
                  className={
                    errors.phone
                      ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                      : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.phone}
                  placeholder=" "
                />
              </div> */}
              <div className="w-full md:w-1/2 px-3">
                <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={PhoneValue}
                  onChange={PhoneChangeHandler}
                  onBlur={PhoneBlurHandler}
                  isValid={PhoneIsValid}
                  error={PhoneHasError}
                  title="Phone"
                  isMandate={true}
                ></Input>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block camelcase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  What’s your enquiry about?
                </label>

                {/* <select
                  className="block appearance-none w-full  border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                  id="grid-state"
                >
                  <option>Please Select Option</option>
                  {cfenquiry_items}
                </select> */}
                <Select
                  id="enquiry"
                  name="enquiry"
                  value={EnquiryValue}
                  onChange={EnquiryChangeHandler}
                  onBlur={EnquiryBlurHandler}
                  // isValid={EnquiryIsValid}
                  // error={EnquiryHasError}
                  isMandate={false}
                >
                  <option>Please Select Option</option>
                  {cfenquiry_items}
                </Select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                  htmlFor="grid-first-name"
                >
                  Is there any other information you would like to share with
                  us?
                </label>

                {/* <input
                  type="text"
                  id="floating_outlined"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent  border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                /> */}
                <TextArea
                  type="text"
                  id="more_info"
                  name="more_info"
                  value={AboutInfoValue}
                  onChange={AboutInfoChangeHandler}
                  onBlur={AboutInfoBlurHandler}
                  // isValid={AboutInfoIsValid}
                  error={AboutInfoHasError}
                  isMandate={false}
                ></TextArea>

                <p className=" text-gray-900 text-xs">
                  By submitting this form, you agree to receive marketing and
                  information from Canva. You can opt out at any time by
                  clicking unsubscribe. Read our
                  <Link
                    to="/register"
                    className="font-medium text-blue-600 dark:text-blue-500 underline"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
            </div>
            <button
              // onClick={dashboardScreen}
              type="submit"
              className=" block py-2 w-full text-sm text-gray-800 bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
              style={{ backgroundColor: "#E3E8FF" }}
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </section>
  );
}

export default ContactForm;

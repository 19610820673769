import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CanvasContext } from "./store/CanvasContext";
import useContainerHandler from "./handlers/useContainerHandler";
import useCustomizationHandler from "./handlers/useCustomizationHandler";
import useEventHandlers from "./handlers/useEventHandlers";
import useGuidelinesHandler from "./handlers/useGuidelinesHandler";
import useZoomHandler from "./handlers/useZoomHandler";
import RightContext from "./store/RightContext";
import useCoreHandler from "./handlers/useCoreHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBackward,
  faBackwardFast,
  faForward,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./Canvas.scss";

const Canvas = ({ jsonData = null, width = 590, height = 380 }) => {
  const {
    sendBackwards,
    sendToBack,
    bringForward,
    bringToFront,
    removeObjects,
  } = useCoreHandler();
  const [context, setContext] = useState(false);
  const [xYPosistion, setXyPosistion] = useState({ x: 0, y: 0 });
  const showNav = (event) => {
    event.preventDefault();
    setContext(false);
    const positionChange = {
      x: event.pageX,
      y: event.pageY,
    };
    setXyPosistion(positionChange);
    setContext(true);
  };
  const hideContext = (event) => {
    setContext(false);
  };
  const [chosen, setChosen] = useState();
  const initMenu = (chosen) => {
    setChosen(chosen);
  };

  // const canvasEl = useRef();
  const canvasEl = useContainerHandler();
  useCustomizationHandler();
  useEventHandlers();
  useGuidelinesHandler();
  useZoomHandler();

  const { canvas, initCanvas, setActiveObject, loadFromJSON } =
    useContext(CanvasContext);

  useLayoutEffect(() => {
    if (jsonData) {
      loadFromJSON(canvasEl.current, jsonData);
    } else {
      initCanvas(canvasEl.current, {
        width: width,
        height: height,
      });
    }
  }, [canvasEl, initCanvas, loadFromJSON, jsonData, height, width]);

  const updateActiveObject = useCallback(
    (e) => {
      if (!e) {
        return;
      }
      setActiveObject(canvas.getActiveObject());
      canvas.renderAll();
    },
    [canvas, setActiveObject]
  );

  useEffect(() => {
    if (!canvas) {
      return;
    }
    canvas.on("selection:created", updateActiveObject);
    canvas.on("selection:updated", updateActiveObject);
    canvas.on("selection:cleared", updateActiveObject);

    return () => {
      canvas.off("selection:created");
      canvas.off("selection:cleared");
      canvas.off("selection:updated");
    };
  }, [canvas, updateActiveObject]);

  useEffect(() => {
    if (canvas) {
      canvas.on("mouse:up", function (options) {
        if (options.button == 3) {
          if (options.target) {
            console.log("an object was clicked! ", options.target.type);
            var pointer = canvas.getPointer(options.originalEvent);

            setActiveObject(options.target);
            var objects = canvas.getObjects();
            for (var i = objects.length - 1; i >= 0; i--) {
              if (objects[i].containsPoint(pointer)) {
                canvas.setActiveObject(objects[i]);
                break;
              }
            }

            if (i < 0) {
              canvas.deactivateAll();
            }

            canvas.renderAll();
          }

          // canvas.remove(options.target);
          console.log("right clicked");
          console.log(options.e.clientX, options.e.clientY);
          setContext(false);
          const positionChange = {
            x: options.e.clientX,
            y: options.e.clientY,
          };
          setXyPosistion(positionChange);

          setContext(true);
        } else {
          setContext(false);
        }
      });
    }
    return () => {
      if (canvas) {
        canvas.off("mouse:up", function (options) {
          if (options.button == 3) {
            // canvas.remove(options.target);
            setContext(false);
          }
        });
      }
    };
  }, [canvas]);

  const MoveForward = () => {
    bringForward();
  };
  const MoveFront = () => {
    bringToFront();
  };
  const MoveBackwards = () => {
    sendBackwards();
  };
  const MoveBack = () => {
    sendToBack();
  };

  return (
    <div>
      <div onContextMenu={showNav} onClick={hideContext}>
        {chosen && <h1>"{chosen}" is chosen</h1>}
        {context && (
          <div
            style={{ top: xYPosistion.y, left: xYPosistion.x }}
            className="rightClick"
          >
            <div className="menuElement" onClick={MoveForward}>
              <FontAwesomeIcon
                icon={faForward}
                className="mr-2"
              ></FontAwesomeIcon>
              Bring Forward
            </div>
            <div className="menuElement" onClick={MoveFront}>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="mr-2"
              ></FontAwesomeIcon>
              Bring to Front
            </div>
            <div className="menuElement" onClick={MoveBackwards}>
              <FontAwesomeIcon
                icon={faBackward}
                className="mr-2"
              ></FontAwesomeIcon>
              Send backward
            </div>
            <div className="menuElement" onClick={MoveBack}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-2"
              ></FontAwesomeIcon>
              Send to back
            </div>
            <div className="menuElement" onClick={removeObjects}>
              <FontAwesomeIcon
                icon={faTrash}
                className="mr-2"
              ></FontAwesomeIcon>
              Delete
            </div>
          </div>
        )}
      </div>

      <canvas
        ref={canvasEl}
        id="fabric-canvas"
        width={width}
        height={height}
        // style={{ border: "1px solid red" }}
        className="block   shadow  dark:bg-gray-800 "
      ></canvas>
    </div>
  );
};
export default Canvas;

export const SampleJson = {
  version: "5.2.1",
  objects: [
    {
      type: "group",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 201.91,
      top: 0.29,
      width: 353,
      height: 330,
      fill: "rgb(0,0,0)",
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.69,
      scaleY: 1.81,
      angle: 0,
      flipX: true,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      objects: [
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -81,
          top: -165.5,
          width: 171.22,
          height: 295.52,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 122.63, 189.32],
            ["Q", 119.63, 210.41, 116.69, 231.49],
            ["Q", 112.8, 259.15000000000003, 108.94, 286.81],
            [
              "C",
              108.96645707862749,
              287.90300019920653,
              108.17204828771001,
              288.84340844358996,
              107.09,
              289,
            ],
            [
              "C",
              103.555133013606,
              289.9230942808077,
              100.1188331181702,
              291.1890995054419,
              96.83000000000001,
              292.78,
            ],
            ["C", 96.47, 292.95, 95.95, 293.03999999999996, 96, 293.59],
            ["C", 96.05, 294.14, 96.56, 294.09, 97, 294.08],
            ["C", 105.2, 293.90999999999997, 113.31, 295.33, 121.55, 295.19],
            ["C", 142.99, 294.85, 164.44, 295.08, 185.89, 295.08],
            ["C", 186.89, 295.08, 187.89, 295.08, 188.89, 295.16999999999996],
            [
              "C",
              194.86096041419722,
              295.63500203483636,
              200.85903958580275,
              295.6350020348364,
              206.83,
              295.17,
            ],
            [
              "C",
              212.07999999999998,
              294.78,
              217.26999999999998,
              293.9,
              222.48999999999998,
              293.30999999999995,
            ],
            [
              "C",
              223.1,
              293.24999999999994,
              223.98,
              293.43999999999994,
              224.01,
              292.46999999999997,
            ],
            [
              "C",
              224.04,
              291.5,
              223.14999999999998,
              291.60999999999996,
              222.57,
              291.46999999999997,
            ],
            [
              "C",
              218.52540665943326,
              290.1635952708064,
              214.3565737095907,
              289.278891172126,
              210.13,
              288.83,
            ],
            [
              "C",
              209.13,
              288.74,
              208.94,
              288.4,
              209.01999999999998,
              287.46999999999997,
            ],
            [
              "C",
              209.24999999999997,
              284.72999999999996,
              209.39999999999998,
              281.97999999999996,
              209.54999999999998,
              279.21999999999997,
            ],
            [
              "C",
              209.98999999999998,
              270.96,
              210.39999999999998,
              262.7,
              210.85,
              254.43999999999997,
            ],
            ["Q", 211.64, 239.99999999999997, 212.47, 225.54999999999995],
            [
              "C",
              212.67,
              221.95999999999995,
              212.63,
              218.32999999999996,
              213.14,
              214.78999999999996,
            ],
            [
              "C",
              213.88,
              209.73999999999995,
              214.30999999999997,
              204.66999999999996,
              214.60999999999999,
              199.58999999999997,
            ],
            [
              "C",
              214.92999999999998,
              194.31999999999996,
              215.14,
              189.05999999999997,
              215.98,
              183.83999999999997,
            ],
            [
              "C",
              217.57,
              173.89999999999998,
              221.92,
              165.14999999999998,
              227.64999999999998,
              157.05999999999997,
            ],
            [
              "C",
              236.64999999999998,
              144.35999999999999,
              246.30999999999997,
              132.10999999999999,
              253.64999999999998,
              118.30999999999997,
            ],
            [
              "C",
              255.20671535943399,
              115.61181281671934,
              256.57336409951625,
              112.8083451078464,
              257.73999999999995,
              109.91999999999999,
            ],
            [
              "C",
              259.6620546508974,
              104.75742856094854,
              261.2978728986838,
              99.49276452674627,
              262.63999999999993,
              94.14999999999995,
            ],
            [
              "C",
              264.63999999999993,
              86.00999999999998,
              266.5199999999999,
              77.82999999999998,
              267.11999999999995,
              69.44999999999997,
            ],
            [
              "C",
              267.62999999999994,
              62.28999999999998,
              266.11999999999995,
              55.549999999999976,
              261.30999999999995,
              49.989999999999974,
            ],
            [
              "C",
              255.82999999999996,
              43.689999999999976,
              248.49999999999994,
              40.989999999999974,
              240.43999999999994,
              39.989999999999974,
            ],
            [
              "C",
              239.07999999999993,
              39.82999999999998,
              238.63999999999993,
              39.479999999999976,
              238.66999999999993,
              38.06999999999997,
            ],
            [
              "C",
              238.79973796899142,
              33.69304510390167,
              238.32868383574115,
              29.31897100943475,
              237.26999999999992,
              25.06999999999997,
            ],
            [
              "C",
              234.6899999999999,
              14.999999999999972,
              227.80999999999992,
              8.419999999999973,
              216.26999999999992,
              10.069999999999972,
            ],
            [
              "C",
              215.50613657189885,
              10.256754339796679,
              214.71439704797584,
              9.89197382615675,
              214.35999999999993,
              9.189999999999971,
            ],
            [
              "C",
              213.42801554446206,
              7.791239677078791,
              212.3558010617384,
              6.491179616776325,
              211.15999999999994,
              5.30999999999996,
            ],
            ["C", 208.27, 2.36, 204.6, 0.89, 200.64, 0],
            ["L", 196.69, 0],
            [
              "C",
              191.50376747335187,
              0.9636373319262526,
              186.59992789953384,
              3.078952102792698,
              182.34,
              6.189999999999994,
            ],
            [
              "C",
              182.00332071641546,
              6.447822515889539,
              181.5582361246665,
              6.515716775647854,
              181.16,
              6.37,
            ],
            [
              "C",
              179.75705124121373,
              6.04750310694835,
              178.319063187723,
              5.903032344564933,
              176.88,
              5.94,
            ],
            ["C", 170.45, 5.590000000000001, 164.51, 6.94, 159.34, 11],
            ["C", 158.57, 11.6, 157.57, 12.11, 157.78, 13.4],
            ["C", 157.83, 13.71, 157.52, 14.13, 157.28, 13.940000000000001],
            [
              "C",
              156.2,
              13.06,
              156.07,
              14.270000000000001,
              155.73,
              14.660000000000002,
            ],
            [
              "C",
              153.80505126222096,
              16.967233588621976,
              152.3326381938556,
              19.616898580311748,
              151.39,
              22.47000000000001,
            ],
            [
              "C",
              151.32311758878262,
              22.905503922488172,
              151.078518554807,
              23.29372838381601,
              150.71455498803854,
              23.542057692392397,
            ],
            [
              "C",
              150.3505914212701,
              23.790387000968785,
              149.89990048061196,
              23.876553095250447,
              149.47,
              23.78,
            ],
            [
              "C",
              145.3339976004584,
              23.56472876085288,
              141.19660350679416,
              24.176668725404692,
              137.29999999999998,
              25.580000000000005,
            ],
            ["C", 135.99, 26.040000000000003, 134.83, 26.48, 135.78, 28.32],
            ["C", 134.57, 26.79, 134.56, 26.77, 133.01, 27.7],
            [
              "C",
              132.02357832616548,
              28.265973149558857,
              131.0718801225224,
              28.890420742826425,
              130.16,
              29.57000000000001,
            ],
            ["C", 122.86999999999999, 35.09, 118.83, 42.74, 116.16, 51.24],
            [
              "C",
              112.61999999999999,
              62.44,
              112.16,
              73.98,
              112.56,
              85.58000000000001,
            ],
            [
              "C",
              113.11488545195795,
              98.34602732020822,
              114.65277216503253,
              111.05030886299818,
              117.16,
              123.57999999999997,
            ],
            [
              "C",
              117.36999999999999,
              124.64000000000001,
              116.61999999999999,
              126.39000000000001,
              118.58,
              126.80000000000001,
            ],
            ["L", 118.58, 127.45000000000002],
            [
              "C",
              117.5,
              127.98000000000002,
              118.07,
              128.78000000000003,
              118.21,
              129.45000000000002,
            ],
            ["Q", 121.35, 145.23000000000002, 125.83999999999999, 160.68],
            [
              "C",
              126.22849600903822,
              162.06229309458294,
              126.3104045717288,
              163.51275722556198,
              126.07999999999998,
              164.93,
            ],
            ["Q", 124.35, 177.13, 122.63, 189.32],
            ["Z"],
            ["M", 124.41, 227.76],
            [
              "C",
              124.10434213725433,
              227.73347465418655,
              123.87693644758835,
              227.46593854869715,
              123.89999999999999,
              227.16,
            ],
            [
              "C",
              123.89999999999999,
              226.79,
              124.02,
              226.45,
              124.47999999999999,
              226.49,
            ],
            [
              "C",
              124.93999999999998,
              226.53000000000003,
              124.80999999999999,
              226.9,
              124.88,
              227.08,
            ],
            ["C", 124.86, 227.47, 124.78, 227.78, 124.41, 227.76],
            ["Z"],
            ["M", 127.57, 208.23999999999998],
            [
              "C",
              127.05,
              208.23999999999998,
              126.99,
              207.73,
              127.00999999999999,
              207.29999999999998,
            ],
            [
              "C",
              127.02999999999999,
              206.86999999999998,
              127.1,
              206.48,
              127.58999999999999,
              206.29999999999998,
            ],
            [
              "C",
              128.11999999999998,
              206.45,
              128.08999999999997,
              206.89999999999998,
              128.10999999999999,
              207.29999999999998,
            ],
            ["C", 128.13, 207.7, 128.11, 208.26, 127.57, 208.24],
            ["Z"],
            ["M", 129.1, 192],
            [
              "C",
              128.79687148350368,
              191.98940089962346,
              128.5551360482046,
              191.74327027459165,
              128.54999999999998,
              191.44,
            ],
            [
              "C",
              128.50688040429387,
              191.1356739999907,
              128.70865814423595,
              190.85055328050734,
              129.01,
              190.79,
            ],
            [
              "C",
              129.38,
              190.73,
              129.48999999999998,
              191.03,
              129.48999999999998,
              191.34,
            ],
            ["C", 129.48999999999998, 191.65, 129.45, 192, 129.1, 192],
            ["Z"],
            ["M", 129.96, 143.39],
            ["C", 129.35, 143.6, 129.24, 142.39, 128.96, 141.72],
            [
              "C",
              124.6747701096144,
              129.64266130181582,
              121.80557869374283,
              117.10882511669273,
              120.41,
              104.36999999999998,
            ],
            [
              "C",
              119.92000000000002,
              100.37,
              119.55000000000001,
              96.29,
              119.13000000000001,
              92.25,
            ],
            [
              "C",
              119.13000000000001,
              91.98,
              119.01,
              91.65,
              119.61000000000001,
              91.43,
            ],
            [
              "C",
              119.96000000000001,
              93.72000000000001,
              120.33000000000001,
              95.97000000000001,
              120.61000000000001,
              98.23,
            ],
            [
              "C",
              122.76000000000002,
              112.81,
              126.30000000000001,
              127.09,
              129.85000000000002,
              141.38,
            ],
            ["C", 130.12, 142.09, 130.88, 143.1, 130, 143.43],
            ["Z"],
            ["M", 119.47, 86.55],
            ["C", 119.47, 85.99, 119.76, 85.44, 120.38, 85.45],
            [
              "C",
              120.99999999999999,
              85.46000000000001,
              120.86,
              86,
              120.89,
              86.45,
            ],
            [
              "C",
              120.92,
              86.9,
              120.89,
              87.60000000000001,
              120.18,
              87.60000000000001,
            ],
            [
              "C",
              119.47000000000001,
              87.60000000000001,
              119.44,
              87,
              119.47,
              86.55,
            ],
            ["Z"],
            ["M", 124.78999999999999, 54.12],
            [
              "C",
              125.3,
              54.12,
              125.27999999999999,
              54.699999999999996,
              125.44999999999999,
              55.05,
            ],
            [
              "C",
              125.44999999999999,
              55.37,
              125.44999999999999,
              55.73,
              125.04999999999998,
              55.69,
            ],
            [
              "C",
              124.64999999999998,
              55.65,
              124.44999999999999,
              55.18,
              124.40999999999998,
              54.769999999999996,
            ],
            [
              "C",
              124.36999999999998,
              54.35999999999999,
              124.38,
              54.08,
              124.79,
              54.12,
            ],
            ["Z"],
            ["M", 132.59, 72.21],
            [
              "C",
              132.4054501675045,
              72.22407255675905,
              132.22733018491294,
              72.13898915531816,
              132.122299123488,
              71.98659114462313,
            ],
            [
              "C",
              132.01726806206304,
              71.83419313392811,
              132.00115879325963,
              71.63745370001159,
              132.08,
              71.47,
            ],
            ["C", 132.31, 70.89, 132.4, 70.08, 133.08, 69.9],
            [
              "C",
              133.61,
              69.75,
              133.60000000000002,
              70.38000000000001,
              133.69000000000003,
              70.78,
            ],
            ["C", 133.53, 71.41, 133.36, 72.1, 132.59, 72.21],
            ["Z"],
            ["M", 133.59, 57.8],
            [
              "C",
              133.6477343331183,
              57.6936821192447,
              133.75901758396813,
              57.62748015748022,
              133.88,
              57.62748015748022,
            ],
            [
              "C",
              134.0009824160319,
              57.62748015748022,
              134.1122656668817,
              57.6936821192447,
              134.17000000000002,
              57.8,
            ],
            [
              "C",
              134.53000000000003,
              58.349999999999994,
              134.3,
              58.8,
              133.83,
              59.33,
            ],
            [
              "C",
              133.35934821380212,
              58.963870423721,
              133.250365355821,
              58.29689533287653,
              133.58,
              57.8,
            ],
            ["Z"],
            ["M", 133.44, 85.64],
            [
              "C",
              133.44,
              85.31,
              133.57999999999998,
              85.07000000000001,
              133.95,
              85.05,
            ],
            [
              "C",
              134.3398565810335,
              85.22333866439611,
              134.57679836895508,
              85.62493491511061,
              134.54,
              86.05,
            ],
            ["C", 134.54, 86.36999999999999, 134.38, 86.69, 134.04, 86.6],
            [
              "C",
              133.62387382856377,
              86.47934408535636,
              133.36248115948553,
              86.06797201598731,
              133.43,
              85.64,
            ],
            ["Z"],
            ["M", 157.85, 91],
            ["C", 149.98999999999998, 91.53, 142.44, 93.16, 135.64, 97.33],
            [
              "C",
              134.17999999999998,
              98.22,
              134.17999999999998,
              97.73,
              133.97,
              96.39999999999999,
            ],
            [
              "C",
              133.69,
              94.66,
              134.31,
              93.83999999999999,
              135.83,
              93.08999999999999,
            ],
            [
              "C",
              142.3984184571082,
              90.01028308160436,
              149.43534436614092,
              88.04994829426576,
              156.64999999999998,
              87.28999999999999,
            ],
            [
              "C",
              169.2583611331624,
              85.60407343992873,
              182.07139616794672,
              86.30629696447211,
              194.42000000000002,
              89.35999999999999,
            ],
            [
              "C",
              195.99212723985207,
              89.66128610663425,
              197.51382226552528,
              90.18310630349121,
              198.94000000000003,
              90.90999999999998,
            ],
            [
              "C",
              188.33747871764976,
              92.42337226929861,
              178.51221058484907,
              97.33600633569895,
              170.94000000000003,
              104.91,
            ],
            [
              "C",
              177.40138629898644,
              100.14788099245605,
              184.6672469332775,
              96.58777864903415,
              192.38999999999993,
              94.4,
            ],
            [
              "C",
              187.52308767512537,
              96.86907077131836,
              183.0888505145245,
              100.11116344015069,
              179.26,
              104,
            ],
            [
              "C",
              171.6756360594021,
              111.91278285490401,
              166.33292075890867,
              121.70085817286225,
              163.78,
              132.35999999999999,
            ],
            [
              "C",
              163.68,
              132.76000000000002,
              163.38,
              133.36,
              163.84,
              133.48000000000002,
            ],
            ["C", 164.71, 133.74, 164.69, 132.82000000000002, 164.84, 132.33],
            [
              "C",
              167.58683024656284,
              125.02538981569955,
              171.53075968851562,
              118.22912408496514,
              176.51,
              112.22000000000003,
            ],
            [
              "C",
              182.1587365658001,
              105.41500371972809,
              189.28188623140193,
              99.98458525166487,
              197.33999999999997,
              96.34000000000003,
            ],
            [
              "C",
              197.70999999999998,
              96.17000000000002,
              198.08999999999997,
              96.00000000000001,
              198.47999999999996,
              95.84000000000002,
            ],
            [
              "C",
              198.718032381312,
              95.83747984668636,
              198.95576622063348,
              95.85757003029099,
              199.18999999999997,
              95.90000000000002,
            ],
            [
              "C",
              196.58989539676654,
              97.45983898653972,
              194.09152894618586,
              99.18331102998843,
              191.70999999999992,
              101.06000000000006,
            ],
            [
              "C",
              190.15999999999997,
              102.38000000000001,
              188.55999999999997,
              103.64000000000001,
              187.10999999999999,
              105.06000000000002,
            ],
            [
              "C",
              185.00857245619807,
              107.05308967111519,
              183.028362792733,
              109.1702109639261,
              181.17999999999998,
              111.40000000000003,
            ],
            [
              "C",
              180.62999999999997,
              112.08000000000003,
              179.95999999999998,
              112.66000000000003,
              181.11999999999998,
              113.40000000000002,
            ],
            [
              "C",
              181.43999999999997,
              113.60000000000002,
              181.84999999999997,
              114.01000000000002,
              181.33999999999997,
              114.40000000000002,
            ],
            [
              "C",
              180.82999999999998,
              114.79000000000002,
              180.64999999999998,
              114.40000000000002,
              180.46999999999997,
              114.05000000000003,
            ],
            [
              "C",
              179.84999999999997,
              112.87000000000002,
              179.46999999999997,
              113.62000000000002,
              178.98999999999998,
              114.20000000000003,
            ],
            [
              "C",
              172.4294580433019,
              123.00695934911965,
              167.2997078376518,
              132.79398276779415,
              163.78999999999996,
              143.20000000000007,
            ],
            [
              "C",
              163.35999999999999,
              144.56000000000006,
              162.91,
              144.50000000000006,
              161.85999999999999,
              143.83000000000004,
            ],
            [
              "C",
              155.86753675587477,
              140.05392988182004,
              150.57012212383387,
              135.27407874831187,
              146.2,
              129.70000000000005,
            ],
            [
              "C",
              145.39,
              128.70000000000005,
              145.42999999999998,
              128.08000000000004,
              146.36999999999998,
              127.20000000000005,
            ],
            [
              "C",
              148.15492538257934,
              125.50675343136398,
              149.73566594876027,
              123.6105359793635,
              151.07999999999998,
              121.55000000000004,
            ],
            [
              "C",
              151.20999999999998,
              121.35000000000004,
              151.32999999999998,
              121.14000000000004,
              150.96999999999997,
              120.67000000000004,
            ],
            [
              "C",
              148.77037989270218,
              122.11868015833656,
              146.87210400805114,
              123.9796017769211,
              145.37999999999997,
              126.15000000000005,
            ],
            [
              "C",
              144.62999999999997,
              127.21000000000005,
              144.29999999999995,
              127.06000000000004,
              143.66999999999996,
              126.07000000000005,
            ],
            [
              "C",
              139.96630406321609,
              120.24674113051668,
              137.22453835655026,
              113.8650450891393,
              135.54999999999995,
              107.17000000000003,
            ],
            [
              "C",
              135.28056677544237,
              106.36777952187845,
              135.678181523718,
              105.49390095423965,
              136.45999999999995,
              105.17000000000004,
            ],
            [
              "Q",
              141.34999999999994,
              102.17000000000004,
              146.22999999999996,
              99.11000000000004,
            ],
            [
              "C",
              146.69999999999996,
              98.81000000000004,
              147.35999999999996,
              98.71000000000004,
              147.57999999999996,
              98.03000000000004,
            ],
            [
              "C",
              146.90999999999997,
              97.53000000000004,
              146.37999999999997,
              98.03000000000004,
              145.87999999999997,
              98.19000000000004,
            ],
            [
              "C",
              142.297748682835,
              99.40989428326515,
              138.98888673124972,
              101.31911442370755,
              136.13999999999996,
              103.81000000000006,
            ],
            [
              "C",
              135.22999999999996,
              104.60000000000005,
              134.92999999999995,
              104.47000000000004,
              134.75999999999996,
              103.29000000000005,
            ],
            [
              "C",
              134.08999999999997,
              98.80000000000005,
              133.81999999999996,
              99.82000000000005,
              137.41999999999996,
              98.12000000000005,
            ],
            [
              "C",
              142.58999999999995,
              95.68000000000005,
              148.13999999999996,
              94.34000000000005,
              153.64999999999995,
              92.96000000000005,
            ],
            [
              "C",
              155.09999999999994,
              92.59000000000005,
              156.54999999999995,
              92.23000000000005,
              157.99999999999994,
              91.90000000000005,
            ],
            [
              "C",
              158.36999999999995,
              91.82000000000005,
              158.84999999999994,
              91.75000000000004,
              158.81999999999994,
              91.36000000000004,
            ],
            ["C", 158.8, 90.75, 158.25, 90.93, 157.85, 91],
            ["Z"],
            ["M", 135.21, 66.76],
            [
              "C",
              135.21,
              66.51,
              135.43,
              66.24000000000001,
              135.64000000000001,
              66.36,
            ],
            [
              "C",
              136.19000000000003,
              66.66,
              136.33,
              67.23,
              136.50000000000003,
              68.02,
            ],
            ["C", 135.6, 67.81, 135.22, 67.43, 135.21, 66.76],
            ["Z"],
            ["M", 137, 70.45],
            [
              "C",
              137.109717455321,
              69.50947789929647,
              137.95899236724873,
              68.8345278377118,
              138.9,
              68.94,
            ],
            ["C", 139.52, 68.94, 140, 69.39, 139.72, 70.28999999999999],
            ["C", 139.45, 70.74, 140, 71.94, 138.64, 71.69],
            ["C", 137.87, 71.56, 136.89, 71.43, 137, 70.45],
            ["Z"],
            ["M", 156.85, 80.58],
            [
              "C",
              159.01999999999998,
              80.28999999999999,
              160.53,
              78.89,
              162.13,
              77.36,
            ],
            ["C", 160.84, 81.84, 155.94, 82.83, 152.13, 79.26],
            ["C", 157.78, 78.88000000000001, 160.41, 75.26, 162.2, 70.26],
            ["C", 163.2, 71.96000000000001, 163.13, 73.26, 162.14, 74.54],
            [
              "C",
              160.8909692898223,
              76.30024637639524,
              159.407241834698,
              77.8815432106547,
              157.73,
              79.24000000000001,
            ],
            ["C", 157.31, 79.55, 156.64, 79.7, 156.83, 80.58],
            ["Z"],
            ["M", 171.20999999999998, 75.58],
            [
              "C",
              175.95999999999998,
              75.58,
              179.32,
              73.45,
              182.07999999999998,
              70.03999999999999,
            ],
            [
              "C",
              181.54999999999998,
              72.94,
              180.57999999999998,
              75.58,
              177.7,
              76.92999999999999,
            ],
            ["C", 175.71, 77.83, 173.59, 77.4, 171.19, 75.55],
            ["Z"],
            ["M", 192.20999999999998, 77.07],
            [
              "C",
              192.42999999999998,
              78.19999999999999,
              192.73999999999998,
              79.07,
              191.83999999999997,
              79.96,
            ],
            [
              "C",
              191.45974178890086,
              78.97234293379601,
              191.60259663229107,
              77.85957889054619,
              192.22,
              77,
            ],
            ["Z"],
            ["M", 215.76, 168.83999999999997],
            [
              "C",
              215.32999999999998,
              164.62999999999997,
              216.76,
              162.04999999999998,
              221.7,
              157.65999999999997,
            ],
            [
              "C",
              219.42233660841967,
              161.2085941283383,
              217.43904430905616,
              164.93771877216685,
              215.76999999999998,
              168.81000000000006,
            ],
            ["Z"],
            ["M", 235.57999999999998, 139.95],
            ["L", 245.82, 127.16999999999999],
            ["L", 246.22, 127.47999999999999],
            [
              "C",
              243.04155330799628,
              131.92507350226236,
              239.4849944163792,
              136.0872176802225,
              235.58999999999997,
              139.92,
            ],
            ["Z"],
            ["M", 239.04999999999998, 131.83999999999997],
            [
              "C",
              239.04999999999998,
              131.30999999999997,
              239.04999999999998,
              130.73999999999998,
              239.79,
              130.64,
            ],
            [
              "C",
              239.96158739398643,
              130.58608800471683,
              240.1487542640162,
              130.62114461626015,
              240.28919941217208,
              130.73350073478485,
            ],
            [
              "C",
              240.42964456032797,
              130.84585685330958,
              240.50493154305968,
              131.02076332397544,
              240.48999999999998,
              131.2,
            ],
            [
              "C",
              240.43512821370854,
              131.79915881312093,
              240.04876721490575,
              132.3168825515166,
              239.48999999999998,
              132.54,
            ],
            ["C", 239, 132.66, 239.05, 132.13, 239.06, 131.81],
            ["Z"],
            ["M", 216.49, 28.19],
            [
              "C",
              216.49,
              24.59,
              216.49,
              20.990000000000002,
              216.49,
              17.400000000000002,
            ],
            [
              "C",
              216.49,
              16.090000000000003,
              216.87,
              15.640000000000002,
              218.20000000000002,
              15.560000000000002,
            ],
            [
              "C",
              225.32000000000002,
              15.130000000000003,
              230.09000000000003,
              18.35,
              232.32000000000002,
              25.200000000000003,
            ],
            [
              "C",
              233.5763820527732,
              29.448846504035565,
              234.14357701693626,
              33.87161676030704,
              234,
              38.3,
            ],
            ["C", 234, 39.3, 233.66, 39.64, 232.65, 39.629999999999995],
            [
              "C",
              228.70000000000002,
              39.58,
              224.75,
              39.629999999999995,
              220.8,
              39.629999999999995,
            ],
            [
              "C",
              219.9620301749777,
              39.53344529500829,
              219.11308558214338,
              39.632717041751015,
              218.32000000000002,
              39.919999999999995,
            ],
            [
              "C",
              216.55,
              40.81999999999999,
              216.46,
              39.919999999999995,
              216.49,
              38.449999999999996,
            ],
            ["C", 216.55, 35, 216.49, 31.6, 216.49, 28.19],
            ["Z"],
            ["M", 217.14000000000001, 78.9],
            [
              "C",
              216.73844775762296,
              78.63654494193531,
              216.49466804459928,
              78.19024054424584,
              216.49,
              77.71000000000001,
            ],
            ["Q", 216.49, 71.91000000000001, 216.49, 66.12],
            [
              "C",
              217.992509031304,
              68.13610337083713,
              219.3684855818435,
              70.24348493246437,
              220.61,
              72.43,
            ],
            [
              "C",
              222.34928789311013,
              75.63019829018819,
              225.5408123440418,
              77.78027792028952,
              229.16000000000003,
              78.19000000000001,
            ],
            [
              "C",
              229.50000000000003,
              78.19000000000001,
              229.97000000000003,
              78.13000000000001,
              230.01000000000002,
              78.62000000000002,
            ],
            [
              "C",
              230.05,
              79.11000000000003,
              229.59000000000003,
              79.16000000000003,
              229.3,
              79.36000000000001,
            ],
            [
              "C",
              225.53553853182214,
              81.71360462642862,
              220.71588844160715,
              81.53128233683168,
              217.14,
              78.9,
            ],
            ["Z"],
            ["M", 223, 88.57],
            [
              "C",
              223,
              88.89999999999999,
              222.91,
              89.19999999999999,
              222.55,
              89.16999999999999,
            ],
            [
              "C",
              222.23592190224295,
              89.13839162646879,
              222.00236275359515,
              88.86517149031475,
              222.02,
              88.54999999999998,
            ],
            [
              "C",
              222.00033349937286,
              88.40078307135926,
              222.04267586615578,
              88.2500256155554,
              222.13715722536642,
              88.13286873013419,
            ],
            [
              "C",
              222.23163858457707,
              88.01571184471298,
              222.3700036673368,
              87.94239206308397,
              222.52,
              87.92999999999998,
            ],
            ["C", 222.83, 87.93, 222.91, 88.27, 223, 88.57],
            ["Z"],
            ["M", 215.2, 23.569999999999993],
            [
              "C",
              215.73999999999998,
              23.569999999999993,
              215.76999999999998,
              24.049999999999994,
              215.82999999999998,
              24.46999999999999,
            ],
            [
              "C",
              215.82999999999998,
              24.73999999999999,
              215.82999999999998,
              25.129999999999992,
              215.42999999999998,
              25.11999999999999,
            ],
            [
              "C",
              215.02999999999997,
              25.10999999999999,
              214.81999999999996,
              24.63999999999999,
              214.77999999999997,
              24.22999999999999,
            ],
            [
              "C",
              214.73999999999998,
              23.81999999999999,
              214.82,
              23.6,
              215.16,
              23.6,
            ],
            ["Z"],
            ["M", 218.67, 93.77999999999999],
            [
              "C",
              223.8510103209179,
              89.04834173304508,
              229.60609839615097,
              84.98651950131314,
              235.79999999999995,
              81.68999999999998,
            ],
            [
              "C",
              238.86400541604513,
              80.14626842809054,
              241.58497997823966,
              78.0000997421596,
              243.79999999999998,
              75.37999999999997,
            ],
            [
              "C",
              240.6,
              75.66999999999999,
              237.6,
              75.96999999999998,
              234.69,
              75.18999999999998,
            ],
            [
              "C",
              230.21,
              73.97999999999999,
              226.32999999999998,
              71.87999999999998,
              223.69,
              67.74999999999999,
            ],
            [
              "C",
              221.19614853819024,
              63.931564238603194,
              219.4140861541528,
              59.69323716173855,
              218.43,
              55.239999999999995,
            ],
            [
              "C",
              218.07,
              53.499999999999986,
              218.24,
              53.30999999999999,
              219.99,
              53.69999999999999,
            ],
            [
              "C",
              224.31,
              54.69999999999999,
              228.62,
              55.12999999999999,
              232.89000000000001,
              53.47999999999999,
            ],
            [
              "C",
              233.61528426973285,
              53.187655707357074,
              234.32312369813621,
              52.853769184525305,
              235.01000000000002,
              52.47999999999999,
            ],
            [
              "C",
              235.31000000000003,
              52.32999999999999,
              235.74,
              52.10999999999999,
              235.52,
              51.68999999999999,
            ],
            [
              "C",
              235.3,
              51.26999999999999,
              234.89000000000001,
              51.48999999999999,
              234.52,
              51.58999999999999,
            ],
            [
              "C",
              229.43,
              53.14999999999999,
              224.20000000000002,
              52.69999999999999,
              219.01000000000002,
              52.749999999999986,
            ],
            [
              "C",
              218.59000000000003,
              52.749999999999986,
              218.06000000000003,
              52.749999999999986,
              218.01000000000002,
              52.249999999999986,
            ],
            [
              "C",
              217.79000000000002,
              50.52999999999999,
              217.35000000000002,
              48.79999999999998,
              217.8,
              47.079999999999984,
            ],
            [
              "C",
              218.04073540538585,
              46.07486383866634,
              218.9683221047923,
              45.38760642046975,
              220,
              45.45,
            ],
            ["C", 224.55, 45.45, 229.11, 45.510000000000005, 233.66, 45.45],
            ["C", 235, 45.45, 234.98, 46.330000000000005, 235.28, 47.14],
            ["C", 235.43, 47.54, 235.49, 48.24, 236.13, 48.08],
            [
              "C",
              236.54999999999998,
              47.98,
              236.65,
              47.42,
              236.57999999999998,
              47,
            ],
            ["C", 236.35, 45.45, 237.19, 45.38, 238.38, 45.51],
            [
              "C",
              243.4684912444821,
              45.89823305736147,
              248.41218623149848,
              47.38577689058379,
              252.87,
              49.870000000000005,
            ],
            ["C", 253.77, 50.4, 254.13, 49.989999999999995, 254.65, 49.46],
            ["C", 254.98000000000002, 49.14, 255.41, 48.67, 255.88, 49.07],
            ["C", 256.35, 49.47, 256.08, 50.07, 255.74, 50.34],
            [
              "C",
              254.84,
              51.17,
              255.41,
              51.660000000000004,
              256.06,
              52.190000000000005,
            ],
            [
              "C",
              256.38,
              52.45,
              256.65,
              52.78000000000001,
              256.98,
              53.040000000000006,
            ],
            [
              "C",
              257.93,
              53.760000000000005,
              258.15000000000003,
              54.370000000000005,
              257.29,
              55.49000000000001,
            ],
            [
              "C",
              255.66990496423918,
              57.847505774159714,
              254.32766877033382,
              60.38433218064085,
              253.29000000000002,
              63.05000000000004,
            ],
            [
              "C",
              256.14991816820117,
              61.91284287746386,
              258.50388327284406,
              59.782557714891105,
              259.92,
              57.05000000000002,
            ],
            [
              "C",
              261.57,
              59.05000000000001,
              259.17,
              60.29000000000001,
              259.28000000000003,
              61.88000000000001,
            ],
            [
              "C",
              259.38000000000005,
              61.93000000000001,
              259.51000000000005,
              62.040000000000006,
              259.57000000000005,
              62.01000000000001,
            ],
            [
              "C",
              261.78000000000003,
              61.01000000000001,
              261.78000000000003,
              61.01000000000001,
              262.1700000000001,
              63.530000000000015,
            ],
            ["C", 263, 68.83, 262, 74, 261, 79.09],
            [
              "C",
              259.31650228983244,
              89.01365913891735,
              256.6913065185425,
              98.75434089744085,
              253.15999999999997,
              108.18,
            ],
            [
              "C",
              251.5694516720644,
              112.66180191634851,
              248.99665443500942,
              116.73112662993746,
              245.63,
              120.09,
            ],
            ["C", 240.75, 124.76, 236.9, 130.27, 232.63, 135.44],
            ["C", 228, 141, 223.56, 146.74, 219, 152.37],
            ["C", 217.39, 154.37, 215.11, 155.54, 213.07, 156.99],
            ["C", 208.97, 159.91, 204.75, 162.66, 200.38, 165.16],
            ["C", 199.82999999999998, 165.47, 198.95, 165.71, 199.22, 166.52],
            ["C", 199.56, 167.52, 200.46, 166.97, 201.08, 166.81],
            [
              "C",
              202.75,
              166.39000000000001,
              204.39000000000001,
              165.87,
              206.08,
              165.49,
            ],
            [
              "C",
              201.88824135456576,
              168.44099256316656,
              197.41677728592032,
              170.97314749642194,
              192.73000000000002,
              173.05,
            ],
            [
              "C",
              197.44830190246756,
              172.89163829844318,
              202.0685272933336,
              171.6604901557994,
              206.24,
              169.45000000000002,
            ],
            [
              "C",
              206.82000000000002,
              169.81000000000003,
              206.49,
              170.16000000000003,
              206.42000000000002,
              170.45000000000002,
            ],
            [
              "C",
              202.99972223877168,
              185.04539414792313,
              201.00700930939817,
              199.93883010914035,
              200.47000000000008,
              214.92000000000007,
            ],
            [
              "C",
              200.10000000000002,
              226.00000000000003,
              199.35000000000002,
              237.07000000000002,
              199.09000000000003,
              248.16000000000003,
            ],
            [
              "C",
              199.03000000000003,
              250.91000000000003,
              198.96000000000004,
              253.67000000000002,
              198.91000000000003,
              256.43,
            ],
            [
              "C",
              198.91000000000003,
              256.87,
              198.8,
              257.54,
              199.44000000000003,
              257.55,
            ],
            [
              "C",
              199.93000000000004,
              257.55,
              199.89000000000001,
              256.94,
              199.93000000000004,
              256.55,
            ],
            [
              "C",
              200.85000000000002,
              246.83,
              201.98000000000005,
              237.13,
              203.13000000000002,
              227.44,
            ],
            [
              "C",
              204.04000000000002,
              219.82,
              205.3,
              212.26,
              206.51000000000002,
              204.69,
            ],
            [
              "C",
              207.79000000000002,
              196.69,
              208.57000000000002,
              188.69,
              209.27,
              180.61,
            ],
            ["C", 209.66, 176.11, 209.99, 171.61, 210.65, 167.11],
            ["C", 210.65, 168.9, 210.73000000000002, 170.68, 210.65, 172.46],
            ["C", 210.09, 182.63, 209.47, 192.8, 208.91, 202.97],
            ["Q", 207.47, 228.64, 206.07999999999998, 254.3],
            [
              "C",
              205.48999999999998,
              265.19,
              204.86999999999998,
              276.09000000000003,
              204.35,
              286.98,
            ],
            ["C", 204.28, 288.47, 203.68, 288.67, 202.42, 288.78000000000003],
            [
              "C",
              189.56,
              289.91,
              176.67999999999998,
              289.03000000000003,
              163.82,
              289.28000000000003,
            ],
            [
              "C",
              158.97,
              289.38000000000005,
              154.10999999999999,
              289.22,
              149.26,
              289.34000000000003,
            ],
            [
              "C",
              147.57,
              289.34000000000003,
              147.26,
              288.90000000000003,
              147.54,
              287.34000000000003,
            ],
            [
              "C",
              149.29999999999998,
              277.42,
              150.79,
              267.47,
              151.85999999999999,
              257.45000000000005,
            ],
            [
              "C",
              151.92,
              256.95000000000005,
              152.29999999999998,
              256.19000000000005,
              151.6,
              256.07000000000005,
            ],
            [
              "C",
              150.9,
              255.95000000000005,
              150.98999999999998,
              256.83000000000004,
              150.85,
              257.28000000000003,
            ],
            [
              "C",
              148.44218752309007,
              265.51172512430054,
              147.028791891205,
              274.002170618451,
              146.64,
              282.5700000000001,
            ],
            [
              "C",
              146.55999999999997,
              284.37000000000006,
              146.41,
              286.1700000000001,
              146.42999999999998,
              287.96000000000004,
            ],
            [
              "C",
              146.42999999999998,
              289.12000000000006,
              145.96999999999997,
              289.34000000000003,
              144.92999999999998,
              289.36,
            ],
            [
              "C",
              141.49999999999997,
              289.41,
              141.55999999999997,
              289.45,
              142.21999999999997,
              286.08000000000004,
            ],
            [
              "C",
              145.21999999999997,
              270.67,
              147.69999999999996,
              255.17000000000004,
              149.95999999999998,
              239.64000000000004,
            ],
            [
              "C",
              152.29,
              223.64000000000004,
              154.17999999999998,
              207.56000000000006,
              155.95999999999998,
              191.49000000000004,
            ],
            [
              "C",
              156.14468406245493,
              190.83086665967699,
              156.11313971992467,
              190.12988127011602,
              155.86999999999998,
              189.49000000000004,
            ],
            [
              "C",
              154.96999999999997,
              189.69000000000003,
              155.15999999999997,
              190.49000000000004,
              155.06999999999996,
              191.06000000000003,
            ],
            [
              "C",
              152.47999999999996,
              206.78000000000003,
              149.85999999999996,
              222.49000000000004,
              147.36999999999998,
              238.23000000000002,
            ],
            [
              "C",
              145.52999999999997,
              249.88000000000002,
              143.82999999999998,
              261.56,
              142.11999999999998,
              273.23,
            ],
            [
              "C",
              141.40999999999997,
              278.08000000000004,
              140.82999999999998,
              282.96000000000004,
              140.2,
              287.83000000000004,
            ],
            [
              "C",
              140.10999999999999,
              288.53000000000003,
              140.2,
              289.41,
              139.13,
              289.31000000000006,
            ],
            [
              "C",
              137.85999999999999,
              289.20000000000005,
              138.44,
              288.20000000000005,
              138.51999999999998,
              287.65000000000003,
            ],
            [
              "C",
              141.2,
              268.99,
              143.95999999999998,
              250.34000000000003,
              146.64999999999998,
              231.65000000000003,
            ],
            [
              "Q",
              150.49999999999997,
              204.86000000000004,
              154.27999999999997,
              178.07000000000005,
            ],
            [
              "C",
              154.82999999999998,
              174.22000000000006,
              155.36999999999998,
              170.36000000000004,
              155.98999999999998,
              166.52000000000004,
            ],
            [
              "C",
              156.17999999999998,
              165.29000000000005,
              156.1,
              164.70000000000005,
              154.57,
              164.67000000000004,
            ],
            [
              "C",
              148.11858891678955,
              164.52172458081608,
              141.87045761741393,
              162.3842978870107,
              136.68,
              158.55000000000004,
            ],
            [
              "C",
              135.68,
              157.83000000000004,
              133.92000000000002,
              157.25000000000003,
              134.52,
              155.66000000000005,
            ],
            [
              "C",
              135.19,
              153.89000000000004,
              136.85000000000002,
              155.06000000000006,
              137.99,
              155.23000000000005,
            ],
            [
              "C",
              144.81266694425832,
              156.16267123799676,
              151.69420663970106,
              156.59715407452302,
              158.57999999999998,
              156.53000000000006,
            ],
            [
              "C",
              160.34,
              156.53000000000006,
              160.53,
              157.14000000000007,
              160.29000000000002,
              158.63000000000005,
            ],
            [
              "C",
              159.8672567206369,
              161.37365447756918,
              159.90443200652058,
              164.16856006173114,
              160.40000000000003,
              166.90000000000006,
            ],
            ["L", 160.86000000000004, 166.95000000000007],
            [
              "C",
              161.27000000000004,
              165.08000000000007,
              161.67000000000004,
              163.21000000000006,
              162.08000000000004,
              161.34000000000006,
            ],
            [
              "C",
              165.91000000000005,
              143.75000000000006,
              174.37000000000003,
              128.59000000000006,
              186.36000000000004,
              115.34000000000006,
            ],
            [
              "C",
              191.36000000000004,
              109.83000000000006,
              196.68000000000004,
              104.64000000000006,
              202.15000000000003,
              99.60000000000007,
            ],
            [
              "C",
              202.46575886947335,
              99.19266661800239,
              202.90969193898144,
              98.90375779498918,
              203.41000000000003,
              98.78000000000007,
            ],
            [
              "C",
              203.57000000000002,
              99.69000000000007,
              202.91000000000003,
              99.96000000000008,
              202.53000000000003,
              100.36000000000007,
            ],
            [
              "C",
              187.31278797613274,
              116.00563646322118,
              175.4355437793636,
              134.58190495773835,
              167.61999999999995,
              154.96000000000015,
            ],
            [
              "C",
              167.12000000000003,
              156.24000000000007,
              167.13000000000002,
              156.71000000000006,
              168.73000000000005,
              156.66000000000005,
            ],
            [
              "C",
              174.16000000000005,
              156.48000000000005,
              179.16000000000005,
              154.66000000000005,
              184.12000000000006,
              152.73000000000005,
            ],
            [
              "C",
              184.7090622620893,
              152.4244227278432,
              185.15960727521986,
              151.90593838733582,
              185.38000000000005,
              151.28000000000006,
            ],
            [
              "C",
              187.38000000000005,
              147.28000000000006,
              189.76000000000005,
              143.58000000000007,
              192.00000000000006,
              139.76000000000005,
            ],
            [
              "C",
              193.57000000000005,
              137.09000000000006,
              194.46000000000006,
              134.07000000000005,
              195.91000000000005,
              131.31000000000006,
            ],
            [
              "C",
              202.32792192279507,
              118.91755359804178,
              211.15745092734844,
              107.93249075102135,
              221.87999999999997,
              99.00000000000003,
            ],
            [
              "C",
              222.42198160760228,
              98.70888081566983,
              222.771286831869,
              98.15454861194222,
              222.79999999999998,
              97.54,
            ],
            [
              "C",
              217.4296101894291,
              100.96070986302882,
              212.57127389600404,
              105.12547909031002,
              208.36999999999995,
              109.91000000000003,
            ],
            [
              "C",
              204.18327196809756,
              114.70603090022377,
              200.41924290336073,
              119.85522266078378,
              197.12,
              125.3,
            ],
            [
              "C",
              197.48999999999998,
              124.39000000000001,
              197.84999999999997,
              123.46000000000001,
              198.23999999999998,
              122.56000000000002,
            ],
            [
              "C",
              198.63,
              121.66000000000003,
              199.11999999999998,
              120.56000000000002,
              199.57,
              119.56000000000002,
            ],
            [
              "C",
              199.80036991849565,
              119.20704213540894,
              199.99467986390638,
              118.83182293047786,
              200.15,
              118.44000000000001,
            ],
            [
              "C",
              201.85,
              111.15,
              207.5,
              106.86000000000001,
              212.58,
              102.17000000000002,
            ],
            [
              "C",
              214.25,
              100.63000000000001,
              216.11,
              99.30000000000001,
              217.87,
              97.85000000000002,
            ],
            [
              "C",
              218.3,
              97.50000000000003,
              218.98000000000002,
              97.33000000000003,
              218.98000000000002,
              96.39000000000003,
            ],
            [
              "C",
              216.87,
              97.05000000000003,
              215.27,
              98.49000000000002,
              213.34000000000003,
              99.39000000000003,
            ],
            [
              "C",
              214.91266535394558,
              97.35809406441294,
              216.68483887009666,
              95.48876925909664,
              218.63,
              93.81,
            ],
            ["Z"],
            ["M", 139.89, 203.43],
            ["C", 139.89, 203.34, 140.14, 203.11, 140.22, 203.13],
            [
              "C",
              140.92,
              203.32999999999998,
              141.28,
              203.79999999999998,
              141.27,
              204.75,
            ],
            ["C", 140.44, 204.54, 140, 204.15, 139.89, 203.43],
            ["Z"],
            ["M", 145.89, 213.58],
            [
              "C",
              145.8582240214127,
              213.39816105587087,
              145.90063426133585,
              213.2111717720365,
              146.0077497723117,
              213.0608342127721,
            ],
            [
              "C",
              146.11486528328757,
              212.91049665350775,
              146.27775341703943,
              212.8093478690263,
              146.45999999999998,
              212.78,
            ],
            [
              "C",
              147.21999999999997,
              212.78,
              147.36999999999998,
              213.42,
              147.59999999999997,
              213.95,
            ],
            [
              "C",
              147.59999999999997,
              214.38,
              147.47999999999996,
              214.75,
              147.05999999999997,
              214.7,
            ],
            [
              "C",
              146.4445036564256,
              214.6847676426154,
              145.94612285925874,
              214.19513036820587,
              145.92,
              213.58,
            ],
            ["Z"],
            ["M", 196.23999999999998, 119.75000000000001],
            [
              "C",
              195.30496007436213,
              118.90749811340692,
              194.63159487800976,
              117.81414740798944,
              194.29999999999998,
              116.60000000000001,
            ],
            ["C", 195.64, 117.41, 196.48, 118, 196.27, 119.75],
            ["Z"],
            ["M", 209.22, 92.1],
            ["C", 209.73, 92.77, 210.06, 93.32, 209.74, 94.00999999999999],
            [
              "C",
              209.68743363072446,
              94.12268871708551,
              209.57434617057712,
              94.19472250741532,
              209.45,
              94.19472250741532,
            ],
            [
              "C",
              209.3256538294229,
              94.19472250741532,
              209.21256636927555,
              94.12268871708551,
              209.16,
              94.00999999999999,
            ],
            [
              "C",
              208.70700808834818,
              93.4453989545361,
              208.7324638985581,
              92.6350556628536,
              209.22,
              92.1,
            ],
            ["Z"],
            ["M", 196.63, 1.86],
            ["C", 197.06, 1.86, 197.18, 2.23, 197.2, 2.59],
            [
              "C",
              197.2270559063668,
              2.734679972720914,
              197.1876415106871,
              2.883858628998353,
              197.0926534719106,
              2.9962934504072507,
            ],
            [
              "C",
              196.99766543313413,
              3.1087282718161484,
              196.8571666613663,
              3.17250805863984,
              196.70999999999998,
              3.17,
            ],
            [
              "C",
              196.27999999999997,
              3.17,
              196.14,
              2.84,
              196.14999999999998,
              2.4699999999999998,
            ],
            [
              "C",
              196.12545113439708,
              2.1723061747891586,
              196.33488285759435,
              1.906153359892643,
              196.63,
              1.86,
            ],
            ["Z"],
            ["M", 185.54, 10.6],
            [
              "C",
              190.28,
              7.449999999999999,
              195.29,
              5.1899999999999995,
              201.18,
              6.09,
            ],
            [
              "C",
              202.8638995854213,
              6.303564641148079,
              204.45055986762787,
              6.997945508634534,
              205.75,
              8.089999999999996,
            ],
            [
              "C",
              201.15083409356083,
              7.556048831736168,
              196.49163222119734,
              8.213777994797093,
              192.21999999999997,
              10.00000000000001,
            ],
            ["L", 194.47, 9.73],
            ["C", 195.24, 9.65, 196.02, 9.6, 196.79, 9.530000000000001],
            [
              "C",
              201.15935443521428,
              9.202880130002148,
              205.54804923904152,
              9.816683599068897,
              209.66,
              11.330000000000005,
            ],
            [
              "C",
              211.1228259180676,
              11.823246886337731,
              212.13712753170645,
              13.158479869916984,
              212.22,
              14.7,
            ],
            [
              "C",
              212.73,
              18.590000000000003,
              212.22,
              22.480000000000004,
              212.22,
              26.370000000000005,
            ],
            [
              "C",
              212.22,
              29.540000000000006,
              211.94,
              32.71000000000001,
              211.82,
              35.88,
            ],
            ["C", 211.82, 36.45, 211.47, 37.18, 212.68, 37.13],
            ["C", 213.55, 37.13, 213.74, 38.13, 214.14000000000001, 38.88],
            [
              "C",
              213.14000000000001,
              39.160000000000004,
              212.68,
              38.330000000000005,
              211.74,
              38.120000000000005,
            ],
            [
              "C",
              211.41,
              40.980000000000004,
              211.07000000000002,
              43.790000000000006,
              210.74,
              46.60000000000001,
            ],
            [
              "C",
              210.14000000000001,
              52.010000000000005,
              209.45000000000002,
              57.42000000000001,
              209.02,
              62.84,
            ],
            [
              "C",
              208.82000000000002,
              65.45,
              208.24,
              68.02000000000001,
              208.22,
              70.63000000000001,
            ],
            [
              "C",
              208.26833022095244,
              71.73623089544293,
              207.55356961513075,
              72.73193786655871,
              206.49,
              73.04,
            ],
            [
              "C",
              202.31,
              74.82000000000001,
              198.19,
              74.23,
              194.07000000000002,
              72.83000000000001,
            ],
            [
              "C",
              193.50000000000003,
              72.63000000000001,
              192.76000000000002,
              71.73000000000002,
              192.25000000000003,
              72.75000000000001,
            ],
            [
              "C",
              191.74000000000004,
              73.77000000000001,
              192.61000000000004,
              74.16000000000001,
              193.42000000000002,
              74.52000000000001,
            ],
            [
              "C",
              197.71,
              76.42000000000002,
              202.02,
              77.05000000000001,
              206.42000000000002,
              74.9,
            ],
            [
              "C",
              206.79000000000002,
              74.72,
              207.31,
              74.10000000000001,
              207.64000000000001,
              74.69000000000001,
            ],
            [
              "C",
              207.97000000000003,
              75.28000000000002,
              207.45000000000002,
              75.82000000000001,
              206.9,
              76.13000000000001,
            ],
            [
              "C",
              204.96270821638635,
              77.34179328668957,
              202.77988149019407,
              78.10697824235008,
              200.51000000000002,
              78.37,
            ],
            [
              "C",
              195.3,
              78.76,
              191.31000000000003,
              76.26,
              187.75000000000003,
              72.85000000000001,
            ],
            [
              "C",
              187.40560511166163,
              72.56590652335792,
              187.20043245394004,
              72.14710980371929,
              187.18702586407457,
              71.70086188391193,
            ],
            [
              "C",
              187.17361927420913,
              71.25461396410458,
              187.35328083803844,
              70.82425570132922,
              187.68000000000004,
              70.52000000000001,
            ],
            ["L", 187.68000000000004, 70.52000000000001],
            [
              "C",
              186.62000000000003,
              67.21000000000001,
              187.34000000000003,
              63.72000000000001,
              186.68000000000004,
              60.40000000000001,
            ],
            [
              "C",
              186.5746874650596,
              59.22568972073042,
              186.5746874650596,
              58.0443102792696,
              186.68000000000004,
              56.87000000000002,
            ],
            [
              "C",
              186.25000000000003,
              45.31000000000001,
              185.86000000000004,
              33.750000000000014,
              185.43000000000004,
              22.190000000000012,
            ],
            [
              "C",
              185.31000000000003,
              18.780000000000012,
              185.13000000000002,
              15.370000000000012,
              184.96000000000004,
              11.960000000000012,
            ],
            [
              "C",
              184.87620635146362,
              11.432911505937316,
              185.10160505160175,
              10.904390415958233,
              185.54,
              10.6,
            ],
            ["Z"],
            ["M", 184.07, 45],
            ["C", 185.23999999999998, 45.74, 184.78, 46.65, 184.76, 47.77],
            [
              "C",
              184.1037510246632,
              47.00832910730472,
              183.847723711954,
              45.98050931570399,
              184.07,
              45,
            ],
            ["Z"],
            ["M", 184.88, 60.39],
            [
              "C",
              185.07999999999998,
              60.8,
              185.14,
              60.86,
              185.12,
              60.910000000000004,
            ],
            [
              "C",
              184.91,
              61.720000000000006,
              185.12,
              62.7,
              184.20000000000002,
              63.24,
            ],
            [
              "C",
              184.20000000000002,
              63.24,
              183.85000000000002,
              63.07,
              183.85000000000002,
              62.97,
            ],
            [
              "C",
              183.94457308380956,
              62.03345760861477,
              184.3015959780021,
              61.142633493979005,
              184.88,
              60.4,
            ],
            ["Z"],
            ["M", 159.88, 17.39],
            ["C", 160.78, 16.57, 159.42, 15.99, 160.14, 15.06],
            [
              "C",
              160.88,
              16.330000000000002,
              161.55999999999997,
              16.060000000000002,
              162.51999999999998,
              15.33,
            ],
            [
              "C",
              165.0954645106981,
              13.45697588617047,
              168.09477909372174,
              12.251065899181583,
              171.24999999999994,
              11.820000000000002,
            ],
            [
              "C",
              171.17999999999998,
              11.22,
              169.91999999999996,
              11.17,
              170.54999999999998,
              10.45,
            ],
            [
              "C",
              171.18,
              9.729999999999999,
              171.63,
              10.559999999999999,
              171.95,
              10.969999999999999,
            ],
            [
              "C",
              172.26999999999998,
              11.379999999999999,
              172.81,
              11.409999999999998,
              173.38,
              11.29,
            ],
            [
              "C",
              174.7509268465973,
              10.929600258972517,
              176.2063834205104,
              11.397843660767613,
              177.10999999999999,
              12.489999999999998,
            ],
            [
              "C",
              177.30999999999997,
              12.7,
              177.67999999999998,
              13.079999999999998,
              177.76,
              13.029999999999998,
            ],
            [
              "C",
              178.38,
              12.639999999999997,
              178.85,
              12.599999999999998,
              178.66,
              13.489999999999998,
            ],
            [
              "C",
              178.51,
              14.219999999999999,
              177.93,
              14.04,
              177.35999999999999,
              13.839999999999998,
            ],
            [
              "C",
              173.35999999999999,
              12.449999999999998,
              170.05999999999997,
              14.419999999999998,
              166.82,
              16.419999999999998,
            ],
            ["C", 166.56, 16.58, 166.34, 16.83, 166.51, 17.159999999999997],
            [
              "C",
              166.67999999999998,
              17.489999999999995,
              167.12,
              17.359999999999996,
              167.44,
              17.239999999999995,
            ],
            [
              "C",
              169.5947626582948,
              16.337305468746056,
              171.895250409093,
              15.832485623578268,
              174.22999999999996,
              15.749999999999996,
            ],
            [
              "C",
              176.61999999999998,
              15.749999999999995,
              177.51999999999998,
              16.399999999999995,
              178.41,
              18.639999999999993,
            ],
            [
              "C",
              180.41,
              23.639999999999993,
              181.01,
              28.96999999999999,
              181.48,
              34.279999999999994,
            ],
            [
              "C",
              182.46311734235715,
              44.66593997067645,
              182.8470867960629,
              55.099892517029694,
              182.62999999999997,
              65.53,
            ],
            [
              "C",
              182.63889069761612,
              66.60647590365208,
              182.21350710544377,
              67.64109531851193,
              181.45,
              68.4,
            ],
            [
              "C",
              178.84815417224897,
              71.3620654474072,
              175.1576503323076,
              73.14418558125963,
              171.22,
              73.34,
            ],
            [
              "C",
              169.86164105636595,
              73.53245398308418,
              168.55892866386353,
              72.72981505738107,
              168.12,
              71.43,
            ],
            ["C", 167.68, 70.25, 167.12, 69.14, 166.67000000000002, 67.95],
            [
              "C",
              163.38000000000002,
              58.53,
              159.39000000000001,
              49.33,
              157.72000000000003,
              39.400000000000006,
            ],
            [
              "C",
              157.44000000000003,
              37.760000000000005,
              157.81000000000003,
              35.870000000000005,
              156.37000000000003,
              34.53000000000001,
            ],
            ["C", 157.07, 28.76, 154.59, 22.27, 159.9, 17.44],
            ["Z"],
            ["M", 125.58, 147.06],
            ["C", 121.88, 133.06, 118.31, 119.06, 116.32, 104.64],
            [
              "C",
              114.11999999999999,
              88.64,
              113.91999999999999,
              72.75,
              117.63,
              56.96,
            ],
            [
              "C",
              119.47999999999999,
              49.11,
              122.50999999999999,
              41.78,
              128.38,
              35.96,
            ],
            [
              "C",
              132.38,
              32.02,
              137.04999999999998,
              29.400000000000002,
              142.76,
              28.96,
            ],
            ["C", 145.29999999999998, 28.76, 147.76, 29.62, 150.25, 29.62],
            ["C", 151.14, 29.62, 151.09, 30.700000000000003, 151.25, 31.34],
            ["Q", 155.31, 45.980000000000004, 159.32, 60.620000000000005],
            [
              "C",
              159.9,
              62.760000000000005,
              160.39,
              64.93,
              161.10999999999999,
              67.02000000000001,
            ],
            [
              "C",
              161.55074670204306,
              68.26963961655373,
              161.39217163802928,
              69.65256168644126,
              160.67999999999998,
              70.77000000000001,
            ],
            [
              "C",
              158.93999999999997,
              73.84,
              156.74999999999997,
              76.36000000000001,
              153.17999999999998,
              77.30000000000001,
            ],
            [
              "C",
              151.68861740306158,
              77.88089972337374,
              149.9959334905028,
              77.29692377354097,
              149.17999999999998,
              75.92000000000002,
            ],
            [
              "C",
              143.00351064496817,
              67.58412566408641,
              137.7274072113997,
              58.61743145519468,
              133.43999999999994,
              49.170000000000016,
            ],
            [
              "C",
              131.98999999999998,
              45.91000000000002,
              129.43999999999997,
              43.350000000000016,
              127.53999999999996,
              40.38000000000002,
            ],
            ["L", 127, 40],
            ["C", 121.57, 47.64, 119.25, 56.379999999999995, 118, 65.5],
            [
              "C",
              117.24227405000275,
              70.84243596279407,
              116.8879388495885,
              76.23434802192793,
              116.94,
              81.62999999999997,
            ],
            [
              "C",
              117,
              84.89999999999999,
              116.77,
              88.16,
              117.09,
              91.42999999999999,
            ],
            [
              "C",
              117.7,
              97.52999999999999,
              117.89,
              103.66999999999999,
              119.09,
              109.71,
            ],
            [
              "C",
              119.16,
              110.08999999999999,
              118.99000000000001,
              111.02,
              119.71000000000001,
              109.97,
            ],
            [
              "C",
              119.86000000000001,
              109.76,
              120.12,
              110.03,
              120.21000000000001,
              110.27,
            ],
            [
              "C",
              120.30000000000001,
              110.50999999999999,
              120.7,
              111.27,
              120.31,
              111.41,
            ],
            ["C", 119.01, 111.91, 119.59, 112.8, 119.72, 113.59],
            ["C", 121.5, 124.48, 123.98, 135.21, 126.39, 145.97],
            [
              "C",
              126.69630090957936,
              146.80812945918254,
              126.76889142892374,
              147.7137826052886,
              126.6,
              148.59,
            ],
            ["C", 125.7, 148.28, 125.72, 147.59, 125.58, 147.06],
            ["Z"],
            ["M", 127.14999999999999, 174.17000000000002],
            [
              "C",
              127.77,
              170.94000000000003,
              128.41,
              167.71,
              129.14999999999998,
              164.51000000000002,
            ],
            [
              "C",
              129.33999999999997,
              163.74,
              128.42,
              163.20000000000002,
              129.14999999999998,
              162.25000000000003,
            ],
            [
              "C",
              129.86381065714443,
              163.45317353381344,
              130.11279147986386,
              164.87592109221015,
              129.84999999999997,
              166.25000000000003,
            ],
            [
              "C",
              128.95999999999998,
              174.40000000000003,
              128.06999999999996,
              182.56000000000003,
              127.11999999999996,
              190.70000000000002,
            ],
            [
              "C",
              125.75999999999996,
              202.3,
              124.38999999999996,
              213.89000000000001,
              122.92999999999996,
              225.48000000000002,
            ],
            [
              "Q",
              119.47999999999996,
              252.73000000000002,
              115.92999999999996,
              279.99,
            ],
            [
              "C",
              115.65999999999997,
              282.13,
              115.29999999999997,
              284.26,
              115.06999999999996,
              286.40000000000003,
            ],
            [
              "C",
              114.97999999999996,
              287.29,
              114.58999999999996,
              287.8,
              113.75999999999996,
              287.74,
            ],
            [
              "C",
              112.75999999999996,
              287.67,
              113.23999999999997,
              286.82,
              113.25999999999996,
              286.33,
            ],
            [
              "C",
              113.80999999999996,
              275.85999999999996,
              114.69999999999996,
              265.43,
              115.67999999999996,
              254.98999999999998,
            ],
            [
              "Q",
              117.22999999999996,
              238.54999999999998,
              119.43999999999997,
              222.19,
            ],
            ["C", 121.66, 206.15, 124.09, 190.11, 127.15, 174.17],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -177.01,
          top: 80.04,
          width: 353.45,
          height: 85.1,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 353.1, 319],
            ["C", 350.85, 316.11, 349.81, 315.94, 346.83000000000004, 318.11],
            [
              "C",
              346.4554401289406,
              318.4078123231658,
              346.0648555770109,
              318.6848936719706,
              345.66,
              318.94,
            ],
            [
              "C",
              345.211154428867,
              319.1132765679017,
              344.85157078398555,
              319.4608728473086,
              344.6631814251798,
              319.90358784050204,
            ],
            [
              "C",
              344.4747920663741,
              320.3463028336955,
              344.4736444267825,
              320.84642507280023,
              344.66,
              321.29,
            ],
            [
              "C",
              345.15000000000003,
              323.12,
              345.45000000000005,
              325.01000000000005,
              345.84000000000003,
              326.87,
            ],
            [
              "C",
              344.5108383982859,
              325.63167827120503,
              343.27731484198466,
              324.29452536464544,
              342.15000000000003,
              322.87,
            ],
            [
              "C",
              339.07000000000005,
              319.2,
              339.08000000000004,
              319.21,
              341.75000000000006,
              315.23,
            ],
            [
              "C",
              342.57000000000005,
              314.02000000000004,
              343.33000000000004,
              312.76,
              344.12000000000006,
              311.52000000000004,
            ],
            ["L", 343.2900000000001, 310.81000000000006],
            ["L", 336.38000000000005, 318.49000000000007],
            ["L", 336.38000000000005, 315.49000000000007],
            [
              "C",
              336.38000000000005,
              311.64000000000004,
              336.38000000000005,
              311.64000000000004,
              332.52000000000004,
              311.64000000000004,
            ],
            [
              "C",
              329.22,
              311.64000000000004,
              329.25000000000006,
              311.64000000000004,
              330.27000000000004,
              308.59000000000003,
            ],
            [
              "C",
              331.55,
              304.76000000000005,
              332.78000000000003,
              300.91,
              334.04,
              297.07000000000005,
            ],
            [
              "C",
              332.5365989124224,
              298.10704464063446,
              331.1277856632274,
              299.2749207070692,
              329.83000000000004,
              300.56000000000006,
            ],
            [
              "C",
              327.83000000000004,
              302.51000000000005,
              325.83000000000004,
              304.7300000000001,
              323.19000000000005,
              305.6700000000001,
            ],
            [
              "C",
              321.6109864338151,
              306.32086702075776,
              320.2313423291165,
              307.3762947608522,
              319.19000000000005,
              308.7300000000001,
            ],
            [
              "C",
              317.28000000000003,
              310.80000000000007,
              315.47,
              312.9600000000001,
              313.58000000000004,
              315.05000000000007,
            ],
            [
              "C",
              313.0877128517192,
              315.79215419598734,
              312.3938868502199,
              316.3784371672542,
              311.58000000000004,
              316.74000000000007,
            ],
            [
              "C",
              311.35662871594343,
              316.3290081065038,
              311.44801379730933,
              315.81808242432203,
              311.80000000000007,
              315.51000000000005,
            ],
            [
              "C",
              312.93000000000006,
              313.96000000000004,
              314.0300000000001,
              312.39000000000004,
              315.2100000000001,
              310.88000000000005,
            ],
            [
              "C",
              317.4938477279133,
              307.95108521923385,
              319.9410670651515,
              305.1533092238646,
              322.5400000000001,
              302.50000000000006,
            ],
            [
              "C",
              323.4116771608598,
              301.4950288045996,
              323.8534343276389,
              300.1877149126679,
              323.7700000000001,
              298.86000000000007,
            ],
            [
              "C",
              323.7700000000001,
              297.9700000000001,
              322.5300000000001,
              299.0400000000001,
              321.8900000000001,
              298.4200000000001,
            ],
            [
              "C",
              323.2100000000001,
              296.8400000000001,
              324.5100000000001,
              295.2100000000001,
              325.8900000000001,
              293.6600000000001,
            ],
            [
              "C",
              326.63696480478814,
              292.9990824914765,
              326.73376703650143,
              291.86825642100763,
              326.1100000000001,
              291.0900000000001,
            ],
            [
              "C",
              325.41000000000014,
              289.9600000000001,
              324.65000000000015,
              290.6700000000001,
              323.9500000000001,
              290.9600000000001,
            ],
            [
              "C",
              319.75247809979,
              292.7417720627585,
              315.918645175761,
              295.2807118152764,
              312.6400000000001,
              298.4500000000001,
            ],
            [
              "C",
              303.1200000000001,
              307.3400000000001,
              291.1100000000001,
              312.7600000000001,
              281.1500000000001,
              321.0400000000001,
            ],
            [
              "C",
              280.8800000000001,
              321.2800000000001,
              280.5600000000001,
              321.50000000000006,
              280.2000000000001,
              321.2000000000001,
            ],
            [
              "C",
              280.02396915591476,
              321.0787773038784,
              279.9037652442713,
              320.8921559233913,
              279.86619298174776,
              320.68175125325945,
            ],
            [
              "C",
              279.8286207192242,
              320.4713465831276,
              279.8768046282489,
              320.25465594894973,
              280.0000000000001,
              320.0800000000001,
            ],
            [
              "C",
              280.2070649799956,
              319.78783810031535,
              280.43428460488576,
              319.5104964993465,
              280.6800000000001,
              319.2500000000001,
            ],
            [
              "C",
              286.6100000000001,
              312.5200000000001,
              293.9400000000001,
              307.54000000000013,
              301.35000000000014,
              302.66000000000014,
            ],
            [
              "C",
              304.07000000000016,
              300.8700000000001,
              306.90000000000015,
              299.22000000000014,
              309.5600000000001,
              297.08000000000015,
            ],
            [
              "C",
              308.66000000000014,
              296.69000000000017,
              307.9500000000001,
              297.65000000000015,
              307.2100000000001,
              297.08000000000015,
            ],
            [
              "C",
              310.0300000000001,
              294.71000000000015,
              312.7900000000001,
              292.35000000000014,
              315.6000000000001,
              290.08000000000015,
            ],
            [
              "C",
              316.00209186763925,
              289.81140065761167,
              316.2759607018406,
              289.3891980864528,
              316.35729612832597,
              288.9125346568177,
            ],
            [
              "C",
              316.4386315548113,
              288.43587122718253,
              316.32026806957685,
              287.9467401253263,
              316.0300000000001,
              287.5600000000002,
            ],
            [
              "C",
              315.4400000000001,
              286.4800000000002,
              314.7000000000001,
              287.1000000000002,
              314.0300000000001,
              287.3500000000002,
            ],
            [
              "C",
              307.9400000000001,
              289.6500000000002,
              302.1700000000001,
              292.8100000000002,
              295.7500000000001,
              294.2100000000002,
            ],
            [
              "C",
              295.3300000000001,
              294.3000000000002,
              294.9400000000001,
              294.62000000000023,
              294.3600000000001,
              294.2100000000002,
            ],
            [
              "C",
              299.2000000000001,
              289.9200000000002,
              303.78000000000014,
              285.5300000000002,
              308.2500000000001,
              281.0300000000002,
            ],
            [
              "C",
              308.7100000000001,
              280.5600000000002,
              309.4600000000001,
              280.1300000000002,
              308.92000000000013,
              279.2200000000002,
            ],
            [
              "C",
              308.38000000000017,
              278.3100000000002,
              307.6200000000001,
              278.8700000000002,
              307.04000000000013,
              279.0700000000002,
            ],
            [
              "C",
              303.06805640332743,
              280.3877045263898,
              299.33870774572614,
              282.34696378491213,
              296,
              284.87,
            ],
            ["C", 293.76, 286.62, 291, 286.68, 288.5, 287.78000000000003],
            [
              "C",
              294.91,
              282.20000000000005,
              299.32,
              275.24,
              303.38,
              267.78000000000003,
            ],
            [
              "C",
              299.98,
              269.09000000000003,
              297.75,
              271.78000000000003,
              294.85,
              273.54,
            ],
            [
              "C",
              286.66,
              278.54,
              278.64000000000004,
              283.76000000000005,
              270.39000000000004,
              288.63,
            ],
            [
              "C",
              269.3198551265414,
              289.39894321163257,
              268.1574390514898,
              290.03054500963754,
              266.93000000000006,
              290.51,
            ],
            [
              "C",
              267.39000000000004,
              289.25,
              267.74000000000007,
              288.25,
              268.12000000000006,
              287.27,
            ],
            [
              "C",
              268.5304210909253,
              286.58225864653105,
              268.4278932720171,
              285.70462051667664,
              267.87000000000006,
              285.13,
            ],
            [
              "C",
              267.2200000000001,
              284.49,
              266.51000000000005,
              284.77,
              265.87000000000006,
              284.99,
            ],
            [
              "C",
              263.13315225394007,
              285.77471076414207,
              260.7255265726542,
              287.4277598835089,
              259.01000000000005,
              289.7,
            ],
            [
              "C",
              256.82000000000005,
              292.7,
              253.72000000000006,
              294.7,
              251.07000000000005,
              297.19,
            ],
            [
              "C",
              250.41000000000005,
              297.81,
              249.07000000000005,
              298.12,
              249.69000000000005,
              299.55,
            ],
            [
              "C",
              249.739865996064,
              299.7012588834504,
              249.67805079940044,
              299.8668629399722,
              249.5412694896193,
              299.94845179142055,
            ],
            [
              "C",
              249.4044881798382,
              300.0300406428689,
              249.22940065890853,
              300.0057469792975,
              249.12000000000006,
              299.89,
            ],
            [
              "C",
              248.26000000000005,
              299.12,
              247.85000000000005,
              299.89,
              247.35000000000005,
              300.33,
            ],
            [
              "C",
              245.54000000000005,
              301.9,
              243.75000000000006,
              303.49,
              241.92000000000004,
              305.05,
            ],
            [
              "C",
              241.52000000000004,
              305.40000000000003,
              241.09000000000003,
              306.14,
              240.45000000000005,
              305.48,
            ],
            [
              "C",
              239.81000000000006,
              304.82000000000005,
              240.52000000000004,
              304.40000000000003,
              240.88000000000005,
              303.97,
            ],
            [
              "C",
              246.24124947040406,
              297.27454412329666,
              252.4077954408605,
              291.26585635578766,
              259.24,
              286.0800000000001,
            ],
            [
              "C",
              262.95000000000005,
              283.33000000000004,
              265.12000000000006,
              279.52000000000004,
              267.4600000000001,
              275.80000000000007,
            ],
            [
              "C",
              268.0600000000001,
              274.8500000000001,
              268.2200000000001,
              273.69000000000005,
              269.2300000000001,
              272.88000000000005,
            ],
            [
              "C",
              272.93367701096804,
              269.7455700316687,
              276.82778681813824,
              266.84335611877776,
              280.89000000000004,
              264.1900000000001,
            ],
            [
              "C",
              284.1100000000001,
              262.19000000000005,
              284.1000000000001,
              262.14000000000004,
              282.6100000000001,
              258.7300000000001,
            ],
            [
              "C",
              282.52000000000015,
              258.51000000000005,
              282.4300000000001,
              258.2800000000001,
              282.33000000000015,
              258.06000000000006,
            ],
            [
              "C",
              280.53000000000014,
              253.94000000000005,
              280.53000000000014,
              253.93000000000006,
              276.65000000000015,
              256.24000000000007,
            ],
            [
              "C",
              265.96145773020675,
              262.7084703515234,
              256.5657939936112,
              271.103873964591,
              248.93999999999997,
              281,
            ],
            ["C", 243.16, 288.36, 236.94, 295.28, 228.15, 299.25],
            [
              "C",
              226.79812784249071,
              299.89838981562286,
              225.52345279095246,
              300.69673892684943,
              224.35,
              301.63,
            ],
            [
              "C",
              216.81031680345012,
              307.1675066460673,
              208.49830857137945,
              311.5677833996483,
              199.68,
              314.69,
            ],
            ["C", 191.68, 317.69, 183.34, 319.69, 175.20000000000002, 322.35],
            [
              "C",
              174.52,
              322.56,
              173.38000000000002,
              323.21000000000004,
              173.3,
              322.08000000000004,
            ],
            [
              "C",
              173.22,
              320.95000000000005,
              174.04000000000002,
              320.08000000000004,
              174.49,
              319.02000000000004,
            ],
            ["L", 174.94, 319.14000000000004],
            [
              "C",
              175.37,
              320.38000000000005,
              176.04,
              319.62000000000006,
              176.6,
              319.34000000000003,
            ],
            [
              "C",
              184.13,
              315.49,
              192,
              312.45000000000005,
              199.85999999999999,
              309.34000000000003,
            ],
            [
              "C",
              207.54,
              306.34000000000003,
              215.23999999999998,
              303.34000000000003,
              222.5,
              299.34000000000003,
            ],
            [
              "C",
              223.12474867237188,
              298.99122115394573,
              223.71417761882327,
              298.58263972515556,
              224.26,
              298.12,
            ],
            [
              "C",
              224.5684817632577,
              297.88671263278843,
              224.66900082964497,
              297.4678831895082,
              224.5,
              297.12,
            ],
            ["C", 224.33, 296.83, 223.97, 297, 223.68, 297.03000000000003],
            ["C", 221.84, 297.22, 219.99, 297.41, 218.15, 297.58000000000004],
            [
              "C",
              206.459666941473,
              298.59026985127946,
              194.9463903166136,
              301.09052607103877,
              183.89,
              305.02000000000004,
            ],
            [
              "C",
              182.62236906415225,
              305.5758437899642,
              181.27007143279,
              305.9139181978048,
              179.89000000000001,
              306.02000000000004,
            ],
            [
              "C",
              174.27,
              306.12000000000006,
              168.65,
              306.37000000000006,
              163.04000000000002,
              306.51000000000005,
            ],
            [
              "C",
              158.50000000000003,
              306.62000000000006,
              153.95000000000002,
              306.97,
              149.42000000000002,
              306.45000000000005,
            ],
            [
              "C",
              141.5196367076828,
              305.48723990538195,
              133.54165433784237,
              305.33310836862734,
              125.60999999999993,
              305.99000000000007,
            ],
            [
              "C",
              121.37000000000002,
              306.38000000000005,
              117.11000000000001,
              306.74000000000007,
              113.03000000000002,
              305.4200000000001,
            ],
            [
              "C",
              101.13000000000001,
              301.5800000000001,
              90.30000000000001,
              295.8900000000001,
              81.77000000000001,
              286.4200000000001,
            ],
            [
              "C",
              81.12,
              285.70000000000005,
              80.77000000000001,
              285.6000000000001,
              80.19000000000001,
              286.4200000000001,
            ],
            [
              "C",
              79.27317101043303,
              287.8137310674923,
              78.2712697077138,
              289.14959947111794,
              77.19000000000001,
              290.4200000000001,
            ],
            [
              "C",
              76.47000000000001,
              291.24000000000007,
              76.68,
              291.7100000000001,
              77.34000000000002,
              292.3300000000001,
            ],
            [
              "C",
              79.15030693910218,
              294.17055821471513,
              80.66187494981482,
              296.2827045868269,
              81.82000000000002,
              298.5900000000001,
            ],
            [
              "C",
              82.03000000000002,
              298.9700000000001,
              82.60000000000002,
              299.4500000000001,
              82.14000000000001,
              299.8400000000001,
            ],
            [
              "C",
              81.62326405990058,
              300.205741122468,
              80.90810119138938,
              300.08505738840677,
              80.54000000000002,
              299.5700000000001,
            ],
            ["L", 79.66000000000003, 298.67000000000013],
            [
              "C",
              71.35973144166849,
              289.80864402956325,
              64.8593952833719,
              279.4196532729127,
              60.52000000000004,
              268.08000000000015,
            ],
            [
              "C",
              60.26039159147204,
              267.34736506409445,
              60.095717687557894,
              266.58448799902277,
              60.03000000000002,
              265.8100000000002,
            ],
            [
              "C",
              59.39368149614555,
              259.8435225165459,
              57.96441345710561,
              253.98856803191407,
              55.77999999999999,
              248.40000000000012,
            ],
            [
              "C",
              55.406014070509485,
              247.1579046996858,
              54.53832505435744,
              246.1239086221046,
              53.380000000000024,
              245.54000000000016,
            ],
            [
              "C",
              53.28000000000002,
              246.86000000000016,
              53.130000000000024,
              248.03000000000017,
              53.120000000000026,
              249.20000000000016,
            ],
            [
              "C",
              53.120000000000026,
              253.27000000000015,
              53.010000000000026,
              257.35000000000014,
              52.67000000000002,
              261.41000000000014,
            ],
            [
              "C",
              51.950000000000024,
              270.04000000000013,
              52.14000000000002,
              278.60000000000014,
              55.75000000000002,
              286.66000000000014,
            ],
            [
              "C",
              57.54000000000002,
              290.66000000000014,
              58.57000000000002,
              294.66000000000014,
              58.00000000000002,
              298.96000000000015,
            ],
            [
              "C",
              57.90000000000002,
              299.67000000000013,
              58.48000000000002,
              300.44000000000017,
              57.90000000000002,
              301.15000000000015,
            ],
            [
              "C",
              57.34000000000002,
              300.8700000000002,
              57.22000000000002,
              300.3700000000002,
              56.99000000000002,
              299.97000000000014,
            ],
            [
              "C",
              53.690000000000026,
              294.19000000000017,
              50.380000000000024,
              288.42000000000013,
              47.120000000000026,
              282.63000000000017,
            ],
            [
              "C",
              46.50000000000003,
              281.52000000000015,
              45.980000000000025,
              280.8100000000002,
              44.840000000000025,
              282.08000000000015,
            ],
            [
              "C",
              44.07000000000002,
              282.94000000000017,
              43.550000000000026,
              282.8100000000002,
              43.08000000000003,
              281.69000000000017,
            ],
            [
              "C",
              42.310000000000024,
              279.88000000000017,
              41.380000000000024,
              278.13000000000017,
              40.50000000000003,
              276.3600000000002,
            ],
            [
              "C",
              40.27000000000003,
              275.9100000000002,
              40.13000000000003,
              275.13000000000017,
              39.50000000000003,
              275.3100000000002,
            ],
            [
              "C",
              38.870000000000026,
              275.4900000000002,
              39.050000000000026,
              276.26000000000016,
              39.160000000000025,
              276.84000000000015,
            ],
            [
              "C",
              39.730000000000025,
              279.66000000000014,
              40.28000000000002,
              282.4900000000001,
              40.840000000000025,
              285.32000000000016,
            ],
            [
              "C",
              39.310000000000024,
              282.9200000000002,
              37.980000000000025,
              280.45000000000016,
              36.71000000000002,
              277.96000000000015,
            ],
            [
              "C",
              35.22000000000002,
              275.03000000000014,
              33.560000000000024,
              272.21000000000015,
              32.96000000000002,
              268.8700000000002,
            ],
            [
              "C",
              32.30387567949633,
              265.69779396899986,
              31.39086659821416,
              262.584198896935,
              30.230000000000032,
              259.5600000000002,
            ],
            [
              "C",
              30.100000000000023,
              259.20000000000016,
              30.06000000000002,
              258.63000000000017,
              29.52000000000002,
              258.6700000000002,
            ],
            [
              "C",
              28.98000000000002,
              258.7100000000002,
              29.02000000000002,
              259.2700000000002,
              28.95000000000002,
              259.6700000000002,
            ],
            [
              "C",
              28.77000000000002,
              260.6000000000002,
              28.640000000000022,
              261.5400000000002,
              28.48000000000002,
              262.5900000000002,
            ],
            [
              "C",
              27.61000000000002,
              261.9500000000002,
              27.620000000000022,
              260.9800000000002,
              27.09000000000002,
              260.3600000000002,
            ],
            [
              "C",
              26.56000000000002,
              259.7400000000002,
              26.42000000000002,
              258.2200000000002,
              25.56000000000002,
              258.3600000000002,
            ],
            [
              "C",
              24.70000000000002,
              258.50000000000017,
              25.07000000000002,
              259.94000000000017,
              25.03000000000002,
              260.7400000000002,
            ],
            [
              "C",
              24.85000000000002,
              264.45000000000016,
              24.70000000000002,
              268.2800000000002,
              26.03000000000002,
              271.7400000000002,
            ],
            [
              "C",
              27.950000000000017,
              276.64000000000016,
              30.44000000000002,
              281.32000000000016,
              32.63000000000002,
              286.1100000000002,
            ],
            [
              "C",
              36.70000000000002,
              294.9900000000002,
              40.280000000000015,
              304.1100000000002,
              43.63000000000002,
              313.26000000000016,
            ],
            [
              "C",
              43.860000000000014,
              313.91000000000014,
              44.41000000000002,
              314.77000000000015,
              43.460000000000015,
              315.1100000000002,
            ],
            [
              "C",
              42.51000000000001,
              315.4500000000002,
              42.460000000000015,
              314.38000000000017,
              42.22000000000001,
              313.8500000000002,
            ],
            [
              "C",
              40.45000000000001,
              310.5100000000002,
              38.600000000000016,
              307.2100000000002,
              37.01000000000001,
              303.8500000000002,
            ],
            [
              "C",
              32.570000000000014,
              294.2800000000002,
              28.23000000000001,
              284.6700000000002,
              22.16000000000001,
              275.9700000000002,
            ],
            [
              "C",
              21.93000000000001,
              275.6400000000002,
              21.91000000000001,
              275.0500000000002,
              21.24000000000001,
              275.0800000000002,
            ],
            [
              "C",
              21.224359711335666,
              275.3297553813707,
              21.224359711335666,
              275.5802446186297,
              21.24000000000001,
              275.8300000000002,
            ],
            [
              "C",
              22.551337075346805,
              285.2480817058271,
              24.340309535435882,
              294.5934602286805,
              26.599999999999966,
              303.83000000000004,
            ],
            [
              "C",
              26.76000000000001,
              304.4600000000002,
              27.42000000000001,
              305.3200000000002,
              26.320000000000007,
              305.6300000000002,
            ],
            [
              "C",
              25.500000000000007,
              305.87000000000023,
              24.520000000000007,
              306.4500000000002,
              23.800000000000008,
              305.2200000000002,
            ],
            [
              "C",
              20.40000000000001,
              299.4400000000002,
              16.250000000000007,
              294.1300000000002,
              13.310000000000008,
              288.0600000000002,
            ],
            [
              "C",
              12.45433013048211,
              285.88868724728997,
              11.218316065651422,
              283.88736036423535,
              9.660000000000007,
              282.15000000000015,
            ],
            [
              "C",
              9.07263630123331,
              282.71888879462796,
              8.862792643340343,
              283.5738074008586,
              9.120000000000008,
              284.35000000000014,
            ],
            [
              "C",
              9.458562377314873,
              286.83954269728116,
              9.942725637885545,
              289.3071057977068,
              10.570000000000004,
              291.7400000000001,
            ],
            [
              "C",
              11.990000000000007,
              296.92000000000013,
              14.470000000000008,
              301.8200000000001,
              14.570000000000007,
              307.3200000000001,
            ],
            [
              "C",
              14.746137575154197,
              308.02584418571985,
              14.746137575154197,
              308.76415581428034,
              14.570000000000007,
              309.4700000000001,
            ],
            [
              "C",
              12.980000000000008,
              307.8200000000001,
              12.020000000000007,
              305.86000000000007,
              10.160000000000007,
              304.4700000000001,
            ],
            [
              "C",
              9.850000000000007,
              306.7700000000001,
              9.780000000000006,
              308.7100000000001,
              10.850000000000007,
              310.7200000000001,
            ],
            [
              "C",
              13.078146980475912,
              315.1037620809345,
              14.944356685337848,
              319.6622635643158,
              16.430000000000007,
              324.3500000000001,
            ],
            [
              "C",
              16.60000000000001,
              324.87000000000006,
              17.110000000000007,
              325.5900000000001,
              16.28000000000001,
              325.9000000000001,
            ],
            [
              "C",
              15.620000000000008,
              326.1500000000001,
              15.470000000000008,
              325.36000000000007,
              15.210000000000008,
              324.9000000000001,
            ],
            [
              "C",
              12.921690327069669,
              321.09997449397264,
              10.89693109885741,
              317.1473504560599,
              9.15000000000002,
              313.0700000000001,
            ],
            [
              "C",
              8.830000000000009,
              312.3500000000001,
              8.71000000000001,
              311.3100000000001,
              7.530000000000009,
              311.5000000000001,
            ],
            [
              "C",
              5.7200000000000095,
              311.8000000000001,
              4.530000000000009,
              311.23000000000013,
              4.250000000000009,
              309.29000000000013,
            ],
            [
              "C",
              4.1495688155110075,
              308.8786343413002,
              3.9345935671012167,
              308.50416132794123,
              3.6300000000000088,
              308.21000000000015,
            ],
            [
              "C",
              2.980000000000009,
              307.53000000000014,
              2.3000000000000087,
              307.42000000000013,
              1.7200000000000089,
              308.32000000000016,
            ],
            [
              "C",
              1.3004552593841605,
              308.90847663671406,
              1.0481853138201476,
              309.59962717250585,
              0.9900000000000089,
              310.32000000000016,
            ],
            [
              "C",
              0.9900000000000089,
              310.97000000000014,
              0.7500000000000089,
              311.50000000000017,
              -0.009999999999991127,
              311.53000000000014,
            ],
            [
              "C",
              -0.009999999999991127,
              317.35000000000014,
              -0.009999999999991127,
              323.16000000000014,
              -0.009999999999991127,
              328.98000000000013,
            ],
            [
              "C",
              -0.009999999999991127,
              330.34000000000015,
              0.30000000000000887,
              330.64000000000016,
              1.6500000000000088,
              330.64000000000016,
            ],
            ["Q", 177.53, 330.58000000000015, 353.42, 330.64000000000016],
            [
              "C",
              353.42,
              327.28000000000014,
              353.42,
              323.92000000000013,
              353.42,
              320.57000000000016,
            ],
            [
              "C",
              353.49075472051146,
              320.02564764588305,
              353.37816120929585,
              319.4732357314812,
              353.1,
              319,
            ],
            ["Z"],
            ["M", 58.53, 316.6],
            [
              "C",
              58.04,
              316.95000000000005,
              57.78,
              316.45000000000005,
              57.53,
              316.15000000000003,
            ],
            [
              "C",
              55.358638818001936,
              313.5917928954502,
              53.78389715467315,
              310.5823625149889,
              52.92,
              307.34000000000003,
            ],
            [
              "C",
              52.71,
              306.52000000000004,
              52.92,
              306.22,
              53.510000000000005,
              306.34000000000003,
            ],
            [
              "C",
              53.68984492996514,
              306.52156935934454,
              53.85691059605929,
              306.7153655320138,
              54.010000000000005,
              306.92,
            ],
            [
              "C",
              55.89775356817809,
              309.6158650295009,
              57.424081909083114,
              312.5474912701819,
              58.55000000000001,
              315.64000000000004,
            ],
            ["C", 58.68, 315.94, 58.86, 316.36, 58.53, 316.6],
            ["Z"],
            ["M", 71.53, 300.14000000000004],
            [
              "C",
              72.51892416977269,
              300.14286715582784,
              73.44290786595326,
              300.6329151890248,
              74,
              301.45,
            ],
            [
              "C",
              72.96577463697332,
              301.6159795900922,
              71.94524987946279,
              301.08335511505686,
              71.49,
              300.14,
            ],
            ["Z"],
            ["M", 74.38, 327.25000000000006],
            [
              "C",
              74.06,
              327.36000000000007,
              73.89999999999999,
              327.00000000000006,
              73.86,
              326.70000000000005,
            ],
            [
              "C",
              73.72850574699693,
              326.25272592531417,
              73.88782897627162,
              325.7707731567582,
              74.26,
              325.49000000000007,
            ],
            [
              "C",
              74.7,
              325.49000000000007,
              74.82000000000001,
              325.74000000000007,
              74.84,
              326.05000000000007,
            ],
            ["C", 74.83, 326.52, 74.89, 327.07, 74.34, 327.25],
            ["Z"],
            ["M", 87.21, 328.20000000000005],
            [
              "C",
              86.67999999999999,
              328.20000000000005,
              86.66999999999999,
              327.69000000000005,
              86.66,
              327.28000000000003,
            ],
            [
              "C",
              86.65,
              326.87,
              86.66,
              326.49,
              87.14999999999999,
              326.53000000000003,
            ],
            [
              "C",
              87.63999999999999,
              326.57000000000005,
              87.72999999999999,
              327.01000000000005,
              87.75999999999999,
              327.43,
            ],
            [
              "C",
              87.78999999999999,
              327.84999999999997,
              87.55,
              328.2,
              87.17,
              328.2,
            ],
            ["Z"],
            ["M", 89.75, 296.89000000000004],
            [
              "C",
              103.42348729780525,
              307.60428629946216,
              120.23064243316209,
              313.5438681874221,
              137.59999999999997,
              313.80000000000007,
            ],
            ["C", 128.69, 319, 98.23, 308.22, 89.71, 296.89],
            ["Z"],
            ["M", 92.75, 327.56000000000006],
            [
              "C",
              92.4,
              326.56000000000006,
              92.81,
              326.08000000000004,
              93.18,
              325.21000000000004,
            ],
            [
              "C",
              93.43790351133104,
              325.5822848088959,
              93.53250242726597,
              326.0437800110424,
              93.44186836326584,
              326.48750928270965,
            ],
            [
              "C",
              93.35123429926573,
              326.9312385543769,
              93.08323727184275,
              327.3186716063907,
              92.7,
              327.56,
            ],
            ["Z"],
            ["M", 106.53, 319],
            [
              "C",
              106.02271687858884,
              318.48598402108377,
              105.9674556536001,
              317.67831996355596,
              106.4,
              317.1,
            ],
            ["C", 107.13, 317.65, 107, 318.18, 106.53, 319],
            ["Z"],
            ["M", 151.64, 323.77],
            [
              "C",
              139.0036946805243,
              325.76812896904295,
              126.07784611479116,
              324.8514506373106,
              113.84999999999997,
              321.09,
            ],
            [
              "C",
              113.57,
              321.01,
              113.28999999999999,
              320.9,
              113.00999999999999,
              320.78999999999996,
            ],
            [
              "C",
              112.72999999999999,
              320.67999999999995,
              112.27,
              320.48999999999995,
              112.30999999999999,
              320.08,
            ],
            [
              "C",
              112.30999999999999,
              319.49,
              112.88999999999999,
              319.58,
              113.30999999999999,
              319.57,
            ],
            [
              "C",
              115.51999999999998,
              319.57,
              117.73999999999998,
              319.57,
              119.96,
              319.57,
            ],
            ["C", 130.54, 319.81, 141.07999999999998, 320.71, 151.6, 321.86],
            ["C", 152.29, 321.93, 153.19, 321.8, 153.15, 322.86],
            ["C", 153.11, 323.92, 152.26, 323.65, 151.64, 323.74],
            ["Z"],
            ["M", 224, 304.85],
            ["L", 228.73, 301.77000000000004],
            ["C", 227.27, 304.47, 226.19, 305.2, 224, 304.85],
            ["Z"],
            ["M", 262.71, 297.08000000000004],
            [
              "C",
              263.14311381634326,
              297.1766619690172,
              263.46071616723555,
              297.5471980450582,
              263.48999999999995,
              297.99000000000007,
            ],
            [
              "C",
              263.55999999999995,
              298.2900000000001,
              263.48999999999995,
              298.64000000000004,
              263.0799999999999,
              298.57000000000005,
            ],
            [
              "C",
              262.73030101144235,
              298.4741331155304,
              262.48330965243235,
              298.1623571377637,
              262.4699999999999,
              297.80000000000007,
            ],
            [
              "C",
              262.3902106723118,
              297.53340355743734,
              262.4862106723118,
              297.24540355743727,
              262.71,
              297.08,
            ],
            ["Z"],
            ["M", 274.59, 315.19000000000005],
            [
              "C",
              270.94576342616,
              317.696693340332,
              266.934744010091,
              319.62292722306995,
              262.7,
              320.90000000000003,
            ],
            [
              "C",
              262.28999999999996,
              321.03000000000003,
              261.75,
              321.40000000000003,
              261.45,
              320.79,
            ],
            [
              "C",
              261.25,
              320.37,
              261.72999999999996,
              320.19,
              262.03,
              320.03000000000003,
            ],
            ["Q", 270.39, 315.37, 278.77, 310.74],
            [
              "C",
              278.9825907809966,
              310.66510602355186,
              279.2048871461618,
              310.6213203758678,
              279.43,
              310.61,
            ],
            ["L", 279.71, 310.96000000000004],
            [
              "C",
              278.23037767791664,
              312.6242443919118,
              276.50350991418094,
              314.05093397015435,
              274.59,
              315.19,
            ],
            ["Z"],
            ["M", 328.12, 309.9200000000001],
            [
              "C",
              327.77,
              309.0900000000001,
              327.85,
              308.50000000000006,
              328.61,
              308.19000000000005,
            ],
            [
              "C",
              328.74437148660684,
              308.1141831299043,
              328.9082167698005,
              308.11249101539335,
              329.04412542861417,
              308.1855165634126,
            ],
            [
              "C",
              329.1800340874278,
              308.2585421114319,
              329.2690565988948,
              308.3961034887456,
              329.28000000000003,
              308.55000000000007,
            ],
            ["C", 329.33, 309.36, 328.73, 309.61, 328.12, 309.92],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: 34.83,
          top: 12.41,
          width: 13.09,
          height: 101.38,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 213.41, 279.28],
            ["C", 214.99, 279.46, 214.93, 278.14, 215.12, 277.09],
            [
              "C",
              217.21163193461427,
              265.3667331422645,
              218.706888817081,
              253.544858415262,
              219.60000000000002,
              241.66999999999985,
            ],
            [
              "C",
              220.34,
              232.42999999999995,
              220.89,
              223.16999999999996,
              221.6,
              213.91999999999996,
            ],
            ["Q", 222.89, 196.91999999999996, 224.69, 179.91999999999996],
            [
              "C",
              224.76,
              179.24999999999997,
              225.3,
              178.01999999999995,
              224.43,
              177.91999999999996,
            ],
            [
              "C",
              223.56,
              177.81999999999996,
              223.81,
              179.13999999999996,
              223.68,
              179.81999999999996,
            ],
            [
              "C",
              220.73000000000002,
              196.38999999999996,
              217.93,
              212.97999999999996,
              215.43,
              229.61999999999995,
            ],
            [
              "C",
              213.68951939001,
              240.67308883616516,
              212.60097127021396,
              251.8190201977573,
              212.16999999999996,
              263.0000000000001,
            ],
            [
              "C",
              212.04,
              267.18,
              211.57999999999998,
              271.37,
              212.01999999999998,
              275.26,
            ],
            ["C", 211.95, 275.92, 211.89999999999998, 276.26, 211.89, 276.64],
            ["C", 211.85, 277.87, 211.88, 279.12, 213.41, 279.28],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -81.04,
          top: 47.75,
          width: 12.87,
          height: 66.13,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 97.4, 279.33],
            [
              "C",
              98.61,
              279.58,
              98.94000000000001,
              278.78999999999996,
              99.2,
              277.85999999999996,
            ],
            ["Q", 101.39, 269.96999999999997, 102.89, 261.93999999999994],
            [
              "C",
              105.24,
              249.09999999999994,
              106.41,
              236.10999999999996,
              107.89,
              223.15999999999994,
            ],
            [
              "C",
              108.21,
              220.24999999999994,
              108.46,
              217.32999999999993,
              108.78,
              214.41999999999993,
            ],
            [
              "C",
              108.84,
              213.90999999999994,
              108.95,
              213.41999999999993,
              108.32000000000001,
              213.26999999999992,
            ],
            [
              "C",
              107.69000000000001,
              213.11999999999992,
              107.78,
              213.80999999999992,
              107.62,
              214.1599999999999,
            ],
            [
              "C",
              107.54479951811156,
              214.38915847624082,
              107.48467112461822,
              214.6229911176038,
              107.44,
              214.8599999999999,
            ],
            [
              "C",
              105.11,
              222.6699999999999,
              103.2,
              230.5799999999999,
              101.33,
              238.5099999999999,
            ],
            [
              "C",
              98.32410595189751,
              250.6652780026922,
              96.53859447381188,
              263.0901599393145,
              96.00000000000001,
              275.6,
            ],
            ["C", 96, 275.84000000000003, 96, 276.6, 96, 277.40000000000003],
            [
              "C",
              95.89692941122368,
              277.8454797846744,
              95.99190294385585,
              278.31376582536893,
              96.26038699280936,
              278.6838902642834,
            ],
            [
              "C",
              96.52887104176287,
              279.0540147031979,
              96.94453340630238,
              279.28967672074714,
              97.4,
              279.33,
            ],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: 48.43,
          top: 59.38,
          width: 7.86,
          height: 59.92,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 233.26, 226],
            ["C", 233.26, 225.59, 233.45, 225.07, 232.91, 224.92],
            [
              "C",
              232.14,
              224.70999999999998,
              232.28,
              225.44,
              232.14,
              225.82999999999998,
            ],
            [
              "C",
              232.03315178184076,
              226.1733166861438,
              231.95291432347375,
              226.5243555664995,
              231.89999999999998,
              226.88,
            ],
            [
              "C",
              230.99999999999997,
              231.51,
              230.07999999999998,
              236.14,
              229.18999999999997,
              240.78,
            ],
            [
              "C",
              227.11999999999998,
              251.67000000000002,
              225.18999999999997,
              262.57,
              225.45999999999998,
              273.71,
            ],
            [
              "C",
              225.4331441219026,
              276.88282586638,
              225.58672534681403,
              280.05461203302946,
              225.91999999999996,
              283.21,
            ],
            [
              "C",
              225.87035521672803,
              283.71736642618384,
              226.09079564661695,
              284.21353128380815,
              226.500585775439,
              284.51677597913647,
            ],
            [
              "C",
              226.91037590426103,
              284.8200206744648,
              227.44930795373486,
              284.88579145156893,
              227.92,
              284.69,
            ],
            ["C", 228.92, 284.46999999999997, 228.92, 283.41, 229.04, 282.69],
            [
              "C",
              230.65296092130694,
              273.93387005991895,
              231.6056433015721,
              265.068909805662,
              231.88999999999996,
              256.17,
            ],
            [
              "C",
              232.2,
              249.53000000000003,
              232.35,
              242.89000000000001,
              232.64,
              236.25,
            ],
            ["C", 232.74, 232.82, 233, 229.42, 233.26, 226],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -114,
          top: 83.16,
          width: 10.63,
          height: 35.31,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 64.38, 261.07],
            [
              "C",
              65.48851898314878,
              268.593811173825,
              67.72004355021059,
              275.90862735286447,
              70.99999999999999,
              282.77,
            ],
            [
              "C",
              71.31,
              283.40999999999997,
              71.53,
              284.2,
              72.52,
              283.91999999999996,
            ],
            [
              "C",
              72.91734745336935,
              283.86572316575774,
              73.26628826629387,
              283.6283554085392,
              73.4627147994735,
              283.2787161794795,
            ],
            [
              "C",
              73.65914133265314,
              282.92907695041976,
              73.68034514153725,
              282.50758728081894,
              73.52,
              282.14,
            ],
            [
              "C",
              73.47644429383635,
              281.90197614535356,
              73.47644429383635,
              281.6580238546464,
              73.52,
              281.41999999999996,
            ],
            [
              "C",
              72.88,
              270.21,
              69.58999999999999,
              260.05999999999995,
              65.33,
              250.17999999999995,
            ],
            [
              "C",
              65.05,
              249.53999999999996,
              65.02,
              248.44999999999996,
              63.89,
              248.69999999999996,
            ],
            [
              "C",
              62.760000000000005,
              248.94999999999996,
              62.99,
              249.97999999999996,
              63.08,
              250.76999999999995,
            ],
            ["C", 63.47, 254.21, 63.85, 257.65, 64.38, 261.07],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -91.81,
          top: 101.46,
          width: 5.27,
          height: 20.34,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 90, 287.24],
            ["C", 90.66, 287, 90.44, 286.35, 90.36, 285.73],
            ["C", 90, 280, 89.62, 274.22, 88, 268.59],
            ["C", 87.8, 267.9, 87.86, 266.9, 86.72, 266.96],
            ["C", 85.58, 267.02, 85.72, 267.96, 85.57, 268.7],
            ["C", 84.42, 275, 86, 280.81, 89, 286.32],
            ["C", 89.19, 286.73, 89.24, 287.52, 90, 287.24],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -134.25,
          top: 91.96,
          width: 9.13,
          height: 23.94,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 44.27, 264.75],
            [
              "C",
              45.57202877720507,
              270.21769520146165,
              47.58942023819531,
              275.48981155284946,
              50.27000000000001,
              280.43,
            ],
            ["C", 50.5, 280.86, 50.59, 281.58, 51.35, 281.36],
            ["C", 51.800000000000004, 281.23, 51.92, 280.79, 51.88, 280.36],
            ["C", 51.88, 280.24, 51.88, 280.12, 51.88, 280],
            [
              "C",
              50.37136601811023,
              272.35429893327284,
              47.71463368611345,
              264.9808552656625,
              43.99999999999999,
              258.13,
            ],
            ["C", 43.82, 257.78, 43.63, 257.27, 43.09, 257.53],
            [
              "C",
              42.550000000000004,
              257.78999999999996,
              42.800000000000004,
              258.23999999999995,
              42.88,
              258.64,
            ],
            ["C", 43.35, 260.68, 43.77, 262.72, 44.27, 264.75],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: 55.02,
          top: 107.13,
          width: 7.86,
          height: 17.69,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 232.36, 290.2],
            [
              "C",
              233.04000000000002,
              290.58,
              233.36,
              289.94,
              233.64000000000001,
              289.57,
            ],
            [
              "C",
              237.16500173780045,
              285.1544719262684,
              239.3233960078065,
              279.8054078658186,
              239.85000000000002,
              274.18,
            ],
            [
              "C",
              239.91000000000003,
              273.57,
              239.94000000000003,
              272.90000000000003,
              239.24,
              272.67,
            ],
            ["C", 238.54, 272.44, 238.24, 273.15000000000003, 237.96, 273.67],
            ["C", 234.96, 278.42, 233.35, 283.72, 232.08, 289.14000000000004],
            ["C", 232, 289.56, 231.94, 290, 232.36, 290.2],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: 96.18,
          top: 108.36,
          width: 6.61,
          height: 6.85,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 278.16, 273.9],
            [
              "C",
              275.76252377249665,
              274.5629786268999,
              273.8878353686553,
              276.43387211494394,
              273.22,
              278.83,
            ],
            [
              "C",
              273.04,
              279.91999999999996,
              273.38000000000005,
              280.77,
              274.68,
              280.7,
            ],
            ["C", 276.61, 280.59, 279.87, 276.92, 279.78000000000003, 274.94],
            ["C", 279.64, 274.08, 279.11, 273.73, 278.16, 273.9],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -1.55,
          top: 8.27,
          width: 5.19,
          height: 58.94,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 179.94, 174.16],
            ["C", 179.76, 175.03, 179.57, 175.91, 179.44, 176.79],
            ["Q", 177.07, 192.33, 176, 208],
            ["C", 175.45, 215.77, 175.4, 223.56, 175.47, 231.35],
            ["C", 175.47, 231.87, 175.32, 232.65, 175.99, 232.71],
            ["C", 176.66000000000003, 232.77, 176.56, 231.94, 176.61, 231.46],
            [
              "C",
              177.49,
              222.76000000000002,
              178.18,
              214.05,
              178.73000000000002,
              205.33,
            ],
            ["Q", 179.38000000000002, 195.33, 179.99, 185.26000000000002],
            [
              "C",
              180.22,
              181.51000000000002,
              180.42000000000002,
              177.76000000000002,
              180.64000000000001,
              173.77,
            ],
            ["C", 180.12, 174.05, 180, 174.09, 179.94, 174.16],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: 16.18,
          top: -134.25,
          width: 10.72,
          height: 3.53,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 194.26, 34.56],
            [
              "C",
              195.14999999999998,
              34.56,
              196.04,
              34.690000000000005,
              197.26,
              34.78,
            ],
            [
              "C",
              199.1629723672234,
              34.68537871647814,
              201.05204556343665,
              34.404027389382556,
              202.89999999999998,
              33.94,
            ],
            [
              "C",
              203.36999999999998,
              33.8,
              203.82999999999998,
              33.66,
              203.89999999999998,
              33.05,
            ],
            [
              "C",
              203.96999999999997,
              32.44,
              203.52999999999997,
              32.14,
              202.98,
              31.919999999999998,
            ],
            [
              "C",
              199.9272340327076,
              30.73278465056014,
              196.48435326010653,
              31.137388702182463,
              193.79,
              33,
            ],
            ["C", 193.45, 33.21, 193.03, 33.47, 193.23999999999998, 34],
            [
              "C",
              193.43602192684224,
              34.37521950041024,
              193.83819830598404,
              34.59602221837044,
              194.26,
              34.56,
            ],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -11.12,
          top: -128.77,
          width: 10.06,
          height: 4.81,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 167.12, 41.52],
            [
              "C",
              169.81067029623262,
              41.27746516682476,
              172.4085795267626,
              40.41491822862508,
              174.71,
              39,
            ],
            ["C", 175.29000000000002, 38.63, 176.13, 38.35, 175.9, 37.42],
            [
              "C",
              175.67000000000002,
              36.49,
              174.74,
              36.88,
              174.06,
              36.730000000000004,
            ],
            [
              "C",
              171.2496016067221,
              37.01357541217999,
              168.60211055713455,
              38.18323415630995,
              166.5,
              40.07000000000001,
            ],
            [
              "C",
              166.2,
              40.32000000000001,
              165.75,
              40.56000000000001,
              165.91,
              41.07000000000001,
            ],
            ["C", 166.07, 41.580000000000005, 166.66, 41.57, 167.12, 41.52],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -35.63,
          top: -119.42,
          width: 6.46,
          height: 3.84,
          fill: "#DD3527",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 142.78, 49.89],
            [
              "C",
              144.48106996806527,
              49.99676482562118,
              146.14137447671385,
              49.34070631116621,
              147.31,
              48.1,
            ],
            [
              "C",
              147.7803316315636,
              47.79478095137429,
              147.9619955848939,
              47.19486743107411,
              147.74,
              46.68,
            ],
            ["C", 147.51000000000002, 46.14, 147.05, 46.1, 146.55, 46.08],
            [
              "C",
              144.97304665772288,
              46.02414106452017,
              143.44525527281473,
              46.633806035219855,
              142.34,
              47.76,
            ],
            [
              "C",
              141.89000000000001,
              48.18,
              141.13,
              48.51,
              141.45000000000002,
              49.33,
            ],
            ["C", 141.77000000000004, 50.15, 142.55, 49.85, 142.78, 49.89],
            ["Z"],
          ],
        },
      ],
    },
    {
      type: "group",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: -0.92,
      top: -1.58,
      width: 86,
      height: 77,
      fill: "rgb(0,0,0)",
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.62,
      scaleY: 1.62,
      angle: 0,
      flipX: false,
      flipY: true,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      objects: [
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -43.5,
          top: -39,
          width: 78.31,
          height: 51.84,
          fill: "rgb(54, 153, 206)",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 75.81, 3.8],
            [
              "C",
              75.36,
              3.6199999999999997,
              74.60000000000001,
              3.4,
              74.13,
              3.2199999999999998,
            ],
            ["C", 73.65999999999998, 3.0399999999999996, 72.42, 3.5, 72, 3.14],
            [
              "C",
              72.24,
              1.2400000000000002,
              77.06,
              1.9000000000000001,
              77.13,
              0.08000000000000007,
            ],
            [
              "C",
              76.41,
              -0.3299999999999999,
              72.72,
              0.9400000000000001,
              71.69999999999999,
              1.08,
            ],
            [
              "C",
              69.60999999999999,
              1.34,
              68.28999999999999,
              1.35,
              66.28999999999999,
              1.5,
            ],
            [
              "C",
              64.10999999999999,
              1.66,
              61.78999999999999,
              1.45,
              59.44999999999999,
              1.5,
            ],
            [
              "C",
              51.85083023535576,
              1.7712578498236204,
              44.292531329374825,
              2.7386397735740617,
              36.86999999999989,
              4.390000000000029,
            ],
            ["C", 34, 5, 29.09, 6.93, 26.12, 5.65],
            ["C", 24.53, 5, 23.77, 3.32, 22, 2.81],
            [
              "C",
              20.446045509096546,
              2.4985421653308,
              18.85147885619891,
              2.4444890584529126,
              17.279999999999987,
              2.65,
            ],
            ["C", 11.94, 2.92, 6.65, 4.52, 1.41, 6],
            ["L", 0, 6.37],
            ["L", 0, 51.84],
            [
              "C",
              2.8875221654563976,
              50.773958940916394,
              5.832063204693794,
              49.869230344960215,
              8.8199999999999,
              49.13000000000001,
            ],
            ["C", 16.32, 47.32, 24.17, 46.13, 31.97, 44.040000000000006],
            [
              "C",
              39.28,
              42.09,
              46.89,
              41.53000000000001,
              53.78,
              37.790000000000006,
            ],
            [
              "C",
              55.370000000000005,
              36.93000000000001,
              57.14,
              35.650000000000006,
              58.71,
              34.870000000000005,
            ],
            [
              "C",
              59.71,
              34.370000000000005,
              61.410000000000004,
              34.09,
              62.3,
              33.34,
            ],
            [
              "C",
              63.18999999999999,
              32.59,
              63.47,
              31.67,
              64.24,
              30.730000000000004,
            ],
            [
              "C",
              64.86999999999999,
              29.950000000000003,
              66.80999999999999,
              29.430000000000003,
              67.16,
              28.780000000000005,
            ],
            [
              "C",
              67.77,
              27.670000000000005,
              66.78999999999999,
              26.970000000000006,
              66.78999999999999,
              26.110000000000007,
            ],
            [
              "C",
              66.78999999999999,
              26.390000000000008,
              66.78999999999999,
              24.28000000000001,
              66.78999999999999,
              24.600000000000005,
            ],
            [
              "C",
              66.74,
              23.950000000000006,
              67.63,
              23.310000000000006,
              67.22999999999999,
              22.660000000000004,
            ],
            [
              "C",
              66.58999999999999,
              21.600000000000005,
              61.80999999999999,
              22.240000000000002,
              61.73999999999999,
              20.760000000000005,
            ],
            [
              "C",
              62.07999999999999,
              20.660000000000004,
              63.44999999999999,
              20.180000000000007,
              62.999999999999986,
              19.760000000000005,
            ],
            [
              "C",
              62.109999999999985,
              18.960000000000004,
              58.679999999999986,
              19.650000000000006,
              57.51999999999998,
              19.830000000000005,
            ],
            [
              "C",
              57.37999999999998,
              18.710000000000004,
              58.33999999999998,
              19.220000000000006,
              59.06999999999998,
              18.530000000000005,
            ],
            [
              "C",
              56.56999999999998,
              17.170000000000005,
              64.69999999999997,
              15.110000000000005,
              65.50999999999998,
              14.900000000000006,
            ],
            [
              "C",
              65.91999999999997,
              14.790000000000006,
              71.71999999999997,
              12.800000000000006,
              71.38999999999997,
              12.420000000000005,
            ],
            [
              "C",
              71.05999999999997,
              12.040000000000004,
              69.64999999999998,
              12.700000000000005,
              69.62999999999997,
              11.900000000000006,
            ],
            [
              "C",
              70.81999999999996,
              11.500000000000005,
              75.15999999999997,
              10.450000000000006,
              75.67999999999996,
              9.420000000000005,
            ],
            [
              "C",
              75.08999999999996,
              8.880000000000006,
              71.05999999999996,
              9.990000000000006,
              71.36999999999996,
              8.940000000000005,
            ],
            [
              "C",
              71.85999999999996,
              7.230000000000005,
              77.79999999999995,
              7.760000000000005,
              78.27999999999996,
              5.760000000000005,
            ],
            ["C", 78.57, 4.59, 76.88, 4.22, 75.81, 3.8],
            ["Z"],
          ],
        },
        {
          type: "path",
          version: "5.2.1",
          originX: "left",
          originY: "top",
          left: -43.5,
          top: -13.34,
          width: 86.06,
          height: 52.21,
          fill: "rgb(221, 53, 39)",
          stroke: null,
          strokeWidth: 1,
          strokeDashArray: null,
          strokeLineCap: "butt",
          strokeDashOffset: 0,
          strokeLineJoin: "miter",
          strokeUniform: false,
          strokeMiterLimit: 4,
          scaleX: 1,
          scaleY: 1,
          angle: 0,
          flipX: false,
          flipY: false,
          opacity: 1,
          shadow: null,
          visible: true,
          backgroundColor: "",
          fillRule: "nonzero",
          paintFirst: "fill",
          globalCompositeOperation: "source-over",
          skewX: 0,
          skewY: 0,
          path: [
            ["M", 85.2, 47.44],
            [
              "C",
              84.92,
              47.96,
              84.92,
              48.059999999999995,
              85.5,
              47.879999999999995,
            ],
            [
              "C",
              85.536682435722,
              47.676071933164586,
              85.40323452039968,
              47.48034832402517,
              85.2,
              47.44,
            ],
            ["Z"],
            ["M", 86.06, 25.659999999999997],
            ["Z"],
            ["M", 84.7, 27.93],
            [
              "C",
              84.946584677933,
              27.067231811880326,
              85.41558261562156,
              26.284419077650163,
              86.06,
              25.66,
            ],
            ["C", 84.43, 26.13, 82.18, 27.25, 80.58, 27.26],
            ["C", 79.21, 27.26, 79.5, 26.82, 78.07, 27.26],
            [
              "C",
              77.13999999999999,
              25.540000000000003,
              72.94,
              29.6,
              71.71,
              28.26,
            ],
            [
              "C",
              72.29361674389412,
              27.65598831624641,
              72.92573042580928,
              27.10079852154844,
              73.6,
              26.599999999999998,
            ],
            [
              "C",
              73.17999999999999,
              26.990000000000002,
              70.14,
              27.720000000000002,
              69.47,
              27.93,
            ],
            [
              "C",
              68.42545899440692,
              28.375197951044438,
              67.24454100559308,
              28.375197951044438,
              66.2,
              27.93,
            ],
            ["C", 66.2, 27.93, 64.59, 27.99, 64.3, 27.93],
            ["C", 63.04, 27.86, 65.08, 27.259999999999998, 62.3, 27.93],
            ["L", 60.79, 28.39],
            ["C", 60.66, 28.45, 60.62, 28.55, 60.67, 28.71],
            [
              "C",
              57.035019748159,
              30.05746002576729,
              53.29134912598346,
              31.09123502331173,
              49.47999999999999,
              31.79999999999999,
            ],
            [
              "C",
              44.16,
              32.8,
              39.199999999999996,
              33.8,
              33.92999999999999,
              34.6,
            ],
            ["C", 22.64, 36.37, 11.56, 39.43, 0, 42.43],
            ["L", 0, 77.87],
            ["L", 30.05, 77.87],
            [
              "C",
              31.11,
              77.27000000000001,
              32.14,
              76.63000000000001,
              33.21,
              75.99000000000001,
            ],
            ["C", 34.28, 75.35000000000001, 35, 74.72, 36, 74.16],
            [
              "C",
              37.00067029250382,
              73.6320707148374,
              37.93973773524705,
              72.9948463786902,
              38.8,
              72.25999999999999,
            ],
            [
              "C",
              42.699999999999996,
              69.21,
              49.53,
              68.91,
              54.43,
              67.41999999999999,
            ],
            [
              "C",
              58.70303220822801,
              66.19438075104296,
              62.90027953762856,
              64.71849903298803,
              67.00000000000001,
              63,
            ],
            ["C", 69.83, 61.77, 72.36, 60.44, 75, 59],
            [
              "C",
              76.07207908387714,
              58.492411999949184,
              77.07929574375378,
              57.8578655042269,
              78,
              57.11,
            ],
            ["C", 78.21, 56.92, 79, 56.74, 79.09, 56.36],
            ["C", 79.39, 54.96, 78.3, 55.66, 77.55, 55.43],
            ["C", 77.28, 54.8, 81, 52.27, 81.3, 52],
            [
              "C",
              82.4025801077385,
              50.9181147841219,
              83.40655115556189,
              49.74012208800912,
              84.3,
              48.48,
            ],
            ["C", 83.97, 47.709999999999994, 82.11, 49.08, 81.39, 49],
            ["C", 79.97, 48.86, 81.51, 48, 81.24, 47.71],
            [
              "C",
              80.96999999999998,
              47.42,
              80.35,
              48.04,
              79.91,
              47.800000000000004,
            ],
            [
              "C",
              80.53,
              46.64000000000001,
              82.78999999999999,
              46.300000000000004,
              82.97999999999999,
              45.260000000000005,
            ],
            [
              "C",
              82.97999999999999,
              45.080000000000005,
              80.88999999999999,
              45.440000000000005,
              80.88,
              45.43000000000001,
            ],
            [
              "C",
              79.61999999999999,
              44.67000000000001,
              81.14999999999999,
              44.43000000000001,
              81.03999999999999,
              43.99000000000001,
            ],
            [
              "C",
              80.72999999999999,
              42.89000000000001,
              81.49,
              43.290000000000006,
              80.03999999999999,
              43.73000000000001,
            ],
            [
              "C",
              79.32,
              43.95000000000001,
              73.1,
              46.640000000000015,
              72.49999999999999,
              46.06000000000001,
            ],
            [
              "C",
              71.28999999999999,
              44.88000000000001,
              74.12999999999998,
              43.63000000000001,
              75.00999999999999,
              43.17000000000001,
            ],
            [
              "C",
              76.29721175189688,
              42.51297034790068,
              77.54894374258396,
              41.788634769289764,
              78.75999999999999,
              41.00000000000001,
            ],
            [
              "C",
              79.48524338301368,
              40.49281479199908,
              80.10646747077924,
              39.851222701355965,
              80.59,
              39.11,
            ],
            ["C", 81.24000000000001, 38.33, 83, 37.49, 83.42, 36.67],
            ["C", 83.68, 36.14, 80.92, 37.510000000000005, 80.68, 37.59],
            [
              "C",
              80.32000000000001,
              36.410000000000004,
              83.47000000000001,
              34.910000000000004,
              83.44000000000001,
              34.88,
            ],
            [
              "C",
              82.9027363168247,
              34.3652041969016,
              82.6848481280554,
              33.60068423630758,
              82.87000000000002,
              32.88,
            ],
            [
              "C",
              83.19000000000001,
              31.12,
              84.23000000000002,
              31.590000000000003,
              85.38000000000002,
              30.310000000000002,
            ],
            ["C", 86.9, 28.62, 85, 29.1, 84.7, 27.93],
            ["Z"],
          ],
        },
      ],
    },
    {
      type: "i-text",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 119.06,
      top: 128.53,
      width: 255.66,
      height: 45.2,
      fill: "#000000",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 0.47,
      scaleY: 0.5,
      angle: 358.64,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "arial",
      fontWeight: "",
      fontSize: 40,
      text: "Be Part of Our",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "left",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [
        {
          start: 0,
          end: 8,
          style: {
            opacity: 1,
            strokeWidth: 0,
          },
        },
        {
          start: 8,
          end: 10,
          style: {
            stroke: "#000000",
            opacity: 1,
            strokeWidth: 0,
          },
        },
        {
          start: 10,
          end: 14,
          style: {
            opacity: 1,
            strokeWidth: 0,
          },
        },
      ],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 68.07,
      top: 151.22,
      width: 85.56,
      height: 22.6,
      fill: "#dd3527",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 2.48,
      scaleY: 6.12,
      angle: 0.49,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "arial",
      fontWeight: "bold",
      fontSize: 20,
      text: "MAY",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "center",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "#fffff4",
      charSpacing: 0,
      styles: [
        {
          start: 0,
          end: 3,
          style: {
            stroke: "#dd3527",
          },
        },
      ],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 87.07,
      top: 379.9,
      width: 146.02,
      height: 22.6,
      fill: "#dd3527",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.15,
      scaleY: 1.63,
      angle: 1.04,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "bold",
      fontSize: 20,
      text: "CELEBRATION",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "left",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 95.72,
      top: 491.29,
      width: 66.4,
      height: 22.6,
      fill: "#000000",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.35,
      scaleY: 1.8,
      angle: 359.34,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "",
      fontSize: 20,
      text: "May 1",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "left",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 79.84,
      top: 271.54,
      width: 69.41,
      height: 22.6,
      fill: "#d36dcb",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 2.53,
      scaleY: 4.85,
      angle: 359.38,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "bold",
      fontSize: 20,
      text: "DAY",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "center",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [
        {
          start: 0,
          end: 3,
          style: {
            fill: "#dd3527",
          },
        },
      ],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
  ],
  background: "#fffff4",
};
export const SampleJson1 = {
  version: "5.2.1",
  objects: [
    {
      type: "circle",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 564,
      top: 287,
      width: 100,
      height: 100,
      fill: "#a47173",
      stroke: "#e12323",
      strokeWidth: 4,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.4,
      scaleY: 1.4,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      radius: 50,
      startAngle: 0,
      endAngle: 360,
    },
    {
      type: "image",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 604.38,
      top: 311.2,
      width: 720,
      height: 1080,
      fill: "rgb(0,0,0)",
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 0.09,
      scaleY: 0.09,
      angle: 2,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      cropX: 0,
      cropY: 0,
      src: "http://fabricjs.com/assets/pug.jpg",
      crossOrigin: null,
      filters: [],
    },
    {
      type: "rect",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 121,
      top: 73,
      width: 50,
      height: 50,
      fill: "#b4aa48",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 6.1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
    },
    {
      type: "i-text",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 138.93,
      top: 82.77,
      width: 400.08,
      height: 45.2,
      fill: "#ca18a6",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 0.66,
      scaleY: 0.78,
      angle: -1,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "",
      fontSize: 40,
      text: "BUSINESS WEBINAR",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "left",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "#000000",
      charSpacing: 0,
      styles: [],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
    },
    {
      type: "circle",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 726,
      top: -33,
      width: 100,
      height: 100,
      fill: "#fbcd70",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      radius: 50,
      startAngle: 0,
      endAngle: 360,
    },
    {
      type: "polygon",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 688,
      top: 385,
      width: 385,
      height: 245,
      fill: "#223b41",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 185,
          y: 0,
        },
        {
          x: 250,
          y: 100,
        },
        {
          x: 385,
          y: 170,
        },
        {
          x: 0,
          y: 245,
        },
      ],
    },
    {
      type: "polygon",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: -271,
      top: 75,
      width: 385,
      height: 245,
      fill: "#f5cb07",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 185,
          y: 0,
        },
        {
          x: 250,
          y: 100,
        },
        {
          x: 385,
          y: 170,
        },
        {
          x: 0,
          y: 245,
        },
      ],
    },
    {
      type: "rect",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 389,
      top: 352,
      width: 50,
      height: 50,
      fill: "#c2c1c2",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 3.83,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 341.95,
      top: 349.77,
      width: 300,
      height: 48.82,
      fill: "#000000",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1.01,
      scaleY: 1.01,
      angle: 1.42,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "",
      fontSize: 20,
      text: "With\nDrew Fig",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "center",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
    {
      type: "textbox",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 62.49,
      top: 426.54,
      width: 300,
      height: 22.6,
      fill: "#f18956",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0.92,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      fontFamily: "helvetica",
      fontWeight: "",
      fontSize: 20,
      text: "Register Now",
      underline: false,
      overline: false,
      linethrough: false,
      textAlign: "left",
      fontStyle: "normal",
      lineHeight: 1.16,
      textBackgroundColor: "",
      charSpacing: 0,
      styles: [],
      direction: "ltr",
      path: null,
      pathStartOffset: 0,
      pathSide: "left",
      pathAlign: "baseline",
      minWidth: 20,
      splitByGrapheme: false,
    },
  ],
  background: "#050a30",
};
/* export const SampleJson = {
  version: "5.2.1",
  objects: [
    {
      type: "image",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 107,
      top: 56,
      width: 600,
      height: 600,
      fill: "rgb(0,0,0)",
      stroke: null,
      strokeWidth: 0,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 0.68,
      scaleY: 0.68,
      angle: -3,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      cropX: 0,
      cropY: 0,
      src: "http://fabricjs.com/assets/printio.png",
      crossOrigin: null,
      filters: [],
    },
    {
      type: "rect",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 560,
      top: 301,
      width: 80,
      height: 80,
      fill: {
        type: "linear",
        coords: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0,
        },
        colorStops: [
          {
            offset: 1,
            color: "rgb(0,0,0)",
            opacity: 1,
          },
          {
            offset: 0,
            color: "rgb(255,255,255)",
            opacity: 1,
          },
        ],
        offsetX: 0,
        offsetY: 0,
        gradientUnits: "percentage",
        gradientTransform: [1, 0, 0, 1, 0, 0],
      },
      stroke: "blue",
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 4,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
    },
    {
      type: "rect",
      version: "5.2.1",
      originX: "left",
      originY: "top",
      left: 60,
      top: 431,
      width: 50,
      height: 50,
      fill: "#494f5e",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeDashOffset: 0,
      strokeLineJoin: "miter",
      strokeUniform: false,
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
    },
  ],
};
 */

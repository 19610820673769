import { useContext } from "react";
import "./Navbar.scss";
import { CanvasContext } from "../../Canvas/store/CanvasContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faFileDownload,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import Toolbox from "../Toolbox/Toolbox";
import DownloadFilter from "../../Projects/DownloadFilter";
import ShareFilter from "../../Projects/ShareFilter";

function Navbar() {
  const { canvas } = useContext(CanvasContext);
  const propertiesToInclude = ["id", "selectable"];

  const handleSave = async () => {
    if (!canvas) {
      return null;
    }
    const c = canvas;

    // const json = c.toJSON(propertiesToInclude); // propertiesToInclude optional
    // let dataURL = c.toDataURL("image/jpeg", 1.0);
    // console.log(json);
    // console.log(canvas.toSVG());
    //     canvas.item(0).sourcePath = '/assets/dragon.svg';
    // console.log(JSON.stringify(canvas.toDatalessJSON()));

    const json = canvas.toDatalessJSON(["clipPath", "eraser"]);
    const out = JSON.stringify(json, null, "\t");
    const blob = new Blob([out], { type: "text/plain" });
    const clipboardItemData = { [blob.type]: blob };
    try {
      navigator.clipboard &&
        (await navigator.clipboard.write([
          new ClipboardItem(clipboardItemData),
        ]));
    } catch (error) {
      console.log(error);
    }
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = "eraser_example.json";
    a.click();
    URL.revokeObjectURL(blobURL);
  };

  const downloadImage = () => {
    const ext = "png";
    const base64 = canvas.toDataURL({
      format: ext,
      enableRetinaScaling: true,
    });
    const link = document.createElement("a");
    link.href = base64;
    link.download = `eraser_example.${ext}`;
    link.click();
  };

  const downloadSVG = () => {
    const svg = canvas.toSVG();
    const a = document.createElement("a");
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const blobURL = URL.createObjectURL(blob);
    a.href = blobURL;
    a.download = "eraser_example.svg";
    a.click();
    URL.revokeObjectURL(blobURL);
  };
  return (
    <div className="navbar h-40 ">
      <div className="inline-flex w-80 mr-2  ">
        <Link
          to={"/dashboard/postGrid"}
          className=" rounded-3xl mt-4  py-1 w-14 h-12 text-2xl text-center text-white    active:bg-blue-500 outline-none"
        >
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className="text-gray-600"
          ></FontAwesomeIcon>
        </Link>
        <p className="w-80 ml-[52px]">
          <img src={logo} className=" w-[172px] h-[75px]" />
        </p>
        {/* <a href="#" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
    Messages
  </a> */}
      </div>

      <div className="grid grid-cols-6 w-full border-l-[3px] border-[#F0F0F0] ">
        <div className=" col-span-5   ">
          <Toolbox></Toolbox>
        </div>
        <div className="col-span-1 items-end content-end float-right text-right pr-5 pt-2">
          {/*  <button className="navshare ">
            <FontAwesomeIcon icon={faFileDownload} color="black" />
          </button>
          <button className="navshare">
            <FontAwesomeIcon icon={faShare} color="black" />
          </button> */}
          <DownloadFilter></DownloadFilter>
          <ShareFilter></ShareFilter>
        </div>
        {/* <button className="btn-primary" onClick={handleSave}>
          Json
        </button>
        <button className="btn-primary" onClick={downloadSVG}>
          Svg
        </button>
        <button className="btn-primary" onClick={downloadImage}>
          Png
        </button> */}
      </div>
    </div>
  );
}

export default Navbar;

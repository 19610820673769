import React from "react";
import PostGridItem from "./PostGridItem";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faDownload,
  faGreaterThan,
  faLessThan,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import DownloadFilter from "./DownloadFilter";
import ShareFilter from "./ShareFilter";

function ProjectAllPostEdit(props) {
  const n = 11; // Or something else
  const tableRows = [...Array(n)].map((e, i) => (
    <td className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
      <input
        type="text"
        name="lname"
        placeholder=""
        className="w-48 basis-1/2 block  text-sm py-1 px-4   border outline-none  focus:outline-none  placeholder:text-slate-600	"
      />
    </td>
  ));
  const post_items = 5; // Or something else
  const PostGridItems = (
    <div className="grid grid-cols-5 gap-2 mr-8 my-6">
      <PostGridItem title="Linkedin Post" height="62"></PostGridItem>
      <PostGridItem title="Facebook Story" height="62"></PostGridItem>
      <PostGridItem title="Facebook Post" height="62"></PostGridItem>
      <PostGridItem title="Instagram Story" height="62"></PostGridItem>
      <PostGridItem title="Instagram Post" height="62"></PostGridItem>
    </div>
  );
  return (
    <div className="px-2 py-5">
      <div>
        <div className="text-left mt-1">
          <Link
            to={"/dashboard/PostGrid"}
            type="submit"
            className="text-center rounded-3xl block py-1 px-2 w-24 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} className="mr-2" /> Back
          </Link>
        </div>
        <div className="grid grid-cols-3 gap-6 my-2">
          <div>
            <h1 className="font-semibold text-[19px] py-4 px-3">
              1. Happy May Day
            </h1>
          </div>
          <div>
            <div className="font-medium text-[10px] items-center text-center  py-5">
              <span className="inline-block ">
                <FontAwesomeIcon icon={faLessThan} className="mr-4" />
                <span className="mx-2">Back</span> 1 / 3
                <span className="mx-2">Next</span>
                <FontAwesomeIcon icon={faGreaterThan} className="ml-2" />
              </span>
            </div>
          </div>
          <div className="pr-8">
            <div className="float-right text-right">
              <DownloadFilter></DownloadFilter>
              <ShareFilter></ShareFilter>
              {/* <button className=" rounded-full  px-1 py-2 mx-1  border  w-10 text-sm bg-[#E6E9EF]   hover:bg-blue-700 active:bg-blue-500 outline-none">
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </button>
              <button className=" rounded-full  px-1 py-2 border bg-[#E6E9EF]  w-10 text-sm   hover:bg-blue-700 active:bg-blue-500 outline-none">
                <FontAwesomeIcon icon={faShareNodes}></FontAwesomeIcon>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {PostGridItems}
      <div className="relative overflow-x-auto">
        <table className="w-full my-8 text-[12px]  dark:text-gray-400 border-separate [border-spacing:0.1rem] ">
          <thead className="text-[10px] text-left text-gray-800  font-normal bg-[#E6E9EF] ">
            <tr>
              <th scope="col" className="px-2 py-2 font-semibold ">
                S.No:
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Post Type
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Title
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Description
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Post Description
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Hash Tags
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Phone Number
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Email
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Address
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Features
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Services
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Products
              </th>
              <th scope="col" className="px-2 py-2 font-semibold ">
                Remarks
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="01"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Facebook Post"
                  className="w-48 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="02"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Facebook Story"
                  className="w-48 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="03"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Instagram Story"
                  className="w-48 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="04"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Instagram Post"
                  className="w-48 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="05"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Twitter Post"
                  className="w-48 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td className="w-48  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="06"
                  className="w-14 basis-1/2 block  text-[12px] py-1    border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              <td className=" font-medium text-gray-900 whitespace-nowrap dark:text-white">
                <input
                  type="text"
                  name="lname"
                  placeholder="Facebook Post"
                  value="Twitter Story"
                  className="w-48 basis-1/2 block  text-[12px] py-1 px-4   border outline-none  focus:outline-none  placeholder:text-slate-600	"
                />
              </td>
              {tableRows}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProjectAllPostEdit;

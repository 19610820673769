import Canvas from "../../Canvas/Canvas";
import "./CanvasArea.scss";
import { SampleJson } from "./SampleJson";
function CanvasArea() {
  return (
    <div className="canvasarea">
      {/* jsonData={SampleJson} width={816} height={144} */}
      <Canvas jsonData={SampleJson} width={590} height={580}></Canvas>
    </div>
  );
}

export default CanvasArea;

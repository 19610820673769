import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import api from "../api/axios";

export const useWelcomeDesigns = create(
  devtools(
    immer((set) => ({
      designsData: [
        {
          id: "1",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg",
        },
        {
          id: "2",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg",
        },
        {
          id: "3",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg",
        },
        {
          id: "4",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg",
        },
        {
          id: "5",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-5.jpg",
        },
        {
          id: "6",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-6.jpg",
        },
        {
          id: "7",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-7.jpg",
        },
        {
          id: "8",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-8.jpg",
        },
        {
          id: "9",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-9.jpg",
        },
        {
          id: "10",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-10.jpg",
        },
        {
          id: "11",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-11.jpg",
        },
        {
          id: "12",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg",
        },
        {
          id: "13",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg",
        },
        {
          id: "14",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg",
        },
        {
          id: "15",
          name: "https://flowbite.s3.amazonaws.com/docs/gallery/square/image-4.jpg",
        },
      ],
      getApi: async () => {
        const apiResponse = await api.get("welcome_designs.php");
        set((state) => {
          console.log(apiResponse.data.data);
          state.designsData = apiResponse.data.data;
        });
      },
    }))
  )
);

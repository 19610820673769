import {
  faArrowRight,
  faArrowUpRightDots,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import Checkbox from "../Utils/Checkbox";

function AccountView(props) {
  const setProfileDetails = useStore((state) => state.setProfileDetails);
  const [lselected, lsetSelected] = React.useState([]);
  const [langselected, langsetSelected] = React.useState([]);
  function handlelSelect(value, name) {
    if (value) {
      // if true
      lsetSelected([...lselected, name]); // add to selected
    } else {
      // if false
      // console.log(value);
      lsetSelected(lselected.filter((item) => item !== name)); // remove from selected
    }
  }
  function handlelangSelect(value, name) {
    if (value) {
      // langsetSelected([]); // unselect all
      // if true
      langsetSelected([name]); // add to selected
    } else {
      // if false
      // console.log(value);
      langsetSelected(langselected.filter((item) => item !== name)); // remove from selected
    }
  }

  let instade_for_list_items;
  const instade_for_list = useStore((state) => state.using_instade_for_profile);
  instade_for_list_items = instade_for_list.map((item, index) => (
    <li className="">
      <Checkbox
        name={item.name}
        value={lselected.includes(item.name)}
        updateValue={handlelSelect}
      >
        {item.name}
      </Checkbox>
      {/* <input
        type="checkbox"
        id={item.id}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={item.id}
        className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="block w-full ">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label> */}
    </li>
  ));

  let language_items;
  const language_list = useStore((state) => state.languages);
  language_items = language_list.map((item, index) => (
    <li className="">
      <Checkbox
        name={item.name}
        value={langselected.includes(item.name)}
        updateValue={handlelangSelect}
      >
        {item.name}
      </Checkbox>
      {/* <input
        type="checkbox"
        id={`language${item.id}`}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={`language${item.id}`}
        className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="block w-full ">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label> */}
    </li>
  ));

  const profile_details = useStore((state) => state.profile_details);

  let navigate = useNavigate();
  const ProfileEdit = () => {
    navigate("/dashboard/ProfileEdit");
  };
  return (
    <div className="mx-2 my-8 mr-8  gap-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="font-semibold text-[20px]">Your Account</div>
        <div className="text-right">
          <button
            type="submit"
            onClick={ProfileEdit}
            className=" rounded-3xl  py-1 m-auto w-28 text-[12px]  bg-gray-50  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#e6e9ef" }}
          >
            Edit Profile
            {/* <FontAwesomeIcon icon={faArrowUpRightDots} className="ml-4" /> */}
          </button>
        </div>
      </div>
      <div className="pb-8 border-b-2">
        <div className="flex gap-20 content-center items-center ">
          <div className="mr-8">
            <h2 className="text-sm mt-8">Profile Photo</h2>

            <img
              src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
              alt="..."
              className="shadow rounded-full max-w-full h-auto align-middle border-none w-16  my-2"
            />
          </div>
          <div>
            <h5 className="font-bold text-sm">Name</h5>
            <p className="text-[10px]">{profile_details.name}</p>
          </div>
          <div>
            <h5 className="font-bold text-sm">Email Address</h5>
            <p className="text-[10px]">{profile_details.email}</p>
          </div>
          <div>
            <h5 className="font-bold text-sm">Phone Number</h5>
            <p className="text-[10px]">{profile_details.phone}</p>
          </div>
        </div>
      </div>
      <div className="pb-8 border-dotted border-b-2">
        <h2 className="font-bold my-4 text-[14px]">
          What will you be using InstaDe for?
        </h2>
        <ul className=" flex gap-1 ">{instade_for_list_items}</ul>
      </div>

      <div className="pb-8 border-b-2">
        <h2 className="font-bold my-6">Language</h2>
        <ul className=" flex gap-1 ">{language_items}</ul>
      </div>
    </div>
  );
}

export default AccountView;

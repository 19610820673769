import React from "react";
import logo from "../../assets/images/logo.png";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Icons from "../../assets/icons/Icons";
import arrowUp from "../../assets/images/arrow-up.svg";

function Menu(props) {
  return (
    <div className="container mx-auto px-48">
      {/* <div className="text-left">
        <img src={logo} className=" center h-24 w-60" />
      </div>
      <div>
        <Link to={"/"}>BG Removal</Link>
      </div> */}
      <nav className="flex items-center justify-between flex-wrap  p-2">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link to="/">
            <img src={logo} className=" center h-24 w-60" />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow text-right">
            <Link
              to="/login"
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0  mr-4 text-[#00136c] font-medium"
            >
              BG Removal
            </Link>
            <Link
              to="/login"
              href="#responsive-header"
              className=" rounded-3xl block py-3 w-32 text-sm text-white bg-blue-500 block mt-4 lg:inline-block text-center lg:mt-0  mr-4 outline-none"
            >
              Log in
            </Link>
            <Link
              // onClick={dashboardScreen}
              to="/register"
              className=" rounded-3xl block py-3 w-32 text-sm text-white bg-blue-500 block mt-4 lg:inline-block text-center lg:mt-0  mr-4 outline-none"
              style={{ backgroundColor: "#6659e3" }}
            >
              Sign up
              {/* <span className=" relative">{Icons["UpArrowIcon"].render()}</span> */}
              <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Menu;

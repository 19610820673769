import React, { useContext, useEffect, useState } from "react";
import useCoreHandler from "../../../Canvas/handlers/useCoreHandler";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { fabric } from "fabric";
import pubimg from "../../../../assets/images/pug.jpg";

function ImageToolbox(props) {
  const { setProperty, getActiveStyle } = useCoreHandler();
  const { activeObject, canvas } = useContext(CanvasContext);

  fabric.Image.filters.Redify = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    {
      type: "Redify",

      /**
       * Fragment source for the redify program
       */
      fragmentSource:
        "precision highp float;\n" +
        "uniform sampler2D uTexture;\n" +
        "varying vec2 vTexCoord;\n" +
        "void main() {\n" +
        "vec4 color = texture2D(uTexture, vTexCoord);\n" +
        "color.g = 0.0;\n" +
        "color.b = 0.0;\n" +
        "gl_FragColor = color;\n" +
        "}",

      applyTo2d: function (options) {
        var imageData = options.imageData,
          data = imageData.data,
          i,
          len = data.length;

        for (i = 0; i < len; i += 4) {
          data[i + 1] = 0;
          data[i + 2] = 0;
        }
      },
    }
  );

  fabric.Image.filters.Redify.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;

  const updateAnimation = (e) => {
    // Add image With Gray Filter
    fabric.Image.fromURL(pubimg, function (img) {
      img.filters.push(new fabric.Image.filters.Grayscale());
      img.filters.push(new fabric.Image.filters.Sepia());
      img.filters.push(new fabric.Image.filters.Redify());

      /* img.filters.push(
        new fabric.Image.filters.Sepia(),
        new fabric.Image.filters.Brightness({ brightness: 80 })
      ); */

      img.applyFilters();
      canvas.add(img);
    });
  };

  return (
    <div>
      <label htmlFor="color">Animation: </label>
      <button onClick={updateAnimation}>Add Image gray</button>
    </div>
  );
}

export default ImageToolbox;

import {
  faArrowUpRightFromSquare,
  faBars,
  faBorderAll,
  faDownload,
  faExpand,
  faGreaterThan,
  faHeart,
  faLessThan,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import laborday from "../../assets/images/laborday.png";
import BackDropModal from "../Utils/Modal";

function PostGridItem(props) {
  let navigate = useNavigate();
  const PostFullView = () => {
    navigate("/dashboard/PostFullView");
  };
  const [customDesignshow, setcustomDesignshow] = useState(false);

  const customDesignDisplay = () => {
    setcustomDesignshow((prevValue) => !prevValue);
  };
  const Editor = () => {
    navigate("/editor");
  };
  const AllPostEdit = () => {
    navigate("/dashboard/postEdit");
  };
  const [downloadpopup, setdownloadpopup] = useState(false);
  const DownloadpopupDisplay = () => {
    setdownloadpopup((prevValue) => !prevValue);
  };
  return (
    <>
      <div className="inline-grid ">
        <div className="group relative w-[230px]">
          <img
            alt="example"
            className={`w-full object-contain h-${props.height}`}
            src={laborday}
          />
          <button
            type="button"
            className=" absolute top-1    text-center content-center  items-center  mx-16 w-24 p-1 text-[10px] font-normal text-white bg-[#6659E3]  rounded-full"
          >
            1080 X 1920
          </button>
          <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-grey-900 opacity-0 group-hover:h-full group-hover:opacity-80  group-hover:bg-black">
            <div
              className="inline-flex gap-1 rounded-full shadow-sm"
              role="group"
            >
              <button
                type="button"
                onClick={DownloadpopupDisplay}
                className="inline-flex items-center  px-2 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full hover:bg-blue-100"
                title="Download"
              >
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </button>
              <button
                type="button"
                onClick={Editor}
                className="inline-flex items-center  px-2 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full hover:bg-blue-100"
                title="Edit Image"
              >
                <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
              </button>
              <button
                type="button"
                onClick={customDesignDisplay}
                className="inline-flex items-center  px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full hover:bg-blue-100"
                title="Full screen"
              >
                <FontAwesomeIcon icon={faExpand}></FontAwesomeIcon>
              </button>
            </div>
          </div>
        </div>
        <h2 className="font-bold text-[12px] text-center my-3">
          {props.title}
        </h2>
      </div>

      {customDesignshow && (
        <BackDropModal
          onConfirmed={customDesignDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0   rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
            {/*body*/}
            <div className="relative p-6  flex-auto ">
              <button
                className="absolute right-2 top-2 items-center text-center w-10 px-2 py-1 rounded-full hover:text-white   hover:bg-[#6659E3]   text-black
                 bg-[#E6E9EF]  float-right  text-2xl"
                onClick={() => customDesignDisplay(false)}
              >
                <span className="">×</span>
              </button>
              <img src={laborday} className="w-fit h-fit md:h-[550px] " />
            </div>
            {/*footer*/}
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={customDesignDisplay}
          ></div>
        </BackDropModal>
      )}

      {downloadpopup && (
        <BackDropModal
          onConfirmed={DownloadpopupDisplay}
          className="items-center justify-center  "
        >
          <div className="z-50 w-80 border-0 p-5 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between  border-b-[1px] border-solid border-slate-300 rounded-t">
              <h3 className="text-[16px] font-semibold">Download File</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                onClick={() => DownloadpopupDisplay(false)}
              >
                <span className="   h-6 w-6 text-[18px] block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative  flex-auto">
              <form className="">
                <h2 className="p-6 text-[12px] text-center ">
                  Do you want to Download This File?
                </h2>
                <div className="">
                  <div className="grid pb-5">
                    <label className="float-left pb-2 text-left text-[10px] font-semibold ">
                      File type
                    </label>
                    <select className="w-full border-[1px] border-gray-300  py-2 text-[10px] rounded-sm text-gray-500 hover:border-[#6659E3] ">
                      <option value="">PDF</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex  p-2  rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => DownloadpopupDisplay(false)}
                className=" rounded-3xl mx-auto py-2   w-24 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Proceed
                {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" /> */}
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={DownloadpopupDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default PostGridItem;

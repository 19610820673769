import React, { useState } from "react";
import whatsapp from "../../assets/images/social/whatsapp.png";
import messanger from "../../assets/images/social/messanger.png";
import googlechat from "../../assets/images/social/googlechat.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faShare,
  faShareAlt,
  faShareAltSquare,
  faShareNodes,
  faShareSquare,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
function ShareFilter(props) {
  const [shareStep, setShareStep] = useState(1);
  const [dropdownShareshow, setDropdownShareshow] = useState(false);
  const dropdownShareDisplay = () => {
    setDropdownShareshow((prevValue) => !prevValue);
  };
  return (
    <>
      <button
        id="dropdownShareButton"
        // data-dropdown-toggle="dropdownShare"
        type="button"
        onClick={dropdownShareDisplay}
        className=" rounded-full  p-1 ml-1 border  w-8 text-[15px] bg-[#E6E9EF]    active:bg-blue-500 outline-none hover:bg-blue-100"
      >
        <FontAwesomeIcon
          icon={faShareNodes}
          style={{ height: "15px" }}
        ></FontAwesomeIcon>
      </button>
      {dropdownShareshow && (
        <>
          <div
            id="dropdownShare"
            class={` absolute right-10 p-5  z-50 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
               ${dropdownShareshow ? "block" : "hidden"} `}
            // className="z-20 hidden w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700"
            aria-labelledby="dropdownShareButton"
          >
            <div className="flex items-start justify-between  border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-[15px] font-semibold">Share Post</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                onClick={() => dropdownShareDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-[18px] block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="w-64 m-auto">
              <div className="grid grid-cols-2 gap-0 items-center content-center text-center my-5">
                <div
                  className={`block ${
                    shareStep === 1
                      ? "text-[#6659E3] border-collapse border-b-2  border-b-[#6659E3]"
                      : "text-gray-200  border-b-2  border-b-[#C5C7D0]"
                  } w-full p-1 text-center cursor-pointer text-[12px] `}
                  onClick={() => setShareStep(1)}
                >
                  Select Posts
                </div>
                <div
                  className={`block ${
                    shareStep === 2
                      ? "text-[#6659E3] border-collapse border-b-2  border-b-[#6659E3]"
                      : "text-gray-200 border-b-2  border-b-[#C5C7D0]"
                  } w-full p-1 text-center cursor-pointer   text-[12px]`}
                  onClick={() => setShareStep(2)}
                >
                  Share
                </div>
              </div>
            </div>
            {shareStep === 1 && (
              <div className="  pt-1">
                <div className="grid pb-5">
                  <label className="float-left pb-2 text-left text-[10px] font-semibold ">
                    File type
                  </label>
                  <select className="w-full border-[1px] border-gray-300  py-2 text-[10px] rounded-sm text-gray-500 hover:border-[#6659E3] ">
                    <option value="">PDF</option>
                  </select>
                </div>
                <ul className=" " style={{ overflowY: "scroll" }}>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            // onChange={() => handleOnChange(0)}
                            className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          1. General designs for Mayday
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            // onChange={() => handleOnChange(1)}
                            className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          2. Generate designs for 20% ....
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-4 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            // onChange={() => handleOnChange(2)}
                            className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                          />
                          3. A warm welcome to Mon...
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right "
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image1"
                          />
                        </div>
                      </div>
                    </div>
                  </li>

                  {/*  <li className="pb-3 ">
                     <div className="flex">
                       <div className="inline-flex ">
                         <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                           <input
                             type="checkbox"
                             className="mr-4  ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                           />
                           2. Generate designs for 20% ....
                         </p>
                       </div>
                       <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                         <div className="flex-shrink-0">
                           <img
                             className="w-8 h-8 rounded-full float-right mx-4"
                             src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                             alt="Neil image2"
                           />
                         </div>
                       </div>
                     </div>
                   </li>
                   <li className="pb-3 ">
                     <div className="flex">
                       <div className="inline-flex ">
                         <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                           <input
                             type="checkbox"
                             className="mr-4 ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                           />
                           3. A warm welcome to Mon...
                         </p>
                       </div>
                       <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                         <div className="flex-shrink-0">
                           <img
                             className="w-8 h-8 rounded-full float-right mx-4"
                             src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                             alt="Neil image3"
                           />
                         </div>
                       </div>
                     </div>
                   </li> */}
                </ul>
                <div className="grid grid-cols-2 py-2 text-[12px] font-medium  text-gray-900 rounded-b-lg   dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                  <span className=" text-left py-2 text-[12px]">
                    (3) Selected
                  </span>
                  <div className="float-right items-end content-end">
                    <button
                      onClick={() => setShareStep(2)}
                      className=" rounded-3xl   py-1  w-24 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                      style={{ backgroundColor: "#6659e3" }}
                    >
                      Next
                      {/*  <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="ml-4"
                    /> */}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {shareStep === 2 && (
              <>
                <div className="text-center py-3">
                  <h2 className="text-[12px] text-gray-600">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="px-2"
                    ></FontAwesomeIcon>
                    Send via Direct Message
                  </h2>
                </div>
                <div className=" items-center text-center divide-y divide-gray-100 dark:divide-gray-700">
                  <div
                    className="inline-flex rounded-full items-center"
                    role="group"
                  >
                    <button
                      type="button"
                      className="inline-flex text-center items-center  mx-2 px-2 py-2 text-sm font-medium text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white "
                    >
                      <div>
                        <img
                          src={whatsapp}
                          className="w-30 h-6 items-center mx-auto  "
                          alt="Whatsapp"
                        />
                        <p className="text-[10px] text-gray-600">Whatsapp</p>
                      </div>
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center  mx-2 px-2 py-2 text-sm font-medium text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white "
                    >
                      <div>
                        <img
                          src={messanger}
                          className="w-30 h-6 mx-auto"
                          alt="chat"
                        />
                        <p className="text-[10px] text-gray-600">Chats</p>
                      </div>
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center  mx-2 px-2 py-2 text-sm font-medium text-gray-900 bg-white  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white "
                    >
                      <div>
                        <img
                          src={googlechat}
                          className="w-30 h-6 mx-auto"
                          alt="google chat"
                        />
                        <p className="text-[10px] text-gray-600">G Chat</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className=" block py-2 text-sm  text-center text-gray-900 rounded-b-lg   dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                  <button
                    onClick={() => setShareStep(1)}
                    className=" rounded-3xl  py-1 m-auto w-32 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                    style={{ backgroundColor: "#6659e3" }}
                  >
                    Back
                  </button>
                </div>
              </>
            )}
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={dropdownShareDisplay}
          ></div>
        </>
      )}
    </>
  );
}

export default ShareFilter;

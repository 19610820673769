import ImagesPanel from "../ImagesPanel/ImagesPanel";
import ObjectsPanel from "../ObjectsPanel/ObjectsPanel";
import TemplatesPanel from "../TemplatesPanel/TemplatesPanel";
import TextPanel from "../TextPanel/TextPanel";
import classNames from "classnames";
import "./PanelItem.scss";
import PixabayPanel from "../PixabayPanel/PixabayPanel";
import UploadsPanel from "../UploadsPanel/UploadsPanel";
import PixabayBackgroundPanel from "../PixabayPanel/PixabayBackgroundPanel";

function PanelItem({ panelOpen, activeTab }) {
  const className = classNames({
    "panel-item-container ": true,
    open: panelOpen,
  });
  // console.log(activeTab);

  return (
    <div className={className}>
      <div className="panel-item">
        {activeTab === "Uploads" && <UploadsPanel />}
        {activeTab === "text" && <TextPanel />}
        {activeTab === "images" && <ImagesPanel />}
        {activeTab === "elements" && <ObjectsPanel />}
        {activeTab === "templates" && <TemplatesPanel />}
        {activeTab === "photos" && <PixabayPanel loadcategory="love" />}
        {activeTab === "backgrounds" && (
          <PixabayBackgroundPanel loadcategory="background" />
        )}
      </div>
    </div>
  );
}

export default PanelItem;

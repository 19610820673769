import { useContext, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import {
  getBackgroundImages,
  getImage,
  getLoveImages,
} from "../../../../services/pixabay";
import { fabric } from "fabric";
function PixabayBackgroundPanel(props) {
  const [search, setSearch] = useState("");
  const [objects, setObjects] = useState([]);
  const [value] = useDebounce(search, 1000);
  const { canvas } = useContext(CanvasContext);
  console.log(props);

  useEffect(() => {
    // getImages("love")
    /*  getImages(props.loadcategory)
      .then((data) => setObjects(data))
      .catch(console.log); */
    console.log(props.loadcategory);
    if (props.loadcategory === "background") {
      getBackgroundImages()
        .then((data) => setObjects(data))
        .catch(console.log);
    }
  }, []);

  useEffect(() => {
    if (value) {
      getLoveImages(value)
        .then((data) => setObjects(data))
        .catch(console.log);
    }
  }, [value]);
  const renderItems = () => {
    return objects.map((obj) => {
      return (
        <div
          className=""
          onClick={() => downloadImage(obj.largeImageURL)}
          key={obj.id}
        >
          <img className="w-full" src={obj.previewURL} alt={obj.id} />
        </div>
      );
    });
  };
  const downloadImage = (ImageUrl) => {
    // console.log(ImageUrl);
    fabric.Image.fromURL(ImageUrl, function (img) {
      img.scaleToWidth(300);
      canvas.add(img);
    });
  };

  return (
    <div className="p-4 h-screen overflow-y-scroll">
      <div>
        <h3 className="text-[12px] text-center font-bold mb-1">Backgrounds</h3>
        <input
          onChange={(e) => setSearch(e.target.value)}
          style={{ background: "#fff" }}
          type="tel"
          placeholder="Search"
          className="w-full h-6"
        />
      </div>
      <div className="grid  grid-cols-1 gap-2 w-full pt-4  ">
        {renderItems()}
      </div>
    </div>
  );
}
export default PixabayBackgroundPanel;

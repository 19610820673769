import React from "react";
import logo from "./assets/images/logo.png";
import error404 from "./assets/images/error-404.png";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMailForward } from "@fortawesome/free-solid-svg-icons";

function NoMatch() {
  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div>
          <img src={logo} className="m-auto center h-24 w-70" />

          <div className="align-middle md:align-top pt-24">
            <div className="align-middle md:align-top pt-24">
              <img src={error404} className="m-auto center h-24 w-34" />
              <h2 className=" 	text-center my-8 text-lg	">Page Not Available</h2>
            </div>
          </div>
        </div>
        <p className="text-center absolute inset-x-0 bottom-0 text-xs">
          <Link
            to="/terms-of-use"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Terms of use
          </Link>
          |
          <Link
            to="/privacy-policy"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Privacy policy
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

export default NoMatch;

import { useCallback, useContext } from "react";
import { CanvasContext } from "../store/CanvasContext";
import { CanvasObjects } from "../CanvasObjects";

function useCoreHandler(props) {
  const { canvas, activeObject } = useContext(CanvasContext);
  // Add objects to canvas
  const addObject = useCallback(
    (options) => {
      const { type, ...textOptions } = options;
      const element = CanvasObjects[type].render(textOptions);
      const workarea = canvas
        .getObjects()
        .find((obj) => obj.id === "fabric-canvas");
      canvas.add(element);
      element.center();

      element.clipPath = workarea;
      canvas.renderAll();
    },
    [canvas]
  );

  // Update properties, optional set metadata if present
  const setProperty = useCallback(
    (property, value) => {
      if (activeObject) {
        activeObject.set(property, value);
        activeObject.setCoords();
        canvas.requestRenderAll();
      }
    },
    [activeObject, canvas]
  );

  // Core Functions
  const getActiveStyle = (styleName) => {
    if (!activeObject) {
      return "";
    }
    return activeObject.getSelectionStyles && activeObject.isEditing
      ? activeObject.getSelectionStyles()[styleName] || ""
      : activeObject[styleName] || "";
  };

  const setActiveStyle = (styleName, value) => {
    if (!activeObject) {
      return;
    }

    if (activeObject.setSelectionStyles && activeObject.isEditing) {
      let style = {};
      style[styleName] = value;
      activeObject.setSelectionStyles(style);
      activeObject.setCoords();
    } else {
      activeObject.set(styleName, value);
    }

    activeObject.setCoords();
    activeObject.canvas.renderAll();
  };

  const getActiveProp = (name) => {
    if (!activeObject) {
      return "";
    }
    return activeObject[name] || "";
  };

  const setActiveProp = (name, value) => {
    if (!activeObject) {
      return;
    }
    activeObject.set(name, value).setCoords();
    activeObject.canvas.renderAll();
  };

  const removeObjects = () => {
    canvas.getActiveObjects().forEach((obj) => {
      canvas.remove(obj);
    });
    canvas.discardActiveObject().renderAll();
  };

  const setCanvasBackgroundColor = useCallback(
    (color) => {
      canvas.backgroundColor = color;
      canvas.renderAll();

      /*  const workarea = canvas
        .getObjects()
        .find((object) => object.id === "fabric-canvas");
      if (workarea) {
        workarea.set("fill", color);
        canvas.requestRenderAll();
      } */
    },
    [canvas]
  );

  const sendBackwards = () => {
    if (!activeObject) {
      return "";
    }
    if (activeObject) {
      canvas.sendBackwards(activeObject);
    }
  };

  const sendToBack = () => {
    if (!activeObject) {
      return "";
    }
    if (activeObject) {
      canvas.sendToBack(activeObject);
    }
  };

  const bringForward = () => {
    if (!activeObject) {
      return "";
    }
    if (activeObject) {
      canvas.bringForward(activeObject);
    }
  };

  const bringToFront = () => {
    if (!activeObject) {
      return "";
    }
    if (activeObject) {
      canvas.bringToFront(activeObject);
    }
  };

  return {
    sendBackwards,
    sendToBack,
    bringForward,
    bringToFront,
    setProperty,
    addObject,
    getActiveStyle,
    setActiveStyle,
    getActiveProp,
    setActiveProp,
    removeObjects,
    setCanvasBackgroundColor,
  };
}

export default useCoreHandler;

import { useCallback } from "react";
import { useState } from "react";
import { createContext } from "react";
import { fabric } from "fabric";
import { initAligningGuidelines } from "../handlers/aligning_guidelines";
export const CanvasContext = createContext({
  canvas: null,
  setCanvas: () => {},
  initCanvas: () => {},
  loadFromJSON: () => {},
  activeObject: null,
  setActiveObject: () => {},
  zoomRatio: 1,
  setZoomRatio: () => {},
});

export const CanvasContextProvider = ({ children }) => {
  const [canvas, setCanvas] = useState(null);
  const [activeObject, setActiveObject] = useState(null);
  const [zoomRatio, setZoomRatio] = useState(1);

  const initCanvas = useCallback((el) => {
    const canvasOptions = {
      preserveObjectStacking: true,
      selection: true,
      defaultCursor: "default",
      // backgroundColor: "#ffffff",
      fireRightClick: true, // <-- enable firing of right click events
      fireMiddleClick: true, // <-- enable firing of middle click events
      stopContextMenu: true, // <--  prevent context menu from showing
    };

    let c = new fabric.Canvas(el, canvasOptions);
    initAligningGuidelines(c);
    c.renderAll();
    setCanvas(c);
  }, []);
  /*   const loadFromJSON = useCallback((el, json) => {
    console.log(json);

    let c = new fabric.Canvas(el);
    c.loadFromJSON(
      json,
      () => {
        c.renderAll.bind(c);
        c.setWidth(json.width);
        c.setHeight(json.height);
      },
      function (o, object) {
        fabric.log(o, object);
      }
    );
    c.renderAll();
    setCanvas(c);
  }, []); */

  const loadFromJSON = useCallback((el, json) => {
    const canvasOptionsJson = {
      preserveObjectStacking: true,
      selection: true,
      defaultCursor: "default",
      // backgroundColor: "#ffffff",
      fireRightClick: true, // <-- enable firing of right click events
      fireMiddleClick: true, // <-- enable firing of middle click events
      stopContextMenu: true, // <--  prevent context menu from showing
    };
    let c = new fabric.Canvas(el, canvasOptionsJson);
    initAligningGuidelines(c);
    if (c) {
      // console.log(json);
      c.loadFromJSON(json, () => {
        c.requestRenderAll();
      });
    }
    setCanvas(c);
  }, []);

  const loadFromSVG = useCallback((el, svgdata) => {
    const canvasOptionsJson = {
      preserveObjectStacking: true,
      selection: true,
      defaultCursor: "default",
      backgroundColor: "#ffffff",
    };
    let c = new fabric.Canvas(el, canvasOptionsJson);
    initAligningGuidelines(c);
    if (c) {
      // console.log(json);
      fabric.loadSVGFromString(svgdata, function (objects, options) {
        var obj = fabric.util.groupSVGElements(objects, options);
        canvas.add(obj).renderAll();
      });
    }
    setCanvas(c);
  }, []);

  return (
    <CanvasContext.Provider
      value={{
        canvas: canvas,
        initCanvas: initCanvas,
        loadFromJSON: loadFromJSON,
        activeObject: activeObject,
        setActiveObject: setActiveObject,
        setCanvas: setCanvas,
        zoomRatio: zoomRatio,
        setZoomRatio: setZoomRatio,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ContactForm from "./ContactForm";

function Contact(props) {
  return (
    <>
      <div className="clear-both">
        <section
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            background:
              "rgb(201,191,229) linear-gradient(90deg, rgba(201,191,229,1) 26%, rgba(255,227,227,1) 89%)",
          }}
        >
          {/* banner screen */}
          <div className="text-center text-black pb-20">
            <h1 className="font-extrabold text-4xl  pb-4 stroke-2 pt-20	">
              Contact our team
            </h1>
            <p className=" pb-4">
              Empower everyone in your team to design anything. Connect with us
              today to learn more<br></br> about how instaDe can help you grow
              your business.
            </p>
          </div>
        </section>
        <section className="container mx-auto px-40 py-20 ">
          {/* capabilites section */}
          <div className="grid grid-cols-2 gap-5">
            <div className="">
              <ul className=" space-y-5 text-sm list-inside  ">
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  Access the world’s easiest drag-and-drop design tool.
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  Design & collaborate together from any device, anywhere in the
                  world.
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  Ensure brand consistency with built controls and workflows for
                  approvals.
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  Discover the perfect personalized plan for your needs.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-5"> Teams empowered by instaDe</h3>
              <div className="container   ">
                <div className="-m-1 flex flex-wrap gap-0">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="">
                      <img
                        alt="gallery"
                        className="block h-20 w-full p-2 "
                        src="https://d3.harvard.edu/platform-rctom/wp-content/uploads/sites/4/2018/11/Sony-Music-Logo-794x200.png"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="">
                      <img
                        alt="gallery"
                        className="block h-20 w-full p-2"
                        src="https://upload.wikimedia.org/wikipedia/commons/8/8f/Logo-engie.svg"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="">
                      <img
                        alt="gallery"
                        className="block h-20 w-full p-2"
                        src="https://static.brandirectory.com/logos/lpll001_lpl_financial.jpg"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="">
                      <img
                        alt="gallery"
                        className="block h-20 w-full p-2"
                        src="https://s3-eu-west-1.amazonaws.com/tpd/logos/5d17b80c4d01c600010f0d4d/0x0.png"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="">
                      <img
                        alt="gallery"
                        className="block h-20 w-full p-2"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Ingram_Micro_logo_new.svg/2560px-Ingram_Micro_logo_new.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactForm></ContactForm>
      </div>
    </>
  );
}

export default Contact;

import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import { Outlet, useNavigate } from "react-router";
import ProjectList from "../components/Projects/ProjectList";
import ProfileMenu from "../components/User/ProfileMenu";
import useStore from "../store/store";
import { Link } from "react-router-dom";

function DashboardLayout(props) {
  let navigate = useNavigate();
  const projects_list = useStore((state) => state.projects_list);
  const redirectHome = () => {
    return navigate("/");
  };
  return (
    <>
      <div className="grid grid-cols-6   h-screen relative   overflow-hidden">
        <div
          className="col-span-1 w-56  "
          style={{ backgroundColor: "#F0F0F0" }}
        >
          <img
            src={logo}
            onClick={redirectHome}
            className=" h-[32px] pl-4 mb-9 mt-6 hover:cursor-pointer"
            alt="logo"
          />
          <ProjectList projects_list={projects_list}></ProjectList>
          <ProfileMenu></ProfileMenu>
        </div>
        <div className="col-span-5  h-screen overflow-y-auto">
          <Outlet></Outlet>
          <div className="py-1"></div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;

import {
  faClose,
  faCross,
  faGreaterThan,
  faLessThan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";
import TextArea from "../Utils/TextArea";
import Select from "../Utils/Select";

function ContactDetails(props) {
  const [phoneFiledShow, setPhoneFieldShow] = useState(false);
  const [emailFieldShow, setEmailShow] = useState(false);
  let navigate = useNavigate();
  let countries_items;
  const countries = useStore((state) => state.contactform_countries);
  countries_items = countries.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  let state_items;
  const states = useStore((state) => state.contactform_states);
  state_items = states.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  const re = /^[0-9\b]+$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const onlyNumbers = /^[0-9]*$/;

  const isNotEmpty = (value) => value.trim() !== "";
  const isTest = (value) => {
    return true;
  };
  const isAlphabet = (value) => {
    if (re.test(value)) {
      return false;
    }
    return true;
  };

  const isValidMobile = (value) => {
    if (value.length !== 10) {
      return false;
    }
    if (!onlyNumbers.test(value)) {
      return false;
    }
    return true;
  };
  const {
    value: countryCodeValue,
    isValid: countryCodeIsValid,
    hasError: countryCodeHasError,
    valueChangeHandler: countryCodeChangeHandler,
    inputBlurHandler: countryCodeBlurHandler,
    reset: resetcountryCode,
  } = useInput(isTest);
  const {
    value: phoneNumberValue,
    isValid: phoneNumberIsValid,
    hasError: phoneNumberHasError,
    valueChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: resetphoneNumber,
  } = useInput(isNotEmpty);
  const {
    value: countryCode1Value,
    isValid: countryCode1IsValid,
    hasError: countryCode1HasError,
    valueChangeHandler: countryCode1ChangeHandler,
    inputBlurHandler: countryCode1BlurHandler,
    reset: resetcountryCode1,
  } = useInput(isTest);
  const {
    value: phoneNumber1Value,
    isValid: phoneNumber1IsValid,
    hasError: phoneNumber1HasError,
    valueChangeHandler: phoneNumber1ChangeHandler,
    inputBlurHandler: phoneNumber1BlurHandler,
    reset: resetphoneNumber1,
  } = useInput(isTest);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetemail,
  } = useInput(isTest);
  const {
    value: email1Value,
    isValid: email1IsValid,
    hasError: email1HasError,
    valueChangeHandler: email1ChangeHandler,
    inputBlurHandler: email1BlurHandler,
    reset: resetemail1,
  } = useInput(isNotEmpty);

  const {
    value: AddressValue,
    isValid: AddressIsValid,
    hasError: AddressHasError,
    valueChangeHandler: AddressChangeHandler,
    inputBlurHandler: AddressBlurHandler,
    reset: resetAddress,
  } = useInput(isTest);

  /*   const {
    value: StateValue,
    isValid: StateIsValid,
    hasError: StateHasError,
    valueChangeHandler: StateChangeHandler,
    inputBlurHandler: StateBlurHandler,
    reset: resetState,
  } = useInput(isNotEmpty);

  const {
    value: CountryValue,
    isValid: CountryIsValid,
    hasError: CountryHasError,
    valueChangeHandler: CountryChangeHandler,
    inputBlurHandler: CountryBlurHandler,
    reset: resetCountry,
  } = useInput(isNotEmpty); */

  let formIsValid = false;

  if (phoneNumberIsValid && emailIsValid) {
    formIsValid = true;
  }
  console.log(phoneNumberValue);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      phoneNumberBlurHandler();

      return;
    }

    console.log("Submitted!");

    const contact_details = {
      country_phonecode: countryCodeValue,
      phone_number: phoneNumberValue,
      country_phonecode1: countryCode1Value,
      phone_number1: phoneNumber1Value,
      email: emailValue,
      email1: email1Value,
      address: AddressValue,
    };
    console.log("submitted");
    props.contactDetailssubmit(contact_details);
    props.handleNext();
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const AddPhoneNumberField = () => {
    setPhoneFieldShow(true);
  };
  const removePhoneNumberField = () => {
    setPhoneFieldShow(false);
  };

  const AddEmailField = () => {
    setEmailShow(true);
  };
  const removeEmailField = () => {
    setEmailShow(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-28 w-80 items-center m-auto">
        <h3 className="text-xl mb-6 font-semibold text-center">
          <span className=" border-l-[3px] border-[#6659E3] px-4 text-center w-16">
            Contact Details
          </span>
        </h3>
        <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
          <div className="w-36 h-12 mr-2">
            {/* <div className="relative mb-4">
                <input
                  type="text"
                  id="country_phonecode"
                  name="country_phonecode"
                  value={inputFields.country_phonecode}
                  onChange={handleChange}
                  className={
                    errors.country_phonecode
                      ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                      : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  }
                  title={errors.country_phonecode ? errors.country_phonecode : ""}
                />
                <label
                  htmlFor="country_phonecode"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  style={{ backgroundColor: "#FBFBFD" }}
                ></label>
              </div> */}
            {/* <Input
                type="text"
                id="country_phonecode"
                name="country_phonecode"
                value={countryCodeValue}
                onChange={countryCodeChangeHandler}
                onBlur={countryCodeBlurHandler}
                // isValid={countryCodeIsValid}
                error={countryCodeHasError}
                isMandate={false}
                showtickMarks={false}
              ></Input> */}
            <Select
              id="country_phonecode"
              name="country_phonecode"
              value={countryCodeValue}
              onChange={countryCodeChangeHandler}
              onBlur={countryCodeBlurHandler}
              // isValid={countryCodeIsValid}
              error={countryCodeHasError}
              isMandate={false}
              showtickMarks={false}
            >
              <option value=""></option>
              <option value="91" selected="selected">
                +91
              </option>
            </Select>
          </div>

          <div className="w-full h-12 ">
            {/* <div className="relative mb-4">
                <input
                  type="text"
                  id="phone_number_id"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  name="phone_number"
                  value={inputFields.phone_number}
                  onChange={handleChange}
                  title={errors.phone_number ? errors.phone_number : ""}
                />
                <label
                  htmlFor="phone_number_id"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  style={{ backgroundColor: "#FBFBFD" }}
                >
                  Phone Number
                </label>
              </div> */}
            <Input
              type="text"
              id="phone_number_id"
              name="phone_number_id"
              value={phoneNumberValue}
              onChange={phoneNumberChangeHandler}
              onBlur={phoneNumberBlurHandler}
              // isValid={phoneNumberIsValid}
              error={phoneNumberHasError}
              title="Phone Number"
              isMandate={false}
            ></Input>
          </div>
          <button
            className="bg-[#FBFBFD] py-2 px-3 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
            onClick={AddPhoneNumberField}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="font-bold"
            ></FontAwesomeIcon>
          </button>
        </div>
        {phoneFiledShow && (
          <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
            <div className="w-36 h-12 mr-2">
              <Select
                id="country_phonecode1"
                name="country_phonecode1"
                value={countryCode1Value}
                onChange={countryCode1ChangeHandler}
                onBlur={countryCode1BlurHandler}
                // isValid={countryCode1IsValid}
                error={countryCode1HasError}
                isMandate={false}
                showtickMarks={false}
              >
                <option value=""></option>
                <option value="91">+91</option>
              </Select>
            </div>

            <div className="w-full h-12 ">
              <Input
                type="text"
                id="phone_number_id1"
                name="phone_number_id1"
                value={phoneNumber1Value}
                onChange={phoneNumber1ChangeHandler}
                onBlur={phoneNumber1BlurHandler}
                // isValid={phoneNumber1IsValid}
                error={phoneNumber1HasError}
                title="Phone Number"
                isMandate={false}
              ></Input>
            </div>
            <button
              className="bg-[#FBFBFD] py-2 px-3 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
              onClick={removePhoneNumberField}
            >
              <FontAwesomeIcon
                icon={faClose}
                className="font-bold"
              ></FontAwesomeIcon>
            </button>
          </div>
        )}
        <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
          <div className="w-full h-12 ">
            {/* <div className="relative mb-4">
                <input
                  type="text"
                  id="email_address_id"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                  placeholder=" "
                  name="email"
                  value={inputFields.email}
                  onChange={handleChange}
                  title={errors.email ? errors.email : ""}
                />
                <label
                  htmlFor="email_address_id"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  style={{ backgroundColor: "#FBFBFD" }}
                >
                  Email Address
                </label>
              </div> */}
            <Input
              type="text"
              id="email_address_id"
              name="email_address_id"
              value={emailValue}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              // isValid={emailIsValid}
              error={emailHasError}
              title="Email Address"
              isMandate={false}
            ></Input>
          </div>
          <button
            className="bg-[#FBFBFD] py-2 px-2 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
            onClick={AddEmailField}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="font-bold"
            ></FontAwesomeIcon>
          </button>
        </div>
        {emailFieldShow && (
          <div className=" flex flex-row items-center  w-full  justify-between mb-4  ">
            <div className="w-full h-12 ">
              <Input
                type="text"
                id="email_address_id"
                name="email_address_id"
                value={email1Value}
                onChange={email1ChangeHandler}
                onBlur={email1BlurHandler}
                // isValid={emailIsValid}
                error={email1HasError}
                title="Email Address"
                isMandate={false}
              ></Input>
            </div>
            <button
              className="bg-[#FBFBFD] py-2 px-2 ml-2  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
              onClick={removeEmailField}
            >
              <FontAwesomeIcon
                icon={faClose}
                className="font-bold"
              ></FontAwesomeIcon>
            </button>
          </div>
        )}

        {/*     <div className="w-full h-12 mb-4">
          <div className="relative mb-4">
            <input
              type="text"
              id="floating_outlined"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_outlined"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              style={{ backgroundColor: "#FBFBFD" }}
            >
              Email Address
            </label>
          </div>
          <button className="bg-[#FBFBFD] py-2 px-4  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg ">
            <FontAwesomeIcon icon={faPlus} className="font-bold"></FontAwesomeIcon>
          </button>
        </div> */}

        <div className="mb-4">
          {/* <textarea
            className="w-full border border-gray-400 p-2 rounded-md"
            placeholder="Address"
          ></textarea> */}
          {/* <div className="relative mb-4">
              <textarea
                type="text"
                id="address"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                cols={3}
                style={{ resize: "none" }}
                placeholder=" "
                name="address"
                value={inputFields.address}
                onChange={handleChange}
                title={errors.address ? errors.address : ""}
              ></textarea>
              <label
                htmlFor="address"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                style={{ backgroundColor: "#FBFBFD" }}
              >
                Address
              </label>
            </div> */}
          <TextArea
            type="text"
            id="address"
            name="address"
            value={AddressValue}
            onChange={AddressChangeHandler}
            onBlur={AddressBlurHandler}
            // isValid={AddressIsValid}
            error={AddressHasError}
            title="Address"
            isMandate={false}
          ></TextArea>
        </div>
      </div>
      <div className=" text-center content-center mt-4 ">
        {props.step > 1 && (
          <button
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg mr-2 "
            onClick={props.handleBack}
          >
            <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
          </button>
        )}

        {props.step < 4 && (
          <button
            type="submit"
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              className="font-bold"
            ></FontAwesomeIcon>
          </button>
        )}
        {props.step === 4 && (
          // <Link
          //   to="/dashboard/welcome"
          //   type="submit"
          //   // className=" rounded-3xl block py-2 w-24 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
          //   className="bg-[#FBFBFD] py-2 px-2  w-24 text-white  rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
          //   style={{ backgroundColor: "#6659e3" }}
          // >
          //   Done
          //   <FontAwesomeIcon icon={faArrowUpRightDots} className="ml-2" />
          // </Link>

          <button
            type="submit"
            // onClick={VerifyDataCheck}
            // className=" rounded-3xl block py-2 w-24 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            className="bg-[#FBFBFD] py-2 px-2  w-24 text-white  rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
            style={{ backgroundColor: "#6659e3" }}
          >
            Done
          </button>
        )}
      </div>
    </form>
  );
}

export default ContactDetails;

import {
  faArrowUpRightFromSquare,
  faBars,
  faBorderAll,
  faExpand,
  faGreaterThan,
  faHeart,
  faLessThan,
  faPencil,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import BackDropModal from "../Utils/Modal";

function PostItem(props) {
  const [customDesignshow, setcustomDesignshow] = useState(false);
  const [fav, setFav] = useState(false);

  const customDesignDisplay = () => {
    setcustomDesignshow((prevValue) => !prevValue);
  };

  let navigate = useNavigate();
  const PostFullView = () => {
    navigate("/dashboard/PostFullView");
  };
  const Editor = () => {
    navigate("/editor");
  };
  const AllPostEdit = () => {
    navigate("/dashboard/postEdit");
  };

  return (
    <>
      <div className=" ">
        <div className="px-1 py-2">
          <div className="font-bold text-xs ">
            1st May 2023
            <span className="inline-block text-right float-right">
              <FontAwesomeIcon icon={faLessThan} className="mr-2" /> 1 / 3
              <FontAwesomeIcon icon={faGreaterThan} className="ml-2" />
            </span>
          </div>
        </div>
        <div className="group relative h-48  ">
          <img
            alt="example"
            className="w-full  h-48  object-center "
            // src="https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg"
            src={props.image}
          />
          {fav && (
            <button
              type="button"
              className={`absolute top-0 right-0 inline-flex items-center  mx-2 px-2 py-2  font-medium text-xl      
               rounded-full ${fav === true ? "text-orange-700" : "text-white"}`}
              onClick={() => {
                setFav((prevValue) => !prevValue);
              }}
              title="Make as favourite"
            >
              <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
            </button>
          )}
          <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-grey-200 opacity-0 group-hover:h-full group-hover:opacity-80  group-hover:bg-black">
            <button
              type="button"
              className={`absolute top-0 right-0 inline-flex items-center  mx-2 px-2 py-2  font-medium text-xl      
               rounded-full ${fav === true ? "text-orange-700" : "text-white"}`}
              onClick={() => {
                setFav((prevValue) => !prevValue);
              }}
              title="Make as favourite"
            >
              <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
            </button>
            <h1 className="text-[10px] mb-4 text-white">
              {props.id}.{props.name}
            </h1>

            <div className="inline-flex rounded-full shadow-sm" role="group">
              <button
                type="button"
                onClick={customDesignDisplay}
                className="inline-flex items-center  mr-1 px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                title="Full screen"
              >
                <FontAwesomeIcon icon={faExpand}></FontAwesomeIcon>
              </button>
              <button
                type="button"
                onClick={AllPostEdit}
                className="inline-flex items-center  mr-1 px-2 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                title="Types of posts"
              >
                <FontAwesomeIcon icon={faBorderAll}></FontAwesomeIcon>
              </button>
              <button
                type="button"
                onClick={Editor}
                className="inline-flex items-center  mr-1 px-2 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                title="Edit Image"
              >
                <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
              </button>
              <button
                type="button"
                onClick={PostFullView}
                className="inline-flex items-center  mr-1 px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                title="Details"
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {customDesignshow && (
        <BackDropModal
          onConfirmed={customDesignDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0   rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
            {/*body*/}
            <div className="relative p-6  flex-auto ">
              <button
                className="absolute right-2 top-2 items-center text-center w-10 px-2 py-1 rounded-full hover:text-white   hover:bg-[#6659E3]   text-black
                 bg-[#E6E9EF]  float-right  text-2xl"
                onClick={() => customDesignDisplay(false)}
              >
                <span className="">×</span>
              </button>
              <img src={props.image} className="w-fit h-fit md:h-[550px] " />
            </div>
            {/*footer*/}
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={customDesignDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default PostItem;

import { tabItems } from "./tabItems";
import PanelItemsListItem from "./PanelsListItem";

import "./PanelsList.scss";

function PanelItems(props) {
  const { setActiveTab, activeTab, setPanelOpen, panelOpen } = props;
  return (
    <div className="panel-items-list w-24">
      {tabItems.map((tabItem) => (
        <PanelItemsListItem
          key={tabItem.name}
          icon={tabItem.icon}
          label={tabItem.name}
          name={tabItem.name}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setPanelOpen={setPanelOpen}
          panelOpen={panelOpen}
        />
      ))}
    </div>
  );
}

export default PanelItems;

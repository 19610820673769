import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle,
  faGreaterThan,
  faLessThan,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import useInput from "../../Hooks/use-input";
import TextArea from "../Utils/TextArea";
import Input from "../Utils/Input";
import Checkbox from "../Utils/Checkbox";
import InputFile from "../Utils/InputFile";

function AboutProject(props) {
  let navigate = useNavigate();

  const isNotEmpty = (value) => value.trim() !== "";
  const isTest = (value) => {
    return true;
  };

  const {
    value: muchInfoValue,
    isValid: muchInfoIsValid,
    hasError: muchInfoHasError,
    valueChangeHandler: muchInfoChangeHandler,
    inputBlurHandler: muchInfoBlurHandler,
    reset: resetmuchInfo,
  } = useInput(isTest);
  const {
    value: keywordsValue,
    isValid: keywordsIsValid,
    hasError: keywordsHasError,
    valueChangeHandler: keywordsChangeHandler,
    inputBlurHandler: keywordsBlurHandler,
    reset: resetkeywords,
  } = useInput(isTest);
  const {
    value: projectFeaturesValue,
    isValid: projectFeaturesIsValid,
    hasError: projectFeaturesHasError,
    valueChangeHandler: projectFeaturesChangeHandler,
    inputBlurHandler: projectFeaturesBlurHandler,
    reset: resetprojectFeatures,
  } = useInput(isTest);

  const {
    value: projectGoalsValue,
    isValid: projectGoalsIsValid,
    hasError: projectGoalsHasError,
    valueChangeHandler: projectGoalsChangeHandler,
    inputBlurHandler: projectGoalsBlurHandler,
    reset: resetprojectGoals,
  } = useInput(isTest);

  const {
    value: LogoValue,
    isValid: LogoIsValid,
    hasError: LogoHasError,
    valueChangeHandler: LogoChangeHandler,
    inputBlurHandler: LogoBlurHandler,
    reset: resetLogo,
  } = useInput(isNotEmpty);

  const {
    value: LogoColorValue,
    isValid: LogoColorIsValid,
    hasError: LogoColorHasError,
    valueChangeHandler: LogoColorChangeHandler,
    inputBlurHandler: LogoColorBlurHandler,
    reset: resetLogoColor,
  } = useInput(isNotEmpty);

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Submitted!");
    const about_details = {
      much_info: muchInfoValue,
      keywords: keywordsValue,
      project_feature: projectFeaturesValue,
      project_goals: projectGoalsValue,
      logo: LogoValue,
      logo_primary_color: LogoColorValue,
    };

    props.aboutDetailssubmit(about_details);
    props.handleNext();
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    console.log(objectUrl);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      //   setErrors({ logo: false });
      return;
    }
    const allowedTypes = [
      "image/jpeg",
      "image/JPEG",
      "image/png",
      "image/PNG",
      "image/gif",
    ];
    if (!allowedTypes.includes(e.target.files[0]?.type)) {
      //   setErrors({ logo: false });
      return;
    }
    // setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // setErrors({ logo: false });
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-28 w-80 items-center m-auto">
        <h3 className="text-xl mb-6 font-semibold text-center">
          <span className=" border-l-[3px] border-[#6659E3] px-4 text-center w-16">
            About Project
          </span>
        </h3>

        <div className="mb-4">
          {/* <div className="relative mb-4">
            <textarea
              type="text"
              cols={3}
              style={{ resize: "none" }}
              placeholder=" "
              id="much_info"
              name="much_info"
              value={inputFields.much_info}
              onChange={handleChange}
              className={
                errors.much_info
                  ? "block px-2.5 pb-2.5 pt-8 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer border border-red-500"
                  : "block px-2.5 pb-2.5 pt-8 w-full text-sm text-gray-900 bg-transparent rounded-lg  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
              }
              title={errors.much_info ? errors.much_info : ""}
            ></textarea>
            <label
              htmlFor="much_info"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              style={{ backgroundColor: "#FBFBFD" }}
            >
              Write as much information as possible about project for better
              social media Creative
            </label>
          </div> */}
          <TextArea
            type="text"
            id="much_info"
            name="much_info"
            value={muchInfoValue}
            onChange={muchInfoChangeHandler}
            onBlur={muchInfoBlurHandler}
            // isValid={muchInfoIsValid}
            error={muchInfoHasError}
            title="Write as much information as possible about project for better social media Creative"
            isMandate={false}
          ></TextArea>
        </div>
        <div className="mb-4">
          {/* <div className="relative mb-4">
            <input
              type="text"
              id="keywords"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
              placeholder=" "
              name="keywords"
              value={inputFields.keywords}
              onChange={handleChange}
              title={errors.keywords ? errors.keywords : ""}
            />
            <label
              htmlFor="keywords"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              style={{ backgroundColor: "#FBFBFD" }}
            >
              Enter keywords closely related to your business
            </label>
          </div> */}
          <Input
            type="text"
            id="keywords"
            name="keywords"
            value={keywordsValue}
            onChange={keywordsChangeHandler}
            onBlur={keywordsBlurHandler}
            // isValid={keywordsIsValid}
            error={keywordsHasError}
            title="Enter keywords closely related to your business"
            isMandate={false}
          ></Input>
        </div>
        <div className="mb-4">
          {/*   <div className="relative mb-4">
            <textarea
              type="text"
              id="project_feature"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
              cols={3}
              style={{ resize: "none" }}
              placeholder=" "
              name="project_feature"
              value={inputFields.project_feature}
              onChange={handleChange}
              title={errors.project_feature ? errors.project_feature : ""}
            ></textarea>
            <label
              htmlFor="project_feature"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              style={{ backgroundColor: "#FBFBFD" }}
            >
              Project Features
            </label>
          </div> */}
          <TextArea
            type="text"
            id="project_feature"
            name="project_feature"
            value={projectFeaturesValue}
            onChange={projectFeaturesChangeHandler}
            onBlur={projectFeaturesBlurHandler}
            // isValid={projectFeaturesIsValid}
            error={projectFeaturesHasError}
            title=" Project Features"
            isMandate={false}
          ></TextArea>
        </div>

        <div className="max-w-6xl mb-4">
          <InputFile
            type="file"
            id="logo"
            name="logo"
            // onChange={onSelectFile}
            onChange={(e) => {
              onSelectFile(e);
              LogoChangeHandler(e);
            }}
            isValid={LogoIsValid}
            error={LogoHasError}
            title="Upload Logo"
            isMandate={true}
          >
            <p className="  ">
              <span className="text-left">
                Upload Logo <span style={{ color: "red" }}>*</span>
              </span>
              <FontAwesomeIcon
                icon={faUpload}
                className="text-right float-right"
              ></FontAwesomeIcon>
            </p>
          </InputFile>
        </div>
        <div className="relative mb-4 ">
          {selectedFile && <img src={preview} className="w-full" />}
        </div>
        {selectedFile && (
          <div className="relative mb-4 ">
            <div className="absolute top-2/4 right-3 grid h-6 w-6 -translate-y-2/4 place-items-center text-blue-gray-500">
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#6659E3" }}
                size="xl"
              />
            </div>
            <input
              id="logocolor"
              name="logocolor"
              value={LogoColorValue}
              onChange={LogoColorChangeHandler}
              onBlur={LogoColorBlurHandler}
              isValid={LogoColorIsValid}
              error={LogoColorHasError}
              title="Logo Primary Color"
              isMandate={true}
              readonly={true}
              className="peer h-full w-full rounded-[7px] border border-gray-300 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3] focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 "
              placeholder=" "
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-gray-800 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-300 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-300 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#6659E3] peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-[#6659E3] peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-[#6659E3] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Logo Primary Color
            </label>
          </div>
        )}

        {/* <ul className=" grid  gap-2 ">
          {props.project_goals_list.map((item, index) => (
            <li className="w-full inline-block">
              <input
                type="checkbox"
                id={`project_goals${item.id}`}
                value={item.id}
                className="hidden peer"
                required=""
                name="project_goals"
              />
              <label
                htmlFor={`project_goals${item.id}`}
                className="inline-block items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1 "
              >
                <div className="inline-block w-full ">
                  
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="mx-2 peer-checked:visible"
                  ></FontAwesomeIcon>
                  {item.name}
                </div>
              </label>
            </li>
          ))}
        </ul> */}
      </div>
      <div className=" text-center content-center mt-4 ">
        {props.step > 1 && (
          <button
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg mr-2 "
            onClick={props.handleBack}
          >
            <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
          </button>
        )}

        {props.step < 4 && (
          <button
            type="submit"
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              className="font-bold"
            ></FontAwesomeIcon>
          </button>
        )}
      </div>
    </form>
  );
}

export default AboutProject;

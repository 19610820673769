import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStore from "../../store/store";
import "./InstadeSlider.scss";

function InstadeSlider(props) {
  const images = useStore((state) => state.capabilities_slider);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div className="mx-20">
      <Slider {...settings}>
        {images.map((image, index) => {
          return (
            <div key={index} className="px-5">
              <img src={image.image} alt={`slide${index}`} />
              <p className="text-xs font-bold text-center text-black py-5">
                {image.name}
              </p>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default InstadeSlider;

import { useContext, useState } from "react";
import { fabric } from "fabric";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons";

const FabricLine = () => {
  const { canvas } = useContext(CanvasContext);
  const [options, setOptions] = useState({
    left: 100,
    top: 100,
    stroke: "black",
    hoverCursor: "default",
    strokeWidth: 6,
  });

  const addLine = () => {
    const line = new fabric.Line([100, 100, 300, 100], options);
    canvas.add(line);
  };

  return (
    <>
      <button onClick={addLine} className="text-[48px]">
        <FontAwesomeIcon
          icon={faDrawPolygon}
          className="border border-[1px]"
        ></FontAwesomeIcon>
      </button>
    </>
  );
};

export default FabricLine;

import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import api from "../api/axios";

export const useProfileDesigns = devtools(
  immer((set) => ({
    using_instade_for_profile: [
      {
        id: "p1",
        name: "Small Business",
      },
      {
        id: "p2",
        name: "Student",
      },
      {
        id: "p3",
        name: "Teacher",
      },
      {
        id: "p4",
        name: "Large Company",
      },
      {
        id: "p5",
        name: "Non-Profit or Charity",
      },
      {
        id: "p6",
        name: "Personal",
      },
      {
        id: "p7",
        name: "Digital Marketing Consultancy",
      },
    ],
    languages: [
      {
        id: "1",
        name: "English (US)",
      },
      {
        id: "2",
        name: "English (India)",
      },
      {
        id: "3",
        name: "English (Phillippines)",
      },
      {
        id: "4",
        name: "English (Australia)",
      },
      {
        id: "5",
        name: "English (United Kingdom)",
      },
    ],
    profile_details: {
      name: "Praveen Kumar",
      email: "usernamefirstname@gmail.com",
      phone: "+91 9642125869",
    },

    setProfileDetails: (profile_data) => {
      console.log("from slice", profile_data);
      set((state) => ({
        profile_details: profile_data,
      }));
    },
    getApi: async () => {
      const apiResponse = await api.get("welcome_designs.php");
      set((state) => {
        console.log(apiResponse.data.data);
        state.designsData = apiResponse.data.data;
      });
    },
  }))
);

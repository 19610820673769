import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import getToken from "../../auth/auth";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";

function Login() {
  let navigate = useNavigate();
  const onlyNumbers = /^[0-9]*$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_phone = /^[6-9]\d{9}$/;
  const re = /^[0-9\b]+$/;
  const isNotEmpty = (value) => value.trim() !== "";

  const isValidMobile = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    // if (value.length !== 10) {
    //   return false;
    // }
    // if (!onlyNumbers.test(value)) {
    //   return false;
    // }

    if (re.test(value.substring(0, 1))) {
      if (!reg_phone.test(value)) {
        return false;
      }
    } else {
      if (!reg_email.test(value)) {
        return false;
      }
    }

    return true;
  };
  const isValidPassword = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length < 8) {
      return false;
    }
    return true;
  };
  const {
    value: MobileValue,
    isValid: MobileIsValid,
    hasError: MobileHasError,
    valueChangeHandler: MobileChangeHandler,
    inputBlurHandler: MobileBlurHandler,
    reset: resetMobile,
  } = useInput(isValidMobile);
  const {
    value: PasswordValue,
    isValid: PasswordIsValid,
    hasError: PasswordHasError,
    valueChangeHandler: PasswordChangeHandler,
    inputBlurHandler: PasswordBlurHandler,
    reset: resetPassword,
  } = useInput(isValidPassword);

  let formIsValid = false;

  if (MobileIsValid && PasswordIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      MobileBlurHandler();
      PasswordBlurHandler();
      return;
    }
    console.log(MobileValue);
    return navigate("/welcome");
  };
  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const redirectHome = () => {
    return navigate("/");
  };
  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="">
          <img
            src={logo}
            onClick={redirectHome}
            className="mx-auto h-[32px] w-[220px] my-9 hover:cursor-pointer"
            alt="logo"
          />

          <div className="align-middle md:align-top md:pt-28 sm:pt-16">
            <h2 className=" font-semibold	 text-center mb-4 text-[19px]	">
              Welcome back to InstaDe
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="max-w-lg w-72">
            <div className="space-y-4">
              {/*  <input
                type="text"
                placeholder="Please Enter Email address/Phone number *"
                name="email"
                autoComplete="false"
                value={inputFields.email}
                onChange={handleChange}
                className={
                  errors.email
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.email ? errors.email : ""}
              /> */}
              <Input
                type="text"
                id="Mobile"
                name="Mobile"
                value={MobileValue}
                onChange={MobileChangeHandler}
                onBlur={MobileBlurHandler}
                isValid={MobileIsValid}
                error={MobileHasError}
                title="Email Address/Phone number"
                isMandate={true}
              ></Input>

              {/* <input
                type="password"
                placeholder="Password *"
                name="password"
                autoComplete="false"
                value={inputFields.password}
                onChange={handleChange}
                className={
                  errors.password
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.password ? errors.password : ""}
              /> */}
              <Input
                type="password"
                id="password"
                name="password"
                value={PasswordValue}
                onChange={PasswordChangeHandler}
                onBlur={PasswordBlurHandler}
                isValid={PasswordIsValid}
                error={PasswordHasError}
                title="Password"
                isMandate={true}
              ></Input>

              <p className="text-xs	 text-center  mt-4 ">
                Forgot Password?
                <Link
                  to="/forgot-password"
                  className="cursor-pointer px-2 font-semibold"
                  style={{ color: "#6659e3" }}
                >
                  Reset now
                </Link>
              </p>
            </div>
            <div className="text-center mt-4">
              <button
                type="submit"
                className="uppercase block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Log in
              </button>
              <p className="text-xs	 text-center  mt-4">
                Don't have an account?
                <Link
                  to="/register"
                  className="cursor-pointer px-2 font-semibold"
                  style={{ color: "#6659e3" }}
                >
                  Sign up
                </Link>
              </p>
              {/*  <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-gray-400"></div>
                <span className="flex-shrink mx-2 text-xs text-gray-400">
                  OR
                </span>
                <div className="flex-grow border-t border-gray-400"></div>
              </div> */}
            </div>
          </form>
          {/* <GoogleLogin
            onSuccess={responseMessage}
            text="continue_with"
            size="large"
            width="380px"
            logo_alignment="center"
            onError={errorMessage}
            className="w-96"
          /> */}
          <div className=" absolute items-center text-center content-center w-72 bottom-12">
            <p className="text-center    text-xs">
              <Link
                to="/terms-of-use"
                className="cursor-pointer px-2  text-gray-600"
              >
                Terms of use
              </Link>
              |
              <Link
                to="/privacy-policy"
                className="cursor-pointer px-2  text-gray-600"
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;

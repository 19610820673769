import {
  faDownload,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import BackDropModal from "../Utils/Modal";
import logo from "../../assets/images/logo.svg";

function DownloadFilter(props) {
  const [downloadpopup, setdownloadpopup] = useState(false);
  const DownloadpopupDisplay = () => {
    setdownloadpopup((prevValue) => !prevValue);

    const isFound = checkedState.some((element) => {
      if (element === true) {
        return true;
      }

      return false;
    });

    if (isFound) {
      // navigate("/dashboard/projectCreate");
      setDropdownDownloadshow(false);
    } else {
      alert("Please select any one design");
    }
  };

  const [dropdownDownloadshow, setDropdownDownloadshow] = useState(false);
  const dropdownDownloadDisplay = () => {
    setDropdownDownloadshow((prevValue) => !prevValue);
  };

  const [checkedState, setCheckedState] = useState(new Array(3).fill(false)); //TODO
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };
  return (
    <>
      <button
        id="dropdownDeleteButton"
        // data-dropdown-toggle="dropdownDownload"
        type="button"
        onClick={dropdownDownloadDisplay}
        className=" rounded-full  p-1 ml-1 border  w-8 text-[15px] bg-[#E6E9EF]    active:bg-blue-500 outline-none hover:bg-blue-100"
      >
        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
      </button>
      {dropdownDownloadshow && (
        <>
          <div
            id="dropdownDownload"
            // className="z-20 hidden w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700"
            class={` absolute right-16 z-50 mt-2 w-80 p-5  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
               ${dropdownDownloadshow ? "block" : "hidden"} `}
            aria-labelledby="dropdownDeleteButton"
          >
            <div className="flex items-start justify-between  border-b-[1px] pb-5 border-solid border-slate-300 rounded-t">
              <h3 className="text-md font-semibold">Download</h3>
              <button
                className="  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                onClick={() => dropdownDownloadDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-[25px] block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="  pt-6">
              <div className="grid pb-5">
                <label className="float-left pb-2 text-left text-[10px] font-semibold ">
                  File type
                </label>
                <select className="w-full border-[1px] border-gray-300  py-2 text-[10px] rounded-sm text-gray-500 hover:border-[#6659E3] ">
                  <option value="">PDF</option>
                </select>
              </div>
              <ul className=" " style={{ overflowY: "scroll" }}>
                <li className="pb-4 ">
                  <div className="flex">
                    <div className="inline-flex ">
                      <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                        <input
                          type="checkbox"
                          onChange={() => handleOnChange(0)}
                          className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                        />
                        1. General designs for Mayday
                      </p>
                    </div>
                    <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                      <div className="flex-shrink-0">
                        <img
                          className="w-8 h-8 rounded-full float-right "
                          src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                          alt="Neil image1"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="pb-4 ">
                  <div className="flex">
                    <div className="inline-flex ">
                      <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                        <input
                          type="checkbox"
                          onChange={() => handleOnChange(1)}
                          className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                        />
                        2. Generate designs for 20% ....
                      </p>
                    </div>
                    <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                      <div className="flex-shrink-0">
                        <img
                          className="w-8 h-8 rounded-full float-right "
                          src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                          alt="Neil image1"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="pb-4 ">
                  <div className="flex">
                    <div className="inline-flex ">
                      <p className="text-[12px] font-normal text-gray-600 truncate dark:text-white ">
                        <input
                          type="checkbox"
                          onChange={() => handleOnChange(2)}
                          className="ml-2 mr-2   bg-[#F0F0F0] border-[#C5C7D0] w-4 h-4 "
                        />
                        3. A warm welcome to Mon...
                      </p>
                    </div>
                    <div className=" right-0 text-base font-semibold text-gray-600 dark:text-white w-full">
                      <div className="flex-shrink-0">
                        <img
                          className="w-8 h-8 rounded-full float-right "
                          src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                          alt="Neil image1"
                        />
                      </div>
                    </div>
                  </div>
                </li>

                {/*  <li className="pb-3 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            className="mr-4  ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                          />
                          2. Generate designs for 20% ....
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right mx-4"
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image2"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="pb-3 ">
                    <div className="flex">
                      <div className="inline-flex ">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white ">
                          <input
                            type="checkbox"
                            className="mr-4 ml-2 bg-[#F0F0F0] border-[#C5C7D0]"
                          />
                          3. A warm welcome to Mon...
                        </p>
                      </div>
                      <div className=" right-0 text-base font-semibold text-gray-900 dark:text-white w-full">
                        <div className="flex-shrink-0">
                          <img
                            className="w-8 h-8 rounded-full float-right mx-4"
                            src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                            alt="Neil image3"
                          />
                        </div>
                      </div>
                    </div>
                  </li> */}
              </ul>
            </div>

            <div className=" block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg  hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
              <button
                className=" rounded-3xl block  py-1 m-auto w-28 text-[12px]  text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
                onClick={DownloadpopupDisplay}
              >
                Download
                {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-4" /> */}
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={dropdownDownloadDisplay}
          ></div>
        </>
      )}

      {downloadpopup && (
        <BackDropModal
          onConfirmed={DownloadpopupDisplay}
          className="items-center justify-center  "
        >
          <div className="z-50 w-80 border-0 p-5 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between  border-b-[1px] border-solid border-slate-300 rounded-t">
              <h3 className="text-[16px] font-semibold">Download File</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                onClick={() => DownloadpopupDisplay(false)}
              >
                <span className="   h-6 w-6 text-[18px] block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative  flex-auto">
              <form className="">
                <h2 className="p-6 text-[12px] text-center ">
                  Do you want to Download This File?
                </h2>
                <div className="">
                  <div className="grid pb-5">
                    <label className="float-left pb-2 text-left text-[10px] font-semibold ">
                      File type
                    </label>
                    <select className="w-full border-[1px] border-gray-300  py-2 text-[10px] rounded-sm text-gray-500 hover:border-[#6659E3] ">
                      <option value="">PDF</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex  p-2  rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => DownloadpopupDisplay(false)}
                className=" rounded-3xl mx-auto py-2   w-24 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Proceed
                {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" /> */}
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={DownloadpopupDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default DownloadFilter;

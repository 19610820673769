import React from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";

const ModalOverlay = (props) => {
  return (
    <>
      <div
        className={`  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${props.className}`}
      >
        <div className={`relative w-auto mx-auto  ${props.className}`}>
          {/*content*/}
          {props.children}
        </div>
      </div>
    </>
  );
};

const BackDropModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          className={props.className}
          title={props.title}
          message={props.message}
          onConfirmed={props.onConfirmed}
        >
          {props.children}
        </ModalOverlay>,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default BackDropModal;

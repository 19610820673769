import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import api from "../api/axios";

export const useProjectDetails = devtools(
  immer((set) => ({
    projectGoals: [
      {
        value: "1",
        label: " Brand Awareness",
      },
      {
        value: "2",
        label: "Consideration",
      },
      {
        value: "3",
        label: "Leads",
      },
      {
        value: "4",
        label: "Sales",
      },
    ],
    projectCategories: [
      {
        id: "1",
        name: " Brand Awareness",
      },
      {
        id: "2",
        name: "Consideration",
      },
      {
        id: "3",
        name: "Leads",
      },
      {
        id: "4",
        name: "Sales",
      },
    ],
    postGrid_items: [
      {
        id: "1",
        name: "Generate Designs for May Day",
        image:
          "https://st1.latestly.com/wp-content/uploads/2022/04/1-Happy-May-Day-2022-Wishes.jpg",
      },
      {
        id: "2",
        name: "Generate Designs for May Day",
        image:
          "https://img.freepik.com/free-vector/usa-labor-day-concept-with-realistic-style_23-2147863341.jpg",
      },
      {
        id: "3",
        name: "Generate Designs for May Day",
        image:
          "https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg",
      },
      {
        id: "4",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466235/466235.jpg",
      },
      {
        id: "5",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466236/4662361.jpg",
      },
      {
        id: "6",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466237/4662371.jpg",
      },
      {
        id: "7",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466234/4662341.jpg",
      },
      {
        id: "8",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc3/14/404411/4044111.jpg",
      },
    ],
    alternative_designs: [
      {
        id: "1",
        name: "Generate Designs for May Day",
        image:
          "https://st1.latestly.com/wp-content/uploads/2022/04/1-Happy-May-Day-2022-Wishes.jpg",
      },
      {
        id: "2",
        name: "Generate Designs for May Day",
        image:
          "https://img.freepik.com/free-vector/usa-labor-day-concept-with-realistic-style_23-2147863341.jpg",
      },
      {
        id: "3",
        name: "Generate Designs for May Day",
        image:
          "https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg",
      },
      {
        id: "4",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466235/466235.jpg",
      },
      {
        id: "5",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466236/4662361.jpg",
      },
      {
        id: "6",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466237/4662371.jpg",
      },
      {
        id: "7",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466234/4662341.jpg",
      },
      {
        id: "8",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc3/14/404411/4044111.jpg",
      },
      {
        id: "9",
        name: "Generate Designs for May Day",
        image:
          "https://www.desicomments.com/wp-content/uploads/2017/01/Brilliant-Pic-Of-May-Day-600x447.jpg",
      },
    ],
    big_items: [
      {
        id: "1",
        name: "Generate Designs for May Day",
        image: "https://www.desicomments.com/dc4/02/466237/4662371.jpg",
      },
      {
        id: "2",
        name: "Generate Designs for May Day",
        image:
          "https://img.freepik.com/free-vector/usa-labor-day-concept-with-realistic-style_23-2147863341.jpg",
      },
    ],
    specialDays: [
      {
        id: "1",
        name: "May day-1st May",
      },
      {
        id: "2",
        name: "Budha Purnima-5th May",
      },
      {
        id: "3",
        name: "Tagore Jayanthi -9th May",
      },
      {
        id: "4",
        name: "Mother's Day- 14th May",
      },
      {
        id: "5",
        name: "Athletics Day",
      },
    ],
    project_create_business_categories: [
      {
        id: "1",
        name: "Real Estate",
      },
      {
        id: "2",
        name: "Finance",
      },
      {
        id: "3",
        name: "Construction",
      },
      {
        id: "4",
        name: "Insurance",
      },
      {
        id: "5",
        name: "Advertising",
      },
      {
        id: "6",
        name: "Manufacturing",
      },
      {
        id: "7",
        name: "Advertising",
      },
      {
        id: "8",
        name: "Nonprofit organization",
      },
      {
        id: "9",
        name: "Automotive",
      },
      {
        id: "10",
        name: "Financial services",
      },
    ],
    project_create_business_subcategories: [
      {
        id: "1",
        name: "Real Estate",
      },
      {
        id: "2",
        name: "Finance",
      },
      {
        id: "3",
        name: "Construction",
      },
      {
        id: "4",
        name: "Insurance",
      },
      {
        id: "5",
        name: "Advertising",
      },
      {
        id: "6",
        name: "Manufacturing",
      },
      {
        id: "7",
        name: "Advertising",
      },
      {
        id: "8",
        name: "Nonprofit organization",
      },
      {
        id: "9",
        name: "Automotive",
      },
      {
        id: "10",
        name: "Financial services",
      },
    ],
    projects_list: [
      {
        id: 1,
        basic_details: {
          project_name: "Karya hub",
          business_category: "1",
          business_subcategory: "3",
          logo: "C:\\fakepath\\65.png",
          logo_primary_color: "#FF0000",
        },
        contact_details: {
          country_phonecode: "+91",
          phone_number: "8639952863",
          email: "thirupathi.013@gmail.com",
          website: "https://google.com",
          address:
            "Progressive Tower, Jaihind Enclave, 19/B Feet Rd,Ayyappa Society, Chana Naik Nagar, Madhapur Hyderabad, Telangana - 500081",
          state: "1",
          country: "1",
        },
        about_details: {
          much_info:
            "Karyahub studios is a premier animation studio that specializes in creating stunning animations",
          keywords: "Animation Studio, 3d Animation Rendering",
          project_feature:
            "We believe that the power of animation lies in its ability to inspire, educate, and entertain",
          project_goals: "",
        },
        service_details: [
          {
            product_name: "Product Name1",
            description:
              "including information about color, sizing, material, pricing, shipping options, and more1",
            price: "25000",
            offer_price: "22000",
            upload_images: "",
          },
          {
            product_name: "Real Estate2",
            description:
              "including information about color, sizing, material, pricing, shipping options, and more2",
            price: "48000",
            offer_price: "45000",
            upload_images: "",
          },
        ],
      },
      {
        id: 2,
        basic_details: {
          project_name: "Raj Technologies",
          business_category: "1",
          business_subcategory: "3",
          logo: "C:\\fakepath\\65.png",
        },
        contact_details: {
          country_phonecode: "+91",
          phone_number: "8639952863",
          email: "thirupathi.013@gmail.com",
          website: "https://google.com",
          address: "Hyderabad",
          state: "1",
          country: "1",
        },
        about_details: {
          much_info: "real estate company from 12 years",
          keywords: "real estate, karya hub",
          project_feature: "to build apartments and other houses",
          project_goals: "",
        },
        service_details: [
          {
            product_name: "Real Estate",
            description: "to awareness of customer about our brand",
            price: "25000",
            offer_price: "22500",
            upload_images: "",
          },
          {
            product_name: "Real Estate2",
            description: "to awareness of customer about our brand",
            price: "48000",
            offer_price: "45000",
            upload_images: "",
          },
        ],
      },
      {
        id: 3,
        basic_details: {
          project_name: "Web Technologies pvt Ltd",
          business_category: "1",
          business_subcategory: "3",
          logo: "C:\\fakepath\\65.png",
        },
        contact_details: {
          country_phonecode: "+91",
          phone_number: "8639952863",
          email: "thirupathi.013@gmail.com",
          website: "https://google.com",
          address: "Hyderabad",
          state: "1",
          country: "1",
        },
        about_details: {
          much_info: "real estate company from 12 years",
          keywords: "real estate, karya hub",
          project_feature: "to build apartments and other houses",
          project_goals: "",
        },
        service_details: [
          {
            product_name: "Real Estate",
            description: "to awareness of customer about our brand",
            price: "25000",
            offer_price: "22500",
            upload_images: "",
          },
          {
            product_name: "Real Estate2",
            description: "to awareness of customer about our brand",
            price: "48000",
            offer_price: "45000",
            upload_images: "",
          },
        ],
      },
    ],
    selected_project: {
      id: 1,
      basic_details: {
        project_name: "Karya hub",
        business_category: "1",
        business_subcategory: "3",
        logo: "C:\\fakepath\\65.png",
        logo_primary_color: "#FF0000",
      },
      contact_details: {
        country_phonecode: "+91",
        phone_number: "8639952863",
        email: "thirupathi.013@gmail.com",
        website: "https://google.com",
        address:
          "Progressive Tower, Jaihind Enclave, 19/B Feet Rd,Ayyappa Society, Chana Naik Nagar, Madhapur Hyderabad, Telangana - 500081",
        state: "1",
        country: "1",
      },
      about_details: {
        much_info:
          "Karyahub studios is a premier animation studio that specializes in creating stunning animations",
        keywords: "Animation Studio, 3d Animation Rendering",
        project_feature:
          "We believe that the power of animation lies in its ability to inspire, educate, and entertain",
        project_goals: "",
      },
      service_details: [
        {
          id: 1,
          product_name: "Product Name1",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more1",
          price: "25000",
          offer_price: "22000",
          upload_images: "",
        },
        {
          id: 2,
          product_name: "Real Estate2",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more2",
          price: "48000",
          offer_price: "45000",
          upload_images: "",
        },
        {
          product_name: "Product Name3",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more1",
          price: "25000",
          offer_price: "22000",
          upload_images: "",
        },
        {
          product_name: "Real Estate4",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more2",
          price: "48000",
          offer_price: "45000",
          upload_images: "",
        },
        {
          product_name: "Product Name3",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more1",
          price: "25000",
          offer_price: "22000",
          upload_images: "",
        },
        {
          product_name: "Real Estate4",
          description:
            "including information about color, sizing, material, pricing, shipping options, and more2",
          price: "48000",
          offer_price: "45000",
          upload_images: "",
        },
      ],
    },
    setProjectList: (o) => {
      console.log(o);
      set((state) => ({
        projects_list: [...state.projects_list, o],
      }));
    },
    selected_Project_id: 1,
    getProject: () => {
      set((state) => {
        console.log(state);
        state.selected_project = state.projects_list.filter(
          (el) => el.id !== state.selected_Project_id
        );
      });
    },

    getApi: async () => {
      const apiResponse = await api.get("welcome_designs.php");
      set((state) => {
        console.log(apiResponse.data.data);
        state.designsData = apiResponse.data.data;
      });
    },
  }))
);

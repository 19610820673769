import {
  faClose,
  faCross,
  faGreaterThan,
  faLessThan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";
import TextArea from "../Utils/TextArea";
import Select from "../Utils/Select";
import ReactSelect from "react-select";

import Checkbox from "../Utils/Checkbox";

function ProjectStep2(props) {
  // const listOptions = props.project_goals_list.map((item) => item.name);
  const listOptions = [
    {
      value: "1",
      label: " Brand Awareness",
    },
    {
      value: "2",
      label: "Consideration",
    },
    {
      value: "3",
      label: "Leads",
    },
    {
      value: "4",
      label: "Sales",
    },
  ];
  const [selected, setSelected] = useState(listOptions);

  function handleSelect(value, name) {
    if (value) {
      // if true
      setSelected([...selected, name]); // add to selected
    } else {
      // if false
      setSelected(selected.filter((item) => item !== name)); // remove from selected
    }
  }

  function selectAll(value) {
    if (value) {
      // if true
      setSelected(listOptions); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  let business_subcategories_items;
  const business_subcategories = useStore(
    (state) => state.project_create_business_subcategories
  );
  business_subcategories_items = business_subcategories.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));
  let business_categories_items;
  const business_categories = useStore(
    (state) => state.project_create_business_categories
  );
  business_categories_items = business_categories.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  const [phoneFiledShow, setPhoneFieldShow] = useState(false);
  const [emailFieldShow, setEmailShow] = useState(false);
  let navigate = useNavigate();
  let countries_items;
  const countries = useStore((state) => state.contactform_countries);
  countries_items = countries.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  let state_items;
  const states = useStore((state) => state.contactform_states);
  state_items = states.map((item, index) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));

  const re = /^[0-9\b]+$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const onlyNumbers = /^[0-9]*$/;

  const isNotEmpty = (value) => value.trim() !== "";
  const isTest = (value) => {
    return true;
  };
  const isAlphabet = (value) => {
    if (re.test(value)) {
      return false;
    }
    return true;
  };

  const isValidMobile = (value) => {
    if (value.length !== 10) {
      return false;
    }
    if (!onlyNumbers.test(value)) {
      return false;
    }
    return true;
  };
  const {
    value: countryCodeValue,
    isValid: countryCodeIsValid,
    hasError: countryCodeHasError,
    valueChangeHandler: countryCodeChangeHandler,
    inputBlurHandler: countryCodeBlurHandler,
    reset: resetcountryCode,
  } = useInput(isTest);
  const {
    value: phoneNumberValue,
    isValid: phoneNumberIsValid,
    hasError: phoneNumberHasError,
    valueChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: resetphoneNumber,
  } = useInput(isTest);
  const {
    value: countryCode1Value,
    isValid: countryCode1IsValid,
    hasError: countryCode1HasError,
    valueChangeHandler: countryCode1ChangeHandler,
    inputBlurHandler: countryCode1BlurHandler,
    reset: resetcountryCode1,
  } = useInput(isTest);
  const {
    value: phoneNumber1Value,
    isValid: phoneNumber1IsValid,
    hasError: phoneNumber1HasError,
    valueChangeHandler: phoneNumber1ChangeHandler,
    inputBlurHandler: phoneNumber1BlurHandler,
    reset: resetphoneNumber1,
  } = useInput(isTest);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetemail,
  } = useInput(isTest);
  const {
    value: email1Value,
    isValid: email1IsValid,
    hasError: email1HasError,
    valueChangeHandler: email1ChangeHandler,
    inputBlurHandler: email1BlurHandler,
    reset: resetemail1,
  } = useInput(isTest);

  const {
    value: AddressValue,
    isValid: AddressIsValid,
    hasError: AddressHasError,
    valueChangeHandler: AddressChangeHandler,
    inputBlurHandler: AddressBlurHandler,
    reset: resetAddress,
  } = useInput(isTest);

  const {
    value: StateValue,
    isValid: StateIsValid,
    hasError: StateHasError,
    valueChangeHandler: StateChangeHandler,
    inputBlurHandler: StateBlurHandler,
    reset: resetState,
  } = useInput(isNotEmpty);

  const {
    value: CountryValue,
    isValid: CountryIsValid,
    hasError: CountryHasError,
    valueChangeHandler: CountryChangeHandler,
    inputBlurHandler: CountryBlurHandler,
    reset: resetCountry,
  } = useInput(isNotEmpty);

  const {
    value: businessCategoryValue,
    isValid: businessCategoryIsValid,
    hasError: businessCategoryHasError,
    valueChangeHandler: businessCategoryChangeHandler,
    inputBlurHandler: businessCategoryBlurHandler,
    reset: resetbusinessCategory,
  } = useInput(isNotEmpty);
  const {
    value: businessSubCategoryValue,
    isValid: businessSubCategoryIsValid,
    hasError: businessSubCategoryHasError,
    valueChangeHandler: businessSubCategoryChangeHandler,
    inputBlurHandler: businessSubCategoryBlurHandler,
    reset: resetbusinessSubCategory,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (businessCategoryIsValid && businessSubCategoryIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      /* StateBlurHandler();
      CountryBlurHandler(); */
      businessCategoryBlurHandler();
      businessSubCategoryBlurHandler();

      return;
    }

    console.log("Submitted!");

    const contact_details = {
      country_phonecode: countryCodeValue,
      phone_number: phoneNumberValue,
      country_phonecode1: countryCode1Value,
      phone_number1: phoneNumber1Value,
      email: emailValue,
      email1: email1Value,
      address: AddressValue,
      state: StateValue,
      country: CountryValue,
      business_category: businessCategoryValue,
      business_subcategory: businessSubCategoryValue,
    };
    props.businessDetailssubmit(contact_details);
    props.handleNext();
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const AddPhoneNumberField = () => {
    setPhoneFieldShow(true);
  };
  const removePhoneNumberField = () => {
    setPhoneFieldShow(false);
  };

  const AddEmailField = () => {
    setEmailShow(true);
  };
  const removeEmailField = () => {
    setEmailShow(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-28 w-80 items-center m-auto">
        <h3 className="text-xl mb-6 font-semibold text-center">
          <span className=" border-l-[3px] border-[#6659E3] px-4 text-center w-16">
            Business Information
          </span>
        </h3>

        <div className="mb-4">
          {/*  <select
              // className="w-full border border-gray-400 py-3 px-2 rounded-md text-gray-500 hover:border-[#6659E3] text-sm"
              name="business_category"
              value={inputFields.business_category}
              onChange={handleChange}
              className={
                errors.business_category
                  ? "w-full border border-gray-400 py-3 px-2 rounded-md text-gray-500 hover:border-[#6659E3] text-sm border border-red-500"
                  : "w-full border border-gray-400 py-3 px-2 rounded-md text-gray-500 hover:border-[#6659E3] text-sm"
              }
              title={errors.business_category ? errors.business_category : ""}
            >
              <option value="">Enter Business Category *</option>
              {business_categories_items}
            </select> */}
          <Select
            id="business_category"
            name="business_category"
            value={businessCategoryValue}
            onChange={businessCategoryChangeHandler}
            onBlur={businessCategoryBlurHandler}
            isValid={businessCategoryIsValid}
            error={businessCategoryHasError}
            title="Business Category"
            isMandate={true}
          >
            <option value="">Select Business Category</option>
            {business_categories_items}
          </Select>
        </div>
        <div className="mb-4">
          {/* <select
              name="business_subcategory"
              value={inputFields.business_subcategory}
              onChange={handleChange}
              className={
                errors.business_subcategory
                  ? "w-full border border-gray-400 py-3 px-2 rounded-md text-gray-500 hover:border-[#6659E3] text-sm border border-red-500"
                  : "w-full border border-gray-400 py-3 px-2 rounded-md text-gray-500 hover:border-[#6659E3] text-sm"
              }
              title={
                errors.business_subcategory ? errors.business_subcategory : ""
              }
            >
              <option value="">Select Business Sub-category *</option>
              {business_subcategories_items}
            </select> */}

          <Select
            id="business_subcategory"
            name="business_subcategory"
            value={businessSubCategoryValue}
            onChange={businessSubCategoryChangeHandler}
            onBlur={businessSubCategoryBlurHandler}
            isValid={businessSubCategoryIsValid}
            error={businessSubCategoryHasError}
            title="Business sub-Category"
            isMandate={true}
          >
            <option value="">Select Business Sub-category</option>
            {business_subcategories_items}
          </Select>
        </div>
        <div className="mb-4">
          <h3 className="text-sm font-bold mb-4"> Project Goal</h3>
          <ReactSelect
            closeMenuOnSelect={false}
            // defaultValue={[colourOptions[4], colourOptions[5]]}
            isMulti
            options={listOptions}
            // className=" text-xs w-full   border	border-slate-300  focus:outline-none placeholder:text-slate-600 "
            // multiple
          >
            {/* <option value="1">Raj Kumar</option>
                      <option value="2">Radha Krishna</option>
                      <option value="3">Naveen</option> */}
          </ReactSelect>

          {/* <ul className=" grid  gap-2 ">
            <li>
              <Checkbox
                name="all"
                value={selected.length === listOptions.length}
                updateValue={selectAll}
              >
                Select All
              </Checkbox>
            </li>
            {listOptions?.map((item) => {
              return (
                <li className="w-full inline-block">
                  <Checkbox
                    name={item}
                    value={selected.includes(item)}
                    updateValue={handleSelect}
                  >
                    {item}
                  </Checkbox>
                </li>
              );
            })}
          </ul> */}
        </div>
      </div>
      <div className=" text-center content-center mt-4 ">
        {props.step > 1 && (
          <button
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg mr-2 "
            onClick={props.handleBack}
          >
            <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
          </button>
        )}

        {props.step < 4 && (
          <button
            type="submit"
            className="bg-[#FBFBFD] py-2 px-2  w-10  font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              className="font-bold"
            ></FontAwesomeIcon>
          </button>
        )}
      </div>
    </form>
  );
}

export default ProjectStep2;

import React from "react";

function PrivacyPolicy(props) {
  return (
    <div className="m-8">
      <h2 className="font-bold text-lg my-6">Privacy Policy</h2>
      <p className="my-6 text-xs font-light text-black leading-6">
        This Privacy Policy is effective as of February 7, 2023. See previous
        versions of the Privacy Policy in our Policy Archives.
      </p>
      <p className="my-6 text-xs font-light text-black leading-6">
        The highlighted text is aimed to give a plain English summary of our
        Privacy Policy. Please ensure you read the main text, as the plain
        English summary is just a summary and doesn’t capture all of the terms.
      </p>
      <h2 className="font-bold text-sm my-6">What does this policy cover</h2>
      <p className="my-6 text-xs font-light text-black leading-6">
        Welcome to Canva, the online and mobile service of Canva Pty Ltd
        (“Canva,” “we,” or “us”). Our Privacy Policy explains how we collect,
        use, disclose, and protect information that applies to our visual
        communication platform (the "Service"), and your choices about the
        collection and use of your information. Capitalized terms that are not
        defined in this Privacy Policy have the meaning given to them in our
        Terms of Use. If you do not want your information processed in
        accordance with this Privacy Policy in general or any part of it, you
        should not use our Service. This policy applies to all users of the
        Service, including, but not limited to users of our Developer Portal.
      </p>
      <p className="my-6 text-xs font-light text-black leading-6">
        We may ask for certain information when you register for a Canva account
        or correspond with us (such as a username, your first and last names,
        birthdate, phone number, profession, physical and e-mail address). We
        also collect any messages you send us through the Service, and may
        collect information you provide in User Content you post to the Service
        (such as text and photos you upload to use in your designs). We use this
        information to operate, maintain, and provide the features and
        functionality of the Service to you, to correspond with you, and to
        address any issues you raise about the Service. If you don’t provide
        your personal information to us, you may not be able to access or use
        our Service or your experience of using our Service may not be as
        enjoyable.
      </p>
      <p className="my-6 text-xs font-light text-black leading-6">
        We may receive information about you from third parties. For example, if
        you access the Service through a third-party connection or log-in, such
        as Facebook Connect, by “following,” “liking,” adding the Canva
        application, linking your account to the Canva Service, etc., that third
        party may pass certain information about your use of its service to
        Canva. This information could include, but is not limited to, the user
        ID associated with your account (for example, your Facebook UID), an
        access token necessary to access that service, any information that you
        have permitted the third party to share with us, and any information you
        have made public in connection with that service. You should always
        review, and if necessary, adjust your privacy settings on third-party
        websites and services before linking or connecting them to the Service.
        You may also unlink your third party account from the Service by
        adjusting your settings on the third party service. If you unlink your
        third party account, we will no longer receive information collected
        about you in connection with that service. And if you choose to
        integrate with a third party application to incorporate elements into
        your designs, enhance media, supercharge your workflow or share to
        social media, we could receive data from such apps (as well as disclose
        data to such apps). If you communicate with us via social media,
        including Instagram, Facebook or TikTok and choose to share your user
        generated content with us, we may receive information such as videos
        you’ve created, your photo, your account name and your comments about
        Canva.
      </p>
      <h2 className="font-bold text-sm my-6">
        Information we receive from other third parties
      </h2>
      <p className="my-6 text-xs font-light text-black leading-6">
        We may obtain information about you from third-party sources, such as
        public sources, social media platforms (like Facebook, Instagram,
        LinkedIn, Twitter and other platforms) and third-party data providers
        and information services. Examples of the information we may obtain from
        such third parties include your company, company size, job title and
        seniority, industry and other profile information. We may share your
        email address or other information in order to obtain and combine this
        information with information that you provide to us or other information
        that we collect when you use our Service. We do this to better
        understand your profile and interests so that we can deliver customized
        offers and other personalized services to you, such as to serve relevant
        offers to you via email, chatbots, phone or personalized advertising.
        You can opt out of Canva collecting more data about you from third-party
        providers to tailor your experience and messages we send you here.
      </p>
      <p className="my-6 text-xs font-light text-black leading-6">
        Some of these providers may combine data collected from our Service
        through cookies, pixels, tags and similar technologies, with email or
        mailing addresses to which they have access to help us serve relevant
        offers to you.
      </p>
      <p className="my-6 text-xs font-light text-black leading-6">
        If you are not a Canva subscriber, we may obtain your contact details
        from a social media platform, such as LinkedIn, or a third party data
        provider, so that we can promote our product offerings and subscriptions
        to you if we think they would be helpful in your role. We also may have
        obtained your details from another Canva user, for example when they
        have shared a design with you. We may also receive information about you
        and your engagement with our advertisements from our ad servers, ad
        networks and social media platforms. This may include the websites you
        visited before coming to Canva so that we can determine advertising
        effectiveness and pay our referral partners.
      </p>
      <h2 className="font-bold text-sm my-6">InstaDe for Education</h2>
      <p className="my-6 text-xs font-light text-black leading-6">
        Please note that references to utilizing third-party information for
        offers and advertising in this Section 1(c) do not apply to students
        using Canva for Education. We do not serve advertising to students using
        Canva for Education. We will directly collect analytics data, or use
        third-party analytics tools, to help us measure traffic and usage trends
        for the Service. These tools collect information sent by your browser or
        mobile device, including the pages you visit and other information
        (please see the paragraph on log file information below) that assists us
        in improving the Service. For more information on the analytics cookies
        we use and how to opt out of third-party collection of this information,
        please see our Cookies Policy.
      </p>
    </div>
  );
}

export default PrivacyPolicy;

import {
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUpRightDots,
  faArrowUpRightFromSquare,
  faCheck,
  faCircleCheck,
  faExpand,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getAllDesigns, getWelcomeDesigns } from "../../repo/welcomeRepo";
import useStore from "../../store/store";
import { useWelcomeDesigns } from "../../store/welcomeDesigns";
import { BackDropModal } from "../Utils/Modal";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
function WelcomeScreen(props) {
  let navigate = useNavigate();
  const dashboardScreen = () => {
    navigate("/dashboard");
  };
  // const [designs, setDesigns] = useState([]);
  let designs_items;
  async function loadInitialDesigns() {
    const initialTodos = await getWelcomeDesigns(1, 50, "");
    // setDesigns(initialTodos.data);
  }
  const designs = useWelcomeDesigns((state) => state.designsData);
  const callGetAPI = useWelcomeDesigns((state) => state.getApi);
  useEffect(() => {
    // callGetAPI();
  }, []);
  const [checkedState, setCheckedState] = useState(
    new Array(designs.length).fill(false)
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };
  const onConfirm = () => {};
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(checkedState);
    const isFound = checkedState.some((element) => {
      if (element === true) {
        return true;
      }

      return false;
    });

    if (isFound) {
      // navigate("/dashboard/projectCreate");
      navigate("/dashboard");
    } else {
      alert("Please select any one design");
    }
  };

  if (designs.length === 0) {
    designs_items = "";
  }
  if (designs.length > 0) {
    designs_items = designs.map((item, index) => (
      <li className="h-[150px]" key={item.id}>
        <input
          type="checkbox"
          id={item.id}
          value={item.id}
          name="designs_selected"
          className="hidden peer"
          checked={checkedState[index]}
          onChange={() => handleOnChange(index)}
        />
        <label
          htmlFor={item.id}
          className="inline-flex items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer    dark:peer-checked:text-gray-300 peer-checked:text-gray-600  dark:text-gray-400   "
        >
          <div className="block h-[150px] relative group">
            <img
              className="h-[150px] w-80"
              src={item.name}
              // src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
              alt=""
            />
            <div
              className={
                checkedState[index] === true
                  ? "absolute top-0 left-0 w-full  flex flex-col justify-center items-center bg-gray-900 opacity-0 h-full opacity-80  "
                  : "absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-gray-900 opacity-0  "
              }
            >
              <div className="inline-flex rounded-full shadow-sm" role="group">
                <button
                  onClick={() => handleOnChange(index)}
                  type="button"
                  className="inline-flex items-center  mx-2 px-4 py-4 text-sm text-3xl text-white bg-black    focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white  dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-lg font-extrabold"
                  ></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div>
          {/* <div className="group relative w-64">
            <img
              alt="example"
              className="w-full object-contain h-auto"
              src="https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg"
            />
            <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-grey-900 opacity-0 group-hover:h-full group-hover:opacity-80  group-hover:bg-black">
              <div className="inline-flex rounded-full shadow-sm" role="group">
                <button
                  type="button"
                  className="inline-flex items-center  mx-2 px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-full"
                >
                  <FontAwesomeIcon icon={faExpand}></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div> */}
        </label>
      </li>
    ));
  }

  return (
    <>
      <div className="align-middle my-4">
        {/* <div className=" bg-white flex justify-center items-center ">
          <img src={logo} className=" center h-24 w-70" />
        </div> */}
        <h1 className=" font-semibold  	 text-center mb-8 text-[24px]	">
          Select the Designs you like
        </h1>
      </div>
      <form onSubmit={handleSubmit}>
        <ul className=" grid  lg:grid-cols-5 md:grid-cols-3 gap-4 sm:grid-cols-2 xs:grid-cols-1 mx-10 mt-12 ">
          {designs_items}
        </ul>

        <div className=" bg-white flex justify-center items-center my-10">
          <button
            // onClick={dashboardScreen}
            type="submit"
            className=" rounded-3xl block py-2 w-20 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
            style={{ backgroundColor: "#6659e3" }}
          >
            DONE
            {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" /> */}
          </button>
        </div>
      </form>
    </>
  );
}

export default WelcomeScreen;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";

function UpdatePassword(props) {
  let navigate = useNavigate();
  const onlyNumbers = /^[0-9]*$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_phone = /^[6-9]\d{9}$/;
  const re = /^[0-9\b]+$/;
  const isNotEmpty = (value) => value.trim() !== "";
  const reg_password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const isValidPassword = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length < 8) {
      return false;
    }
    if (!reg_password.test(value)) {
      return false;
    }

    return true;
  };

  const {
    value: PasswordValue,
    isValid: PasswordIsValid,
    hasError: PasswordHasError,
    valueChangeHandler: PasswordChangeHandler,
    inputBlurHandler: PasswordBlurHandler,
    reset: resetPassword,
  } = useInput(isValidPassword);
  const isValidConfirmPassword = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length < 8) {
      return false;
    }
    if (!reg_password.test(value)) {
      return false;
    }
    if (value !== PasswordValue) {
      return false;
    }

    return true;
  };
  const {
    value: ConfirmPasswordValue,
    isValid: ConfirmPasswordIsValid,
    hasError: ConfirmPasswordHasError,
    valueChangeHandler: ConfirmPasswordChangeHandler,
    inputBlurHandler: ConfirmPasswordBlurHandler,
    reset: resetConfirmPassword,
  } = useInput(isValidConfirmPassword);
  let formIsValid = false;

  if (
    ConfirmPasswordIsValid &&
    PasswordIsValid &&
    PasswordValue === ConfirmPasswordValue
  ) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      ConfirmPasswordBlurHandler();
      PasswordBlurHandler();
      return;
    }
    console.log(PasswordValue);
    return navigate("/login");
  };
  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="">
          <img src={logo} className=" center h-24 w-70" />

          <div className="align-middle md:align-top pt-24 mb-4">
            <p
              className=" font-light	 text-center  text-xs	"
              style={{ color: "#007038" }}
            >
              Congratulations! you have succssfully reset your password
            </p>
            {/* <h2 className=" font-extrabold	 text-center mb-6 text-lg	">
              Create New Password
            </h2> */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {/* <input
                type="password"
                placeholder="Create New Password"
                name="mobileNumber"
                onChange={handleChange}
                className="block text-sm py-3 px-4  w-full border outline-none  focus:outline-none  placeholder:text-slate-600	"
              />
              {errors.mobileNumber ? (
                <p className="error">
                  Email should be at least 15 characters long
                </p>
              ) : null} */}
              <Input
                type="password"
                id="password"
                name="password"
                value={PasswordValue}
                onChange={PasswordChangeHandler}
                onBlur={PasswordBlurHandler}
                isValid={PasswordIsValid}
                error={PasswordHasError}
                title="Create New Password"
                isMandate={true}
              ></Input>
              {/* <input
                type="password"
                placeholder="Confirm Password"
                name="mobileNumber"
                onChange={handleChange}
                className="block text-sm py-3 px-4  w-full border outline-none  focus:outline-none  placeholder:text-slate-600	"
              />
              {errors.mobileNumber ? (
                <p className="error">
                  Email should be at least 15 characters long
                </p>
              ) : null} */}
              <Input
                type="password"
                id="confirm_password"
                name="confirm_password"
                value={ConfirmPasswordValue}
                onChange={ConfirmPasswordChangeHandler}
                onBlur={ConfirmPasswordBlurHandler}
                isValid={ConfirmPasswordIsValid}
                error={ConfirmPasswordHasError}
                title="Confirm Password"
                isMandate={true}
              ></Input>
            </div>

            <div className="text-center mt-6">
              <button
                type="submit"
                className=" block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <p className="text-center absolute inset-x-0 bottom-0 text-xs">
          <Link
            to="/terms-of-use"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Terms of use
          </Link>
          |
          <Link
            to="/privacy-policy"
            className="cursor-pointer px-2 font-bold text-gray-600"
          >
            Privacy policy
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

export default UpdatePassword;

import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import api from "../api/axios";

export const useFrontDetails = devtools(
  immer((set) => ({
    capabilities_slider: [
      {
        id: "1",
        name: "Linkedin Post",
        image:
          "https://st1.latestly.com/wp-content/uploads/2022/04/1-Happy-May-Day-2022-Wishes.jpg",
      },
      {
        id: "2",
        name: "Facebook Story",
        image:
          "https://img.freepik.com/free-vector/usa-labor-day-concept-with-realistic-style_23-2147863341.jpg",
      },
      {
        id: "3",
        name: "Facebook Post",
        image:
          "https://images.template.net/62934/May-Day-Event-Linkedin-Post-Template.jpeg",
      },
      {
        id: "4",
        name: "Instagram Story",
        image: "https://www.desicomments.com/dc4/02/466235/466235.jpg",
      },
      {
        id: "5",
        name: "Instagram Post",
        image: "https://www.desicomments.com/dc4/02/466236/4662361.jpg",
      },
      {
        id: "6",
        name: "Facebook Story",
        image: "https://www.desicomments.com/dc4/02/466237/4662371.jpg",
      },
      {
        id: "7",
        name: "Instagram Story",
        image: "https://www.desicomments.com/dc4/02/466234/4662341.jpg",
      },
      {
        id: "8",
        name: "Instagram Post",
        image: "https://www.desicomments.com/dc3/14/404411/4044111.jpg",
      },
      {
        id: "9",
        name: "Facebook Post",
        image:
          "https://www.desicomments.com/wp-content/uploads/2017/01/Brilliant-Pic-Of-May-Day-600x447.jpg",
      },
    ],

    getApi: async () => {
      const apiResponse = await api.get("welcome_designs.php");
      set((state) => {
        console.log(apiResponse.data.data);
        state.designsData = apiResponse.data.data;
      });
    },
  }))
);

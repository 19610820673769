import {
  faEllipsisVertical,
  faGear,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./ProjectList.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Modal from "../Utils/Modal";
import ProjectSingeItem from "./ProjectSingeItem";

function ProjectList(props) {
  let navigate = useNavigate();
  const ProjectCreate = () => {
    navigate("/dashboard/projectCreate");
  };
  const [selectedP, setSelectedP] = useState();

  const selectedProject = (data) => {
    setSelectedP(data);
  };
  return (
    <div className="m-4">
      <h1 className="font-[550] text-[13px]  border-b-[1px] pt-4 pb-3 border-gray-300 ">
        PROJECTS
      </h1>
      <button
        onClick={ProjectCreate}
        type="button"
        className="my-4  w-full inline-flex items-center font-medium   px-2 py-2 text-[10px]  text-gray-900 border-gray-300 border-[1px] hover:bg-[#6659E3] hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600
         dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white  rounded-[3px] text-center"
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="mr-3"
          style={{ height: "9px" }}
        ></FontAwesomeIcon>
        New Project
      </button>
      <div
        className="m-0 md:h-[520px]"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        {props.projects_list.map((project) => (
          <ProjectSingeItem
            key={project.id}
            id={project.id}
            project={project}
            selectedProject={selectedProject}
            selectedP={selectedP}
          ></ProjectSingeItem>
        ))}
      </div>
    </div>
  );
}

export default ProjectList;

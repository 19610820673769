import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import api from "../api/axios";

export const useContactForm = devtools(
  immer((set) => ({
    contactform_Jobs: [
      {
        id: "1",
        name: " CMO/Head of Marketing",
      },
      {
        id: "2",
        name: "CCO/Head of Design",
      },
      {
        id: "3",
        name: "Marketing Director",
      },
      {
        id: "4",
        name: " Digital Marketing Manager",
      },
      {
        id: "5",
        name: " UI/UX Manager",
      },
      {
        id: "6",
        name: "Freelancer",
      },
      {
        id: "7",
        name: "Manager",
      },
      {
        id: "8",
        name: "Project Manager/Team Leader",
      },
    ],
    contactform_company_size: [
      {
        id: "1",
        name: "0 - 50",
      },
      {
        id: "2",
        name: "51 - 100",
      },
      {
        id: "3",
        name: "101 - 150",
      },
      {
        id: "4",
        name: " 151 - 200",
      },
      {
        id: "5",
        name: "201 - 250",
      },
      {
        id: "6",
        name: "251 - 500",
      },
      {
        id: "7",
        name: "501 - 1000",
      },
      {
        id: "8",
        name: "1001 - 2000",
      },
    ],

    contactform_job_title: [
      {
        id: "1",
        name: "CMO/Head of Marketing",
      },
      {
        id: "2",
        name: "CCO/Head of Design",
      },
      {
        id: "3",
        name: "Marketing Director",
      },
      {
        id: "4",
        name: " Digital Marketing Manager",
      },
      {
        id: "5",
        name: "UI/UX Manager",
      },
      {
        id: "6",
        name: "Freelancer",
      },
      {
        id: "7",
        name: "Manager",
      },
      {
        id: "8",
        name: "Project Manager/ Team Leader",
      },
    ],
    contactform_countries: [
      {
        id: "1",
        name: "United States",
      },
      {
        id: "2",
        name: "United Kingdom",
      },
      {
        id: "3",
        name: "United Arab Emirates",
      },
      {
        id: "4",
        name: "India",
      },
      {
        id: "5",
        name: "Srilanka",
      },
      {
        id: "6",
        name: "Nepal",
      },
      {
        id: "7",
        name: "Irland",
      },
      {
        id: "8",
        name: "Australia",
      },
    ],
    contactform_states: [
      {
        id: "1",
        name: "United States",
      },
      {
        id: "2",
        name: "United Kingdom",
      },
      {
        id: "3",
        name: "United Arab Emirates",
      },
      {
        id: "4",
        name: "India",
      },
      {
        id: "5",
        name: "Srilanka",
      },
      {
        id: "6",
        name: "Nepal",
      },
      {
        id: "7",
        name: "Irland",
      },
      {
        id: "8",
        name: "Australia",
      },
    ],
    contactform_enquiry: [
      {
        id: "1",
        name: "I'm Interested in buying instaDe for my team/business",
      },
      {
        id: "2",
        name: "I'm interested in buing instaDe for myself",
      },
      {
        id: "3",
        name: "I have a question about instaDe for Education",
      },
      {
        id: "4",
        name: "I have a question about instaDe for NonProfits",
      },
      {
        id: "5",
        name: "I have a billing issue",
      },
      {
        id: "6",
        name: "I have a support issue",
      },
    ],

    getApi: async () => {
      const apiResponse = await api.get("welcome_designs.php");
      set((state) => {
        console.log(apiResponse.data.data);
        state.designsData = apiResponse.data.data;
      });
    },
  }))
);

import { create } from "zustand";
import { createAuthSlice } from "./authSlice";
import { createRegisterSlice } from "./registerSlice";
import { useProfileDesigns } from "./profileSlice";
import { useProjectDetails } from "./projectSlice";
import { createTokenSlice } from "./tokenSlice";
import { useFrontDetails } from "./frontSlice";
import { useContactForm } from "./contactSlice";

/* const useStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
})); */

export const useStore = create((...a) => ({
  ...createAuthSlice(...a),
  ...createRegisterSlice(...a),
  ...useProfileDesigns(...a),
  ...useProjectDetails(...a),
  ...createTokenSlice(...a),
  ...useFrontDetails(...a),
  ...useContactForm(...a),
}));

export default useStore;

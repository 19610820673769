import {
  faArrowRight,
  faArrowUpRightDots,
  faCheckCircle,
  faCircle,
  faGreaterThan,
  faLessThan,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useStore from "../../store/store";
import ProjectStep1 from "./ProjectStep1";
import ProjectStep2 from "./ProjectStep2";
import ProjectStep3 from "./ProjectStep3";
import ProjectStep4 from "./ProjectStep4";
import BasicDetails from "./BasicDetails";
import BusinessDetails from "./BusinessDetails";
import AboutProject from "./AboutProject";
import ContactDetails from "./ContactDetails";

function ProjectCreate(props) {
  const setProjectList = useStore((state) => state.setProjectList);

  let navigate = useNavigate();
  const [allDetails, setAllDetails] = useState({
    id: Math.random(),
    basic_details: "",
    contact_details: "",
    about_details: "",
    service_details: "",
  });
  const basicDetailssubmit = (basic_details) => {
    setAllDetails((prevValue) => ({ ...prevValue, basic_details }));
    // console.log(basic_details);
  };

  const businessDetailssubmit = (business_details) => {
    setAllDetails((prevValue) => ({ ...prevValue, business_details }));
    // console.log(basic_details);
  };
  const contactDetailssubmit = (contact_details) => {
    setAllDetails((prevValue) => ({ ...prevValue, contact_details }));
    setProjectList(allDetails);

    return navigate("/dashboard/welcome");
  };
  const aboutDetailssubmit = (about_details) => {
    setAllDetails((prevValue) => ({ ...prevValue, about_details }));
  };
  const serviceDetailssubmit = (service_details) => {
    // setAllDetails((prevValue) => [
    //   ...prevValue,
    //   { service_details: service_details },
    // ]);
    setAllDetails((prevValue) => ({ ...prevValue, service_details }));
    console.log(allDetails);
    setProjectList(allDetails);

    return navigate("/dashboard/welcome");
  };

  let project_golas_items;
  const project_goals_list = useStore((state) => state.projectGoals);

  const GenerateScreenRedirect = () => {
    navigate("/dashboard/welcome");
  };
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className=" ">
      {/* flex items-center justify-center */}
      <div className="bg-white  py-14   w-full ">
        <div className="w-52 m-auto">
          <div className="grid grid-cols-4 gap-0 items-center content-center text-center">
            <div
              className={` border-r border-gray-400 ${
                step === 1 ? "bg-[#6659E3] text-white" : "bg-gray-200"
              } w-10 p-[3px] text-center cursor-pointer `}
              // onClick={() => setStep(1)}
            ></div>
            <div
              className={` ${
                step === 2 ? "bg-[#6659E3] text-white" : "bg-gray-200"
              } w-10  p-[3px] text-center cursor-pointer  `}
              // onClick={() => setStep(2)}
            ></div>
            <div
              className={` ${
                step === 3 ? "bg-[#6659E3] text-white" : "bg-gray-200"
              } w-10  p-[3px] text-center cursor-pointer  `}
              // onClick={() => setStep(3)}
            ></div>
            <div
              className={` ${
                step === 4 ? "bg-[#6659E3] text-white" : "bg-gray-200"
              } w-10  p-[3px] text-center cursor-pointer  `}
              // onClick={() => setStep(4)}
            ></div>
          </div>
        </div>
        {step === 1 && (
          <>
            {/* <ProjectStep1
            handleNext={handleNext}
            basicDetailssubmit={basicDetailssubmit}
          /> */}
            <BasicDetails
              handleNext={handleNext}
              basicDetailssubmit={basicDetailssubmit}
            />
          </>
        )}
        {step === 2 && (
          <>
            {/* <ProjectStep2
            handleNext={handleNext}
            handleBack={handleBack}
            step={step}
            contactDetailssubmit={contactDetailssubmit}
          /> */}

            <BusinessDetails
              project_goals_list={project_goals_list}
              handleNext={handleNext}
              handleBack={handleBack}
              step={step}
              businessDetailssubmit={businessDetailssubmit}
            />
          </>
        )}
        {step === 3 && (
          <>
            {/* <ProjectStep3
            project_goals_list={project_goals_list}
            handleNext={handleNext}
            handleBack={handleBack}
            step={step}
            aboutDetailssubmit={aboutDetailssubmit}
            /> */}
            <AboutProject
              handleNext={handleNext}
              handleBack={handleBack}
              step={step}
              aboutDetailssubmit={aboutDetailssubmit}
            />
          </>
        )}
        {step === 4 && (
          <>
            {/* <ProjectStep4
              handleNext={handleNext}
              handleBack={handleBack}
              step={step}
              serviceDetailssubmit={serviceDetailssubmit}
            /> */}
            <ContactDetails
              handleNext={handleNext}
              handleBack={handleBack}
              step={step}
              serviceDetailssubmit={serviceDetailssubmit}
              contactDetailssubmit={contactDetailssubmit}
            />
          </>
        )}
        {/* <div className=" text-center content-center mt-4 ">
          {step > 1 && (
            <button
              className="bg-[#FBFBFD] py-2 px-4  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg mr-2 "
              onClick={handleBack}
            >
              <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
            </button>
          )}

          {step < 4 && (
            <button
              className="bg-[#FBFBFD] py-2 px-4  w-12 font-bold rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
              onClick={handleNext}
            >
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="font-bold"
              ></FontAwesomeIcon>
            </button>
          )}
          {step === 4 && (
            <button
              type="submit"
              onClick={GenerateScreenRedirect}
              // className=" rounded-3xl block py-2 w-24 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
              className="bg-[#FBFBFD] py-2 px-2  w-24 text-white  rounded-full border hover:bg-[#6659E3] hover:text-white hover:shadow-lg "
              style={{ backgroundColor: "#6659e3" }}
            >
              Done
              <FontAwesomeIcon icon={faArrowUpRightDots} className="ml-2" />
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default ProjectCreate;

import React, { useContext, useEffect, useState } from "react";
import useCoreHandler from "../../../Canvas/handlers/useCoreHandler";
import { CanvasContext } from "../../../Canvas/store/CanvasContext";

function LineToolbox(props) {
  const { setProperty, getActiveStyle } = useCoreHandler();
  const { activeObject } = useContext(CanvasContext);
  const [options, setOptions] = useState({
    left: 100,
    top: 100,
    stroke: "black",
    hoverCursor: "default",
    strokeWidth: 6,
  });

  const updateStroke = (e) => {
    setOptions({
      ...options,
      stroke: e.target.value,
    });
    setProperty("stroke", e.target.value, activeObject);
  };

  const updateStrokeWidth = (e) => {
    setOptions({
      ...options,
      strokeWidth: e.target.value,
    });
    setProperty("strokeWidth", parseInt(e.target.value, 10), activeObject);
  };

  useEffect(() => {
    if (activeObject) {
      const activeOptions = {
        stroke: getActiveStyle("stroke", activeObject),
        strokeWidth: getActiveStyle("strokeWidth", activeObject),
      };
      setOptions({ ...options, ...activeOptions });
    }
  }, [activeObject]);
  return (
    <div>
      <label htmlFor="strokeWidth">Stroke Width: </label>
      <input
        type="number"
        style={{ width: "50px" }}
        className="btn-object-action"
        name="strokeWidth"
        min="1"
        value={options.strokeWidth}
        onChange={updateStrokeWidth}
      />
      <br />
      <label htmlFor="color">Stroke: </label>
      <input
        type="color"
        name="stroke"
        style={{ width: "50px" }}
        value={options.stroke}
        onChange={updateStroke}
        className="btn-object-action"
      />
      <br />
    </div>
  );
}

export default LineToolbox;

import { Route, Routes } from "react-router";
import "./App.scss";
import Editor from "./components/Editor/Editor";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Forgot from "./components/Auth/Forgot";
import VerifyEmail from "./components/Auth/VerifyEmail";
import Profile from "./components/Auth/Profile";
import TermsOfUse from "./components/Common/TermsOfUse";
import Policies from "./components/Common/Policies";
import Dashboard from "./components/Dashboard/Dashboard";
import ProjectList from "./components/Projects/ProjectList";
import ProjectCreate from "./components/Projects/ProjectCreate";
import ProjectUpdate from "./components/Projects/ProjectUpdate";
import NoMatch from "./NoMatch";
import ProtectedRoute from "./components/Utils/ProtectedRoute";
import { useEffect, useState } from "react";
import AccountEdit from "./components/User/AccountEdit";
import VerifyMobile from "./components/Auth/VerifyMobile";
import VerifyMobileOtp from "./components/Auth/VerifyMobileOtp";
import WelcomeScreen from "./components/Dashboard/WelcomeScreen";
import DashboardLayout from "./Pages/DashboardLayout";
import Welcome from "./components/Projects/Welcome";
import UpdatePassword from "./components/Auth/UpdatePassword";
import ProjectDetialsGrid from "./components/Projects/ProjectDetialsGrid";
import ProjectAllPostEdit from "./components/Projects/ProjectAllPostEdit";
import PrivacyPolicy from "./components/Dashboard/PrivacyPolicy";
import AccountView from "./components/User/AccountView";
import PostFullView from "./components/Projects/PostFullView";
import Test from "./components/Test";
import setToken from "./auth/auth";
import Home from "./components/Front/Home";
import Contact from "./components/Front/Contact";
import FrontLayout from "./Pages/FrontLayout";
import FrontLayoutNew from "./Pages/FrontLayoutNew";
import HomeNew from "./components/Front/HomeNew";
function App() {
  // console.disableYellowBox = true;
  useEffect(() => {
    // setToken();
  }, []);

  const user = useState(false);
  return (
    <>
      <Routes>
        <Route path="/" element={<FrontLayout />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="contact" element={<Contact />} />
        </Route>

        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<Forgot />} />
        <Route path="UpdatePassword" element={<UpdatePassword />} />
        <Route path="verifyemail" element={<VerifyEmail />} />
        <Route path="verifyMobile" element={<VerifyMobile />} />
        <Route path="verifyMobileOtp" element={<VerifyMobileOtp />} />
        <Route path="editor" element={<Editor />} />
        <Route path="profile" element={<Profile />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="privacy-policy" element={<Policies />} />
        <Route path="welcome" element={<WelcomeScreen />} />

        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index={true} element={<Dashboard />} />
          <Route path="projectCreate" element={<ProjectCreate />} />
          <Route path="postGrid" element={<ProjectDetialsGrid />} />
          <Route path="postEdit" element={<ProjectAllPostEdit />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="Profile" element={<AccountView />} />
          <Route path="ProfileEdit" element={<AccountEdit />} />
          <Route path="PostFullView" element={<PostFullView />} />
          {/* <Route path="projectUpdate/:id" element={<ProjectUpdate />} /> */}
          <Route path="welcome" element={<Welcome />} />
          <Route path="projects" element={<ProjectList />} />
          <Route path="projectUpdate/:project_id" element={<ProjectUpdate />} />
          <Route path="test" element={<Test />} />

          {/* <Route
            path="AccountEdit"
            element={
              <ProtectedRoute user={user}>
                <AccountEdit />
              </ProtectedRoute>
            }
          /> */}
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

export default App;

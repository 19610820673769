import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostItem from "./PostItem";
import {
  faArrowRight,
  faCalendar,
  faCircle,
  faClose,
  faDownload,
  faEnvelope,
  faImage,
  faPizzaSlice,
  faPlus,
  faShare,
  faShareNodes,
  faTrash,
  faTrashCan,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import useStore from "../../store/store";
import { useState } from "react";
import BackDropModal from "../Utils/Modal";

import MonthFilter from "./MonthFilter";
import CustomDesignFilter from "./CustomDesignFilter";
import DeleteFilter from "./DeleteFilter";
import DownloadFilter from "./DownloadFilter";
import ShareFilter from "./ShareFilter";
import GenerateMore from "./GenerateMore";

function ProjectDetialsGrid(props) {
  const n = 8; // Or something else

  let postItems;
  const instade_for_list = useStore((state) => state.postGrid_items);
  postItems = instade_for_list.map((item, index) => (
    <PostItem {...item} key={item.id}></PostItem>
  ));

  // const postItems = [...Array(n)].map((e, i) => <PostItem></PostItem>);
  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3  my-4 mx-5">
        <div class="lg:col-span-3 md:col-span-2">
          <MonthFilter></MonthFilter>
        </div>
        <div className="float-right text-right lg:col-span-1 md:col-span-1 lg:pr-5  pt-3">
          <CustomDesignFilter></CustomDesignFilter>
          <DeleteFilter></DeleteFilter>
          <DownloadFilter></DownloadFilter>
          <ShareFilter></ShareFilter>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-3 ml-6 mr-10">{postItems}</div>
      <GenerateMore></GenerateMore>
    </>
  );
}

export default ProjectDetialsGrid;

export const createRegisterSlice = (set) => ({
  Regemail: "thiru",
  Regpassword: "",
  Regfname: "",
  Reglname: "",
  RegmobileNumber: "",
  addRegEmail: () => set((state) => ({ Regemail: state.Regemail })),
  addRegPassword: () => set((state) => ({ Regpassword: state.Regpassword })),
  addFname: () => set((state) => ({ Regfname: state.fname })),
  addLname: () => set((state) => ({ Reglname: state.lname })),
  addRegMobile: () =>
    set((state) => ({ RegmobileNumber: state.RegmobileNumber })),
});

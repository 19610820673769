import {
  faCalendar,
  faCircle,
  faPlus,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import BackDropModal from "../Utils/Modal";
import useStore from "../../store/store";
import { useNavigate } from "react-router";

function CustomDesignFilter(props) {
  const [shareStep, setShareStep] = useState(1);
  const [customDesignshow, setcustomDesignshow] = useState(false);

  const customDesignDisplay = () => {
    setcustomDesignshow(true);
  };
  const onConfirm = () => {
    setcustomDesignshow(false);
  };
  let special_days_items;
  const special_days_list = useStore((state) => state.specialDays);
  special_days_items = special_days_list.map((item, index) => (
    <li className="w-full inline-block">
      <input
        type="checkbox"
        id={item.id}
        value=""
        className="hidden peer"
        required=""
      />
      <label
        htmlFor={item.id}
        className="inline-block items-center justify-between w-full text-gray-500 bg-white border-2 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 text-xs peer-checked:bg-[#6659E3] peer-checked:text-white p-1"
      >
        <div className="inline-block w-full text-[8px]">
          <FontAwesomeIcon icon={faCircle} className="mx-2"></FontAwesomeIcon>
          {item.name}
        </div>
      </label>
    </li>
  ));

  let navigate = useNavigate();
  const [inputFields, setInputFields] = useState({
    special_day: "",
    selected_date: "",
    design_type: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues) => {
    const re = /^[0-9\b]+$/;
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    let errors = {};

    if (!inputValues.special_day) {
      errors.special_day = "Please Select Specialday";
    }
    if (!inputValues.selected_date) {
      errors.selected_date = "Please Select Date";
    }
    if (!inputValues.design_type) {
      errors.design_type = "Please Enter design type";
    }

    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // setErrors(validateValues(inputFields));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));

    setSubmitting(true);
    //
  };
  const finishSubmit = () => {
    const more_designs_filter_data = {
      special_day: inputFields.special_day,
      selected_date: inputFields.selected_date,
      design_type: inputFields.design_type,
    };
    console.log(more_designs_filter_data);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <>
      <button
        className=" rounded-3xl  py-2 mr-3 h-8  w-36 text-xs text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
        style={{ backgroundColor: "#6659e3" }}
        onClick={customDesignDisplay}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="mr-1"
          style={{ height: "10px" }}
        ></FontAwesomeIcon>
        Custom Design
      </button>
      {customDesignshow && (
        <>
          <BackDropModal onConfirmed={onConfirm}>
            <form className="w-[750px]">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none z-50">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-md font-semibold">Add More Designs</h3>
                  <button
                    className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => onConfirm(false)}
                  >
                    <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form className="">
                    <div className="relative mb-4">
                      <input
                        type="text"
                        id="floating_outlined"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floating_outlined"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        style={{ backgroundColor: "#FBFBFD" }}
                      >
                        Enter What you want to Design (Ex: Posts for Budha
                        Purniam) *
                      </label>
                    </div>
                    <div className="relative mb-4 ">
                      <div className="absolute top-2/4 right-3 grid h-5 w-5 -translate-y-2/4 place-items-center text-blue-gray-500">
                        <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                      </div>
                      <input
                        className="peer h-full w-full rounded-[7px] border border-gray-300 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#6659E3] focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder=" "
                      />
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-300 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-300 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#6659E3] peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-[#6659E3] peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-[#6659E3] peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Select Date *
                      </label>
                    </div>

                    <div className="relative flex py-5 items-center">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="flex-shrink mx-2 text-xs text-gray-400">
                        OR
                      </span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>
                    <div className="w-96 m-auto">
                      <div className="grid grid-cols-3 gap-0 items-center content-center text-center my-5">
                        <div
                          className={`block ${
                            shareStep === 1
                              ? "text-[#6659E3] border-collapse border-b-2  border-b-[#6659E3]"
                              : "text-gray-200 "
                          } w-full p-1 text-center cursor-pointer `}
                          onClick={() => setShareStep(1)}
                        >
                          Special Days
                        </div>
                        <div
                          className={`block ${
                            shareStep === 2
                              ? "text-[#6659E3] border-collapse border-b-2  border-b-[#6659E3]"
                              : "text-gray-200"
                          } w-full p-1 text-center cursor-pointer  `}
                          onClick={() => setShareStep(2)}
                        >
                          Product Categories
                        </div>
                        <div
                          className={`block ${
                            shareStep === 3
                              ? "text-[#6659E3] border-collapse border-b-2  border-b-[#6659E3]"
                              : "text-gray-200"
                          } w-full p-1 text-center cursor-pointer  `}
                          onClick={() => setShareStep(3)}
                        >
                          Product & Service
                        </div>
                      </div>
                    </div>
                    {shareStep == 1 && (
                      <>
                        <h2 className="text-xs font-bold">
                          Special Days in May 2023
                        </h2>
                        <ul className=" flex  gap-1 my-4">
                          {special_days_items}
                        </ul>
                      </>
                    )}
                    {shareStep == 2 && (
                      <>
                        <h2 className="text-xs font-bold">Categories</h2>
                        <div className="relative mb-4">
                          <input
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            style={{ backgroundColor: "#FBFBFD" }}
                          >
                            Enter Category *
                          </label>
                        </div>
                      </>
                    )}
                    {shareStep == 3 && (
                      <>
                        <h2 className="text-xs font-bold">Product & Service</h2>
                        <div className="relative mb-4">
                          <input
                            type="text"
                            id="floating_outlined"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6659E3] peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#6659E3] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            style={{ backgroundColor: "#FBFBFD" }}
                          >
                            Enter Product / Service Name *
                          </label>
                        </div>
                      </>
                    )}
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    // onClick={dashboardScreen}
                    type="submit"
                    // onClick={() => onConfirm(false)}
                    className=" rounded-3xl block py-2 w-48 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                    style={{ backgroundColor: "#6659e3" }}
                  >
                    Generate Designs
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className="ml-2"
                    />
                  </button>
                </div>
              </div>
              <div
                className="opacity-25 fixed inset-0 z-40 bg-black"
                onClick={onConfirm}
              ></div>
            </form>
          </BackDropModal>
        </>
      )}
    </>
  );
}

export default CustomDesignFilter;

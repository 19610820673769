import FabricGradient from "./FabricGradient";
import FabricLine from "./FabricLine";
import FabricRect from "./FabricRect";
import FabricShape from "./FabricShapes";

function ObjectsPanel() {
  return (
    <div className="p-4 h-screen overflow-y-scroll">
      <div>
        <h3 className="text-[12px] text-center font-bold mb-1">Backgrounds</h3>
        <input
          style={{ background: "#fff" }}
          type="tel"
          placeholder="Search"
          className="w-full h-6"
        />
      </div>
      <div className="grid  grid-cols-1 gap-2 w-full pt-4  ">
        <p className="text-[10px]">Lines & Shapes</p>
        <div className="inline-flex gap-2  text-[#C5C7D0] " role="group">
          <FabricLine></FabricLine>
          <FabricRect></FabricRect>
          <FabricShape></FabricShape>
          <FabricGradient></FabricGradient>
        </div>
      </div>
    </div>
  );
}
export default ObjectsPanel;

import { useContext } from "react";
import DefaultToolbox from "./DefaultToolbox/DefaultToolbox";
import TextToolbox from "./TextToolbox/TextToolbox";

import "./Toolbox.scss";
import { CanvasContext } from "../../Canvas/store/CanvasContext";
import LineToolbox from "./ObjectToolbox/LineToolbox";
import useCoreHandler from "../../Canvas/handlers/useCoreHandler";
import ImageToolbox from "./ImageToolbox/ImageToolbox";
import AnimationObjects from "./Animation/Animation";
import Eraser from "./Eraser/Eraser";
import CommonTools from "./Common/CommonTools";

function Toolbox() {
  const { activeObject } = useContext(CanvasContext);
  const { removeObjects } = useCoreHandler();
  if (!activeObject) {
    return (
      <>
        {/* <DefaultToolbox /> */}
        <TextToolbox removeSelected={removeObjects} />
        {/* <Eraser></Eraser> */}
      </>
    );
  }
  const activeObjectType = activeObject.type;
  // console.log(activeObjectType);

  return (
    <div>
      {activeObjectType === "textbox" && (
        <TextToolbox removeSelected={removeObjects} />
      )}
      {activeObjectType === "line" && <LineToolbox />}
      {activeObjectType === "image" && <ImageToolbox />}
      {/* <AnimationObjects></AnimationObjects> */}
      {/* <CommonTools></CommonTools> */}
      {/* <button onClick={removeObjects}>Delete Selected</button> */}
    </div>
  );
}

export default Toolbox;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import facebook from "../../../../assets/images/social/facebook.png";
import google_photos from "../../../../assets/images/social/google_photos.png";
import dropbox from "../../../../assets/images/social/dropbox.png";
import google_drive from "../../../../assets/images/social/google_drive.png";

import instagram from "../../../../assets/images/social/instagram.png";
import { faCloudUpload, faUpload } from "@fortawesome/free-solid-svg-icons";

function UploadsPanel() {
  return (
    <div>
      <div className="grid p-3">
        <h3 className="grid-col text-[11px] items-center text-center font-semibold">
          Upload Files
        </h3>
        <div className="grid-col">
          <div className="">
            <label
              className="grid justify-center w-full h-22  transition bg-[#EEF1FF]   border-dashed 
                            appearance-none cursor-pointer hover:border-gray-400 focus:outline-none text-center  my-4 p-5 "
            >
              <p className=" font-light text-[10px] text-gray-400">
                Drag Media here to upload or conntect an account
              </p>
              <p className="text-[#6659E3] font-semibold text-[10px] ">
                <button>
                  <FontAwesomeIcon icon={"facebook"}></FontAwesomeIcon>
                </button>
              </p>
              <input type="file" name="file_upload" className="hidden" />
              <div className=" flex flex-col justify-center items-center">
                <div
                  className="inline-flex rounded-full shadow-sm  "
                  role="group"
                >
                  <button
                    data-tooltip-target="button-group-icons-example-tablet-tooltip"
                    class="flex items-center justify-center w-5 h-5 text-xs font-medium text-gray-700 bg-red border-4 border-[#3B5998]  toggle-tablet-view hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mr-3"
                  >
                    <img
                      src={facebook}
                      className=" hover:cursor-pointer"
                      alt="logo"
                    />
                  </button>
                  <button
                    data-tooltip-target="button-group-icons-example-tablet-tooltip"
                    class="flex items-center justify-center w-5 h-5 text-xs font-medium text-gray-700 bg-red border-4 border-white   toggle-tablet-view hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400  dark:hover:text-white   mr-3"
                  >
                    <img
                      src={google_drive}
                      className=" hover:cursor-pointer"
                      alt="logo"
                    />
                  </button>
                  <button
                    data-tooltip-target="button-group-icons-example-tablet-tooltip"
                    class="flex items-center justify-center w-5 h-5 text-xs font-medium text-gray-700 bg-red border-4 border-[#E06382]  toggle-tablet-view hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700  mr-3"
                  >
                    <img
                      src={instagram}
                      className=" hover:cursor-pointer"
                      alt="logo"
                    />
                  </button>
                  <button
                    data-tooltip-target="button-group-icons-example-tablet-tooltip"
                    class="flex items-center justify-center w-5 h-5 text-xs font-medium text-gray-700 bg-red border-4 border-[#1976D2]  toggle-tablet-view hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700  mr-3"
                  >
                    <img
                      src={dropbox}
                      className=" hover:cursor-pointer"
                      alt="logo"
                    />
                  </button>
                  <button
                    data-tooltip-target="button-group-icons-example-tablet-tooltip"
                    class="flex items-center justify-center w-5 h-5 text-xs font-medium text-gray-700 bg-red border-4 border-white  toggle-tablet-view hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700  mr-3"
                  >
                    <img
                      src={google_photos}
                      className=" hover:cursor-pointer"
                      alt="logo"
                    />
                  </button>
                </div>
              </div>
            </label>
          </div>
          <div className="items-center content-center text-center">
            <button className=" items-center block  mx-auto w-48 py-1 font-semibold text-black border-[1px] border-black rounded-sm  outline-none">
              <FontAwesomeIcon
                icon={faCloudUpload}
                className="mr-2"
                style={{ height: "14px" }}
              ></FontAwesomeIcon>
              <span className="text-[12px]">Upload from computer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UploadsPanel;

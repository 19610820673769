import React, { useState } from "react";
import BackDropModal from "../Utils/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function GenerateMore(props) {
  const [generatemore, setgeneratemore] = useState(false);
  const generateMoreDisplay = () => {
    setgeneratemore((prevValue) => !prevValue);
  };
  const designs_count = 10; // Or something else

  const designs = [...Array(designs_count)].map((e, i) => (
    <option value={i + 1}>{i + 1}</option>
  ));
  return (
    <>
      <div className="text-center m-6 ">
        <button
          type="submit"
          onClick={generateMoreDisplay}
          className=" rounded-3xl block py-1 m-auto w-36 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
          style={{ backgroundColor: "#6659e3" }}
        >
          Generate More
        </button>
      </div>
      {generatemore && (
        <BackDropModal
          onConfirmed={generateMoreDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-bold">Generate Files</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => generateMoreDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="">
                <h2 className="text-xl text-center mx-10">
                  Do you want to Generate More?
                </h2>
                <div className="mt-4">
                  <label className="text-xs font-bold">
                    Select Number of Files
                  </label>
                  <select className="w-full border border-gray-400 py-3 px-2  text-gray-500 hover:border-[#6659E3] text-sm">
                    {/* <option value="">10</option>
                    <option value="Web Development">20</option> */}
                    {designs}
                  </select>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => generateMoreDisplay(false)}
                className=" rounded-3xl block py-2 w-48 text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Generate
                <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" />
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={generateMoreDisplay}
          ></div>
        </BackDropModal>
      )}
    </>
  );
}

export default GenerateMore;

import React, { useEffect, useState } from "react";
import "./Register.scss";
import logo from "../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import Login from "./Login";
import { GoogleLogin } from "@react-oauth/google";
import { registerUser } from "../../auth/auth";
import useStore from "../../store/store";
import Input from "../Utils/Input";
import useInput from "../../Hooks/use-input";

function Register() {
  let navigate = useNavigate();
  /*  const [inputFields, setInputFields] = useState({
    email: "",
    password: "",
    fname: "",
    lname: "",
    confirmpwd: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    fname: false,
    lname: false,
    password: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues) => {
    const re = /^[0-9\b]+$/;
    const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    let errors = {};
    if (!reg_email.test(inputValues.email)) {
      errors.email = "Email is invalid";
    }
    if (!inputValues.email) {
      errors.email = "Please Enter Email address";
    }

    if (!inputValues.fname) {
      errors.fname = "Please enter First Name";
    }
    if (re.test(inputValues.fname)) {
      errors.fname = "Only allow alphabets only";
    }
    if (!inputValues.lname) {
      errors.lname = "Please enter Last Name";
    }
    if (re.test(inputValues.lname)) {
      errors.lname = "Only allow alphabets only";
    }

    if (!reg_password.test(inputValues.password)) {
      errors.password =
        "1.Capital Letter 2.small Letters 3.Number 4.Special Character";
      // errors.confirmpwd =
      //   "1.Capital Letter 2.small Letters 3.Number 4.Special Character";
    }
    // if (inputValues.password !== inputValues.confirmpwd) {
    //   errors.password = "password and confirm password should match";
    // }
    // if (inputValues.password !== inputValues.confirmpwd) {
    //   errors.confirmpwd = "password and confirm password should match";
    // }
    if (inputValues.password.length < 8) {
      errors.password = "Password is too short";
    }
    if (!inputValues.password) {
      errors.password = "Please enter password";
    }
    return errors;
  };
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));

    useStore.setState({ Regemail: inputFields.email });

    setSubmitting(true);

    //     try {
    //   localStorage.clear();
    //   var jwtres = await registerUser(inputFields);
    //   console.log(jwtres);
    //   if (jwtres) {
    //     setAlertstate({
    //       open: true,
    //       vertical: "bottom",
    //       horizontal: "center",
    //       message: "Registration Successfully.",
    //       severity: "success",
    //     });
    //     return navigate("/verifyemail");
    //   } else {
    //     setAlertstate({
    //       open: true,
    //       vertical: "bottom",
    //       horizontal: "center",
    //       message: "Registration failed. Please try again.",
    //       severity: "error",
    //     });
    //   }
    // } catch (e) {
    //   setAlertstate({
    //     open: true,
    //     vertical: "bottom",
    //     horizontal: "center",
    //     message: "Invalid Data. Please try again.",
    //     severity: "error",
    //   });
    // }
  };
  const finishSubmit = () => {
    console.log(inputFields);
    // return navigate("/login");
    return navigate("/verifyemail");
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]); */

  const re = /^[0-9\b]+$/;
  const reg_email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const reg_password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const isNotEmpty = (value) => value.trim() !== "";
  const isTest = (value) => value;
  const isAlphabet = (value) => {
    if (re.test(value)) {
      return false;
    }
    return true;
  };
  const isAlphabetMandate = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (re.test(value)) {
      return false;
    }
    return true;
  };

  const isPasswordMandate = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (value.length < 8) {
      return false;
    }

    if (!reg_password.test(value)) {
      return false;
    }
    return true;
  };

  const isEmailMandate = (value) => {
    if (!isNotEmpty(value)) {
      return false;
    }
    if (!value.includes("@")) {
      return false;
    }
    if (!reg_email.test(value)) {
      return false;
    }
    return true;
  };
  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isAlphabetMandate);
  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(isAlphabet);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmailMandate);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(isPasswordMandate);
  let formIsValid = false;

  if (firstNameIsValid && emailIsValid && passwordIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      firstNameBlurHandler();

      emailBlurHandler();
      passwordBlurHandler();
      return;
    }
    useStore.setState({ Regemail: emailValue });
    console.log("Submitted!");
    console.log(firstNameValue, lastNameValue, emailValue);
    return navigate("/verifyemail");
    resetFirstName();
    resetLastName();
    resetEmail();
    resetPassword();
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const redirectHome = () => {
    return navigate("/");
  };

  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className=" ">
          {/* <Link to="/" className=""> */}
          <img
            src={logo}
            onClick={redirectHome}
            className="mx-auto h-[32px] w-[220px] my-9 hover:cursor-pointer"
            alt="logo"
          />
          {/* </Link> */}

          <div className="align-middle md:align-top md:pt-28 sm:pt-16">
            <h2 className=" font-semibold	 text-center mb-6 text-lg	">
              Let's get started
            </h2>
          </div>
          {/* {Object.keys(errors).length === 0 && submitting ? (
            <span className="success">Successfully Registered Login Now✓</span>
          ) : null} */}
          <form onSubmit={handleSubmit}>
            <div className="space-y-4 ">
              {/* <div className="flex flex-row ">
                <input
                  type="text"
                  name="fname"
                  placeholder="First Name *"
                  value={inputFields.fname}
                  onChange={handleChange}
                  className={
                    errors.fname
                      ? "w-36 basis-1/2 block mr-2 text-sm py-3 px-4   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                      : "w-36 basis-1/2 block mr-2 text-sm py-3 px-4   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.fname ? errors.fname : ""}
                />
                
                <input
                  type="text"
                  name="lname"
                  placeholder="Last Name *"
                  value={inputFields.lname}
                  onChange={handleChange}
                  className={
                    errors.lname
                      ? "w-36 basis-1/2 block mr-2 text-sm py-3 px-4   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                      : "w-36 basis-1/2 block mr-2 text-sm py-3 px-4   border outline-none  focus:outline-none  placeholder:text-slate-600"
                  }
                  title={errors.lname ? errors.lname : ""}
                />
              </div> */}
              <div className="flex  gap-2 w-72">
                <Input
                  type="text"
                  id="fname"
                  name="fname"
                  value={firstNameValue}
                  onChange={firstNameChangeHandler}
                  onBlur={firstNameBlurHandler}
                  isValid={firstNameIsValid}
                  error={firstNameHasError}
                  title="First Name"
                  isMandate={true}
                ></Input>

                <Input
                  type="text"
                  id="lname"
                  name="lname"
                  value={lastNameValue}
                  onChange={lastNameChangeHandler}
                  onBlur={lastNameBlurHandler}
                  // isValid={lastNameIsValid}
                  error={lastNameHasError}
                  title="Last Name"
                ></Input>
              </div>
              {/* <input
                type="text"
                name="email"
                value={inputFields.email}
                onChange={handleChange}
                placeholder="Email address * "
                className={
                  errors.email
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.email ? errors.email : ""}
              /> */}
              <Input
                type="text"
                id="email"
                name="email"
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                isValid={emailIsValid}
                error={emailHasError}
                title="Email address"
                isMandate={true}
              ></Input>

              {/* <input
                type="password"
                name="password"
                value={inputFields.password}
                onChange={handleChange}
                autoComplete="false"
                placeholder="Create Password * "
                className={
                  errors.password
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.password ? errors.password : ""}
              /> */}

              {/* <Input
                type="password"
                id="password"
                name="password"
                value={inputFields.password}
                onChange={handleChange}
                error={errors.password}
                title="Create Password"
              ></Input> */}
              <Input
                type="password"
                id="password"
                name="password"
                value={passwordValue}
                onChange={passwordChangeHandler}
                onBlur={passwordBlurHandler}
                isValid={passwordIsValid}
                error={passwordHasError}
                title="Create Password"
                isMandate={true}
              ></Input>

              {/* <input
                type="password"
                name="confirmpwd"
                value={inputFields.confirmpwd}
                onChange={handleChange}
                autoComplete="false"
                placeholder="Confirm Password * "
                className={
                  errors.confirmpwd
                    ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600 border border-red-500"
                    : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                }
                title={errors.confirmpwd ? errors.confirmpwd : ""}
              /> */}
            </div>
            <div className="text-center mt-6">
              <button
                type="submit"
                className=" block py-2 w-full  text-sm uppercase  text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Create an account
              </button>
              <p className="text-xs	 text-center  mt-4">
                Already have an account?
                <Link
                  to="/login"
                  className="cursor-pointer px-2 font-bold"
                  style={{ color: "#6659e3" }}
                >
                  Log in
                </Link>
              </p>
              {/* <div className="relative flex py-5 items-center">
                <div className="flex-grow border-t border-gray-400"></div>
                <span className="flex-shrink mx-2 text-xs text-gray-400">
                  OR
                </span>
                <div className="flex-grow border-t border-gray-400"></div>
              </div> */}
            </div>
          </form>
          {/*  <GoogleLogin
            onSuccess={responseMessage}
            text="continue_with"
            size="large"
            width="400px"
            logo_alignment="center"
            onError={errorMessage}
            className="w-full"
          /> */}
          <div className=" absolute text-center w-72 items-center bottom-0 mb-2 mx-auto">
            <p className="text-center    text-xs">
              <Link
                to="/terms-of-use"
                className="cursor-pointer px-2  text-gray-600"
              >
                Terms of use
              </Link>
              |
              <Link
                to="/privacy-policy"
                className="cursor-pointer px-2  text-gray-600"
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Register;

import React from "react";
import {
  faEllipsisVertical,
  faGear,
  faPlus,
  faTrashCan,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./ProjectList.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Modal from "../Utils/Modal";
import BackDropModal from "../Utils/Modal";
import TeamPopup from "./TeamPopup";
import ProjectEditMenu from "./ProjectEditMenu";

function ProjectSingeItem(props) {
  const [EditMenu, setEditMenu] = useState(false);
  const [teampopup, setteampopup] = useState(false);
  const TeamepopupDisplay = () => {
    setteampopup((prevValue) => !prevValue);
    setEditMenu(false);
  };

  const CloseModal = () => {
    setEditMenu(false);
  };
  const EditMenuDisplay = () => {
    setEditMenu((prevValue) => !prevValue);
  };

  // const hideAllMenu = () => {
  //   setEditMenu(false);
  //   setEditMenu1(false);
  // };
  const [deletepopup, setdeletepopup] = useState(false);
  const DeletepopupDisplay = () => {
    setdeletepopup((prevValue) => !prevValue);
    setEditMenu(false);
  };
  // defaultChecked

  const selectedProject = () => {
    props.selectedProject(props.project.id);
  };
  return (
    <>
      {EditMenu && (
        <>
          <div
            id="dropdownRight"
            className=" absolute z-50 ml-52   bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              className=" text-sm text-gray-700 dark:text-gray-200 divide-y divide-gray-200"
              aria-labelledby="dropdownRightButton"
            >
              <li className="p-2">
                <Link to={`/dashboard/projectUpdate/${props.project.id}`}>
                  <div className="flex items-center space-x-4">
                    <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white"></div>
                    <div className="">
                      <p className="text-sm font-medium  truncate dark:text-white">
                        Edit Project
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="p-2">
                <button onClick={TeamepopupDisplay}>
                  <div className="flex items-center space-x-4">
                    <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white"></div>
                    <div className="">
                      <p className="text-sm font-medium  truncate dark:text-white">
                        Add Team Member
                      </p>
                    </div>
                  </div>
                </button>
              </li>
              <li className="p-2">
                <button onClick={DeletepopupDisplay}>
                  <div className="flex items-center space-x-4">
                    <div className="pl-2 inline-flex items-center text-base font-semibold  dark:text-white"></div>
                    <div className="">
                      <p className="text-sm font-medium  truncate dark:text-white">
                        Delete Project
                      </p>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-20 "
            onClick={EditMenuDisplay}
          ></div>
        </>
      )}
      <ul className="tree">
        <li className="mb-4">
          <input
            type="checkbox"
            id={props.project.id}
            onClick={selectedProject}
            checked={props.project.id === props.selectedP}
          />
          <label htmlFor={props.project.id} className="tree_label w-full">
            <div className="flex items-center space-x-2">
              <div className="flex-shrink-0">
                <img
                  className="w-5 rounded-full"
                  src="https://w7.pngwing.com/pngs/980/304/png-transparent-computer-icons-user-profile-avatar-heroes-silhouette-avatar-thumbnail.png"
                  alt="Neil "
                />
              </div>
              <div className="flex w-32">
                <p className="text-[12px] font-[600] text-gray-900 truncate dark:text-white">
                  {props.project.basic_details.project_name}
                </p>
              </div>
              <button
                id="dropdownRightButton"
                data-dropdown-toggle="dropdownRight"
                data-dropdown-placement="right"
                className="pl-6 "
                type="button"
                onClick={EditMenuDisplay}
              >
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  style={{ height: "12px" }}
                />
              </button>

              {/* <ProjectEditMenu
                projectid={props.project.id}
                EditMenuDisplay={EditMenuDisplay}
                EditMenu={EditMenu}
                TeamepopupDisplay={TeamepopupDisplay}
                DeletepopupDisplay={DeletepopupDisplay}
              ></ProjectEditMenu> */}
            </div>
          </label>

          <ul>
            <li>
              <span className="tree_label">
                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                  <Link
                    to="/dashboard/postGrid"
                    className="px-2 rounded-3xl block  text-[8px] w-10  text-white bg-[#6659e3]  hover:bg-blue-700 active:bg-blue-500 outline-none"
                  >
                    2023
                  </Link>
                </p>
              </span>
            </li>
            <li>
              <span className="tree_label">
                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                  <button
                    type="submit"
                    className=" rounded-3xl block  text-[8px] w-10  text-black bg-white   hover:bg-blue-700 active:bg-blue-500 outline-none"
                  >
                    2022
                  </button>
                </p>
              </span>
            </li>
          </ul>
        </li>
      </ul>

      {deletepopup && (
        <BackDropModal
          onConfirmed={DeletepopupDisplay}
          className="items-center justify-center "
        >
          <div className="z-50 border-0 w-96 p-5 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between  border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-md font-semibold">Delete Project?</h3>
              <button
                className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-normal outline-none focus:outline-none"
                onClick={() => DeletepopupDisplay(false)}
              >
                <span className=" text-black  h-6 w-6 text-[18px] block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative flex-auto pt-4">
              <form className="">
                <div className="text-[12px]  w-full text-center ">
                  Do you want to Delete Project Really? You will loss the
                  <br /> all images and date of this project, You can not <br />
                  get back, is it OK?
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex  p-6  rounded-b">
              <button
                // onClick={dashboardScreen}
                type="submit"
                onClick={() => DeletepopupDisplay(false)}
                className=" rounded-3xl mx-auto   py-2 w-20 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                style={{ backgroundColor: "#6659e3" }}
              >
                Yes
                {/* <FontAwesomeIcon icon={faUpRightFromSquare} className="ml-2" /> */}
              </button>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={DeletepopupDisplay}
          ></div>
        </BackDropModal>
      )}
      <TeamPopup
        setEditMenu={setEditMenu}
        teampopup={teampopup}
        TeamepopupDisplay={TeamepopupDisplay}
      ></TeamPopup>
    </>
  );
}

export default ProjectSingeItem;

import React from "react";
import { Outlet } from "react-router";
import Footer from "../components/Front/Footer";
import Menu from "../components/Front/Menu";

function FrontLayout(props) {
  return (
    <div className="contailer">
      <div
        style={{
          background:
            "rgb(201,191,229) linear-gradient(90deg, rgba(201,191,229,1) 26%, rgba(255,227,227,1) 89%)",
        }}
      >
        <Menu></Menu>
      </div>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
}

export default FrontLayout;

import {
  faClose,
  faPlus,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import useInput from "../../Hooks/use-input";
import Select from "../Utils/Select";

function MonthFilter(props) {
  const [monthslist, setMonthsList] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [months, setMonths] = useState([]);
  const [dropdownMonthshow, setDropdownMonthshow] = useState(false);
  const dropdownMonthDisplay = () => {
    setDropdownMonthshow((prevValue) => !prevValue);
  };
  const selectedMonths = (event) => {
    let filteredMonths = monthslist.filter(
      (month) => month !== event.target.value
    );
    setMonthsList(filteredMonths);
    let filteredMonthsSelected = monthslist.filter(
      (month) => month === event.target.value
    );
    setMonths(filteredMonthsSelected);
    // setMonths((prevValue) => [...prevValue, event.target.value]);
    resetSelectedMonth();
  };
  const isTest = (value) => {
    return true;
  };
  const isNotEmpty = (value) => value.trim() !== "";

  const {
    value: SelectedMonthValue,
    isValid: SelectedMonthIsValid,
    hasError: SelectedMonthHasError,
    valueChangeHandler: SelectedMonthChangeHandler,
    inputBlurHandler: SelectedMonthBlurHandler,
    reset: resetSelectedMonth,
  } = useInput(isTest);
  const removeMonth = (smonth) => {
    let filteredMonths = months.filter((month) => month !== smonth);

    setMonths(filteredMonths);
    console.log(filteredMonths);
    setMonthsList((prevValue) => [...prevValue, smonth]);
    // let filteredMonthsList = monthslist.filter(
    //   (smap) => !filteredMonths.includes(smap)
    // );

    // console.log(filteredMonthsList);

    // setMonthsList(filteredMonthsList);
  };
  return (
    <div>
      <div className="relative inline-block text-left">
        <div className="flex">
          {months.map((smonth) => (
            <button
              className=" rounded-md mr-2 mt-4  py-1 h-6 w-14 text-[12px] text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
              style={{ backgroundColor: "#6659e3" }}
              onClick={() => removeMonth(smonth)}
            >
              {smonth.substring(0, 3)}
              {/* <FontAwesomeIcon
            icon={faClose}
            className="pl-4"
            onClick={() => removeMonth(smonth)}
          ></FontAwesomeIcon> */}
            </button>
          ))}
          <button
            id="dropdownDeleteButton"
            // data-dropdown-toggle="dropdownDelete"
            type="button"
            onClick={dropdownMonthDisplay}
            className=" flex mt-3  w-28  text-[12px] text-center items-center rounded-3xl p-1 hover:text-white   hover:bg-[#6659E3] active:[#6659E3] outline-none  "
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="w-2 border rounded-full p-2 font-medium"
              style={{ height: "10px" }}
            ></FontAwesomeIcon>
            <span className="ml-2 text-[12px] font-medium ">Add Month</span>
          </button>
        </div>

        {dropdownMonthshow && (
          <>
            <div
              className={` absolute -right-20 z-50 p-5   rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
               ${dropdownMonthshow ? "block" : "hidden"} `}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div className="flex items-start justify-between  border-slate-200 rounded-t">
                <h3 className="text-[14px] font-semibold">Select Month</h3>
                <button
                  className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none  outline-none focus:outline-none"
                  onClick={() => dropdownMonthDisplay(false)}
                >
                  <span className=" text-black  h-6 w-6 text-[16px] block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="divide-y divide-gray-100 dark:divide-gray-700">
                <form className="">
                  <div className="relative ">
                    {/* <select
                      className="w-full border-[1px] border-gray-300 text-[12px]"
                      onChange={selectedMonths}
                    >
                      <option value="">Select</option>
                      {monthslist.map((monthname) => (
                        <option value={monthname}>{monthname}</option>
                      ))}
                    </select> */}

                    <Select
                      id="SelectMonth"
                      className="w-full border-[1px] border-gray-300 text-[12px]"
                      name="SelectMonth"
                      value={SelectedMonthValue}
                      onChange={selectedMonths}
                      onBlur={SelectedMonthBlurHandler}
                      isValid={SelectedMonthIsValid}
                      error={SelectedMonthHasError}
                      title="Select Month"
                      isMandate={true}
                      showtickMarks={false}
                    >
                      <option>Please Select Option</option>
                      {monthslist.map((monthname) => (
                        <option value={monthname}>{monthname}</option>
                      ))}
                    </Select>
                  </div>
                </form>
              </div>

              <div className=" block py-2 text-sm  text-center text-gray-900 rounded-b-lg  hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                <button
                  className=" rounded-3xl  py-2 m-auto w-36 text-[12px]  text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                  style={{ backgroundColor: "#6659e3" }}
                  onClick={() => dropdownMonthDisplay(false)}
                >
                  Generate Designs
                  {/*  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className="ml-4"
                  /> */}
                </button>
              </div>
            </div>
            <div
              className="opacity-25 fixed inset-0 z-40 bg-black"
              onClick={dropdownMonthDisplay}
            ></div>
          </>
        )}
      </div>
    </div>
  );
}

export default MonthFilter;

import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import email from "../../assets/images/email.png";
import "./verifyEmail.scss";

import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMailForward } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../store/store";
import useInput from "../../Hooks/use-input";
import Input from "../Utils/Input";

function VerifyEmail() {
  let navigate = useNavigate();
  const Regemail = useStore((state) => state.Regemail);

  const isNotEmpty = (value) => value.trim() !== "";

  const {
    value: verifycodeValue,
    isValid: verifycodeIsValid,
    hasError: verifycodeHasError,
    valueChangeHandler: verifycodeChangeHandler,
    inputBlurHandler: verifycodeBlurHandler,
    reset: resetverifycode,
  } = useInput(isNotEmpty);

  let formIsValid = false;

  if (verifycodeIsValid) {
    formIsValid = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      verifycodeBlurHandler();
      return;
    }
    console.log(verifycodeValue);
    return navigate("/verifyMobile");
  };

  return (
    <React.Fragment>
      <div className=" bg-white flex justify-center items-center ">
        <div className="h-full">
          <img src={logo} className="m-auto center h-24 w-70" />

          <div className="align-middle md:align-top pt-12">
            <div className="align-middle md:align-top pt-12">
              <img src={email} className="m-auto center h-24 w-34" />
              <h2 className=" 	 text-center my-8 text-lg	">Verify your email</h2>
            </div>
            <div className="w-full text-center text-xs">
              we've sent an code to <b>{Regemail}</b>,to verify your email
              address <br />
              and activate your account. Please enter code below
              <br />
              <div className=" bg-white flex justify-center items-center ">
                <form onSubmit={handleSubmit}>
                  <div className=" flex flex-row items-center gap-2 pt-2  w-full max-w-xs justify-between ">
                    <div className="w-96 h-12 ">
                      {/*  <input
                        maxLength="1"
                        
                        name="otp4"
                        type="number"
                        value={inputFields.otp4}
                        onChange={handleChange}
                        className={
                          errors.otp4
                            ? "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-red-600  border-red-500"
                            : "block text-sm py-3 px-4  w-full   border outline-none  focus:outline-none  placeholder:text-slate-600"
                        }
                        title={errors.otp4}
                      /> */}
                      <Input
                        type="text"
                        id="verifycode"
                        name="verifycode"
                        value={verifycodeValue}
                        onChange={verifycodeChangeHandler}
                        onBlur={verifycodeBlurHandler}
                        isValid={verifycodeIsValid}
                        error={verifycodeHasError}
                        title="Enter Code"
                        isMandate={true}
                      ></Input>
                    </div>
                  </div>
                  <div className="text-center mt-6">
                    <button
                      type="submit"
                      className=" block py-2 w-full text-sm text-white bg-blue-500  hover:bg-blue-700 active:bg-blue-500 outline-none"
                      style={{ backgroundColor: "#6659e3" }}
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
              <br />
              <Link
                to="/register"
                className="cursor-pointer px-2 font-bold text-blue-600"
              >
                Click here
              </Link>
              if you did not receive an email or would like ot change the email
              address
              <br /> you signed up with.
            </div>
          </div>
          <div className="mt-6 bottom-0">
            <p className="text-center  inset-x-0 bottom-0 text-xs">
              <Link
                to="/terms-of-use"
                className="cursor-pointer px-2 font-bold text-gray-600"
              >
                Terms of use
              </Link>
              |
              <Link
                to="/privacy-policy"
                className="cursor-pointer px-2 font-bold text-gray-600"
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VerifyEmail;
